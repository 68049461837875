import React from 'react';
import classes from './FileLoader.module.scss';

const FileLoader = props => {
    return (
        <div className={classes.FileLoader} style={props.style}>
            <span/>
        </div>
    )
}


export default FileLoader