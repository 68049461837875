export function isChatDisabled(role, status) {
    switch (status) {
        case "created":
            return true;
        case "moderation":
            return (role === "doctor");
        case "accepted":
            return false;
        case "in_work":
            return false;
        case "paused":
            return true;
        case "waiting_for_payment":
            return false;
        case "finished":
            return true;
        case "declined":
            return true;
        default:
            return true;
    }
}