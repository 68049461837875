export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_ERROR = "GET_SERVICES_ERROR";
export const GET_LOG_FILE_SUCCESS = "GET_LOG_FILE_SUCCESS";
export const GET_LOG_FILE_ERROR = "GET_LOG_FILE_ERROR";
export const GET_SPECIALIZATIONS_SUCCESS = "GET_SPECIALIZATIONS_SUCCESS";
export const GET_SPECIALIZATIONS_ERROR = "GET_SPECIALIZATIONS_ERROR";
export const GET_PERFORMERS_SUCCESS = "GET_PERFORMERS_SUCCESS";
export const GET_PERFORMERS_ERROR = "GET_PERFORMERS_ERROR";
export const GET_CREATORS_SUCCESS = "GET_CREATORS_SUCCESS";
export const GET_CREATORS_ERROR = "GET_CREATORS_ERROR";
export const SET_SCROLL_STATUS = "SET_SCROLL_STATUS";
