import React from 'react';

import Logo from 'components/Logo/Logo';

import classes from './Container.module.scss';

const Container = props => {

    const cls = [classes.inner];
    if (props.innerClassName) {
        cls.push(props.innerClassName);
    }

    return (
        <div className={classes.Container}>
            <Logo/>
            <div className={cls.join(' ')}>
              {props.children}  
            </div> 
        </div>
    )
}


export default Container