import React, { Component } from "react";

import Button from "components/UI/Button/Button";

import classes from "./DoctorProfile.module.scss";
import { getDoctorInfoByID } from "store/Profile/Doctors/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { formatExperienceYears } from "../../../../components/UI/helpers";

class DoctorProfile extends Component {
  render() {
    const cls = [classes.DoctorProfile];
    if (this.props.type === "full") {
      cls.push(classes.full);
    }

    if (this.props.type === "small") {
      cls.push(classes.small);
    }
    return (
      <section className={cls.join(" ")}>
        <div className={classes.header}>
          <div className={classes.main_info}>
            <div className={classes.specialization}>
              {this.props.doctor.doctor_profile.specialization.title}
            </div>
            <div className={classes.name}>
              {this.props.doctor.profile.second_name}{" "}
              {this.props.doctor.profile.first_name}{" "}
              {this.props.doctor.profile.patronymic_name || ""}
            </div>
            <div className={classes.category}>
              {this.props.doctor.doctor_profile.qualification_category}
            </div>
            {this.props.doctor.doctor_profile
              .medical_speciality_work_experience && (
              <div className={classes.experience}>
                Стаж&nbsp;
                {formatExperienceYears(
                  this.props.doctor.doctor_profile
                    .medical_speciality_work_experience
                )}
                &nbsp;
              </div>
            )}
          </div>
          <div
            className={classes.photo}
            style={{
              backgroundImage: `url("${this.props.doctor.profile.userpic_url}")`,
            }}
          />
        </div>
        {this.props.type === "full" ? (
          <div className={classes.header_btn}>
            <Link to={"/profile/new_consultation/" + this.props.doctor.id}>
              <Button type={"primary"}>Получить консультацию</Button>
            </Link>
            {this.props.doctor.doctor_profile.bookform_url ? (
              <a
                href={this.props.doctor.doctor_profile.bookform_url}
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <Button type={"additional"}>Записаться на прием</Button>
              </a>
            ) : null}
          </div>
        ) : null}
        <div className={classes.education}>
          <div className={classes.education__title}>ОБРАЗОВАНИЕ</div>
          <div className={classes.container}>
            <div className={classes.education__item}>
              <div className={classes.education__type}>
                Основное образование:
              </div>
              <div className={classes.education__text}>
                {this.props.doctor.doctor_profile.main_education}
              </div>
            </div>
            {this.props.doctor.doctor_profile.internship ? (
              <div className={classes.education__item}>
                <div className={classes.education__type}>Интернатура:</div>
                <div className={classes.education__text}>
                  {this.props.doctor.doctor_profile.internship}
                </div>
              </div>
            ) : null}
            {this.props.doctor.doctor_profile.internship ? (
              <div className={classes.education__item}>
                <div className={classes.education__type}>Ординатура:</div>
                <div className={classes.education__text}>
                  {this.props.doctor.doctor_profile.residency}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {this.props.doctor.doctor_profile.further_education.passing_date ? (
          <div className={classes.qualification}>
            <div className={classes.qualification__title}>
              Повышение квалификации
            </div>
            <div className={classes.container}>
              {this.props.doctor.doctor_profile.further_education.map(
                (item) => {
                  return (
                    <div className={classes.qualification__item}>
                      <div className={classes.qualification__year}>
                        {/* только год */}
                        {item.passing_date.slice(6, 10)}&nbsp;г.
                      </div>
                      <div className={classes.qualification__text}>
                        {item.title}
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        ) : null}
        {this.props.doctor.doctor_profile.description ? (
          <div className={classes.skills}>
            <div className={classes.skills__title}>Профессиональные навыки</div>
            <div className={classes.container}>
              <div className={classes.skills__item}>
                {this.props.doctor.doctor_profile.description}
              </div>
            </div>
          </div>
        ) : null}
        {this.props.type === "small" ? (
          <div className={classes.footer_btn}>
            <Link
              to={
                this.props.role === "patient"
                  ? `/profile/new_consultation/${this.props.doctor.id}`
                  : "/logout"
              }
            >
              <Button type={"primary"}>Получить консультацию</Button>
            </Link>
            {this.props.doctor.doctor_profile.bookform_url ? (
              <a
                href={this.props.doctor.doctor_profile.bookform_url}
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <Button type={"additional"}>Записаться на прием</Button>
              </a>
            ) : null}
          </div>
        ) : null}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    doctorInfo: state.doctors.editedDoctorProfile,
    role: state.profile.role,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getDoctorInfoByID: (id) => dispatch(getDoctorInfoByID(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorProfile);
