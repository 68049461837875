import {
  GET_INVITES_SUCCESS,
  GET_INVITES_ERROR,
  GET_LOG_FILE_SUCCESS,
  GET_LOG_FILE_ERROR,
  CREATE_INVITE_SUCCESS,
  CREATE_INVITE_ERROR,
  CLEAR_CREATE_INVITE_STATUS,
} from "./actionTypes";

const initialState = {
  invitesList: null,
  invitesTotal: 0,
  invitesListStatus: null,
  createInviteStatus: null,
  file: null,
  logFileStatus: null,
};

export default function invitesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INVITES_SUCCESS:
      return {
        ...state,
        invitesList: action.data.items,
        invitesTotal: action.data.total,
        invitesListStatus: "SUCCESS",
      };
    case GET_INVITES_ERROR:
      return {
        ...state,
        invitesListStatus: "ERROR",
      };
    case GET_LOG_FILE_SUCCESS:
      return {
        ...state,
        file: action.data,
        logFileStatus: "SUCCESS",
      };
    case GET_LOG_FILE_ERROR:
      return {
        ...state,
        file: null,
        logFileStatus: "ERROR",
      };
    case CREATE_INVITE_SUCCESS:
      return {
        ...state,
        createInviteStatus: action.createInviteStatus,
      };
    case CREATE_INVITE_ERROR:
      return {
        ...state,
        createInviteStatus: action.createInviteStatus,
      };
    case CLEAR_CREATE_INVITE_STATUS:
      return {
        ...state,
        createInviteStatus: null,
      };
    case "AUTH_LOGOUT":
      return {
        ...state,
        invitesList: null,
      };
    default:
      return state;
  }
}
