import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import BackLink from "components/UI/BackLink/BackLink";
import ModalWindow from "components/UI/ModalWindow/ModalWindow";
import ModalContent from "components/UI/ModalWindow/SuccessErrorMessage/SuccessErrorMessage";
import Button from "components/UI/Button/Button";
import GenderControl from "components/UI/GenderControl/GenderControl";
import Input from "components/UI/Input/Input";
import Scroller from "hoc/CustomScrollbar/Scroller";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";

import { validateControl } from "containers/Auth/Auth";
import { convertDateToString } from "components/Profile/dateConverter";
import classes from "./NewSupport.module.scss";
import {
  createSupport,
  loadPicture,
  removePicture,
  clearCreateSupportStatus,
} from "store/Profile/Supports/actions";

let modalTimeout;
class NewSupport extends Component {
  state = {
    isUserpicLoading: false,
    scrollTrigger: false,
    scrollTop: null,
    isAddressesMatch: false,
    initialState: null,
    accountInfoValid: true,
    accountInfoTouched: false,
    passwordInputValid: false,
    passwordMatch: false,
    personalData: {
      second_name: {
        placeholder: "Фамилия",
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        label: "Фамилия",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      first_name: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Имя",
        label: "Имя",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      patronymic_name: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "Отчество",
        label: "Отчество",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {},
      },
      gender: {
        value: "male",
        touched: false,
        valid: true,
      },
      date_of_birth: {
        value: null,
        type: "date",
        errorMessage: "Введите корректное значение",
        placeholder: "Дата Рождения",
        label: "Дата Рождения",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      registration_address: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес регистрации",
        label: "Адрес регистрации",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      real_address: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес места жительства",
        label: "Адрес места жительства",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      email: {
        value: "",
        type: "email",
        errorMessage: "",
        placeholder: "Введите почту",
        label: "Почта",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: { required: true, email: true },
      },
      password: {
        value: "",
        type: "password",
        errorMessage:
          "Пароль должен содержать не менее 8 символов, цифры и латинские буквы, не должен являться словарным словом или набором символов, находящихся рядом на клавиатуре",
        placeholder: "Пароль",
        label: "Пароль",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
          minLength: 8,
        },
      },
      repeatPassword: {
        value: "",
        type: "password",
        errorMessage: "Пароли должны совпадать",
        placeholder: "Повторите пароль",
        label: "Повторите пароль",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {},
      },
      userpic: {
        touched: false,
        valid: true,
      },
    },
    modalShow: false,
  };
  // модалка
  modalShowOpen = () => {
    this.setState({ modalShow: true });
  };
  modalShowClose = () => {
    this.setState({ modalShow: false });
    this.props.clearCreateSupportStatus();
  };
  componentDidMount() {
    // записываем начальный стэйт
    let newState = { ...this.state };
    newState.initialState = { ...this.state };
    this.setState({ ...newState });
  }
  componentDidUpdate(prevProps, prevState) {
    // открываем модалку со статусом отправки запроса
    if (prevProps.createSupportStatus !== this.props.createSupportStatus) {
      // стираем данные, если запрос успешен
      if (this.props.createSupportStatus === "SUCCESS") {
        this.clearState();
        this.modalShowOpen();
      } else if (this.props.createSupportStatus === "ERROR") {
        this.modalShowOpen();
      }
    }
    // Дублируем адреса
    if (
      prevState.isAddressesMatch === false &&
      this.state.isAddressesMatch === true
    ) {
      const formControls = { ...this.state.personalData };
      const real_address = { ...formControls["real_address"] };
      const registration_address = { ...formControls["registration_address"] };
      real_address.value = registration_address.value;
      real_address.touched = true;
      registration_address.touched = true;
      registration_address.valid = validateControl(
        registration_address.value,
        registration_address.validation
      );
      real_address.valid = registration_address.valid;
      formControls.real_address = real_address;
      formControls.registration_address = registration_address;
      this.setState({
        personalData: formControls,
        accountInfoTouched: true,
      });
    }
    if (prevProps.userpic_url !== this.props.userpic_url) {
      this.setState({ isUserpicLoading: false });
    }
  }
  componentWillUnmount() {
    clearTimeout(modalTimeout);
  }
  // добавление фото
  addImageHadler = (event) => {
    this.props.loadPicture(event.target.files[0]);
    this.setState({
      accountInfoTouched: true,
      isUserpicLoading: true,
    });
  };
  removeImageHandler = () => {
    this.props.removePicture();
  };
  // работа с инпутами
  clearState = () => {
    let clearedState = { ...this.state.initialState };
    clearedState.initialState = { ...this.state.initialState };
    this.setState({ ...clearedState });
  };
  onChangeInputHandler = (event, controlName) => {
    const formControls = { ...this.state.personalData };
    const control = { ...formControls[controlName] };

    if (control.type === "address") {
      control.value = event;
      // для инпутов с типом адрес значение хранится в event (костыль)
    } else {
      control.value = event.target.value;
    }
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);

    if (controlName === "registration_address" && this.state.isAddressesMatch) {
      const real_address = { ...formControls["real_address"] };
      real_address.value = control.value;
      real_address.touched = true;
      real_address.valid = control.valid;
      formControls["real_address"] = real_address;
    }

    formControls[controlName] = control;

    let accountInfoTouched = true;
    let accountInfoValid = true;
    Object.keys(formControls).forEach((key) => {
      if (!formControls[key].valid) {
        accountInfoValid = false;
      }
    });
    this.setState({
      accountInfoTouched: accountInfoTouched,
      accountInfoValid: accountInfoValid,
      personalData: formControls,
    });
  };
  onChangeDateHandler = (date) => {
    const formControls = { ...this.state.personalData };
    let personalData = { ...this.state.personalData };
    personalData.date_of_birth.value = date;
    personalData.date_of_birth.touched = true;
    personalData.date_of_birth.valid = validateControl(
      personalData.date_of_birth.value,
      personalData.date_of_birth.validation
    );

    let accountInfoValid = true;
    Object.keys(formControls).forEach((key) => {
      if (!formControls[key].valid) {
        accountInfoValid = false;
      }
    });
    this.setState({
      accountInfoTouched: true,
      accountInfoValid: accountInfoValid,
      personalData: personalData,
    });
  };
  genderChangeHandler = (gender) => {
    let personalData = { ...this.state.personalData };
    personalData.gender.value = gender;
    personalData.gender.touched = true;
    let accountInfoTouched = true;
    this.setState({
      accountInfoTouched: accountInfoTouched,
      personalData: personalData,
    });
  };
  onChangeInputPassHandler = (event, controlName) => {
    const formControls = { ...this.state.personalData };
    const control = { ...formControls[controlName] };

    control.value = event.target.value;
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);
    formControls[controlName] = control;

    let isPasswordsMatch = false;
    if (formControls.password.value === formControls.repeatPassword.value) {
      isPasswordsMatch = true;
      formControls.repeatPassword.valid = isPasswordsMatch;
    } else {
      formControls.repeatPassword.valid = isPasswordsMatch;
    }
    let passwordInputValid = validateControl(control.value, control.validation);
    // let passwordInputValid = true;
    // Object.keys(formControls).forEach((key) => {
    //   if (!formControls[key].valid) {
    //     passwordInputValid = false;
    //   }
    // });
    let accountInfoValid = true;
    Object.keys(formControls).forEach((key) => {
      if (!formControls[key].valid) {
        accountInfoValid = false;
      }
    });

    this.setState({
      passwordInputValid: passwordInputValid,
      personalData: formControls,
      passwordMatch: isPasswordsMatch,
      accountInfoValid,
    });
  };
  // отправка формы
  sendAccountInfo() {
    let data = {
      email: this.state.personalData.email.value,
      password: this.state.personalData.password.value,
      first_name: this.state.personalData.first_name.value,
      second_name: this.state.personalData.second_name.value,
      patronymic_name: this.state.personalData.patronymic_name.value,
      gender: this.state.personalData.gender.value,
      date_of_birth: convertDateToString(
        this.state.personalData.date_of_birth.value
      ),
      registration_address:
        this.state.personalData.registration_address.value.value ||
        this.state.personalData.registration_address.value,
      real_address:
        this.state.personalData.real_address.value.value ||
        this.state.personalData.real_address.value,
      userpic_url: this.props.userpic_url,
    };
    this.props.createSupport(data);
  }
  touchAllControls = (controlGroupName) => {
    const controls = { ...this.state[controlGroupName] };
    Object.keys(controls).forEach((controlName) => {
      controls[controlName].touched = true;
    });
    this.setState({
      [controlGroupName]: controls,
    });
  };
  render() {
    return (
      <section className={classes.NewSupport}>
        <Scroller top={this.state.scrollTop} page={this.state.scrollTrigger} />
        <Helmet>
          <title>Новый специалист тех. поддержки. Портал здоровья</title>
          <meta
            name="title"
            content="Новый специалист тех. поддержки. Портал здоровья"
          />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:title"
            content="Новый специалист тех. поддержки. Портал здоровья"
          />
        </Helmet>
        <div className={classes.back}>
          <BackLink to={"/profile/supports"} />
          <span>Назад</span>
        </div>
        <div className={classes.title}>Новый спец. тех. поддержки</div>
        <form
          className={classes.form}
          onSubmit={(event) => event.preventDefault()}
        >
          <h5 id="password" className={classes.section_title}>
            Задайте данные для входа
          </h5>
          <div className={classes.section_content}>
            <div className={classes.inputs}>
              {/* старый пароль */}
              <Input
                value={this.state.personalData.email.value}
                type={this.state.personalData.email.type}
                placeholder={this.state.personalData.email.placeholder}
                valid={this.state.personalData.email.valid}
                touched={this.state.personalData.email.touched}
                label={this.state.personalData.email.label}
                shouldValidate={this.state.personalData.email.shouldValidate}
                errorMessage={this.state.personalData.email.errorMessage}
                onChange={(event) => this.onChangeInputHandler(event, "email")}
              ></Input>

              {/* новый пароль */}
              <Input
                value={this.state.personalData.password.value}
                type={this.state.personalData.password.type}
                placeholder={this.state.personalData.password.placeholder}
                valid={this.state.personalData.password.valid}
                touched={this.state.personalData.password.touched}
                label={this.state.personalData.password.label}
                shouldValidate={this.state.personalData.password.shouldValidate}
                errorMessage={this.state.personalData.password.errorMessage}
                onChange={(event) =>
                  this.onChangeInputPassHandler(event, "password")
                }
              ></Input>

              {/* повтор нового пароля */}
              <Input
                value={this.state.personalData.repeatPassword.value}
                type={this.state.personalData.repeatPassword.type}
                placeholder={this.state.personalData.repeatPassword.placeholder}
                valid={this.state.personalData.repeatPassword.valid}
                touched={this.state.personalData.repeatPassword.touched}
                label={this.state.personalData.repeatPassword.label}
                shouldValidate={
                  this.state.personalData.repeatPassword.shouldValidate
                }
                errorMessage={
                  this.state.personalData.repeatPassword.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputPassHandler(event, "repeatPassword")
                }
              ></Input>
            </div>
          </div>
          <h5 id="account" className={classes.section_title}>
            Личные данные
          </h5>
          <div className={classes.section_withPhoto}>
            <div className={classes.inputs}>
              {/* фамилия */}

              <Input
                className={classes.Input}
                value={this.state.personalData.second_name.value}
                placeholder={this.state.personalData.second_name.placeholder}
                valid={this.state.personalData.second_name.valid}
                touched={this.state.personalData.second_name.touched}
                label={this.state.personalData.second_name.label}
                shouldValidate={
                  this.state.personalData.second_name.shouldValidate
                }
                errorMessage={this.state.personalData.second_name.errorMessage}
                onChange={(event) =>
                  this.onChangeInputHandler(event, "second_name")
                }
              ></Input>

              {/* имя */}

              <Input
                className={classes.Input}
                value={this.state.personalData.first_name.value}
                placeholder={this.state.personalData.first_name.placeholder}
                valid={this.state.personalData.first_name.valid}
                touched={this.state.personalData.first_name.touched}
                label={this.state.personalData.first_name.label}
                shouldValidate={
                  this.state.personalData.first_name.shouldValidate
                }
                errorMessage={this.state.personalData.first_name.errorMessage}
                onChange={(event) =>
                  this.onChangeInputHandler(event, "first_name")
                }
              ></Input>

              {/* отчество */}

              <Input
                className={classes.Input}
                value={this.state.personalData.patronymic_name.value}
                placeholder={
                  this.state.personalData.patronymic_name.placeholder
                }
                valid={this.state.personalData.patronymic_name.valid}
                touched={this.state.personalData.patronymic_name.touched}
                label={this.state.personalData.patronymic_name.label}
                shouldValidate={
                  this.state.personalData.patronymic_name.shouldValidate
                }
                errorMessage={
                  this.state.personalData.patronymic_name.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "patronymic_name")
                }
              ></Input>

              <div className={classes.row}>
                {/* пол */}
                <GenderControl
                  value={this.state.personalData.gender.value}
                  onChange={(event) => this.genderChangeHandler(event)}
                />
                {/* дата рожд */}

                <Input
                  className={classes.Input}
                  type={this.state.personalData.date_of_birth.type}
                  value={this.state.personalData.date_of_birth.value}
                  placeholder={
                    this.state.personalData.date_of_birth.placeholder
                  }
                  valid={this.state.personalData.date_of_birth.valid}
                  touched={this.state.personalData.date_of_birth.touched}
                  label={this.state.personalData.date_of_birth.label}
                  shouldValidate={
                    this.state.personalData.date_of_birth.shouldValidate
                  }
                  errorMessage={
                    this.state.personalData.date_of_birth.errorMessage
                  }
                  onChange={(event, date) => this.onChangeDateHandler(date)}
                ></Input>
              </div>

              {/* адрес регистр */}

              <Input
                className={classes.Input}
                type={this.state.personalData.registration_address.type}
                value={this.state.personalData.registration_address.value}
                placeholder={
                  this.state.personalData.registration_address.placeholder
                }
                valid={this.state.personalData.registration_address.valid}
                touched={this.state.personalData.registration_address.touched}
                label={this.state.personalData.registration_address.label}
                shouldValidate={
                  this.state.personalData.registration_address.shouldValidate
                }
                errorMessage={
                  this.state.personalData.registration_address.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "registration_address")
                }
              ></Input>

              {/* адр места жительства */}

              <Input
                className={classes.Input}
                type={this.state.personalData.real_address.type}
                value={this.state.personalData.real_address.value}
                placeholder={this.state.personalData.real_address.placeholder}
                valid={this.state.personalData.real_address.valid}
                touched={this.state.personalData.real_address.touched}
                label={this.state.personalData.real_address.label}
                shouldValidate={
                  this.state.personalData.real_address.shouldValidate
                }
                errorMessage={this.state.personalData.real_address.errorMessage}
                onChange={(event) =>
                  this.onChangeInputHandler(event, "real_address")
                }
                disabled={this.state.isAddressesMatch}
              ></Input>
              <label className={classes.checkbox}>
                <input
                  type="checkbox"
                  onChange={(event) => {
                    this.setState({ isAddressesMatch: !!event.target.checked });
                  }}
                  checked={this.state.isAddressesMatch}
                />
                <span className={classes.checkmark} />
                Адреса совпадают
              </label>

              <Button
                disabled={
                  !(
                    this.state.accountInfoValid &&
                    this.state.accountInfoTouched &&
                    this.state.passwordInputValid &&
                    this.state.passwordMatch &&
                    this.props.userpic_url !== null
                  )
                }
                onClick={() => this.sendAccountInfo()}
                type={"primary"}
                needTip={true}
                onDisableClick={() => {
                  this.touchAllControls("personalData");
                  if (
                    !this.state.passwordInputValid ||
                    !this.state.passwordMatch
                  ) {
                    this.setState({
                      scrollTop: document.getElementById("password").offsetTop,
                      scrollTrigger: !this.state.scrollTrigger,
                    });
                  } else {
                    this.setState({
                      scrollTop: document.getElementById("account").offsetTop,
                      scrollTrigger: !this.state.scrollTrigger,
                    });
                  }
                }}
              >
                Сохранить
              </Button>
            </div>
            {/* фото */}
            <div
              className={[
                classes.picture_loader,
                !this.props.userpic_url &&
                this.state.personalData.userpic.touched
                  ? classes.invalid
                  : null,
              ].join(" ")}
              style={{ backgroundImage: `url(${this.props.userpic_url})` }}
            >
              <input
                className={classes.picture_input}
                type={"file"}
                onChange={(event) => this.addImageHadler(event)}
              />
              {this.props.userpic_url ? (
                <div
                  className={classes.remove_btn}
                  onClick={() => this.removeImageHandler()}
                >
                  <img src={"/profile/images/cross.svg"} alt={"remove"} />
                </div>
              ) : (
                <div className={classes.add_btn}>
                  {this.state.isUserpicLoading ? (
                    <FileLoader style={{ height: "14px" }} />
                  ) : (
                    <img src={"/profile/images/plus.svg"} alt={"add"} />
                  )}
                  <div className={classes.add_btn__text}>Загрузить фото</div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* модальное окно */}
        {this.state.modalShow && this.props.createSupportStatus !== "" ? (
          <ModalWindow timeout={3000} closed={this.modalShowClose}>
            {this.props.createSupportStatus === "SUCCESS" ? (
              <ModalContent
                title={"Успешная регистрация тех. поддержки!"}
                subtitle={`Новый пользователь технической поддержки
             ${this.state.personalData.second_name.value} ${this.state.personalData.first_name.value} ${this.state.personalData.patronymic_name.value} успешно зарегистрирован`}
                status={this.props.createSupportStatus}
              ></ModalContent>
            ) : (
              <ModalContent
                title={"Ошибка!"}
                subtitle={`Пользователь с таким Email уже существует`}
                status={this.props.createSupportStatus}
              ></ModalContent>
            )}
          </ModalWindow>
        ) : null}
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    userpic_url: state.supports.userpic_url,
    createSupportStatus: state.supports.createSupportStatus,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    createSupport: (data) => dispatch(createSupport(data)),
    loadPicture: (file) => dispatch(loadPicture(file)),
    removePicture: () => dispatch(removePicture()),
    clearCreateSupportStatus: () => dispatch(clearCreateSupportStatus()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(NewSupport);
