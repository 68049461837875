import React from 'react'
import classes from './DocumentItem.module.scss';

import FileItem from 'components/UI/FileControl/FileItem/FileItem';

const DocumentItem = props => {
    return (
        <div className={classes.DocumentItem}>
            <div className={classes.header}>
                <span>{props.medicalOrganization}&nbsp;от&nbsp;{props.date}</span>
            </div>
            <div className={classes.title}>
                {props.title}
            </div>
            <div className={classes.type}>
                Тип документа:<br/>
                <strong>{props.type}</strong>
            </div>
            <FileItem style={{margin: 0}} file={props.fileUrl} download/>
        </div>
    )
}


export default DocumentItem