import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/ru";
import { Redirect } from "react-router-dom";

import Button from "components/UI/Button/Button";
import BackLink from "components/UI/BackLink/BackLink";
import Input from "components/UI/Input/Input";

import classes from "./ArticleEdit.module.scss";
import {
  editArticle,
  getArticleByID,
  loadPicture,
  removePicture,
} from "store/Profile/Articles/actions";

const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "indent",
    "outdent",
    "|",
    "insertTable",
  ],
  language: "ru",
  placeholder: "Текст статьи",
};

class FaqEdit extends Component {
  state = {
    article_title: "",
    article_desc: "",
    formTouched: false,
  };
  addImageHadler = (event) => {
    this.props.loadPicture(event.target.files[0]);
    let newState = { ...this.state };
    newState.formTouched = true;
    this.setState({
      ...newState,
    });
  };

  removeImageHandler = () => {
    this.props.removePicture();
  };
  componentDidMount() {
    this.props.getArticleByID(this.props.match.params.id);
  }
  componentDidUpdate(prevProps, prevState) {
    let editedArticle = this.props.editedArticle;
    if (prevProps.editedArticle !== editedArticle && editedArticle) {
      this.initArticle(editedArticle);
    }
    if (
      prevState.article_desc !== this.state.article_desc &&
      prevState.article_desc !== ""
    ) {
      this.setState({ formTouched: true });
    }
  }
  initArticle(editedArticle) {
    let newState = { ...this.state };
    newState.article_title = editedArticle.title;
    newState.article_desc = editedArticle.body;

    this.setState({
      ...newState,
    });
  }
  inputChangeHandler = (event, targetName) => {
    let formControl = { ...this.state };
    formControl[targetName] = event.target.value;
    formControl.formTouched = true;
    this.setState({ ...formControl });
  };

  sendFormHandler = () => {
    this.props.editArticle(
      this.props.match.params.id,
      this.state.article_title,
      this.state.article_desc,
      this.props.article_image
    );
  };

  render() {
    return (
      <section className={classes.ArticleEdit}>
        <Helmet>
          <title>Редактирование статьи. Портал здоровья</title>
          <meta name="title" content="Редактирование статьи. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:title"
            content="Редактирование статьи. Портал здоровья"
          />
        </Helmet>
        <div className={classes.back}>
          <BackLink to={"/profile/articles"} />
          <span>Назад</span>
        </div>
        <form
          className={classes.form}
          onSubmit={(event) => event.preventDefault()}
        >
          <h4 className={classes.title}>Редактирование статьи</h4>
          <div className={classes.input_wrapper}>
            <Input
              value={this.state.article_title}
              placeholder={"Заголовок статьи"}
              valid={false}
              touched={false}
              label={"Заголовок статьи"}
              shouldValidate={false}
              errorMessage={""}
              onChange={(event) =>
                this.inputChangeHandler(event, "article_title")
              }
            ></Input>
          </div>
          {/* Текст статьи (редактор) */}
          <CKEditor
            editor={ClassicEditor}
            data={this.state.article_desc}
            onChange={(event, editor) => {
              const data = editor.getData();
              this.setState({ article_desc: data });
            }}
            onReady={(editor) => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  "min-height",
                  "800px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            config={editorConfiguration}
          />
          {/* загрузка фото */}
          <div
            className={classes.picture_loader}
            style={{ backgroundImage: `url(${this.props.article_image})` }}
          >
            <input
              className={classes.picture_input}
              type={"file"}
              onChange={(event) => this.addImageHadler(event)}
            />
            {this.props.article_image ? (
              <div
                className={classes.remove_btn}
                onClick={() => this.removeImageHandler()}
              >
                <img src={"/profile/images/cross.svg"} alt={"remove"} />
              </div>
            ) : (
              <img src={"/profile/images/plus.svg"} alt={"add"} />
            )}
          </div>
          <div className={classes.btn_submit}>
            <Button
              disabled={
                this.state.article_title !== "" &&
                this.state.article_desc !== "" &&
                this.props.article_image !== null &&
                !this.state.formTouched
              }
              onClick={() => this.sendFormHandler()}
              type={"primary"}
            >
              Сохранить изменения
            </Button>
          </div>

          {/* сообщение об ошибке */}
          {this.props.editArticleStatus === "SUCCESS" ? (
            <Redirect to={"/profile/articles"} />
          ) : null}
        </form>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    editArticleStatus: state.articles.editArticleStatus,
    editedArticle: state.articles.editedArticle,
    article_image: state.articles.article_image,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getArticleByID: (id) => dispatch(getArticleByID(id)),
    editArticle: (id, article_title, article_desc, article_image) =>
      dispatch(editArticle(id, article_title, article_desc, article_image)),
    loadPicture: (file) => dispatch(loadPicture(file)),
    removePicture: () => dispatch(removePicture()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqEdit);
