import React from "react";
import Scroller from "hoc/CustomScrollbar/Scroller";

import classes from "./Navigation.module.scss";

const Navigation = (props) => {
  const leftArrowCls = [classes.left];
  const rightArrowCls = [classes.right];

  if (props.activePage === 1) {
    leftArrowCls.push(classes.disabled);
  }

  if (props.activePage === props.pages) {
    rightArrowCls.push(classes.disabled);
  }

  // Скролл до актовной страницы
  React.useEffect(() => {
    const activePage = document.getElementsByClassName(classes.active)[0];
    const navigator = document.getElementsByClassName(classes.pages)[0];
    navigator.scrollLeft = activePage.offsetLeft - navigator.offsetLeft - 75;
  }, [props.activePage]);

  function renderPages() {
    const pageList = [];
    const isSmall = document.documentElement.clientWidth < 768;
    const smallCoef = isSmall ? 2 : 0;
    for (let i = 1; i <= props.pages; i++) {
      const cls = [classes.page];
      if (i === props.activePage) {
        cls.push(classes.active);
      }
      if (
        i <= 3 - smallCoef ||
        props.pages - i <= 2 - smallCoef ||
        (props.activePage - i <= (isSmall ? 0 : 1) &&
          props.activePage - i >= (isSmall ? 0 : -1))
      ) {
        pageList.push(
          <span
            key={i}
            className={cls.join(" ")}
            onClick={() => props.onChange(props.pages, i)}
          >
            {i}
          </span>
        );
      }
      if (
        (i === 4 - smallCoef && props.activePage > (isSmall ? 2 : 5)) ||
        (i === props.pages - (3 - smallCoef) &&
          props.activePage < props.pages - (isSmall ? 1 : 4))
      ) {
        pageList.push(<span className={classes.dots}>...</span>);
      }
    }
    return pageList;
  }

  return (
    <div className={classes.Navigation}>
      <Scroller top={0} page={props.activePage} />
      <div
        className={leftArrowCls.join(" ")}
        onClick={() => props.onChange(props.pages, props.activePage - 1)}
      >
        <img src={"/profile/images/left-arrow.svg"} alt={"left-arrow"} />
        <span>Предыдущая</span>
      </div>
      <div className={classes.pages}>{renderPages()}</div>
      <div
        className={rightArrowCls.join(" ")}
        onClick={() => props.onChange(props.pages, props.activePage + 1)}
      >
        <span>Следующая</span>
        <img src={"/profile/images/right-arrow.svg"} alt={"right-arrow"} />
      </div>
    </div>
  );
};

export default Navigation;
