import React from "react";

import classes from "./HealthStatusModal.module.scss";

const HealthStatusModal = (props) => {
  const cls = [classes.HealthStatusModal];
  cls.push(classes[props.color]);
  if (props.isOpen) {
    return (
      <div className={classes.wrapper}>
        <div className={cls.join(" ")}>
          <div className={classes.header}>
            <div className={classes.color_indicator} />
            <div className={classes.title}>Состояние здоровья</div>
            <div className={classes.close} onClick={props.onClose}>
              <img src="/profile/images/close-modal.svg" alt="close" />
            </div>
          </div>
          <div className={classes.description}>
            {props.color === "red" ? (
              <React.Fragment>
                Судя по Вашим ответам, Ваше здоровье находится в критическом
                состоянии (в «оранжево-красной» зоне), когда из-за
                функциональных нарушений Вы едва можете работать и даже решать
                ежедневные бытовые задачи.
                <br />
                С большой вероятностью, Вам уже поставлен диагноз хронического
                заболевания, и Вы регулярно принимаете лекарства для контроля
                симптомов.
                <br />
                Нужно иметь в виду, что Ваш организм продолжает бороться; он
                старается восстановить здоровье, но этому что-то мешает. Скорее
                всего, это «что-то» - Ваши ежедневные действия: неправильное
                питание, неумение ограничивать стресс или управлять своей
                реакцией на него, недостаточный сон и отдых, недостаток
                движения, дисгармоничные отношения с близкими людьми. Среди
                причин прогрессирующего ухудшения здоровья могут быть и вредные
                вещества, которые поступают извне и накапливаются в организме.
                <br />
                Более точно сказать о характере функциональных нарушений и о
                том, в чём именно Ваш образ жизни отклоняется от оптимального,
                можно будет после заполнения Вами подробных опросников.
                <br />
                Вам нужно срочно заняться восстановлением своего здоровья и
                пройти детальное обследование у врача.
              </React.Fragment>
            ) : null}
            {props.color === "yellow" ? (
              <React.Fragment>
                Судя по Вашим ответам, по состоянию здоровья Вы находитесь в
                «жёлтой» зоне; ваше здоровье исчерпано более чем на 50% от
                оптимального. Это значит, что организм больше не в состоянии
                компенсировать давление неблагоприятных факторов среды и уже
                находится в состоянии хронической болезни. При визите к врачу
                Вам, скорее всего, поставят диагноз и назначат «таблетки» для
                контроля отдельных симптомов болезни.
                <br />
                Однако чтобы одержать стратегическую победу над болезнью,
                продлить себе жизнь и улучшить её качество, Вам необходимо
                кардинально пересмотреть образ жизни, который Вы ведёте.
                <br />
                Помните, что основной инструмент управления здоровьем – это не
                таблетки, а Ваши ежедневные действия: питание, управление
                стрессом, сон и отдых, движение, ограничение поступления
                токсинов, поддержание гармоничных отношений с близкими людьми.
                <br />
                Более точно сказать о характере функциональных нарушений и о
                том, в чём именно Ваш образ жизни отклоняется от оптимального,
                можно будет после заполнения Вами подробных опросников.
              </React.Fragment>
            ) : null}
            {props.color === "gray" ? (
              <React.Fragment>
                Судя по вашим ответам, Вы находитесь в «серой зоне»: уровень
                здоровья 50-70% от оптимального. Это состояние здоровья, когда
                организм начинает сигнализировать о неблагополучии, о
                неправильном образе жизни. Но за счёт определённого запаса
                прочности ему удаётся скомпенсировать Ваше поведение в части
                поддержания здоровья.
                <br />
                Такое состояние может сохраняться многие годы. Но с каждым годом
                Ваша устойчивость будет снижаться. Это значит, что возрастает
                вероятность срыва (резкого ухудшения здоровья) в ответ на более
                интенсивный или длительный, чем обычно, стресс, инфекцию и тому
                подобные непредвиденные обстоятельства.
                <br />
                Более точно сказать о характере функциональных нарушений и о
                том, в чём именно Ваш образ жизни отклоняется от оптимального,
                можно будет после заполнения Вами более подробных опросников.
              </React.Fragment>
            ) : null}
            {props.color === "green" ? (
              <React.Fragment>
                Судя по Вашим ответам, у Вас нет значительных функциональных
                нарушений, то есть, по предварительной оценке, уровень вашего
                здоровья на шкале превышает примерно 70% от оптимального.
                <br />
                Это означает, что Вам в целом удаётся вести здоровый образ жизни
                или потенциал Вашего здоровья столь высок, что неблагоприятные
                факторы пока не смогли его подорвать.
                <br />
                Более точно сказать о том, насколько здоровый образ жизни Вы
                ведёте и какие факторы риска для здоровья у Вас присутствуют,
                можно будет после заполнения вами подробных опросников.
                <br />
                Необходимо в плановом порядке повторно заполнить данную анкету
                через 3 месяца.
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </div>
    );
  } else return null;
};

export default HealthStatusModal;
