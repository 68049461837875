// patient - пациент
// assistant - ассистент
// doctor - специалист
// support - тех. поддержка
// admin - администратор
export default function getLinks(role) {
  switch (role) {
    case "patient":
      return [
        {
          to: "/profile/summary",
          label: "События",
          exact: false,
          iconUrl: "/profile/images/summary.svg",
        },
        {
          to: "/profile/doctors",
          label: "Специалисты",
          exact: false,
          iconUrl: "/profile/images/doctors.svg",
        },
        {
          to: "/profile/consultations",
          label: "Консультации",
          exact: false,
          iconUrl: "/profile/images/consultations.svg",
        },
        {
          to: "/profile/reports",
          label: "Мои услуги",
          exact: false,
          iconUrl: "/profile/images/reports.svg",
        },
        {
          to: "/profile/health",
          label: "Мое здоровье",
          exact: false,
          iconUrl: "/profile/images/health.svg",
        },
        {
          to: "/profile/medcard",
          label: "Медкарта",
          exact: false,
          iconUrl: "/profile/images/medcard.svg",
        },
      ];
    case "assistant":
      return [
        {
          to: "/profile/consultations",
          label: "Консультации",
          exact: false,
          iconUrl: "/profile/images/consultations.svg",
        },
        {
          to: "/profile/doctors",
          label: "Специалисты",
          exact: false,
          iconUrl: "/profile/images/doctors.svg",
        },
        {
          to: "/profile/patients",
          label: "Пациенты",
          exact: false,
          iconUrl: "/profile/images/patients.svg",
        },
        {
          to: "/profile/reports",
          label: "Отчетность",
          exact: false,
          iconUrl: "/profile/images/reports.svg",
        },
        {
          to: "/profile/invites",
          label: "Приглашения",
          exact: false,
          iconUrl: "/profile/images/invites.svg",
        },
      ];
    case "provider":
      return [
        {
          to: "/profile/summary",
          label: "События",
          exact: false,
          iconUrl: "/profile/images/summary.svg",
        },
        {
          to: "/profile/doctors",
          label: "Специалисты",
          exact: false,
          iconUrl: "/profile/images/doctors.svg",
        },
        {
          to: "/profile/patients",
          label: "Пациенты",
          exact: false,
          iconUrl: "/profile/images/patients.svg",
        },
        {
          to: "/profile/reports",
          label: "Отчеты",
          exact: false,
          iconUrl: "/profile/images/reports.svg",
        },
      ];
    case "doctor":
      return [
        {
          to: "/profile/summary",
          label: "События",
          exact: false,
          iconUrl: "/profile/images/summary.svg",
        },
        {
          to: "/profile/consultations",
          label: "Консультации",
          exact: false,
          iconUrl: "/profile/images/consultations.svg",
        },
        {
          to: "/profile/patients/",
          label: "Мои пациенты",
          exact: false,
          iconUrl: "/profile/images/supports.svg",
        },
        {
          to: "/profile/reports",
          label: "Отчетность",
          exact: false,
          iconUrl: "/profile/images/reports.svg",
        },
      ];
    case "support":
      return [
        {
          to: "/profile/consultations",
          label: "Консультации",
          exact: false,
          iconUrl: "/profile/images/consultations.svg",
        },
        {
          to: "/profile/doctors",
          label: "Специалисты",
          exact: false,
          iconUrl: "/profile/images/doctors.svg",
        },
        {
          to: "/profile/assistants",
          label: "Ассистенты",
          exact: false,
          iconUrl: "/profile/images/assistants.svg",
        },
        {
          to: "/profile/patients",
          label: "Пациенты",
          exact: false,
          iconUrl: "/profile/images/patients.svg",
        },
        {
          to: "/profile/journal",
          label: "Журнал действий",
          exact: false,
          iconUrl: "/profile/images/journal.svg",
        },
      ];
    case "admin":
      return [
        {
          to: "/profile/supports",
          label: "Тех поддержка",
          exact: false,
          iconUrl: "/profile/images/supports.svg",
        },
        {
          to: "/profile/providers",
          label: "Провайдеры",
          exact: false,
          iconUrl: "/profile/images/providers.svg",
        },
        {
          to: "/profile/assistants",
          label: "Ассистенты",
          exact: false,
          iconUrl: "/profile/images/assistants.svg",
        },
        {
          to: "/profile/doctors",
          label: "Специалисты",
          exact: false,
          iconUrl: "/profile/images/doctors.svg",
        },
        {
          to: "/profile/patients",
          label: "Пациенты",
          exact: false,
          iconUrl: "/profile/images/patients.svg",
        },
        {
          to: "/profile/articles",
          label: "Статьи",
          exact: false,
          iconUrl: "/profile/images/articles.svg",
        },
        {
          to: "/profile/faq",
          label: "FAQ",
          exact: false,
          iconUrl: "/profile/images/faq.svg",
        },
        {
          to: "/profile/feedback",
          label: "Обратная связь",
          exact: false,
          iconUrl: "/profile/images/feedback.svg",
        },
      ];
    default:
      return null;
  }
}
