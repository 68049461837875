import {
  GET_ACCOUNT_ERROR,
  GET_ACCOUNT_SUCCESS,
  EDIT_ACCOUNT_ERROR,
  EDIT_ACCOUNT_SUCCESS,
  CLEAR_ACCOUNT_STATUS,
  ADD_REPRESENTATIVE_SUCCESS,
  ADD_REPRESENTATIVE_ERROR,
  GET_REPRESENTATIVES_SUCCESS,
  GET_REPRESENTATIVES_ERROR,
  DELETE_REPRESENTATIVE_SUCCESS,
  LOAD_PICTURE_SUCCESS,
  LOAD_PICTURE_ERROR,
  REMOVE_PICTURE,
  GET_HEALTH_STATUS_SUCCESS,
  GET_HEALTH_STATUS_ERROR,
  GET_MY_ACCOUNT_SUCCESS,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_ERROR,
  CHANGE_DELETED_STATUS_SUCCESS,
  CHANGE_DELETED_STATUS_ERROR,
  CLEAR_ACCOUNT_DELETED_STATUS,
} from "./actionTypes";

const initialState = {
  account: null,
  myAccount: null,
  health_status: null,
  accountEditStatus: "",
  userpic_url: "",
  representatives: [],
  addRepresentativeStatus: null,
  getRepresentativesStatus: null,
  accountDeleteStatus: "",
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: action.account,
        userpic_url: action.account.userpic_url,
      };
    case GET_MY_ACCOUNT_SUCCESS:
      return {
        ...state,
        myAccount: action.myAccount,
      };
    case GET_ACCOUNT_ERROR:
      return {
        ...state,
      };
    case GET_HEALTH_STATUS_SUCCESS:
      return {
        ...state,
        health_status: action.data,
      };
    case GET_HEALTH_STATUS_ERROR:
      return {
        ...state,
        health_status: null,
      };
    case EDIT_ACCOUNT_SUCCESS:
      return {
        ...state,
        accountEditStatus: action.accountEditStatus,
      };
    case EDIT_ACCOUNT_ERROR:
      return {
        ...state,
        accountEditStatus: action.accountEditStatus,
      };
    case CLEAR_ACCOUNT_STATUS:
      return {
        ...state,
        accountEditStatus: "",
      };
    case ADD_REPRESENTATIVE_SUCCESS:
      return {
        ...state,
        addRepresentativeStatus: action.addRepresentativeStatus,
      };
    case ADD_REPRESENTATIVE_ERROR:
      return {
        ...state,
        addRepresentativeStatus: action.addRepresentativeStatus,
      };
    case GET_REPRESENTATIVES_SUCCESS:
      return {
        ...state,
        representatives: action.representatives,
        getRepresentativesStatus: action.getRepresentativesStatus,
      };
    case GET_REPRESENTATIVES_ERROR:
      return {
        ...state,
        getRepresentativesStatus: action.getRepresentativesStatus,
      };
    case DELETE_REPRESENTATIVE_SUCCESS:
      return {
        ...state,
      };

    case LOAD_PICTURE_SUCCESS:
      return {
        ...state,
        userpic_url: action.url,
      };
    case LOAD_PICTURE_ERROR:
      return {
        ...state,
        userpic_url: "",
      };
    case REMOVE_PICTURE:
      return {
        ...state,
        userpic_url: "",
      };
    case CHANGE_STATUS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_STATUS_ERROR:
      return {
        ...state,
      };
    case CHANGE_DELETED_STATUS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_DELETED_STATUS_ERROR:
      return {
        ...state,
        accountDeleteStatus: action.error,
      };
    case CLEAR_ACCOUNT_DELETED_STATUS:
      return {
        ...state,
        accountDeleteStatus: "",
      };
    default:
      return state;
  }
}
