import React from "react";
import Input from "../../components/UI/Input/Input";
import Button from "components/UI/Button/Button";
import ModalWindow from "components/UI/ModalWindow/ModalWindow";
import ModalContent from "components/UI/ModalWindow/SuccessErrorMessage/SuccessErrorMessage";
import { validateControl } from "containers/Auth/Auth";

import classes from "./CovidPage.module.scss";

const ContactForm = ({ testId, onSubmit, status }) => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [modalShow, setModalShow] = React.useState("");

  const [isEmailValid, setIsEmailValid] = React.useState(false);
  const [isEmailTouched, setIsEmailTouched] = React.useState(false);

  React.useEffect(() => {
    setIsEmailValid(validateControl(email, { require: true, email: true }));
  }, [email]);

  const isFormValid = isEmailValid;

  const sendForm = () => {
    const data = {
      name,
      email,
      covid_test_result_id: testId,
    };
    onSubmit(data);
    setModalShow(true);

    setName("");
    setEmail("");
    setIsEmailTouched(false);
  };

  const modalShowClose = () => {
    setModalShow(false);
  };

  return (
    <>
      <div className={classes.contacts}>
        <div className={classes.subtitle}>
          Оставьте ваши контакты, чтобы мы могли отправить результаты вам на
          почту.
        </div>
        <form className={classes.form} onSubmit={(e) => e.preventDefault()}>
          <div className={classes.wrapper}>
            <Input
              value={name}
              placeholder={"Ваше имя"}
              label={"Ваше имя:"}
              shouldValidate={false}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
            <Input
              value={email}
              placeholder={"Почта"}
              valid={isEmailValid}
              touched={isEmailTouched}
              label={"Почта:"}
              shouldValidate={true}
              errorMessage={"Введите корректный email"}
              onChange={(event) => {
                setIsEmailTouched(true);
                setEmail(event.target.value);
              }}
            />
            <Button type="primary" onClick={sendForm} disabled={!isFormValid}>
              Отправить
            </Button>
          </div>
        </form>
      </div>
      {modalShow && status !== "" ? (
        <ModalWindow timeout={3000} closed={modalShowClose}>
          {status === "ok" ? (
            <ModalContent
              title={"Успешно отправлено!"}
              status={status}
            ></ModalContent>
          ) : (
            <ModalContent title={"Ошибка!"} status={status}></ModalContent>
          )}
        </ModalWindow>
      ) : null}
    </>
  );
};

export default ContactForm;
