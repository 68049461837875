import React from 'react'
import { Scrollbars } from 'react-custom-scrollbars';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ScrollbarContext from 'context/ScrollbarContext'

export const CustomScrollbar = props => {
    const { pathname } = useLocation();
    const scrollRef = React.useRef();
    // сколл вверх при каждом переходе
    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop()
        }
    }, [pathname]);

    const value = {scrollTop: (top) => scrollRef.current.scrollTop(top)}

    return (
        <ScrollbarContext.Provider value={value}>
            <Scrollbars
                ref={scrollRef}
                {...props}
            />
        </ScrollbarContext.Provider>
    );
}