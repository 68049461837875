import React from 'react';

import classes from './TestNavigation.module.scss';

const TestNavigation = props => {
    return (
        <div className={classes.TestNavigation}>
            {props.sections.map((section, index) => {
                const cls = [classes.section];
                if (index <= props.activeSection) {
                    cls.push(classes.active)
                }
                return (
                    <div className={cls.join(' ')}>
                        <div className={classes.icon}>
                            <div className={classes.left_line} />
                            <div className={classes.number}>
                                {index + 1}
                            </div>
                            <div className={classes.right_line} />
                        </div>
                        <div className={classes.text}>
                            {section}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}


export default TestNavigation