import React from 'react';

import classes from './Search.module.scss';

const Search = props => {
    return (
        <form
            className={classes.Search}
            onSubmit={event => {
                event.preventDefault()
                if (event.keyCode === 13) {
                    props.onSearch();
                }
            }}
        >
            <input
                className={classes.input}
                type="text"
                onChange={(event) => props.onChange(event.target.value)}
                placeholder={props.placeholder || "Ищите что-то конкретное"}
                value={props.value || ""}
            />
            <button
                className={classes.button}
                onClick={() => props.onSearch()}
            >
                Найти
            </button>
            {props.total !== null ?
                <div className={classes.total}>
                    Найдено: {props.total}
                    {props.showReset ?
                        <div
                            className={classes.reset}
                            onClick={props.onReset}>
                            Сбросить
                        </div>
                        :
                        null
                    }
                </div>
                :
                null
            }
        </form>
    )
}


export default Search