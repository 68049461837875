import React from "react";

import Card from "./Card/Card";

import classes from "./CardList.module.scss";

function getFormatDate(date) {
  const formatDate =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const formatMonth =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const formatYear = date.getFullYear();

  return `${formatDate}.${formatMonth}.${formatYear}`;
}
const CardList = (props) => {
  return (
    <div className={classes.CardList}>
      {props.cards.map((card, index) => {
        return (
          <Card
            key={index}
            id={card.id}
            title={card.title}
            type={card.type}
            medical_organization={card.medical_organization}
            document_date={getFormatDate(new Date(card.document_date))}
            document_url={card.document_url}
            document_type={card.document_type}
            created_at={getFormatDate(new Date(card.created_at))}
          />
        );
      })}
    </div>
  );
};

export default CardList;
