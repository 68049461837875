import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import BackLink from "components/UI/BackLink/BackLink";
import ModalWindow from "components/UI/ModalWindow/ModalWindow";
import ModalContent from "components/UI/ModalWindow/SuccessErrorMessage/SuccessErrorMessage";
import DeleteConfirm from "components/UI/ModalWindow/DeleteConfirm/DeleteConfirm";
import Button from "components/UI/Button/Button";
import Select from "components/UI/Select/Select";
import GenderControl from "components/UI/GenderControl/GenderControl";
import FileControl from "components/UI/FileControl/FileControl";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";
import { validateControl, renderInputs } from "containers/Auth/Auth";
import {
  convertInputToPoints,
  convertPointsToInput,
} from "components/UI/Input/EnumItemsInput";
import {
  convertDateToString,
  convertStringToDate,
} from "components/Profile/dateConverter";
import TimePicker from "components/UI/TimePicker/TimePicker";
import Input from "components/UI/Input/Input";
import classes from "./DoctorEdit.module.scss";
import {
  createDoctorProfile,
  createDoctorProfileByID,
  updateDoctorProfileByID,
  updateDoctorProfileMy,
  getDoctorByID,
  getDoctorMy,
} from "store/Profile/Doctors/actions";
import {
  getAccountInfoByID,
  editAccountInfoByID,
  changePassword,
  getAccountInfo,
  editAccountInfo,
  sendNewPassword,
  loadPicture,
  removePicture,
  clearAccountStatus,
  changeUserStatus,
  changeUserDeletedStatus,
  clearAccountDeletedStatus,
} from "store/Profile/Account/actions";
import { getSpecializations } from "store/actions/profile";
import { Helmet } from "react-helmet";
import Scroller from "hoc/CustomScrollbar/Scroller";

const ACADEMIC_DEGREES = [
  "",
  "Кандидат медицинских наук",
  "Кандидат педагогических наук",
  "Кандидат психологических наук",
  "Доктор медицинских наук",
  "Доктор педагогических наук",
  "Доктор психологических наук",
];

const ACADEMIC_TITLES = ["", "Доцент", "Профессор"];

const QUALIFICATION_CATEGORIES = [
  "",
  "Вторая квалификационная категория",
  "Первая квалификационная категория",
  "Высшая квалификационная категория",
];

class DoctorEdit extends Component {
  state = {
    isUserpicLoading: false,
    firstInvalidIntputKey: null,
    topScroll: null,
    scrollTrigger: false,
    isAddressesMatch: true,
    fileLoading: false,
    modalShow: false,
    confirmModalShow: false,
    modalPropfileShow: false,
    modalQuestionarieShow: false,
    accountInfoValid: true,
    accountInfoTouched: false,
    passwordInputValid: false,
    passwordMatch: false,
    passChange: {
      password: {
        value: "",
        type: "password",
        errorMessage:
          "Пароль должен содержать не менее 8 символов, цифры и латинские буквы, не должен являться словарным словом или набором символов, находящихся рядом на клавиатуре",
        placeholder: "Пароль",
        label: "Пароль",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
          minLength: 8,
        },
      },
      repeatPassword: {
        value: "",
        type: "password",
        errorMessage: "Пароли должны совпадать",
        placeholder: "Повторите пароль",
        label: "Повторите пароль",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {},
      },
    },
    personalData: {
      second_name: {
        placeholder: "Фамилия",
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        label: "Фамилия",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      first_name: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Имя",
        label: "Имя",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      patronymic_name: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "Отчество",
        label: "Отчество",
        valid: false,
        touched: false,
        shouldValidate: false,
        validation: {},
      },
      gender: {
        value: "male",
        touched: false,
        valid: true,
      },
      date_of_birth: {
        value: null,
        type: "date",
        errorMessage: "Введите корректное значение",
        placeholder: "Дата Рождения",
        label: "Дата Рождения",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      registration_address: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес",
        label: "Адрес",
        valid: false,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      real_address: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес места жительства",
        label: "Адрес места жительства",
        valid: false,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_name: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Название организации",
        label: "Название организации",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_address: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес местонахождения",
        label: "Адрес местонахождения",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_phone: {
        masked: true,
        mask: "+7 (999) 999-99-99",
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Телефон",
        label: "Телефон",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_email: {
        value: "",
        type: "email",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес электронной почты",
        label: "Адрес электронной почты",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_bik: {
        value: "",
        type: "bic",
        errorMessage: "Обязательное поле",
        placeholder: "БИК Банка",
        label: "БИК Банка",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_account: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Расчетный счет",
        label: "Расчетный счет",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_agreement_number: {
        value: "",
        type: "number",
        errorMessage: "Обязательное поле",
        placeholder: "Номер агентского договора",
        label: "Номер агентского договора",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_agreement_date: {
        value: null,
        type: "date",
        errorMessage: "Обязательное поле",
        placeholder: "Дата агентского договора",
        label: "Дата агентского договора",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
    },
    // ------------анкета
    showQuestionarie: false,
    questionarieTouched: false,
    questionarieValid: false,
    questionarie: {
      medical_speciality_title: {
        value: "",
        type: "text",
        placeholder: "Название врачебной специальности",
        label: "Название врачебной специальности",
        valid: true,
        touched: true,
        shouldValidate: false,
      },
      medical_speciality_work_experience: {
        value: "",
        type: "text",
        errorMessage: "Введите число",
        placeholder: "Стаж работы по врачебной специальности, лет",
        label: "Стаж работы, лет",
        valid: false,
        touched: true,
        shouldValidate: true,
        validation: {
          integer: true,
          maxCount: 100,
        },
      },
      qualification_category: {
        value: 0, // index
        valid: true,
        touched: true,
      },
      academic_degree: {
        value: 0, // index
        valid: true,
        touched: true,
      },
      academic_title: {
        value: 0, // index
        valid: true,
        touched: true,
      },
      specialization_id: {
        value: 0,
        valid: true,
        touched: true,
      },
      professional_skills: {
        string_value: "",
        array_value: [],
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Введите навыки через запятую",
        label: "Введите навыки через запятую",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      main_education: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Основное учебное заведение*",
        label: "Основное учебное заведение*",
        valid: false,
        touched: true,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      internship: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Интернатура",
        label: "Интернатура",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      residency: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Ординатура",
        label: "Ординатура",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      further_education: [
        {
          loading: false,
          title: {
            value: "",
            type: "text",
            errorMessage: "Обязательное поле",
            placeholder: "Наименование курса",
            label: "Наименование курса",
            valid: true,
            touched: true,
            shouldValidate: false,
          },
          passing_date: {
            value: null,
            type: "date",
            errorMessage: "Обязательное поле",
            placeholder: "Дата прохождения",
            label: "Дата прохождения",
            valid: true,
            touched: true,
            shouldValidate: false,
          },
          document_url: {
            value: [],
            valid: true,
            touched: true,
          },
        },
      ],
      work_experience: [
        {
          title: {
            value: "",
            type: "text",
            errorMessage: "Обязательное поле",
            placeholder: "Название учреждения",
            label: "Название учреждения",
            valid: true,
            touched: true,
            shouldValidate: true,
            validation: {
              required: false,
            },
          },
          start_date: {
            value: null,
            type: "date",
            errorMessage: "Обязательное поле",
            placeholder: "Дата начала",
            label: "Дата начала",
            valid: true,
            touched: true,
            shouldValidate: true,
            validation: {
              required: false,
            },
          },
          end_date: {
            value: null,
            type: "date",
            errorMessage: "",
            placeholder: "Дата завершения",
            label: "Дата завершения",
            valid: true,
            touched: true,
            shouldValidate: false,
            validation: {},
          },
          organization_website: {
            value: "",
            type: "text",
            errorMessage: "",
            placeholder: "Сайт учреждения или компании",
            label: "Сайт учреждения или компании",
            valid: true,
            touched: true,
            shouldValidate: false,
            validation: {},
          },
        },
      ],
      research_interests: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "Сфера научных интересов",
        label: "Сфера научных интересов",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      scientific_publications_count: {
        value: "",
        type: "text",
        errorMessage: "Введите число",
        placeholder: "",
        label: "",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
          integer: true,
          maxCount: 100,
        },
      },
      patents_count: {
        value: "",
        type: "text",
        errorMessage: "Введите число",
        placeholder: "",
        label: "",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
          integer: true,
          maxCount: 100,
        },
      },
      guidelines_count: {
        value: "",
        type: "text",
        errorMessage: "Введите число",
        placeholder: "",
        label: "",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
          integer: true,
          maxCount: 100,
        },
      },
      professional_medical_association: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "Член профессиональной ассоциации врачей",
        label: "Член профессиональной ассоциации врачей",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      diseases_and_health_problems: {
        string_value: "",
        array_value: [],
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder:
          "Введите заболевания или проблемы со здоровьем через запятую*",
        label: "*",
        valid: false,
        touched: true,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      working_hours_start_weekdays: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "",
        label: "",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      working_hours_end_weekdays: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "",
        label: "",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      working_hours_start_weekends: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "",
        label: "",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      working_hours_end_weekends: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "",
        label: "",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      contact_phone: {
        masked: true,
        mask: "+7 (999) 999-99-99",
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Мобильный телефон*",
        label: "Мобильный телефон*",
        valid: false,
        touched: true,
        shouldValidate: true,
        validation: {
          required: true,
          phone: true,
        },
      },
      work_phone: {
        masked: true,
        mask: "+7 (999) 999-99-99",
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "Рабочий телефон",
        label: "Рабочий телефон",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      email: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "Почта",
        label: "Почта",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
          email: true,
        },
      },
      clinic_title: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Название медицинской организации",
        label: "Название медицинской организации",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
        },
      },
      clinic_address: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес клиники",
        label: "Адрес клиники",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
        },
      },
      clinic_website: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Сайт лечебного учреждения",
        label: "Сайт лечебного учреждения",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      home_appointments_available: {
        value: false,
        valid: true,
        touched: true,
      },
      consultation_specialization_id: {
        value: 0,
        valid: true,
        touched: true,
      },
      clinic_consultation_price: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Стоимость",
        label: "Стоимость",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
          integer: true,
        },
      },
      home_consultation_price: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Стоимость",
        label: "Стоимость",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
          integer: true,
        },
      },
      video_consultation_price: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Стоимость",
        label: "Стоимость",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
          integer: true,
        },
      },
      consultation_clinic_title: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "Наименование клиники, где осуществляется прием",
        label: "Наименование клиники, где осуществляется прием",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      consultation_clinic_address: {
        value: "",
        type: "address",
        errorMessage: "",
        placeholder: "Адрес клиники, где осуществляется прием",
        label: "Адрес клиники, где осуществляется прием",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      diagnostic_methods: [
        {
          title: {
            value: "",
            type: "text",
            errorMessage: "Обязательное поле",
            placeholder: "Методы инструментальной диагностики",
            label: "Методы инструментальной диагностики",
            valid: true,
            touched: true,
            shouldValidate: true,
            validation: {
              required: false,
            },
          },
          price: {
            value: "",
            type: "text",
            errorMessage: "Обязательное поле",
            placeholder: "Стоимость",
            label: "Стоимость",
            valid: true,
            touched: true,
            shouldValidate: true,
            validation: {
              required: false,
              integer: true,
            },
          },
        },
      ],
      diagnostic_clinic_title: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "Наименование клиники, где осуществляется прием",
        label: "Наименование клиники, где осуществляется прием",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      diagnostic_clinic_address: {
        value: "",
        type: "address",
        errorMessage: "",
        placeholder: "Адрес клиники, где осуществляется прием",
        label: "Адрес клиники, где осуществляется прием",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      treatment_methods: [
        {
          title: {
            value: "",
            type: "text",
            errorMessage: "Обязательное поле",
            placeholder: "Метод лечения",
            label: "Метод лечения",
            valid: true,
            touched: true,
            shouldValidate: true,
            validation: {
              required: false,
            },
          },
          price: {
            value: "",
            type: "text",
            errorMessage: "Обязательное поле",
            placeholder: "Стоимость",
            label: "Стоимость",
            valid: true,
            touched: true,
            shouldValidate: true,
            validation: {
              required: false,
              integer: true,
            },
          },
        },
      ],
      treatment_clinic_title: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "Наименование клиники, где осуществляется прием",
        label: "Наименование клиники, где осуществляется прием",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      treatment_clinic_address: {
        value: "",
        type: "address",
        errorMessage: "",
        placeholder: "Адрес клиники, где осуществляется прием",
        label: "Адрес клиники, где осуществляется прием",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      dynamic_observation_methods: [
        {
          title: {
            value: "",
            type: "text",
            errorMessage: "Обязательное поле",
            placeholder: "Технологии и услуги",
            label: "Технологии и услуги",
            valid: true,
            touched: true,
            shouldValidate: true,
            validation: {
              required: false,
            },
          },
          price: {
            value: "",
            type: "text",
            errorMessage: "Обязательное поле",
            placeholder: "Стоимость",
            label: "Стоимость",
            valid: true,
            touched: true,
            shouldValidate: true,
            validation: {
              required: false,
              integer: true,
            },
          },
        },
      ],
      dynamic_observation_clinic_title: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "Наименование клиники, где осуществляются услуги",
        label: "Наименование клиники, где осуществляются услуги",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      dynamic_observation_clinic_address: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес клиники, где осуществляется прием",
        label: "Адрес клиники, где осуществляется прием",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      bookform: {
        value: "",
        type: "text",
        errorMessage: "Укажите ссылку",
        placeholder: "",
        label: "Ссылка на расписание",
        valid: true,
        touched: false,
        shouldValidate: true,
        validation: {
          link: true,
        },
      },
      description: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Краткое описание специалиста",
        label: "Краткое описание специалиста",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
        },
      },
    },
  };
  // модалка
  modalShowOpen = () => {
    this.setState({ modalShow: true });
  };
  modalShowClose = () => {
    this.setState({ modalShow: false });
    this.props.clearAccountDeletedStatus();
  };
  confirmModalShowOpen = () => {
    this.setState({ confirmModalShow: true });
  };
  confirmModalShowClose = () => {
    this.setState({ confirmModalShow: false });
  };
  modalProfileOpen = () => {
    this.setState({ modalPropfileShow: true });
  };
  modalProfileClose = () => {
    this.setState({ modalPropfileShow: false });
    this.props.clearAccountStatus();
  };
  modalQuestionarieOpen = () => {
    let newState = { ...this.state };
    newState.modalQuestionarieShow = true;
    this.setState({ ...newState });
  };
  modalQuestionarieClose = () => {
    let newState = { ...this.state };
    newState.modalQuestionarieShow = false;
    this.setState({ ...newState });
    this.props.clearAccountStatus();
  };
  componentDidMount() {
    if (this.props.role) {
      if (this.props.role !== "doctor") {
        this.props.getAccountInfoByID(this.props.match.params.id);
        this.props.getDoctorByID(this.props.match.params.id);
      } else {
        this.props.getAccountInfo();
        this.props.getDoctorMy();
      }
    }
    this.props.getSpecializations();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.questionarie !== this.state.questionarie) {
      const checkValidation = this.checkQuestionarieValidation();
      this.setState({
        questionarieValid: checkValidation[0],
        firstInvalidIntputKey: checkValidation[1],
      });
    }
    // получение данных после обновления страницы
    if (this.props.role !== prevProps.role && prevProps.role === null) {
      if (this.props.role !== "doctor") {
        this.props.getAccountInfoByID(this.props.match.params.id);
        this.props.getDoctorByID(this.props.match.params.id);
      } else {
        this.props.getAccountInfo();
        this.props.getDoctorMy();
      }
    }
    let account = this.props.account;
    let editedDoctorProfile = this.props.editedDoctorProfile;
    if (prevProps.account !== account && account !== null) {
      this.initAccount(account);
    }
    if (
      prevProps.editedDoctorProfile !== editedDoctorProfile &&
      editedDoctorProfile !== null
    ) {
      this.initQuestionarie(editedDoctorProfile);
      this.setState({ showQuestionarie: true });
    }
    // открываем модалку со статусом отправки запроса
    if (prevProps.account && this.props.account) {
      if (
        prevProps.account.user.is_active !== this.props.account.user.is_active
      ) {
        this.modalShowOpen();
      }
    }
    if (prevProps.dataFetchStatus !== this.props.dataFetchStatus) {
      this.modalQuestionarieOpen();
    }
    // открываем модалку со статусом отправки запроса
    if (prevProps.accountEditStatus !== this.props.accountEditStatus) {
      this.modalProfileOpen();
    }
    // Дублируем адреса
    if (
      prevState.isAddressesMatch === false &&
      this.state.isAddressesMatch === true
    ) {
      const formControls = { ...this.state.personalData };
      const real_address = { ...formControls["real_address"] };
      const registration_address = { ...formControls["registration_address"] };
      real_address.value = registration_address.value;
      real_address.touched = true;
      registration_address.touched = true;
      registration_address.valid = validateControl(
        registration_address.value,
        registration_address.validation
      );
      real_address.valid = registration_address.valid;
      formControls.real_address = real_address;
      formControls.registration_address = registration_address;
      this.setState({
        personalData: formControls,
        accountInfoTouched: true,
      });
    }
    if (prevProps.userpic_url !== this.props.userpic_url) {
      this.setState({ isUserpicLoading: false });
    }
  }
  initAccount(account) {
    let newState = { ...this.state };
    if (this.props.role === "doctor") {
      newState.passChange = {
        oldPassword: {
          value: "",
          type: "password",
          errorMessage:
            "Пароль должен содержать не менее 8 символов, цифры и латинские буквы, не должен являться словарным словом или набором символов, находящихся рядом на клавиатуре",
          placeholder: "Текущий пароль",
          label: "Текущий пароль",
          valid: false,
          touched: false,
          shouldValidate: true,
          validation: {
            required: true,
            minLength: 8,
          },
        },
        password: {
          value: "",
          type: "password",
          errorMessage:
            "Пароль должен содержать не менее 8 символов, цифры и латинские буквы, не должен являться словарным словом или набором символов, находящихся рядом на клавиатуре",
          placeholder: "Новый пароль",
          label: "Новый пароль",
          valid: false,
          touched: false,
          shouldValidate: true,
          validation: {
            required: true,
            minLength: 8,
          },
        },
        repeatPassword: {
          value: "",
          type: "password",
          errorMessage: "Пароли должны совпадать",
          placeholder: "Повторите новый пароль",
          label: "Повторите новый пароль",
          valid: false,
          touched: false,
          shouldValidate: true,
          validation: {},
        },
      };
    }

    var organization_agreement_date = null;
    if (newState.personalData.organization_agreement_date.value !== null) {
      organization_agreement_date = new Date(
        account.organization_agreement_date
      );
    }

    newState.personalData.first_name.value = account.first_name;
    newState.personalData.first_name.valid = true;
    newState.personalData.gender.value = account.gender;
    newState.personalData.second_name.value = account.second_name;
    newState.personalData.second_name.valid = true;
    newState.personalData.patronymic_name.value = account.patronymic_name;
    newState.personalData.patronymic_name.valid = true;
    newState.personalData.registration_address.value =
      account.registration_address;
    newState.personalData.registration_address.valid = true;
    newState.personalData.real_address.value = account.real_address;
    newState.personalData.real_address.valid = true;
    newState.personalData.date_of_birth.value = new Date(account.date_of_birth);
    newState.personalData.date_of_birth.valid = true;
    newState.personalData.organization_name.value = account.organization_name;
    newState.personalData.organization_name.valid = true;
    newState.personalData.organization_address.value =
      account.organization_address;
    newState.personalData.organization_address.valid = true;
    newState.personalData.organization_phone.value = account.organization_phone;
    newState.personalData.organization_phone.valid = true;
    newState.personalData.organization_email.value = account.organization_email;
    newState.personalData.organization_email.valid = true;
    newState.personalData.organization_bik.value = account.organization_bik;
    newState.personalData.organization_bik.valid = true;
    newState.personalData.organization_account.value =
      account.organization_account;
    newState.personalData.organization_account.valid = true;
    newState.personalData.organization_agreement_number.value =
      account.organization_agreement_number;
    newState.personalData.organization_agreement_number.valid = true;
    newState.personalData.organization_agreement_date.value =
      organization_agreement_date;
    newState.personalData.organization_agreement_date.valid = true;
    this.setState({
      ...newState,
    });
  }
  initQuestionarie(editedDoctorProfile) {
    let newState = { ...this.state };
    // анкета
    newState.questionarie.bookform.value = editedDoctorProfile.bookform_url;
    newState.questionarie.bookform.valid = true;
    newState.questionarie.medical_speciality_title.value =
      editedDoctorProfile.medical_speciality_title;
    newState.questionarie.medical_speciality_title.valid = true;
    newState.questionarie.medical_speciality_work_experience.value =
      editedDoctorProfile.medical_speciality_work_experience;
    newState.questionarie.medical_speciality_work_experience.valid = true;
    newState.questionarie.qualification_category.value =
      QUALIFICATION_CATEGORIES.indexOf(
        editedDoctorProfile.qualification_category
      );
    newState.questionarie.qualification_category.valid = true;
    newState.questionarie.academic_degree.value = ACADEMIC_DEGREES.indexOf(
      editedDoctorProfile.academic_degree
    );
    newState.questionarie.academic_degree.valid = true;
    newState.questionarie.academic_title.value = ACADEMIC_TITLES.indexOf(
      editedDoctorProfile.academic_title
    );
    newState.questionarie.academic_title.valid = true;
    newState.questionarie.specialization_id.value =
      editedDoctorProfile.specialization_id - 1;
    newState.questionarie.specialization_id.valid = true;
    newState.questionarie.professional_skills.array_value =
      editedDoctorProfile.professional_skills;
    newState.questionarie.professional_skills.string_value =
      convertPointsToInput(editedDoctorProfile.professional_skills);
    newState.questionarie.professional_skills.valid = true;
    newState.questionarie.main_education.value =
      editedDoctorProfile.main_education;
    newState.questionarie.main_education.valid = true;
    newState.questionarie.internship.value = editedDoctorProfile.internship;
    newState.questionarie.internship.valid = true;
    newState.questionarie.residency.value = editedDoctorProfile.residency;
    newState.questionarie.residency.valid = true;
    newState.questionarie.further_education =
      editedDoctorProfile.further_education.length !== 0
        ? editedDoctorProfile.further_education.map((obj) => {
            return {
              title: {
                value: obj.title,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Наименование курса",
                label: "Наименование курса",
                valid: true,
                touched: true,
              },
              passing_date: {
                value: convertStringToDate(obj.passing_date),
                type: "date",
                errorMessage: "Обязательное поле",
                placeholder: "Дата прохождения",
                label: "Дата прохождения",
                valid: true,
                touched: true,
              },
              document_url: {
                value: obj.document_url ? [obj.document_url] : [],
                valid: true,
                touched: true,
              },
            };
          })
        : [
            {
              title: {
                value: null,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Наименование курса",
                label: "Наименование курса",
                valid: true,
                touched: true,
              },
              passing_date: {
                value: null,
                type: "date",
                errorMessage: "Обязательное поле",
                placeholder: "Дата прохождения",
                label: "Дата прохождения",
                valid: true,
                touched: true,
              },
              document_url: {
                value: [],
                valid: true,
                touched: true,
              },
            },
          ];
    newState.questionarie.further_education.valid = true;
    newState.questionarie.work_experience =
      editedDoctorProfile.work_experience.length !== 0
        ? editedDoctorProfile.work_experience.map((obj) => {
            return {
              title: {
                value: obj.title,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Название учреждения",
                label: "Название учреждения",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                },
              },
              start_date: {
                value: convertStringToDate(obj.start_date),
                type: "date",
                errorMessage: "Обязательное поле",
                placeholder: "Дата начала",
                label: "Дата начала",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                },
              },
              end_date: {
                value: convertStringToDate(obj.end_date),
                type: "date",
                errorMessage: "",
                placeholder: "Дата завершения",
                label: "Дата завершения",
                valid: true,
                touched: true,
                shouldValidate: false,
                validation: {},
              },
              organization_website: {
                value: obj.organization_website,
                type: "text",
                errorMessage: "",
                placeholder: "Сайт учреждения или компании",
                label: "Сайт учреждения или компании",
                valid: true,
                touched: true,
                shouldValidate: false,
                validation: {},
              },
            };
          })
        : [
            {
              title: {
                value: null,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Название учреждения",
                label: "Название учреждения",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                },
              },
              start_date: {
                value: null,
                type: "date",
                errorMessage: "Обязательное поле",
                placeholder: "Дата начала",
                label: "Дата начала",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                },
              },
              end_date: {
                value: null,
                type: "date",
                errorMessage: "",
                placeholder: "Дата завершения",
                label: "Дата завершения",
                valid: true,
                touched: true,
                shouldValidate: false,
                validation: {},
              },
              organization_website: {
                value: null,
                type: "text",
                errorMessage: "",
                placeholder: "Сайт учреждения или компании",
                label: "Сайт учреждения или компании",
                valid: true,
                touched: true,
                shouldValidate: false,
                validation: {},
              },
            },
          ];
    newState.questionarie.work_experience.valid = true;
    newState.questionarie.research_interests.value =
      editedDoctorProfile.research_interests;
    newState.questionarie.research_interests.valid = true;
    newState.questionarie.scientific_publications_count.value =
      editedDoctorProfile.scientific_publications_count;
    newState.questionarie.scientific_publications_count.valid = true;
    newState.questionarie.patents_count.value =
      editedDoctorProfile.patents_count;
    newState.questionarie.patents_count.valid = true;
    newState.questionarie.guidelines_count.value =
      editedDoctorProfile.guidelines_count;
    newState.questionarie.guidelines_count.valid = true;
    newState.questionarie.professional_medical_association.value =
      editedDoctorProfile.professional_medical_association;
    newState.questionarie.professional_medical_association.valid = true;
    newState.questionarie.diseases_and_health_problems.array_value =
      editedDoctorProfile.diseases_and_health_problems;
    newState.questionarie.diseases_and_health_problems.string_value =
      convertPointsToInput(editedDoctorProfile.diseases_and_health_problems);
    newState.questionarie.diseases_and_health_problems.valid = true;
    newState.questionarie.working_hours_end_weekdays.value =
      editedDoctorProfile.working_hours_end_weekdays === null
        ? "--:--"
        : editedDoctorProfile.working_hours_end_weekdays;
    newState.questionarie.working_hours_end_weekdays.valid = true;
    newState.questionarie.working_hours_end_weekends.value =
      editedDoctorProfile.working_hours_end_weekends === null
        ? "--:--"
        : editedDoctorProfile.working_hours_end_weekends;
    newState.questionarie.working_hours_end_weekends.valid = true;
    newState.questionarie.working_hours_start_weekdays.value =
      editedDoctorProfile.working_hours_start_weekdays === null
        ? "--:--"
        : editedDoctorProfile.working_hours_start_weekdays;
    newState.questionarie.working_hours_start_weekdays.valid = true;
    newState.questionarie.working_hours_start_weekends.value =
      editedDoctorProfile.working_hours_start_weekends === null
        ? "--:--"
        : editedDoctorProfile.working_hours_start_weekends;
    newState.questionarie.working_hours_start_weekends.valid = true;
    newState.questionarie.contact_phone.value =
      editedDoctorProfile.contact_phone;
    newState.questionarie.contact_phone.valid = true;
    newState.questionarie.work_phone.value = editedDoctorProfile.work_phone;
    newState.questionarie.work_phone.valid = true;
    newState.questionarie.email.value = editedDoctorProfile.email;
    newState.questionarie.email.valid = true;
    newState.questionarie.clinic_title.value = editedDoctorProfile.clinic_title;
    newState.questionarie.clinic_title.valid = true;
    newState.questionarie.clinic_address.value =
      editedDoctorProfile.clinic_address;
    newState.questionarie.clinic_address.valid = true;
    newState.questionarie.clinic_website.value =
      editedDoctorProfile.clinic_website;
    newState.questionarie.clinic_website.valid = true;
    newState.questionarie.home_appointments_available.value =
      editedDoctorProfile.home_appointments_available;
    newState.questionarie.home_appointments_available.valid = true;
    newState.questionarie.consultation_specialization_id.value =
      editedDoctorProfile.consultation_specialization_id - 1;
    newState.questionarie.consultation_specialization_id.valid = true;
    newState.questionarie.clinic_consultation_price.value =
      editedDoctorProfile.clinic_consultation_price;
    newState.questionarie.clinic_consultation_price.valid = true;
    newState.questionarie.home_consultation_price.value =
      editedDoctorProfile.home_consultation_price;
    newState.questionarie.home_consultation_price.valid = true;
    newState.questionarie.video_consultation_price.value =
      editedDoctorProfile.video_consultation_price;
    newState.questionarie.video_consultation_price.valid = true;
    newState.questionarie.consultation_clinic_title.value =
      editedDoctorProfile.consultation_clinic_title;
    newState.questionarie.consultation_clinic_title.valid = true;
    newState.questionarie.consultation_clinic_address.value =
      editedDoctorProfile.consultation_clinic_address;
    newState.questionarie.consultation_clinic_address.valid = true;
    newState.questionarie.diagnostic_methods =
      editedDoctorProfile.diagnostic_methods.length !== 0
        ? editedDoctorProfile.diagnostic_methods.map((obj) => {
            return {
              title: {
                value: obj.title,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Методы инструментальной диагностики",
                label: "Методы инструментальной диагностики",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                },
              },
              price: {
                value: obj.price,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Стоимость",
                label: "Стоимость",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                  integer: true,
                },
              },
            };
          })
        : [
            {
              title: {
                value: null,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Методы инструментальной диагностики",
                label: "Методы инструментальной диагностики",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                },
              },
              price: {
                value: null,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Стоимость",
                label: "Стоимость",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                  integer: true,
                },
              },
            },
          ];
    newState.questionarie.diagnostic_methods.valid = true;
    newState.questionarie.diagnostic_clinic_title.value =
      editedDoctorProfile.diagnostic_clinic_title;
    newState.questionarie.diagnostic_clinic_title.valid = true;
    newState.questionarie.diagnostic_clinic_address.value =
      editedDoctorProfile.diagnostic_clinic_address;
    newState.questionarie.diagnostic_clinic_address.valid = true;
    newState.questionarie.treatment_clinic_title.value =
      editedDoctorProfile.treatment_clinic_title;
    newState.questionarie.treatment_clinic_title.valid = true;
    newState.questionarie.treatment_clinic_address.value =
      editedDoctorProfile.treatment_clinic_address;
    newState.questionarie.treatment_clinic_address.valid = true;
    newState.questionarie.treatment_methods =
      editedDoctorProfile.treatment_methods.length !== 0
        ? editedDoctorProfile.treatment_methods.map((obj) => {
            return {
              title: {
                value: obj.title,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Метод лечения",
                label: "Метод лечения",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                },
              },
              price: {
                value: obj.price,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Стоимость",
                label: "Стоимость",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                  integer: true,
                },
              },
            };
          })
        : [
            {
              title: {
                value: null,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Метод лечения",
                label: "Метод лечения",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                },
              },
              price: {
                value: null,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Стоимость",
                label: "Стоимость",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                  integer: true,
                },
              },
            },
          ];
    newState.questionarie.treatment_methods.valid = true;
    newState.questionarie.dynamic_observation_clinic_title.value =
      editedDoctorProfile.dynamic_observation_clinic_title;
    newState.questionarie.dynamic_observation_clinic_title.valid = true;
    newState.questionarie.dynamic_observation_clinic_address.value =
      editedDoctorProfile.dynamic_observation_clinic_address;
    newState.questionarie.dynamic_observation_clinic_address.valid = true;
    newState.questionarie.dynamic_observation_methods =
      editedDoctorProfile.dynamic_observation_methods.length !== 0
        ? editedDoctorProfile.dynamic_observation_methods.map((obj) => {
            return {
              title: {
                value: obj.title,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Технологии и услуги",
                label: "Технологии и услуги",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                },
              },
              price: {
                value: obj.price,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Стоимость",
                label: "Стоимость",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                  integer: true,
                },
              },
            };
          })
        : [
            {
              title: {
                value: null,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Технологии и услуги",
                label: "Технологии и услуги",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                },
              },
              price: {
                value: null,
                type: "text",
                errorMessage: "Обязательное поле",
                placeholder: "Стоимость",
                label: "Стоимость",
                valid: true,
                touched: true,
                shouldValidate: true,
                validation: {
                  required: false,
                  integer: true,
                },
              },
            },
          ];

    newState.questionarie.dynamic_observation_methods.valid = true;
    newState.questionarie.description.value = editedDoctorProfile.description;
    newState.questionarie.description.valid = true;

    this.setState({
      ...newState,
    });
  }
  // добавление фото
  addImageHadler = (event) => {
    this.props.loadPicture(event.target.files[0]);
    this.setState({
      accountInfoTouched: true,
      isUserpicLoading: true,
    });
  };
  removeImageHandler = () => {
    this.props.removePicture();
  };
  onChangeInputHandler = (event, controlName) => {
    const formControls = { ...this.state.personalData };
    const control = { ...formControls[controlName] };

    if (control.type === "address") {
      control.value = event;
      // для инпутов с типом адрес значение хранится в event
    } else if (control.type === "bic") {
      control.value = event.data ? event.data.bic : "";
    } else {
      control.value = event.target.value;
    }
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);

    if (
      (controlName === "registration_address" && this.state.isAddressesMatch) ||
      controlName === "organization_address"
    ) {
      const real_address = { ...formControls[controlName] };
      real_address.value = control.value;
      real_address.touched = true;
      real_address.valid = control.valid;
      formControls[controlName] = real_address;
    }

    if (controlName === "organization_bik") {
      const bic = { ...formControls[controlName] };
      bic.value = control.value;
      bic.touched = true;
      bic.valid = control.valid;
      formControls[controlName] = bic;
    }

    formControls[controlName] = control;

    let accountInfoTouched = true;
    let accountInfoValid = true;
    Object.keys(formControls).forEach((key) => {
      if (!formControls[key].valid) {
        accountInfoValid = false;
      }
    });
    this.setState({
      accountInfoTouched: accountInfoTouched,
      accountInfoValid: accountInfoValid,
      personalData: formControls,
    });
  };
  onChangeDateHandler = (date) => {
    let personalData = { ...this.state.personalData };
    personalData.date_of_birth.value = date;
    personalData.date_of_birth.touched = true;
    personalData.date_of_birth.valid = validateControl(
      personalData.date_of_birth.value,
      personalData.date_of_birth.validation
    );
    this.setState({
      accountInfoTouched: true,
      accountInfoValid: personalData.date_of_birth.valid,
      personalData: personalData,
    });
  };
  onChangeAgreementDateHandler = (date) => {
    let personalData = { ...this.state.personalData };
    personalData.organization_agreement_date.value = date;
    personalData.organization_agreement_date.touched = true;
    personalData.organization_agreement_date.valid = validateControl(
      personalData.organization_agreement_date.value,
      personalData.organization_agreement_date.validation
    );
    this.setState({
      accountInfoTouched: true,
      accountInfoValid: personalData.organization_agreement_date.valid,
      personalData: personalData,
    });
  };
  genderChangeHandler = (gender) => {
    let personalData = { ...this.state.personalData };
    personalData.gender.value = gender;
    personalData.gender.touched = true;
    let accountInfoTouched = true;
    this.setState({
      accountInfoTouched: accountInfoTouched,
      personalData: personalData,
    });
  };
  onChangeInputPassHandler = (event, controlName) => {
    const formControls = { ...this.state.passChange };
    const control = { ...formControls[controlName] };

    control.value = event.target.value;
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);
    formControls[controlName] = control;

    let isPasswordsMatch = false;
    if (formControls.password.value === formControls.repeatPassword.value) {
      isPasswordsMatch = true;
      formControls.repeatPassword.valid = isPasswordsMatch;
    } else {
      formControls.repeatPassword.valid = isPasswordsMatch;
    }
    let passwordInputValid = true;
    Object.keys(formControls).forEach((key) => {
      if (!formControls[key].valid) {
        passwordInputValid = false;
      }
    });

    this.setState({
      passwordInputValid: passwordInputValid,
      passChange: formControls,
      passwordMatch: isPasswordsMatch,
    });
  };
  // отправка формы с личными данными
  sendPersonalData() {
    let data = {
      id: this.props.match.params.id,
      first_name: this.state.personalData.first_name.value,
      second_name: this.state.personalData.second_name.value,
      patronymic_name: this.state.personalData.patronymic_name.value,
      gender: this.state.personalData.gender.value,
      date_of_birth: convertDateToString(
        this.state.personalData.date_of_birth.value
      ),
      registration_address:
        this.state.personalData.registration_address.value.value ||
        this.state.personalData.registration_address.value,
      real_address:
        this.state.personalData.registration_address.value.value ||
        this.state.personalData.registration_address.value,
      userpic_url: this.props.userpic_url,
      organization_name: this.state.personalData.organization_name.value,
      organization_address: this.state.personalData.organization_address.value
        ? this.state.personalData.organization_address.value.value ||
          this.state.personalData.organization_address.value
        : null,
      organization_phone: this.state.personalData.organization_phone.value,
      organization_email: this.state.personalData.organization_email.value,
      organization_bik: this.state.personalData.organization_bik.value
        ? this.state.personalData.organization_bik.value.value ||
          this.state.personalData.organization_bik.value
        : null,
      organization_account: this.state.personalData.organization_account.value,
      organization_agreement_number:
        this.state.personalData.organization_agreement_number.value,
      organization_agreement_date: convertDateToString(
        this.state.personalData.organization_agreement_date.value
      ),
    };
    if (this.props.role !== "doctor") {
      this.props.editAccountInfoByID(data);
    } else {
      this.props.editAccountInfo(data);
    }

    if (this.props.accountEditStatus !== "") {
      this.modalProfileOpen();
    }
    this.setState({ accountInfoTouched: false });
  }
  // смена пароля
  sendNewPassword() {
    let data = {
      id: this.props.match.params.id,
      password: this.state.passChange.password.value,
      repeatPassword: this.state.passChange.repeatPassword.value,
    };
    if (this.props.role === "doctor") {
      data = {
        new_password1: this.state.passChange.password.value,
        new_password2: this.state.passChange.repeatPassword.value,
        old_password: this.state.passChange.oldPassword.value,
      };
      this.props.changePassword(data);
    } else {
      this.props.sendNewPassword(data);
    }
    if (this.props.accountEditStatus !== "") {
      this.modalProfileOpen();
    }
    let newState = { ...this.state };
    newState.passChange.password.value = "";
    newState.passChange.repeatPassword.value = "";
    if (this.props.role === "doctor") {
      newState.passChange.oldPassword.value = "";
    }
    this.setState({ ...newState });
  }
  //  ------------ методы анкеты
  // отправка формы с личными данными
  sendQuestionarie() {
    let data = {
      id: this.props.match.params.id,
      medical_speciality_title:
        this.state.questionarie.medical_speciality_title.value,
      medical_speciality_work_experience:
        this.state.questionarie.medical_speciality_work_experience.value ||
        null,
      qualification_category:
        QUALIFICATION_CATEGORIES[
          this.state.questionarie.qualification_category.value
        ],
      academic_degree:
        ACADEMIC_DEGREES[this.state.questionarie.academic_degree.value],
      academic_title:
        ACADEMIC_TITLES[this.state.questionarie.academic_title.value],
      specialization_id: this.state.questionarie.specialization_id.value + 1,
      professional_skills:
        this.state.questionarie.professional_skills.array_value,
      main_education: this.state.questionarie.main_education.value,
      internship: this.state.questionarie.internship.value,
      residency: this.state.questionarie.residency.value,
      further_education: this.state.questionarie.further_education.reduce(
        (res, obj) => {
          if (
            obj.title.value ||
            obj.passing_date.value ||
            obj.document_url.value[0]
          ) {
            res.push({
              title: obj.title.value,
              passing_date: convertDateToString(obj.passing_date.value),
              document_url: obj.document_url.value[0],
            });
          }
          return res;
        },
        []
      ),
      work_experience: this.state.questionarie.work_experience.reduce(
        (res, obj) => {
          if (
            obj.title.value ||
            obj.start_date.value ||
            obj.end_date.value ||
            obj.organization_website.value
          ) {
            res.push({
              title: obj.title.value,
              start_date: convertDateToString(obj.start_date.value),
              end_date: convertDateToString(obj.end_date.value),
              organization_website: obj.organization_website.value,
            });
          }
          return res;
        },
        []
      ),
      research_interests: this.state.questionarie.research_interests.value,
      scientific_publications_count:
        this.state.questionarie.scientific_publications_count.value,
      patents_count: this.state.questionarie.patents_count.value,
      guidelines_count: this.state.questionarie.guidelines_count.value,
      professional_medical_association:
        this.state.questionarie.professional_medical_association.value,
      diseases_and_health_problems:
        this.state.questionarie.diseases_and_health_problems.array_value,
      working_hours_end_weekdays:
        this.state.questionarie.working_hours_end_weekdays.value === "--:--" ||
        this.state.questionarie.working_hours_end_weekdays.value === ""
          ? null
          : this.state.questionarie.working_hours_end_weekdays.value,
      working_hours_end_weekends:
        this.state.questionarie.working_hours_end_weekends.value === "--:--" ||
        this.state.questionarie.working_hours_end_weekends.value === ""
          ? null
          : this.state.questionarie.working_hours_end_weekends.value,
      working_hours_start_weekdays:
        this.state.questionarie.working_hours_start_weekdays.value ===
          "--:--" ||
        this.state.questionarie.working_hours_start_weekdays.value === ""
          ? null
          : this.state.questionarie.working_hours_start_weekdays.value,
      working_hours_start_weekends:
        this.state.questionarie.working_hours_start_weekends.value ===
          "--:--" ||
        this.state.questionarie.working_hours_start_weekends.value === ""
          ? null
          : this.state.questionarie.working_hours_start_weekends.value,
      contact_phone: this.state.questionarie.contact_phone.value,
      work_phone: this.state.questionarie.work_phone.value,
      email: this.state.questionarie.email.value,
      clinic_title: this.state.questionarie.clinic_title.value,
      clinic_address:
        this.state.questionarie.clinic_address.value.value ||
        this.state.questionarie.clinic_address.value,
      clinic_website: this.state.questionarie.clinic_website.value,
      home_appointments_available:
        this.state.questionarie.home_appointments_available.value,
      consultation_specialization_id:
        this.state.questionarie.consultation_specialization_id.value + 1,
      clinic_consultation_price:
        this.state.questionarie.clinic_consultation_price.value,
      home_consultation_price:
        this.state.questionarie.home_consultation_price.value,
      video_consultation_price:
        this.state.questionarie.video_consultation_price.value,
      consultation_clinic_title:
        this.state.questionarie.consultation_clinic_title.value,
      consultation_clinic_address:
        this.state.questionarie.consultation_clinic_address.value.value ||
        this.state.questionarie.consultation_clinic_address.value,
      diagnostic_methods: this.state.questionarie.diagnostic_methods.reduce(
        (res, obj) => {
          if (obj.title.value || obj.price.value) {
            res.push({
              title: obj.title.value,
              price: obj.price.value,
            });
          }
          return res;
        },
        []
      ),
      diagnostic_clinic_title:
        this.state.questionarie.diagnostic_clinic_title.value,
      diagnostic_clinic_address:
        this.state.questionarie.diagnostic_clinic_address.value.value ||
        this.state.questionarie.diagnostic_clinic_address.value,
      treatment_clinic_title:
        this.state.questionarie.treatment_clinic_title.value,
      treatment_clinic_address:
        this.state.questionarie.treatment_clinic_address.value.value ||
        this.state.questionarie.treatment_clinic_address.value,
      treatment_methods: this.state.questionarie.treatment_methods.reduce(
        (res, obj) => {
          if (obj.title.value || obj.price.value) {
            res.push({ title: obj.title.value, price: obj.price.value });
          }
          return res;
        },
        []
      ),
      dynamic_observation_clinic_title:
        this.state.questionarie.dynamic_observation_clinic_title.value,
      dynamic_observation_clinic_address:
        this.state.questionarie.dynamic_observation_clinic_address.value
          .value ||
        this.state.questionarie.dynamic_observation_clinic_address.value,
      dynamic_observation_methods:
        this.state.questionarie.dynamic_observation_methods.reduce(
          (res, obj) => {
            if (obj.title.value || obj.price.value) {
              res.push({
                title: obj.title.value,
                price: obj.price.value,
              });
            }
            return res;
          },
          []
        ),
      description: this.state.questionarie.description.value,
      bookform_url:
        this.state.questionarie.bookform.value === ""
          ? null
          : this.state.questionarie.bookform.value,
    };
    if (
      this.props.editedDoctorProfile === null &&
      this.props.role === "doctor"
    ) {
      this.props.createDoctorProfile(data);
    } else if (
      this.props.editedDoctorProfile === null &&
      this.props.role !== "doctor"
    ) {
      this.props.createDoctorProfileByID(data);
    } else if (this.props.role !== "doctor") {
      this.props.updateDoctorProfileByID(data);
    } else {
      this.props.updateDoctorProfileMy(data);
    }

    if (this.props.dataFetchStatus !== "") {
      this.modalQuestionarieOpen();
    }
    this.setState({ questionarieTouched: false });
  }
  checkQuestionarieValidation = () => {
    let questionarieValid = true;
    let firstInvalidIntputKey = null;
    Object.keys(this.state.questionarie).forEach((key) => {
      if (
        key === "further_education" ||
        key === "work_experience" ||
        key === "diagnostic_methods" ||
        key === "treatment_methods" ||
        key === "dynamic_observation_methods"
      ) {
        Object.keys(this.state.questionarie[key]).forEach((objKey) => {
          Object.keys(this.state.questionarie[key][objKey]).forEach((input) => {
            if (
              !this.state.questionarie[key][objKey][input].valid &&
              this.state.questionarie[key][objKey][input].shouldValidate
            ) {
              questionarieValid = false;
              if (!firstInvalidIntputKey) {
                firstInvalidIntputKey = key;
              }
            }
          });
        });
      } else if (key === "diseases_and_health_problems") {
        if (this.state.questionarie[key].array_value.length <= 0) {
          questionarieValid = false;
          if (!firstInvalidIntputKey) {
            firstInvalidIntputKey = key;
          }
        }
      } else {
        if (
          !this.state.questionarie[key].valid &&
          this.state.questionarie[key].shouldValidate
        ) {
          questionarieValid = false;
          if (!firstInvalidIntputKey) {
            firstInvalidIntputKey = key;
          }
        }
      }
    });
    return [questionarieValid, firstInvalidIntputKey];
  };
  onChangeInputQuestionarieHandler = (event, controlName) => {
    let newState = { ...this.state };
    const formControls = newState.questionarie;
    const control = { ...formControls[controlName] };

    if (control.type === "address") {
      control.value = event;
      // для инпутов с типом адрес значение хранится в event
    } else {
      control.value = event.target.value;
    }
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);
    formControls[controlName] = control;

    newState.questionarieTouched = true;

    const checkValidation = this.checkQuestionarieValidation();
    newState.questionarieValid = checkValidation[0];
    newState.firstInvalidIntputKey = checkValidation[1];

    this.setState({
      ...newState,
    });
  };
  specializationChangeHandler = (event, controlName) => {
    let newState = { ...this.state };
    newState.questionarie[controlName].value = Number.parseInt(
      event.target.value
    );
    newState.questionarie[controlName].touched = true;
    newState.questionarieTouched = true;
    const checkValidation = this.checkQuestionarieValidation();
    newState.questionarieValid = checkValidation[0];
    newState.firstInvalidIntputKey = checkValidation[1];
    this.setState({
      ...newState,
    });
  };
  onChangeEnumInputHandler = (event, controlName) => {
    let newState = { ...this.state };
    const formControls = newState.questionarie;
    const control = { ...formControls[controlName] };

    control.array_value = convertInputToPoints(event.target.value);
    control.string_value = event.target.value;
    control.touched = true;
    control.valid = validateControl(control.string_value, control.validation);
    formControls[controlName] = control;

    newState.questionarieTouched = true;
    const checkValidation = this.checkQuestionarieValidation();
    newState.questionarieValid = checkValidation[0];
    newState.firstInvalidIntputKey = checkValidation[1];

    this.setState({
      ...newState,
    });
  };
  addFurtherEducation = () => {
    let newState = { ...this.state };
    newState.questionarie.further_education.push({
      title: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Наименование курса",
        label: "Наименование курса",
        valid: true,
        touched: true,
      },
      passing_date: {
        value: null,
        type: "date",
        errorMessage: "Обязательное поле",
        placeholder: "Дата прохождения",
        label: "Дата прохождения",
        valid: true,
        touched: true,
      },
      document_url: {
        value: [],
        valid: true,
        touched: true,
      },
    });
    const checkValidation = this.checkQuestionarieValidation();

    newState.questionarieValid = checkValidation[0];
    newState.firstInvalidIntputKey = checkValidation[1];
    newState.questionarieTouched = true;

    this.setState({ ...newState });
  };
  onChangeTimeHandler = (event, controlName) => {
    let newState = { ...this.state };
    newState.questionarie[controlName].value = event;
    newState.questionarieTouched = true;
    const checkValidation = this.checkQuestionarieValidation();
    newState.questionarieValid = checkValidation[0];
    newState.firstInvalidIntputKey = checkValidation[1];
    this.setState({
      ...newState,
    });
  };
  onChangeDynamicInputHandler = (
    event,
    controlName,
    index,
    dynamicName,
    date
  ) => {
    let newState = { ...this.state };
    let questionarie = { ...newState.questionarie };
    let dynamicItem = [...questionarie[dynamicName]];
    let formControls = { ...dynamicItem[index] };
    const control = { ...formControls[controlName] };
    if (control.type === "date" && date !== undefined) {
      control.value = date;
    } else {
      control.value = event.target.value;
    }
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);
    formControls[controlName] = control;

    newState.questionarieTouched = true;

    dynamicItem[index] = formControls;
    questionarie[dynamicName] = dynamicItem;
    newState.questionarie = questionarie;
    this.setState({
      ...newState,
    });
  };
  addExperience = () => {
    let newState = { ...this.state };
    newState.questionarie.work_experience.push({
      title: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Название учреждения",
        label: "Название учреждения",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
        },
      },
      start_date: {
        value: null,
        type: "date",
        errorMessage: "Обязательное поле",
        placeholder: "Дата начала",
        label: "Дата начала",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
        },
      },
      end_date: {
        value: null,
        type: "date",
        errorMessage: "Обязательное поле",
        placeholder: "Дата завершения",
        label: "Дата завершения",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
      organization_website: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Сайт учреждения или компания",
        label: "Сайт учреждения или компания",
        valid: true,
        touched: true,
        shouldValidate: false,
        validation: {},
      },
    });
    const checkValidation = this.checkQuestionarieValidation();

    newState.questionarieValid = checkValidation[0];
    newState.firstInvalidIntputKey = checkValidation[1];
    newState.questionarieTouched = true;

    this.setState({ ...newState });
  };
  addFileHandler = (event, index) => {
    let newState = { ...this.state };
    newState.questionarie.further_education[index].loading = true;
    this.setState({ ...newState });
    return new Promise((resolve, reject) => {
      var bodyFormData = new FormData();
      bodyFormData.append("file", event.target.files[0]);
      axios({
        method: "post",
        url: "/doctor_profiles/upload_document",
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
        .then((response) => {
          newState.questionarie.further_education[index].document_url.value = [
            response.data.url,
          ];
          newState.questionarie.further_education[index].loading = false;
          newState.questionarieTouched = true;
          const checkValidation = this.checkQuestionarieValidation();
          newState.questionarieValid = checkValidation[0];
          newState.firstInvalidIntputKey = checkValidation[1];
          this.setState({ ...newState });
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  removeFileHandler = (event, index) => {
    let newState = { ...this.state };
    newState.questionarie.further_education[index].document_url.value = "";
    newState.questionarieTouched = true;
    const checkValidation = this.checkQuestionarieValidation();
    newState.questionarieValid = checkValidation[0];
    newState.firstInvalidIntputKey = checkValidation[1];
    this.setState({ ...newState });
  };

  checkboxHandler = (event) => {
    let newState = { ...this.state };
    newState.questionarieTouched = true;
    const checkValidation = this.checkQuestionarieValidation();
    newState.questionarieValid = checkValidation[0];
    newState.firstInvalidIntputKey = checkValidation[1];
    if (event.target.checked) {
      newState.questionarie.home_appointments_available.value = true;
      this.setState({
        ...newState,
      });
    } else {
      newState.questionarie.home_appointments_available.value = false;
      this.setState({
        ...newState,
      });
    }
  };
  addDiagnostic = () => {
    let newState = { ...this.state };
    newState.questionarie.diagnostic_methods.push({
      title: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Методы инструментальной диагностики",
        label: "Методы инструментальной диагностики",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
        },
      },
      price: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Стоимость",
        label: "Стоимость",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
          integer: true,
        },
      },
    });
    const checkValidation = this.checkQuestionarieValidation();

    newState.questionarieValid = checkValidation[0];
    newState.firstInvalidIntputKey = checkValidation[1];
    newState.questionarieTouched = true;

    this.setState({ ...newState });
  };
  addTreatment = () => {
    let newState = { ...this.state };
    newState.questionarie.treatment_methods.push({
      title: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Метод лечения",
        label: "Метод лечения",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
        },
      },
      price: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Стоимость",
        label: "Стоимость",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
          integer: true,
        },
      },
    });
    const checkValidation = this.checkQuestionarieValidation();

    newState.questionarieValid = checkValidation[0];
    newState.firstInvalidIntputKey = checkValidation[1];
    newState.questionarieTouched = true;

    this.setState({ ...newState });
  };
  addDynamic = () => {
    let newState = { ...this.state };
    newState.questionarie.dynamic_observation_methods.push({
      title: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Технологии и услуги",
        label: "Технологии и услуги",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
        },
      },
      price: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Стоимость",
        label: "Стоимость",
        valid: true,
        touched: true,
        shouldValidate: true,
        validation: {
          required: false,
          integer: true,
        },
      },
    });
    const checkValidation = this.checkQuestionarieValidation();

    newState.questionarieValid = checkValidation[0];
    newState.firstInvalidIntputKey = checkValidation[1];
    newState.questionarieTouched = true;

    this.setState({ ...newState });
  };
  touchAllControls = (controlGroupName) => {
    const controls = { ...this.state[controlGroupName] };
    Object.keys(controls).forEach((controlName) => {
      controls[controlName].touched = true;
    });
    this.setState({
      [controlGroupName]: controls,
    });
  };
  scrollTo = (top) => {
    this.setState({
      topScroll: top,
      scrollTrigger: !this.state.scrollTrigger,
    });
  };

  changeUserStatusHandler = (newStatus) => {
    const data = { is_active: newStatus };
    this.props.changeUserStatus(this.props.account?.user_id, data);
  };

  changeUserDeletedStatusHandler = (newStatus) => {
    const data = { is_deleted: newStatus };
    this.props.changeUserDeletedStatus(this.props.account?.user_id, data);
  };

  deleteConfirmedHandler = () => {
    this.changeUserDeletedStatusHandler(true);
    this.confirmModalShowClose();
  };

  restoreConfirmedHandler = () => {
    this.changeUserDeletedStatusHandler(false);
    this.confirmModalShowClose();
  };

  render() {
    let fullName;

    if (this.props.account) {
      fullName = `${this.props.account.second_name} ${
        this.props.account.first_name
      }${
        this.props.account.patronymic_name
          ? " " + this.props.account.patronymic_name
          : ""
      }${this.props.account.second_name ? ". " : ""}`;
    }

    return (
      <section className={classes.DoctorEdit}>
        <Scroller top={this.state.topScroll} page={this.state.scrollTrigger} />
        {fullName ? (
          <Helmet>
            <title>{fullName}Портал здоровья</title>
            <meta name="title" content={`${fullName}Портал здоровья`} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:title" content={`${fullName}Портал здоровья`} />
          </Helmet>
        ) : null}
        {this.props.role !== "doctor" ? (
          <div className={classes.back}>
            <BackLink to="/profile/doctors" />
            <span>Назад</span>
          </div>
        ) : null}
        <div className={classes.title}>
          {fullName ? fullName.replace(".", "") : "Профиль"}
        </div>
        <form
          className={classes.form}
          onSubmit={(event) => event.preventDefault()}
        >
          <div className={classes.section_title}>
            <h5 id="password">Смена пароля</h5>
            {this.props.role === "admin" || this.props.role === "support" ? (
              this.props.account?.user.is_active ? (
                <span
                  className={classes.block_btn}
                  onClick={() => this.changeUserStatusHandler(false)}
                >
                  Заблокировать
                </span>
              ) : (
                <span
                  className={classes.block_btn}
                  onClick={() => this.changeUserStatusHandler(true)}
                >
                  Разблокировать
                </span>
              )
            ) : null}
            {this.props.role === "admin" || this.props.role === "support" ? (
              this.props.account?.user.is_deleted ? (
                <span
                  className={classes.block_btn}
                  onClick={this.confirmModalShowOpen}
                >
                  Восстановить
                </span>
              ) : (
                <span
                  className={classes.block_btn}
                  onClick={this.confirmModalShowOpen}
                >
                  Удалить
                </span>
              )
            ) : null}
          </div>
          <div className={classes.section_content}>
            <div className={classes.inputs}>
              {/* новый пароль */}
              {/* повтор нового пароля */}
              {renderInputs(
                this.state.passChange,
                this.onChangeInputPassHandler
              )}

              <Button
                disabled={
                  !(this.state.passwordInputValid && this.state.passwordMatch)
                }
                onClick={() => this.sendNewPassword()}
                type={"primary"}
                onDisableClick={() => {
                  this.touchAllControls("passChange");
                  this.scrollTo(document.getElementById("password").offsetTop);
                }}
                needTip={true}
              >
                Сохранить
              </Button>
            </div>
          </div>
          <h5 id="personal" className={classes.section_title}>
            Личные данные
          </h5>
          <div className={classes.section_withPhoto}>
            <div className={classes.inputs}>
              {/* фамилия */} {/* имя */}
              {/* отчество */}
              {renderInputs(
                {
                  second_name: this.state.personalData.second_name,
                  first_name: this.state.personalData.first_name,
                  patronymic_name: this.state.personalData.patronymic_name,
                },
                this.onChangeInputHandler
              )}
              <div className={classes.row}>
                {/* пол */}
                <GenderControl
                  value={this.state.personalData.gender.value}
                  onChange={(event) => this.genderChangeHandler(event)}
                />
                {/* дата рожд */}
                {renderInputs(
                  {
                    date_of_birth: this.state.personalData.date_of_birth,
                  },
                  (event, controlName, date) => this.onChangeDateHandler(date)
                )}
              </div>
              {/* адрес */}
              <Input
                type={this.state.personalData.registration_address.type}
                value={this.state.personalData.registration_address.value}
                placeholder={
                  this.state.personalData.registration_address.placeholder
                }
                valid={this.state.personalData.registration_address.valid}
                touched={this.state.personalData.registration_address.touched}
                label={this.state.personalData.registration_address.label}
                shouldValidate={
                  !!this.state.personalData.registration_address.validation
                }
                errorMessage={
                  this.state.personalData.registration_address.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "registration_address")
                }
              />
              {/* название организации */}
              <Input
                className={classes.Input}
                value={this.state.personalData.organization_name.value}
                placeholder={
                  this.state.personalData.organization_name.placeholder
                }
                valid={this.state.personalData.organization_name.valid}
                touched={this.state.personalData.organization_name.touched}
                label={this.state.personalData.organization_name.label}
                shouldValidate={
                  this.state.personalData.organization_name.shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_name.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "organization_name")
                }
              ></Input>
              {/* адрес местонахождения */}
              <Input
                className={classes.Input}
                type={this.state.personalData.organization_address.type}
                value={this.state.personalData.organization_address.value}
                placeholder={
                  this.state.personalData.organization_address.placeholder
                }
                valid={this.state.personalData.organization_address.valid}
                touched={this.state.personalData.organization_address.touched}
                label={this.state.personalData.organization_address.label}
                shouldValidate={
                  this.state.personalData.organization_address.shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_address.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "organization_address")
                }
              ></Input>
              {/* телефон */}
              <Input
                className={classes.Input}
                masked={this.state.personalData.organization_phone.masked}
                mask={this.state.personalData.organization_phone.mask}
                type={this.state.personalData.organization_phone.type}
                value={this.state.personalData.organization_phone.value}
                placeholder={
                  this.state.personalData.organization_phone.placeholder
                }
                valid={this.state.personalData.organization_phone.valid}
                touched={this.state.personalData.organization_phone.touched}
                label={this.state.personalData.organization_phone.label}
                shouldValidate={
                  this.state.personalData.organization_phone.shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_phone.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "organization_phone")
                }
              ></Input>
              {/* адрес электронной почты */}
              <Input
                className={classes.Input}
                value={this.state.personalData.organization_email.value}
                placeholder={
                  this.state.personalData.organization_email.placeholder
                }
                valid={this.state.personalData.organization_email.valid}
                touched={this.state.personalData.organization_email.touched}
                label={this.state.personalData.organization_email.label}
                shouldValidate={
                  this.state.personalData.organization_email.shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_email.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "organization_email")
                }
              ></Input>
              {/* бик банка */}
              <Input
                className={classes.Input}
                type={this.state.personalData.organization_bik.type}
                value={this.state.personalData.organization_bik.value}
                placeholder={
                  this.state.personalData.organization_bik.placeholder
                }
                valid={this.state.personalData.organization_bik.valid}
                touched={this.state.personalData.organization_bik.touched}
                label={this.state.personalData.organization_bik.label}
                shouldValidate={
                  this.state.personalData.organization_bik.shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_bik.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "organization_bik")
                }
              ></Input>
              {/* расчетный счёт */}
              <Input
                className={classes.Input}
                value={this.state.personalData.organization_account.value}
                placeholder={
                  this.state.personalData.organization_account.placeholder
                }
                valid={this.state.personalData.organization_account.valid}
                touched={this.state.personalData.organization_account.touched}
                label={this.state.personalData.organization_account.label}
                shouldValidate={
                  this.state.personalData.organization_account.shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_account.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "organization_account")
                }
              ></Input>
              {/* номер агентского договора */}
              <Input
                className={classes.Input}
                value={
                  this.state.personalData.organization_agreement_number.value
                }
                placeholder={
                  this.state.personalData.organization_agreement_number
                    .placeholder
                }
                valid={
                  this.state.personalData.organization_agreement_number.valid
                }
                touched={
                  this.state.personalData.organization_agreement_number.touched
                }
                label={
                  this.state.personalData.organization_agreement_number.label
                }
                shouldValidate={
                  this.state.personalData.organization_agreement_number
                    .shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_agreement_number
                    .errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(
                    event,
                    "organization_agreement_number"
                  )
                }
              ></Input>
              {/* дата агентского договора */}
              <Input
                className={classes.Input}
                type={this.state.personalData.organization_agreement_date.type}
                value={
                  this.state.personalData.organization_agreement_date.value
                }
                placeholder={
                  this.state.personalData.organization_agreement_date
                    .placeholder
                }
                valid={
                  this.state.personalData.organization_agreement_date.valid
                }
                touched={
                  this.state.personalData.organization_agreement_date.touched
                }
                label={
                  this.state.personalData.organization_agreement_date.label
                }
                shouldValidate={
                  this.state.personalData.organization_agreement_date
                    .shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_agreement_date
                    .errorMessage
                }
                onChange={(event, date) =>
                  this.onChangeAgreementDateHandler(date)
                }
              ></Input>
              <Button
                disabled={
                  !(
                    this.state.accountInfoTouched && this.state.accountInfoValid
                  )
                }
                onClick={() => this.sendPersonalData()}
                type={"primary"}
                onDisableClick={() => {
                  if (!this.props.account) {
                    this.touchAllControls("personalData");
                    this.scrollTo(
                      document.getElementById("personal").offsetTop
                    );
                  }
                }}
                needTip={!this.props.account}
              >
                Сохранить
              </Button>
            </div>
            {/* фото */}
            <div
              className={classes.picture_loader}
              style={{ backgroundImage: `url(${this.props.userpic_url})` }}
            >
              <input
                className={classes.picture_input}
                type={"file"}
                onChange={(event) => this.addImageHadler(event)}
              />
              {this.props.userpic_url ? (
                <div
                  className={classes.remove_btn}
                  onClick={() => this.removeImageHandler()}
                >
                  <img src={"/profile/images/cross.svg"} alt={"remove"} />
                </div>
              ) : (
                <div className={classes.add_btn}>
                  {this.state.isUserpicLoading ? (
                    <FileLoader style={{ height: "14px", margin: 0 }} />
                  ) : (
                    <img
                      className={classes.plus}
                      src={"/profile/images/plus.svg"}
                      alt={"add"}
                    />
                  )}
                  <div className={classes.add_btn__text}>Загрузить фото</div>
                </div>
              )}
            </div>
          </div>
          {/* ------------- АНКЕТА ---------------- */}
          {!this.state.showQuestionarie ? (
            <React.Fragment>
              <h5 className={classes.section_title}>{`${
                this.props.role === "doctor"
                  ? "Ваша анкета"
                  : "Анкета специалиста"
              }`}</h5>
              <div className={classes.section_content}>
                <div className={classes.inputs}>
                  <div className={classes.questionarie__instruction}>
                    Для того, чтобы приступить к работе на портале пациента,
                    необходимо заполнить анкету специалиста. Заполнение
                    информации может занять от 10 минут. Вы готовы?
                  </div>
                  <Button
                    disabled={false}
                    onClick={() => this.setState({ showQuestionarie: true })}
                    type={"additional"}
                  >
                    Начать
                  </Button>
                </div>
              </div>
            </React.Fragment>
          ) : null}
          {this.state.showQuestionarie ? (
            <React.Fragment>
              <h5
                id="medical_speciality_title"
                className={classes.section_title}
              >
                Направление специальности
              </h5>
              <div className={classes.section_content}>
                {/* название врачебной специальности */}
                <div className={classes.inputs_fullWidth}>
                  {renderInputs(
                    {
                      medical_speciality_title:
                        this.state.questionarie.medical_speciality_title,
                    },
                    this.onChangeInputQuestionarieHandler
                  )}
                </div>
                <div
                  id="medical_speciality_work_experience"
                  className={classes.columns}
                >
                  {/* стаж работы по врач спец */}
                  {renderInputs(
                    {
                      medical_speciality_work_experience:
                        this.state.questionarie
                          .medical_speciality_work_experience,
                    },
                    this.onChangeInputQuestionarieHandler
                  )}
                  {/* специализация */}
                  {this.props.specializations ? (
                    <Select
                      id="specialization_id"
                      value={this.state.questionarie.specialization_id.value}
                      onChange={(event) =>
                        this.specializationChangeHandler(
                          event,
                          "specialization_id"
                        )
                      }
                      data={this.props.specializations.map((i) => i.title)}
                      label={"Специализация*"}
                    />
                  ) : null}
                </div>
              </div>
              <h5
                id="medical_speciality_title"
                className={classes.section_title}
              >
                Описание квалификации
              </h5>
              <div className={classes.section_content}>
                {/* квалиф категория */}
                <div className={classes.inputs_fullWidth}>
                  <Select
                    id="qualification_category"
                    value={this.state.questionarie.qualification_category.value}
                    onChange={(event) =>
                      this.specializationChangeHandler(
                        event,
                        "qualification_category"
                      )
                    }
                    data={QUALIFICATION_CATEGORIES}
                    label={"Квалификационная категория врача"}
                  />
                </div>
                <div
                  id="medical_speciality_work_experience"
                  className={classes.columns}
                >
                  {/* учен степень */}
                  <Select
                    id="academic_degree"
                    value={this.state.questionarie.academic_degree.value}
                    onChange={(event) =>
                      this.specializationChangeHandler(event, "academic_degree")
                    }
                    data={ACADEMIC_DEGREES}
                    label={"Ученая степень"}
                  />
                  {/* науч звание */}
                  <Select
                    id="academic_title"
                    value={this.state.questionarie.academic_title.value}
                    onChange={(event) =>
                      this.specializationChangeHandler(event, "academic_title")
                    }
                    data={ACADEMIC_TITLES}
                    label={"Ученое звание"}
                  />
                </div>
              </div>
              <h5 id="professional_skills" className={classes.section_title}>
                Профессиональные навыки и применяемые методы лечения и
                диагностики
              </h5>
              <div className={classes.section_content}>
                <div className={classes.inputs_fullWidth}>
                  {/* навыки */}
                  <Input
                    value={
                      this.state.questionarie.professional_skills.string_value
                    }
                    type={this.state.questionarie.professional_skills.type}
                    placeholder={
                      this.state.questionarie.professional_skills.placeholder
                    }
                    valid={this.state.questionarie.professional_skills.valid}
                    touched={
                      this.state.questionarie.professional_skills.touched
                    }
                    label={this.state.questionarie.professional_skills.label}
                    shouldValidate={
                      this.state.questionarie.professional_skills.shouldValidate
                    }
                    errorMessage={
                      this.state.questionarie.professional_skills.errorMessage
                    }
                    onChange={(event) =>
                      this.onChangeEnumInputHandler(
                        event,
                        "professional_skills"
                      )
                    }
                  ></Input>
                </div>
              </div>
              {/* образование */}
              <h5 id="main_education" className={classes.section_title}>
                Образование
              </h5>
              <div id="internship" className={classes.section_content}>
                <div id="residency" className={classes.inputs_fullWidth}>
                  {renderInputs(
                    {
                      main_education: this.state.questionarie.main_education,
                      internship: this.state.questionarie.internship,
                      residency: this.state.questionarie.residency,
                    },
                    this.onChangeInputQuestionarieHandler
                  )}
                </div>
              </div>
              {/* Курсы повышения квалификации */}
              <h5 id="further_education" className={classes.section_title}>
                Курсы повышения квалификации
              </h5>
              <div className={classes.section_content}>
                <div className={classes.inputs_fullWidth}>
                  {this.state.questionarie.further_education.map(
                    (obj, index) => {
                      return [
                        // назваие
                        renderInputs(
                          {
                            title: obj.title,
                          },
                          (event, controlName) =>
                            this.onChangeDynamicInputHandler(
                              event,
                              controlName,
                              index,
                              "further_education"
                            )
                        ),
                        <div
                          className={[
                            classes.row,
                            classes.furtherEducation_row,
                          ].join(" ")}
                        >
                          {/* дата */}
                          <div className={classes.row__input}>
                            {renderInputs(
                              {
                                passing_date: obj.passing_date,
                              },
                              (event, controlName, date) =>
                                this.onChangeDynamicInputHandler(
                                  event,
                                  controlName,
                                  index,
                                  "further_education",
                                  date
                                )
                            )}
                          </div>

                          <div className={classes.row__input}>
                            <div
                              className={classes.furtherEducation__fileInput}
                            >
                              <div className={classes.fileInput__title}>
                                Добавить диплом или сертификат:
                              </div>

                              <FileControl
                                remove={(e) => this.removeFileHandler(e, index)}
                                files={[...obj.document_url.value]}
                                onChange={(e) => this.addFileHandler(e, index)}
                                maxFilesCount={1}
                              >
                                Загрузить документ
                              </FileControl>
                              {obj.loading ? (
                                <div className={classes.loader}>
                                  <FileLoader
                                    style={{ height: "15px", margin: 0 }}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>,
                        // добавить еще
                        this.state.questionarie.further_education.length - 1 ===
                        index ? (
                          <Button
                            disabled={false}
                            onClick={() => this.addFurtherEducation()}
                            type={"plus"}
                            text={"Добавить еще"}
                          ></Button>
                        ) : null,
                      ];
                    }
                  )}
                </div>
              </div>
              {/* Опыт работы */}
              <h5 id="work_experience" className={classes.section_title}>
                Опыт работы
              </h5>
              <div className={classes.section_content}>
                <div className={classes.inputs_fullWidth}>
                  {this.state.questionarie.work_experience.map((obj, index) => {
                    return [
                      // Название учреждения
                      renderInputs(
                        {
                          title: obj.title,
                        },
                        (event, controlName, date) =>
                          this.onChangeDynamicInputHandler(
                            event,
                            controlName,
                            index,
                            "work_experience",
                            date
                          )
                      ),
                      <div
                        className={[
                          classes.row,
                          classes.work_experience_row,
                        ].join(" ")}
                      >
                        <div className={classes.row__input}>
                          {renderInputs(
                            {
                              start_date: obj.start_date,
                            },
                            (event, controlName, date) =>
                              this.onChangeDynamicInputHandler(
                                event,
                                controlName,
                                index,
                                "work_experience",
                                date
                              )
                          )}
                        </div>
                        {/* Дата заверш */}
                        <div className={classes.row__input}>
                          {renderInputs(
                            {
                              end_date: obj.end_date,
                            },
                            (event, controlName, date) =>
                              this.onChangeDynamicInputHandler(
                                event,
                                controlName,
                                index,
                                "work_experience",
                                date
                              )
                          )}
                        </div>
                        {/* Сайт учреждения или компания */}
                        <div className={classes.row__input}>
                          {renderInputs(
                            {
                              organization_website: obj.organization_website,
                            },
                            (event, controlName) =>
                              this.onChangeDynamicInputHandler(
                                event,
                                controlName,
                                index,
                                "work_experience"
                              )
                          )}
                        </div>
                      </div>,
                      // добавить еще
                      this.state.questionarie.work_experience.length - 1 ===
                      index ? (
                        <Button
                          disabled={false}
                          onClick={() => this.addExperience()}
                          type={"plus"}
                          text={"Добавить еще"}
                        ></Button>
                      ) : null,
                    ];
                  })}
                </div>
              </div>
              {/* Опыт в научной деятельности */}
              <h5 className={classes.section_title} id="research_interests">
                Опыт в научной деятельности
              </h5>
              <div className={classes.section_content}>
                <div className={classes.inputs_fullWidth}>
                  {/* сфера науч интересов */}
                  {renderInputs(
                    {
                      research_interests:
                        this.state.questionarie.research_interests,
                    },
                    this.onChangeInputQuestionarieHandler
                  )}
                  <div className={classes.scienceCount}>
                    <div className={classes.scienceCount__title}>
                      Укажите количество профессиональных и научных достижений
                    </div>
                    {/* науч публик */}
                    <div className={classes.scienceCount__row}>
                      <div className={classes.scienceCount__row__input}>
                        {renderInputs(
                          {
                            scientific_publications_count:
                              this.state.questionarie
                                .scientific_publications_count,
                          },
                          this.onChangeInputQuestionarieHandler
                        )}
                      </div>
                      <div
                        id="scientific_publications_count"
                        className={classes.scienceCount__row__inputText}
                      >
                        научных публикаций
                      </div>
                    </div>
                    {/* патенты */}
                    <div className={classes.scienceCount__row}>
                      <div className={classes.scienceCount__row__input}>
                        {renderInputs(
                          {
                            patents_count:
                              this.state.questionarie.patents_count,
                          },
                          this.onChangeInputQuestionarieHandler
                        )}
                      </div>
                      <div
                        id="patents_count"
                        className={classes.scienceCount__row__inputText}
                      >
                        патентов
                      </div>
                    </div>
                    {/* метод реком */}
                    <div className={classes.scienceCount__row}>
                      <div className={classes.scienceCount__row__input}>
                        {renderInputs(
                          {
                            guidelines_count:
                              this.state.questionarie.guidelines_count,
                          },
                          this.onChangeInputQuestionarieHandler
                        )}
                      </div>
                      <div
                        id="guidelines_count"
                        className={classes.scienceCount__row__inputText}
                      >
                        методических рекомендаций
                      </div>
                    </div>
                  </div>

                  {/* член проф асс врачей */}
                  {renderInputs(
                    {
                      professional_medical_association:
                        this.state.questionarie
                          .professional_medical_association,
                    },
                    this.onChangeInputQuestionarieHandler
                  )}
                </div>
              </div>
              {/* Заболевания или проблемы здоровья, с которыми работает врач */}
              <h5
                id="diseases_and_health_problems"
                className={classes.section_title}
              >
                Заболевания или проблемы здоровья, с которыми работает врач
              </h5>
              <div className={classes.section_content}>
                <div className={classes.inputs_fullWidth}>
                  {/* Заболевания и проблемы со здоровьем */}
                  <Input
                    value={
                      this.state.questionarie.diseases_and_health_problems
                        .string_value
                    }
                    type={
                      this.state.questionarie.diseases_and_health_problems.type
                    }
                    placeholder={
                      this.state.questionarie.diseases_and_health_problems
                        .placeholder
                    }
                    valid={
                      this.state.questionarie.diseases_and_health_problems.valid
                    }
                    touched={
                      this.state.questionarie.diseases_and_health_problems
                        .touched
                    }
                    label={
                      this.state.questionarie.diseases_and_health_problems.label
                    }
                    shouldValidate={
                      this.state.questionarie.diseases_and_health_problems
                        .shouldValidate
                    }
                    errorMessage={
                      this.state.questionarie.diseases_and_health_problems
                        .errorMessage
                    }
                    onChange={(event) =>
                      this.onChangeEnumInputHandler(
                        event,
                        "diseases_and_health_problems"
                      )
                    }
                  ></Input>
                </div>
              </div>
              {/* Время приема */}
              <h5
                id="working_hours_start_weekdays"
                className={classes.section_title}
              >
                Режим работы специалиста
              </h5>
              <div className={classes.section_content}>
                <div className={classes.inputs_fullWidth}>
                  <table className={classes.table}>
                    <tr className={classes.table__trRow}>
                      <th className={classes.table__thRow}>Будни</th>
                      <td className={classes.table__tdRow}>
                        <div className={classes.time__pretext}>с</div>
                        <TimePicker
                          interval={30}
                          value={this.state.questionarie.working_hours_start_weekdays.value.slice(
                            0,
                            5
                          )}
                          onChange={(event) =>
                            this.onChangeTimeHandler(
                              event,
                              "working_hours_start_weekdays"
                            )
                          }
                        ></TimePicker>
                      </td>
                      <td className={classes.table__tdRow}>
                        <div
                          id="working_hours_end_weekdays"
                          className={classes.time__pretext}
                        >
                          до
                        </div>
                        <TimePicker
                          interval={30}
                          value={this.state.questionarie.working_hours_end_weekdays.value.slice(
                            0,
                            5
                          )}
                          onChange={(event) =>
                            this.onChangeTimeHandler(
                              event,
                              "working_hours_end_weekdays"
                            )
                          }
                        ></TimePicker>
                      </td>
                    </tr>
                    <tr className={classes.table__trRow}>
                      <th
                        id="working_hours_start_weekends"
                        className={classes.table__thRow}
                      >
                        Выходные
                      </th>
                      <td className={classes.table__tdRow}>
                        <div className={classes.time__pretext}>c</div>
                        <TimePicker
                          interval={30}
                          value={this.state.questionarie.working_hours_start_weekends.value.slice(
                            0,
                            5
                          )}
                          onChange={(event) =>
                            this.onChangeTimeHandler(
                              event,
                              "working_hours_start_weekends"
                            )
                          }
                        ></TimePicker>
                      </td>
                      <td className={classes.table__tdRow}>
                        <div
                          id="working_hours_end_weekends"
                          className={classes.time__pretext}
                        >
                          до
                        </div>
                        <TimePicker
                          interval={30}
                          value={this.state.questionarie.working_hours_end_weekends.value.slice(
                            0,
                            5
                          )}
                          onChange={(event) =>
                            this.onChangeTimeHandler(
                              event,
                              "working_hours_end_weekends"
                            )
                          }
                        ></TimePicker>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              {/* Контактные данные */}
              <h5 id="contact_phone" className={classes.section_title}>
                Контактные данные для записи на прием
              </h5>
              <div id="work_phone" className={classes.section_content}>
                <div id="email" className={classes.inputs}>
                  {/* телефон почта*/}
                  {renderInputs(
                    {
                      contact_phone: this.state.questionarie.contact_phone,
                      work_phone: this.state.questionarie.work_phone,
                      email: this.state.questionarie.email,
                    },
                    this.onChangeInputQuestionarieHandler
                  )}
                </div>
              </div>
              {/* Место проведения приёма */}
              <h5 id="clinic_title" className={classes.section_title}>
                Место проведения приёма
              </h5>
              <div id="clinic_address" className={classes.section_content}>
                <div id="clinic_website" className={classes.inputs_fullWidth}>
                  {/* название адрес сайт клиники*/}
                  {renderInputs(
                    {
                      clinic_title: this.state.questionarie.clinic_title,
                      clinic_address: this.state.questionarie.clinic_address,
                      clinic_website: this.state.questionarie.clinic_website,
                    },
                    this.onChangeInputQuestionarieHandler
                  )}
                  {/* возм выезда на дом */}
                  <label className={classes.checkbox}>
                    <input
                      id="home_appointments_available"
                      type="checkbox"
                      onChange={(event) => {
                        this.checkboxHandler(event);
                      }}
                      checked={
                        this.state.questionarie.home_appointments_available
                          .value
                      }
                    />
                    <span className={classes.checkmark}></span>
                    Возможность выезда на дом
                  </label>
                </div>
              </div>
              {/* Врачебная консультация в качестве */}
              <h5
                id="consultation_specialization_id"
                className={classes.section_title}
              >
                Врачебная консультация в качестве
              </h5>
              <div className={classes.section_content}>
                <div className={classes.inputs}>
                  {this.props.specializations ? (
                    <Select
                      value={
                        this.state.questionarie.consultation_specialization_id
                          .value
                      }
                      onChange={(event) =>
                        this.specializationChangeHandler(
                          event,
                          "consultation_specialization_id"
                        )
                      }
                      data={this.props.specializations.map((i) => i.title)}
                      label={"Специализация"}
                    />
                  ) : null}
                </div>
                <div
                  id="clinic_consultation_price"
                  className={classes.consultationCount__row}
                >
                  <div className={classes.consultationCount__row__input}>
                    {renderInputs(
                      {
                        clinic_consultation_price:
                          this.state.questionarie.clinic_consultation_price,
                      },
                      this.onChangeInputQuestionarieHandler
                    )}
                  </div>
                  <div className={classes.consultationCount__row__inputText}>
                    Очная в клинике
                  </div>
                </div>
                <div
                  id="home_consultation_price"
                  className={classes.consultationCount__row}
                >
                  <div className={classes.consultationCount__row__input}>
                    {renderInputs(
                      {
                        home_consultation_price:
                          this.state.questionarie.home_consultation_price,
                      },
                      this.onChangeInputQuestionarieHandler
                    )}
                  </div>
                  <div className={classes.consultationCount__row__inputText}>
                    Очная на дому у пациента
                  </div>
                </div>
                <div
                  id="video_consultation_price"
                  className={classes.consultationCount__row}
                >
                  <div className={classes.consultationCount__row__input}>
                    {renderInputs(
                      {
                        video_consultation_price:
                          this.state.questionarie.video_consultation_price,
                      },
                      this.onChangeInputQuestionarieHandler
                    )}
                  </div>
                  <div className={classes.consultationCount__row__inputText}>
                    Видео-консультация
                  </div>
                </div>
                <div
                  id="consultation_clinic_title"
                  className={classes.inputs_fullWidth}
                >
                  {/* наименов клиники */}
                  {renderInputs(
                    {
                      consultation_clinic_title:
                        this.state.questionarie.consultation_clinic_title,
                    },
                    this.onChangeInputQuestionarieHandler
                  )}
                  {/* адр клиники */}
                  {renderInputs(
                    {
                      consultation_clinic_address:
                        this.state.questionarie.consultation_clinic_address,
                    },
                    this.onChangeInputQuestionarieHandler
                  )}
                </div>
              </div>
              {/* Применяемые методы инструментальной диагностики */}
              <h5 id="diagnostic_methods" className={classes.section_title}>
                Применяемые методы инструментальной диагностики
              </h5>
              <div className={classes.section_content}>
                <div className={classes.inputs_fullWidth}>
                  {this.state.questionarie.diagnostic_methods.map(
                    (obj, index) => {
                      return [
                        // Методы инструментальной диагностики
                        <div className={classes.methods__row}>
                          <div className={classes.methods__title}>
                            {renderInputs(
                              {
                                title: obj.title,
                              },
                              (event, controlName) =>
                                this.onChangeDynamicInputHandler(
                                  event,
                                  controlName,
                                  index,
                                  "diagnostic_methods"
                                )
                            )}
                          </div>
                          <div className={classes.methods__price}>
                            {renderInputs(
                              {
                                price: obj.price,
                              },
                              (event, controlName) =>
                                this.onChangeDynamicInputHandler(
                                  event,
                                  controlName,
                                  index,
                                  "diagnostic_methods"
                                )
                            )}
                          </div>
                        </div>,
                        // добавить еще
                        this.state.questionarie.diagnostic_methods.length -
                          1 ===
                        index ? (
                          <Button
                            disabled={false}
                            onClick={() => this.addDiagnostic()}
                            type={"plus"}
                            text={"Добавить еще"}
                          ></Button>
                        ) : null,
                      ];
                    }
                  )}
                  <div
                    id="diagnostic_clinic_title"
                    style={{ marginTop: "32px" }}
                  >
                    {renderInputs(
                      {
                        diagnostic_clinic_title:
                          this.state.questionarie.diagnostic_clinic_title,
                      },
                      this.onChangeInputQuestionarieHandler
                    )}
                    {renderInputs(
                      {
                        diagnostic_clinic_address:
                          this.state.questionarie.diagnostic_clinic_address,
                      },
                      this.onChangeInputQuestionarieHandler
                    )}
                  </div>
                </div>
              </div>
              {/* Применяемые лечебно-профилактические и реабилитационные услуги и методы лечения */}
              <h5 id="treatment_methods" className={classes.section_title}>
                Применяемые лечебно-профилактические и реабилитационные услуги и
                методы лечения
              </h5>
              <div className={classes.section_content}>
                <div className={classes.inputs_fullWidth}>
                  {this.state.questionarie.treatment_methods.map(
                    (obj, index) => {
                      return [
                        // Методы инструментальной диагностики
                        <div className={classes.methods__row}>
                          <div className={classes.methods__title}>
                            {renderInputs(
                              {
                                title: obj.title,
                              },
                              (event, controlName) =>
                                this.onChangeDynamicInputHandler(
                                  event,
                                  controlName,
                                  index,
                                  "treatment_methods"
                                )
                            )}
                          </div>
                          <div className={classes.methods__price}>
                            {renderInputs(
                              {
                                price: obj.price,
                              },
                              (event, controlName) =>
                                this.onChangeDynamicInputHandler(
                                  event,
                                  controlName,
                                  index,
                                  "treatment_methods"
                                )
                            )}
                          </div>
                        </div>,
                        // добавить еще
                        this.state.questionarie.treatment_methods.length - 1 ===
                        index ? (
                          <Button
                            disabled={false}
                            onClick={() => this.addTreatment()}
                            type={"plus"}
                            text={"Добавить еще"}
                          ></Button>
                        ) : null,
                      ];
                    }
                  )}
                  <div
                    id="treatment_clinic_title"
                    style={{ marginTop: "32px" }}
                  >
                    {renderInputs(
                      {
                        treatment_clinic_title:
                          this.state.questionarie.treatment_clinic_title,
                      },
                      this.onChangeInputQuestionarieHandler
                    )}
                    {renderInputs(
                      {
                        treatment_clinic_address:
                          this.state.questionarie.treatment_clinic_address,
                      },
                      this.onChangeInputQuestionarieHandler
                    )}
                  </div>
                </div>
              </div>
              {/* Применяемые технологии и услуги врачебного динамического наблюдения за факторами риска пациента */}
              <h5
                id="dynamic_observation_methods"
                className={classes.section_title}
              >
                Применяемые технологии и услуги врачебного динамического
                наблюдения за факторами риска пациента
              </h5>
              <div className={classes.section_content}>
                <div className={classes.inputs_fullWidth}>
                  {this.state.questionarie.dynamic_observation_methods.map(
                    (obj, index) => {
                      return [
                        // Методы инструментальной диагностики
                        <div className={classes.methods__row}>
                          <div className={classes.methods__title}>
                            {renderInputs(
                              {
                                title: obj.title,
                              },
                              (event, controlName) =>
                                this.onChangeDynamicInputHandler(
                                  event,
                                  controlName,
                                  index,
                                  "dynamic_observation_methods"
                                )
                            )}
                          </div>
                          <div className={classes.methods__price}>
                            {renderInputs(
                              {
                                price: obj.price,
                              },
                              (event, controlName) =>
                                this.onChangeDynamicInputHandler(
                                  event,
                                  controlName,
                                  index,
                                  "dynamic_observation_methods"
                                )
                            )}
                          </div>
                        </div>,
                        // добавить еще
                        this.state.questionarie.dynamic_observation_methods
                          .length -
                          1 ===
                        index ? (
                          <Button
                            disabled={false}
                            onClick={() => this.addDynamic()}
                            type={"plus"}
                            text={"Добавить еще"}
                          ></Button>
                        ) : null,
                      ];
                    }
                  )}
                  <div
                    id="dynamic_observation_clinic_title"
                    style={{ marginTop: "32px" }}
                  >
                    {renderInputs(
                      {
                        dynamic_observation_clinic_title:
                          this.state.questionarie
                            .dynamic_observation_clinic_title,
                      },
                      this.onChangeInputQuestionarieHandler
                    )}
                    {renderInputs(
                      {
                        dynamic_observation_clinic_address:
                          this.state.questionarie
                            .dynamic_observation_clinic_address,
                      },
                      this.onChangeInputQuestionarieHandler
                    )}
                  </div>
                </div>
              </div>
              <h5
                id="diseases_and_health_problems"
                className={classes.section_title}
              >
                Ссылка на расписание
              </h5>
              <div className={classes.section_content}>
                <div className={classes.inputs_fullWidth}>
                  {/* Заболевания и проблемы со здоровьем */}
                  <Input
                    value={this.state.questionarie.bookform.value}
                    type={this.state.questionarie.bookform.type}
                    placeholder={this.state.questionarie.bookform.placeholder}
                    valid={this.state.questionarie.bookform.valid}
                    touched={this.state.questionarie.bookform.touched}
                    label={this.state.questionarie.bookform.label}
                    shouldValidate={
                      this.state.questionarie.bookform.shouldValidate
                    }
                    errorMessage={this.state.questionarie.bookform.errorMessage}
                    onChange={(event) =>
                      this.onChangeInputQuestionarieHandler(event, "bookform")
                    }
                  ></Input>
                </div>
              </div>
              {/* Опишите кратко свои навыки и специализацию */}
              <h5 id="description" className={classes.section_title}>
                Опишите кратко свои навыки и специализацию
              </h5>
              <div className={classes.section_content}>
                <div className={classes.inputs_fullWidth}>
                  {!this.state.questionarie.description.valid ? (
                    <span className={classes.errorMessage}>
                      Обязательное поле
                    </span>
                  ) : null}
                  <textarea
                    value={this.state.questionarie.description.value}
                    className={[
                      classes.textarea,
                      !this.state.questionarie.description.valid
                        ? classes.textarea_invalid
                        : null,
                    ].join(" ")}
                    placeholder={"Краткое описание специалиста"}
                    onChange={(event) =>
                      this.onChangeInputQuestionarieHandler(
                        event,
                        "description"
                      )
                    }
                  />
                </div>
                <div className={classes.inputs}>
                  <div
                    style={{
                      marginBottom: "26px",
                      fontSize: "14px",
                      lineHeight: "24px",
                    }}
                  >
                    Внимательно проверьте все данные, которые ввели. Если все
                    хорошо, то отправляйте
                  </div>
                  <Button
                    disabled={
                      !(
                        this.state.questionarieTouched &&
                        this.state.questionarieValid
                      )
                    }
                    onClick={() => this.sendQuestionarie()}
                    type={"primary"}
                    onDisableClick={() => {
                      this.scrollTo(
                        document.getElementById(
                          this.state.firstInvalidIntputKey
                        )
                          ? document.getElementById(
                              this.state.firstInvalidIntputKey
                            ).offsetTop - 250
                          : null
                      );
                    }}
                    needTip={true}
                  >
                    Готово
                  </Button>
                </div>
              </div>
            </React.Fragment>
          ) : null}
        </form>
        {/* модальные окна */}
        {this.state.modalShow && this.props.account ? (
          <ModalWindow closed={this.modalShowClose}>
            {this.props.account.user.is_active ? (
              <ModalContent
                title={"Пользователь разблокирован!"}
                icon={"/profile/images/unblocked.svg"}
                subtitle={`Для его блокировки нажмите "Заблокировать"`}
              ></ModalContent>
            ) : (
              <ModalContent
                title={"Пользователь заблокирован!"}
                icon={"/profile/images/blocked.svg"}
                subtitle={`Для его разблокировки нажмите "Разблокировать"`}
              ></ModalContent>
            )}
          </ModalWindow>
        ) : null}
        {this.state.confirmModalShow ? (
          <ModalWindow closed={this.modalShowClose}>
            <DeleteConfirm
              confirmed={
                !this.props.account.user.is_deleted
                  ? this.deleteConfirmedHandler
                  : this.restoreConfirmedHandler
              }
              closed={this.modalShowClose}
              title={`Вы точно хотите ${
                this.props.account.user.is_deleted ? "восстановить" : "удалить"
              } специалиста: "${this.state.personalData.second_name.value} ${
                this.state.personalData.first_name.value
              }" ?`}
              action={
                this.props.account.user.is_deleted ? "Восстановить" : "Удалить"
              }
            ></DeleteConfirm>
          </ModalWindow>
        ) : null}
        {this.state.modalQuestionarieShow &&
        this.props.dataFetchStatus !== "" ? (
          <ModalWindow timeout={3000} closed={this.modalQuestionarieClose}>
            {this.props.dataFetchStatus === "SUCCESS" ? (
              <ModalContent
                title={"Успех!"}
                status={this.props.dataFetchStatus}
              ></ModalContent>
            ) : (
              <ModalContent
                title={"Ошибка!"}
                subtitle={`Попробуйте позже`}
                status={this.props.dataFetchStatus}
              ></ModalContent>
            )}
          </ModalWindow>
        ) : null}
        {this.state.modalPropfileShow && this.props.accountEditStatus !== "" ? (
          <ModalWindow timeout={3000} closed={this.modalProfileClose}>
            {this.props.accountEditStatus === "SUCCESS" ? (
              <ModalContent
                title={"Успех!"}
                status={this.props.accountEditStatus}
              ></ModalContent>
            ) : (
              <ModalContent
                title={"Ошибка!"}
                subtitle={`Попробуйте позже`}
                status={this.props.accountEditStatus}
              ></ModalContent>
            )}
          </ModalWindow>
        ) : null}
        {this.props.accountDeleteStatus !== "" ? (
          <ModalWindow closed={this.modalShowClose}>
            {this.props.accountDeleteStatus === "Duplicate user" ? (
              <ModalContent
                title={"Ошибка!"}
                subtitle={`Пользователь с данным Email не может быть восстановлен, т.к. уже зарегистрирован другой пользователь с данным Email`}
                icon={"/profile/images/ERROR.svg"}
                status={this.props.accountDeleteStatus}
              ></ModalContent>
            ) : null}
          </ModalWindow>
        ) : null}
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    accountEditStatus: state.account.accountEditStatus,
    accountDeleteStatus: state.account.accountDeleteStatus,
    account: state.account.account,
    userpic_url: state.account.userpic_url,

    dataFetchStatus: state.doctors.dataFetchStatus,
    editedDoctorProfile: state.doctors.editedDoctorProfile,
    attachment: state.doctors.attachment,

    specializations: state.profile.specializations,
    role: state.profile.role,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    editAccountInfoByID: (data) => dispatch(editAccountInfoByID(data)),
    getAccountInfoByID: (id) => dispatch(getAccountInfoByID(id)),
    editAccountInfo: (data) => dispatch(editAccountInfo(data)),
    getAccountInfo: () => dispatch(getAccountInfo()),
    sendNewPassword: (data) => dispatch(sendNewPassword(data)),
    loadPicture: (file) => dispatch(loadPicture(file)),
    removePicture: () => dispatch(removePicture()),
    getSpecializations: () => dispatch(getSpecializations()),
    changePassword: (data) => dispatch(changePassword(data)),

    createDoctorProfile: (data) => dispatch(createDoctorProfile(data)),
    createDoctorProfileByID: (data) => dispatch(createDoctorProfileByID(data)),
    updateDoctorProfileByID: (data) => dispatch(updateDoctorProfileByID(data)),
    updateDoctorProfileMy: (data) => dispatch(updateDoctorProfileMy(data)),
    getDoctorByID: (id) => dispatch(getDoctorByID(id)),
    getDoctorMy: () => dispatch(getDoctorMy()),
    clearAccountStatus: () => dispatch(clearAccountStatus()),
    changeUserStatus: (id, data) => dispatch(changeUserStatus(id, data)),
    changeUserDeletedStatus: (id, data) =>
      dispatch(changeUserDeletedStatus(id, data)),
    clearAccountDeletedStatus: () => dispatch(clearAccountDeletedStatus()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DoctorEdit);
