import React from 'react';
import ScrollbarContext from 'context/ScrollbarContext';

const Scroller = props => {
    const scrollbar = React.useContext(ScrollbarContext);
    React.useEffect(() => {
        scrollbar.scrollTop(props.top ? props.top : 0);
    }, [props.page])
    return null;
}

export default Scroller