import React from 'react'

import FileItem from 'components/UI/FileControl/FileItem/FileItem';
import HealthStatus from 'components/UI/HealthStatus/HealthStatus';

import classes from './TestResultItem.module.scss';

const TestResultItem = props => {
    return (
        <div className={classes.TestResultItem}>
            <div className={classes.header}>
                <div className={classes.info}>
                    {props.color ?
                        <HealthStatus
                            style={{ marginBottom: "21px" }}
                            color={props.color}
                            score={props.score}
                            description="балл здоровья"
                        />
                        : null
                    }
                    <div className={classes.date}>
                        от&nbsp;{props.date}
                    </div>
                </div>
                <div className={classes.type}>
                    {props.type}
                </div>
            </div>
            <div className={classes.file}>
                <FileItem
                    style={{ margin: "0 auto", width: "250px" }}
                    file={props.result_url}
                    download
                />
            </div>
        </div>
    )
}


export default TestResultItem