import React from "react";

import FileItem from "./FileItem/FileItem";

import classes from "./FileControl.module.scss";

export function formatedFileName(url) {
  const extension = url.substring(url.lastIndexOf("."));
  const fileFullName = url.substring(url.lastIndexOf("/") + 1);
  const fileName = fileFullName.slice(0, -extension.length);
  const shortName = `${fileName.slice(0, 3)}...${fileName.slice(
    fileName.length - 3,
    fileName.length
  )}${extension}`;
  return shortName;
}

const FileControl = (props) => {
  const disabled = props.files.length >= props.maxFilesCount;
  const cls = [classes.add_file];
  if (disabled) {
    cls.push(classes.disabled);
  }
  return (
    <div className={classes.FileControl} style={props.style}>
      {props.files.map((file, index) => {
        return (
          <FileItem
            index={index}
            key={index}
            file={file}
            remove={props.remove}
            download={false}
          />
        );
      })}
      <label className={cls.join(" ")}>
        <input
          className={classes.input}
          disabled={disabled}
          value=""
          type={"file"}
          accept=".png, .jpg, .jpeg, .pdf"
          onChange={(event) => props.onChange(event)}
        />
        <div
          className={classes.add}
          style={{
            maskImage: "url('/profile/images/add.svg')",
            WebkitMaskImage: "url('/profile/images/add.svg')",
          }}
        ></div>
        <span className={classes.label}>Выберите документ</span>
      </label>
    </div>
  );
};

export default FileControl;
