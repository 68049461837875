import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import MainHeader from "components/Main/MainHeader/MainHeader";

import Footer from "components/Footer/Footer";
import Logo from "components/Logo/Logo";
import BackLink from "components/UI/BackLink/BackLink";

import classes from "./CovidPage.module.scss";

const CovidPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Проверка здоровья и симптомы после COVID-19. Портал здоровья
        </title>
        <meta
          name="title"
          content="Проверка здоровья и симптомы после COVID-19. Портал здоровья"
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:title"
          content="Проверка здоровья и симптомы после COVID-19. Портал здоровья"
        />
      </Helmet>
      <MainHeader />
      <div className={classes.Covid}>
        <div className={classes.container}>
          <section className={classes.hero}>
            <h2 className={`${classes.hero_subtitle} ${classes.subtitle}`}>
              Проверка здоровья и симптомы после COVID-19
            </h2>
            <div className={`${classes.hero_text} ${classes.text}`}>
              Цель данного сервиса — помочь тем, кто долго не может
              восстановиться после перенесённой коронавирусной инфекции или
              хочет оценить свой риск тяжёлого течения ковида и снизить его.
            </div>
          </section>
          <section className={classes.factors}>
            <h2 className={`${classes.factors_subtitle} ${classes.subtitle}`}>
              Тяжесть течения коронавирусной инфекции (COVID-19), симптомы
              и&nbsp;восстановление после неё определяются тремя основными
              факторами:
            </h2>
            <div className={classes.factors_body}>
              <ul className={classes.factors_list}>
                <li className={classes.factors_item}>
                  Эффективной работой иммунной системы (её&nbsp;способностью
                  блокировать и уничтожать вирус);
                </li>
                <li className={classes.factors_item}>
                  Активностью противовоспалительных систем организма
                  (способностью завершать воспалительную реакцию, естественную
                  при вирусной инфекции);
                </li>
                <li className={classes.factors_item}>
                  «Запасом прочности» организма в целом — условно говоря,
                  степенью старения систем его жизнеобеспечения: дыхательной
                  системы, сердечно-сосудистой системы, почек, печени.
                </li>
              </ul>
              <div className={classes.factors_img}>
                <img src="/images/covid-doctor_01.png" alt="" />
              </div>
            </div>
          </section>
          <section className={classes.causes}>
            <h2 className={`${classes.causes_subtitle} ${classes.subtitle}`}>
              Перечисленные факторы напрямую зависят от того, что мы делаем изо
              дня в день:
            </h2>
            <ul className={classes.causes_list}>
              <li className={classes.causes_item}>
                <div className={classes.causes_item__number}>
                  <img src={"/images/round.svg"} alt={"round"} />
                  <span>1</span>
                </div>
                <div className={classes.causes_item__text}>Что едим?</div>
              </li>

              <li className={classes.causes_item}>
                <div className={classes.causes_item__number}>
                  <img src={"/images/round.svg"} alt={"round"} />
                  <span>2</span>
                </div>
                <div className={classes.causes_item__text}>Как двигаемся?</div>
              </li>
              <li className={classes.causes_item}>
                <div className={classes.causes_item__number}>
                  <img src={"/images/round.svg"} alt={"round"} />
                  <span>3</span>
                </div>
                <div className={classes.causes_item__text}>
                  Как справляемся со стресом?
                </div>
              </li>
              <li className={classes.causes_item}>
                <div className={classes.causes_item__number}>
                  <img src={"/images/round.svg"} alt={"round"} />
                  <span>4</span>
                </div>
                <div className={classes.causes_item__text}>
                  Сколько токсинов поступает в организм?{" "}
                </div>
              </li>
            </ul>
            <div className={classes.causes_text}>
              Иными словами, тяжесть коронавирусной инфекции — это производное
              от нашего здоровья, а здоровье — производное от нашего образа
              жизни. Эту точку зрения разделяет множество врачей и экспертов во
              всём мире, и на ней основаны очень эффективные научно обоснованные
              методы лечения и профилактики.
            </div>
            <div
              className={`${classes.causes_text} ${classes.causes_text__icon}`}
            >
              Восстановить или улучшить здоровье невозможно с помощью «волшебной
              таблетки». Поэтому не следует уповать на вакцинацию как на
              гарантию защиты от инфекции, вакцинация лучше всего работает
              именно как часть продуманной системы.
            </div>
          </section>
          <section className={classes.questions}>
            <h2 className={`${classes.questions_subtitle} ${classes.subtitle}`}>
              Данный сервис поможет Вам ответить на следующие вопросы:
            </h2>
            <div className={classes.questions_body}>
              <div className={classes.questions_img}>
                <img src="/images/covid-doctor_02.png" alt="" />
              </div>
              <ul className={classes.questions_list}>
                <li className={classes.questions_item}>
                  От каких факторов зависит тяжесть течения коронавирусной
                  инфекции?
                </li>
                <li className={classes.questions_item}>
                  Как измерить свой уровень риска тяжёлого течения ковид?
                </li>
                <li className={classes.questions_item}>
                  Как снизить индивидуальный риск тяжёлого течения ковида
                  (помимо вакцинации)?
                </li>
                <li className={classes.questions_item}>
                  Как самостоятельно оценить свои симптомы после ковида?
                </li>
                <li className={classes.questions_item}>
                  Как улучшить своё здоровье после ковид?
                </li>
                <li className={classes.questions_item}>
                  Как подобрать оптимальную программу восстановления?
                </li>
              </ul>
            </div>
          </section>
          <section className={classes.test}>
            <h2 className={`${classes.test_subtitle} ${classes.subtitle}`}>
              Чтобы начать работу с сервисом, необходимо пройти тест-опросник
            </h2>
            <div className={classes.explanation}>
              Нажмите на то утверждение, которое относится к Вам
            </div>
            <ul className={classes.test_links}>
              <li className={classes.test_link}>
                <Link to="/covid/test?user_choice=1">
                  Я не болел ковидом, хочу оценить свои риски тяжёлого течения и
                  снизить эти риски.
                  <div className={classes.test_link__bg}></div>
                </Link>
              </li>
              <li className={classes.test_link}>
                <Link to="/covid/test?user_choice=2">
                  Я переболел ковидом и долго не могу восстановиться. Хочу
                  понять, почему это происходит, как вернуться к нормальному
                  состоянию здоровья. Хочу пройти реабилитацию после ковида.
                  <div className={classes.test_link__bg}></div>
                </Link>
              </li>
              <li className={classes.test_link}>
                <Link to="/covid/test?user_choice=3">
                  Я переболел, восстановился и хочу снизить риск повторной
                  болезни.
                  <div className={classes.test_link__bg}></div>
                </Link>
              </li>
            </ul>
            <div className={classes.note}>
              С точки зрения соотношения простоты и доступности, себестоимости и
              информативности, начинать оценку симптомов после COVID-19 и
              состояния здоровья лучше всего именно с онлайн тестирования. По
              результатам опросника можно будет сделать вывод об общем уровне
              проблем со здоровьем, а в случае нашего новогосервиса — о риске
              тяжёлого течения ковида Тест позволяет определить зону риска по
              принципу светофора: красная — высокий риск, жёлтая — средний риск,
              зелёная — низкий риск. Разумеется, на выводы опросника можно
              полагаться лишь при условии, что человек достаточно наблюдателен и
              точно отражает своё самочувствие в ответах на вопросы.
            </div>
            <div className={classes.icon}>
              <img src="/images/heart.svg" alt="" />
            </div>
          </section>

          <footer className={classes.footer}>
            <div className={classes.author}>
              Автор: Мартюшев-Поклад Андрей Васильевич, кандидат медицинских
              наук, врач функциональной медицины
            </div>
            <div className={classes.copy}>
              © 2020 Портал здоровья, All rights reserved.
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default CovidPage;
