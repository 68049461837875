import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Button from "components/UI/Button/Button";
import Container from "hoc/Container/Container";
import BackLink from "components/UI/BackLink/BackLink";

import { validateControl, renderInputs, errorDescriptions } from "../Auth";
import classes from "./SignUp.module.scss";

import { reg } from "store/Auth/actions";
import { Helmet } from "react-helmet";

class SignUp extends Component {
  state = {
    checked: false,
    isFormValid: false,
    isPasswordsMatch: false,
    formControls: {
      email: {
        value: "",
        type: "email",
        errorMessage: "Введите корректный email",
        placeholder: "Почта",
        label: "Почта",
        valid: false,
        touched: false,
        validation: {
          required: true,
          email: true,
        },
      },
      password: {
        value: "",
        type: "password",
        errorMessage:
          "Пароль должен содержать не менее 8 символов, цифры и латинские буквы, не должен являться словарным словом или набором символов, находящихся рядом на клавиатуре",
        placeholder: "Пароль",
        label: "Пароль",
        valid: false,
        touched: false,
        validation: {
          required: true,
          minLength: 8,
        },
      },
      repeatPassword: {
        value: "",
        type: "password",
        errorMessage: "Пароли должны совпадать",
        placeholder: "Повторите пароль",
        label: "Повторите пароль",
        valid: false,
        touched: false,
        validation: {},
      },
    },
  };

  registerHandler = () => {
    const search = document.location.search;
    let token = null;
    if (search.indexOf("token") !== -1) {
      const tokenStartIndex = search.indexOf("token=") + "token=".length;
      token = search.slice(tokenStartIndex);
    }

    this.props.reg(
      this.state.formControls.email.value,
      this.state.formControls.password.value,
      token
    );

    let formControls = { ...this.state.formControls };
    formControls.password.value = "";
    formControls.password.valid = false;
    formControls.password.touched = false;
    formControls.repeatPassword.value = "";
    formControls.repeatPassword.valid = "";
    formControls.repeatPassword.touched = "";

    this.setState({
      formControls,
      isFormValid: false,
      checked: false,
    });
  };

  submitHandler = (event) => {
    event.preventDefault();
  };

  onChangeHandler = (event, controlName) => {
    const formControls = { ...this.state.formControls };
    const control = { ...formControls[controlName] };

    control.value = event.target.value;
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);

    formControls[controlName] = control;

    let isFormValid = true;

    Object.keys(formControls).forEach((name) => {
      isFormValid = formControls[name].valid && isFormValid;
    });

    let isPasswordsMatch = false;

    if (controlName === "repeatPassword") {
      isPasswordsMatch = control.value === formControls.password.value;
      control.valid = isPasswordsMatch;
    }

    this.setState({
      isPasswordsMatch,
      formControls,
      isFormValid,
    });
  };

  checkboxHandler = (event) => {
    if (event.target.checked) {
      this.setState({
        checked: true,
      });
    } else {
      this.setState({
        checked: false,
      });
    }
  };

  render() {
    return (
      <section className={classes.SignUp}>
        <Helmet>
          <title>Регистрация. Портал здоровья</title>
          <meta name="title" content="Регистрация. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Регистрация. Портал здоровья" />
        </Helmet>
        {this.props.regStatus === "NEED_CONFIRMATION" ? (
          <Redirect to={"/profile/confirm_email"} />
        ) : null}
        <Container innerClassName={classes.inner}>
          <h3 className={classes.title}>
            <BackLink to="/" />
            Регистрация
          </h3>

          <form onSubmit={this.submitHandler} className={classes.form}>
            {renderInputs(this.state.formControls, this.onChangeHandler)}

            <label className={classes.checkbox}>
              <input
                type="checkbox"
                onChange={(event) => {
                  this.checkboxHandler(event);
                }}
                checked={this.state.checked}
              />
              <span className={classes.checkmark}></span>Я согласен на:
            </label>

            <a
              className={classes.link}
              href="/agreement"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Обработку персональных данных (ФЗ 152)
            </a>
            <a
              className={classes.link}
              href="/confidentiality"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Передачу персональных данных третьим лицам
            </a>
            <a
              className={classes.link}
              href="/terms_of_use"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Обращение для информирования и напоминания
            </a>
            <Button
              type="primary"
              onClick={this.registerHandler}
              disabled={
                !this.state.isFormValid ||
                !this.state.checked ||
                !this.state.isPasswordsMatch
              }
            >
              Далее
            </Button>
            {this.props.signUpError ? (
              <div className={classes.error}>
                {errorDescriptions[this.props.signUpError.data.detail]}
              </div>
            ) : null}
            <hr className={classes.hr} />
            <div className={classes.login}>
              Уже есть аккаунт?&nbsp;
              <NavLink to={"/profile/login"}>Войдите</NavLink>
            </div>
          </form>
        </Container>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    signUpError: state.auth.signUpError,
    regStatus: state.auth.regStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    reg: (email, password, token) => dispatch(reg(email, password, token)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
