import React, { Component } from "react";
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";

import Button from "components/UI/Button/Button";
import CardList from "components/Profile/Medcard/CardList/CardList";
import EmptySection from "components/Profile/EmptySection/EmptySection";
import Navigation from "components/UI/Navigation/Navigation";
import classes from "./Medcard.module.scss";
import { getMedcardDocs } from 'store/Profile/Medcard/actions';
const CARDS_IN_ONE_PAGE = 10;

class Medcard extends Component {
  state = {
    activePage: 1,
  };
  componentDidMount() {
    this.props.getMedcardDocs();
  }
  changePageHandler = (pages, jumpTo) => {
    let activePage = this.state.activePage;

    if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
      return;
    } else {
      activePage = jumpTo;
    }
    this.setState({ activePage });
    window.scrollTo(0, 0);
  };

  renderSection = () => {
    // if (!this.props.consultations) return null;

    // let consultations = this.props.consultations;

    let pagesAmount =
      Math.trunc(this.props.documents.length / CARDS_IN_ONE_PAGE) +
      (this.props.documents.length % CARDS_IN_ONE_PAGE ? 1 : 0);

    const currentPageDocuments = this.props.documents.filter((item, index) => {
      return (
        index >= (this.state.activePage - 1) * CARDS_IN_ONE_PAGE &&
        index < this.state.activePage * CARDS_IN_ONE_PAGE
      );
    });

    const section = (
      <React.Fragment>
        <CardList cards={currentPageDocuments} />
        {pagesAmount > 1 ? (
          <Navigation
            activePage={this.state.activePage}
            pages={pagesAmount}
            onChange={this.changePageHandler}
          />
        ) : null}
      </React.Fragment>
    );

    return section;
  };
  render() {
    return (
      <section className={classes.Medcard}>
        <Helmet>
          <title>Медкарта. Портал здоровья</title>
          <meta name="title" content="Медкарта. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Медкарта. Портал здоровья" />
        </Helmet>
        <div className={classes.header}>
          <div className={classes.titleWrapper}>
            <div className={classes.title_active}>Медицинский архив</div>
            <Link to={'/profile/medcard/accesses'} className={classes.title}>Доступы</Link>
          </div>
          {this.props.documents && this.props.documents.length !== 0 ? (
            <Link to={"/profile/new_document"}>
              <Button type={"plus"} text={'Добавить документ'}></Button>
            </Link>
          ) : null}
        </div>
        {this.props.documents && this.props.documents.length !== 0 ? (
          this.renderSection()
        ) : (
            <EmptySection
              title={"У вас пока нет документов"}
              text={`Здесь хранятся все ваши личные файлы, которые косаются вашего здоровья.
                 Специалист может загружать заключения после проведения консультации,
                 а также вы сами можете добавлять все необходимые документы`}
            >
              <Link to={"/profile/new_document"}>
                <Button type={"plus"} text={'Добавить документ'}></Button>
              </Link>
            </EmptySection>
          )}
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    documents: state.medcard.documents,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getMedcardDocs: () => dispatch(getMedcardDocs()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Medcard);
