import React from 'react';
import parse from 'html-react-parser';


import classes from './FaqItem.module.scss';

const FaqItem = props => {
    const [isFull, setIsFull] = React.useState(false);
    const [isSmallAnswer, setIsSmallAnswer] = React.useState(false);
    const answerCls = [classes.answer];
    if (isFull) {
        answerCls.push(classes.full)
    }

    const answerRef = React.useRef(null);
    React.useEffect(() => {
        if (answerRef.current) {
           setIsSmallAnswer(answerRef.current.offsetHeight < 109);
        }
    },[answerRef])


    return (
        <div className={classes.FaqItem}>
            <h4 className={classes.question}>{props.question}</h4>
            <div ref={answerRef} className={answerCls.join(" ") + " customHTMLblock"}>{parse(props.answer)}</div>
            {isSmallAnswer ?
                <span className={classes.more}/>
                :
                isFull ?
                    <span
                        onClick={() => setIsFull(false)}
                        className={classes.more}
                    >Скрыть</span>
                    :
                    <span
                        onClick={() => setIsFull(true)}
                        className={classes.more}
                    >Читать еще</span>

            }

        </div>
    )
}


export default FaqItem