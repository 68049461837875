import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import BackLink from "components/UI/BackLink/BackLink";
import Button from "components/UI/Button/Button";
import GenderControl from "components/UI/GenderControl/GenderControl";
import Input from "components/UI/Input/Input";
import Representative from "components/Profile/Representative/Representative";
import ModalWindow from "components/UI/ModalWindow/ModalWindow";
import ModalContent from "components/UI/ModalWindow/SuccessErrorMessage/SuccessErrorMessage";
import DeleteConfirm from "components/UI/ModalWindow/DeleteConfirm/DeleteConfirm";
import TestResultItem from "./TestResultItem/TestResultItem";
import DocumentItem from "./DocumentItem/DocumentItem";

import classes from "./PatientEdit.module.scss";
import {
  getPatientByID,
  getRepresentativesByID,
  getTestResultsByID,
  changeUserStatus,
  getDocumentsAccess,
  getDocumentsByID,
  clearGetAccessStatus,
  changeDeletedPatientStatus,
  clearDeletedPatientStatus,
} from "store/Profile/Patients/actions";
import {
  sendNewPassword,
  clearEditAssistantStatus,
} from "store/Profile/Assistants/actions";

import { validateControl } from "containers/Auth/Auth";
import { convertDateForDev } from "components/Profile/dateConverter";
import { formatedFileName } from "components/UI/FileControl/FileControl";
import { documentTypes } from "components/Profile/Medcard/CardList/enums";
import { tests } from "containers/Profile/Health/Health";

let modalTimeout;
class PatientEdit extends Component {
  state = {
    passwordInputValid: false,
    passwordMatch: false,
    passChange: {
      password: {
        value: "",
        type: "password",
        errorMessage:
          "Пароль должен содержать не менее 8 символов, цифры и латинские буквы, не должен являться словарным словом или набором символов, находящихся рядом на клавиатуре",
        placeholder: "Пароль",
        label: "Пароль",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
          minLength: 8,
        },
      },
      repeatPassword: {
        value: "",
        type: "password",
        errorMessage: "Пароли должны совпадать",
        placeholder: "Повторите пароль",
        label: "Повторите пароль",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {},
      },
    },
    modalShow: false,
    confirmModalShow: false,
    modalPassChangedShow: false,
  };
  // модалка
  closeOnTimeout() {
    modalTimeout = setTimeout(() => this.modalShowClose(), 3000);
  }
  modalShowOpen = () => {
    this.setState({ modalShow: true });
    this.closeOnTimeout();
  };
  confirmModalShowOpen = () => {
    this.setState({ confirmModalShow: true });
  };
  modalPassChangedShowOpen = () => {
    this.setState({ modalPassChangedShow: true });
    this.closeOnTimeout();
  };
  modalShowClose = () => {
    this.setState({
      modalShow: false,
      confirmModalShow: false,
      modalPassChangedShow: false,
    });
    this.props.clearEditStatus();
    this.props.clearDeletedPatientStatus();
  };
  componentDidMount() {
    this.props.getPatientByID(this.props.match.params.id);
    this.props.getRepresentativesByID(this.props.match.params.id);
    this.props.getTestResultsByID(this.props.match.params.id);
    this.props.getDocumentsByID(this.props.match.params.id);
  }
  componentDidUpdate(prevProps) {
    // открываем модалку со статусом отправки запроса
    if (this.props.patient && prevProps.patient) {
      if (
        prevProps.patient.user.is_active !== this.props.patient.user.is_active
      ) {
        this.modalShowOpen();
      }
    }
    if (prevProps.editStatus !== this.props.editStatus) {
      this.modalPassChangedShowOpen();
    }
  }

  componentWillUnmount() {
    clearTimeout(modalTimeout);
    this.props.clearGetAccessStatus();
  }

  onChangeInputPassHandler = (event, controlName) => {
    const formControls = { ...this.state.passChange };
    const control = { ...formControls[controlName] };

    control.value = event.target.value;
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);
    formControls[controlName] = control;

    let isPasswordsMatch = false;
    if (formControls.password.value === formControls.repeatPassword.value) {
      isPasswordsMatch = true;
      formControls.repeatPassword.valid = isPasswordsMatch;
    } else {
      formControls.repeatPassword.valid = isPasswordsMatch;
    }
    let passwordInputValid = true;
    Object.keys(formControls).forEach((key) => {
      if (!formControls[key].valid) {
        passwordInputValid = false;
      }
    });

    this.setState({
      passwordInputValid: passwordInputValid,
      passChange: formControls,
      passwordMatch: isPasswordsMatch,
    });
  };

  getDocumentsAccessHandler = () => {
    this.props.getDocumentsAccess(this.props.match.params.id);
  };

  // смена пароля
  sendNewPassword() {
    let data = {
      id: this.props.match.params.id,
      password: this.state.passChange.password.value,
      repeatPassword: this.state.passChange.repeatPassword.value,
    };
    this.props.sendNewPassword(data);
    if (this.props.sendNewPasswordStatus !== "") {
      this.modalShowOpen();
    }
    let newState = { ...this.state };
    newState.passChange.password.value = "";
    newState.passChange.repeatPassword.value = "";
    this.setState({ ...newState });
  }

  changeUserStatusHandler = (newStatus) => {
    const data = { is_active: newStatus };
    this.props.changeUserStatus(this.props.patient?.user_id, data);
  };

  changeUserDeletedStatusHandler = (newStatus) => {
    const data = { is_deleted: newStatus };
    this.props.changeDeletedPatientStatus(this.props.patient?.user_id, data);
  };

  deleteConfirmedHandler = () => {
    this.changeUserDeletedStatusHandler(true);
    this.modalShowClose();
  };

  restoreConfirmedHandler = () => {
    this.changeUserDeletedStatusHandler(false);
    this.modalShowClose();
  };

  render() {
    if (this.props.patient) {
      const fullName = `${this.props.patient.second_name} ${
        this.props.patient.first_name
      }${
        this.props.patient.patronymic_name
          ? " " + this.props.patient.patronymic_name
          : ""
      }${this.props.patient.second_name ? ". " : ""}`;

      return (
        <section className={classes.PatientEdit}>
          <Helmet>
            <title>{fullName}Портал здоровья</title>
            <meta name="title" content={`${fullName}Портал здоровья`} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:title" content={`${fullName}Портал здоровья`} />
          </Helmet>
          <div className={classes.back}>
            <BackLink to="/profile/patients" />
            <span>Назад</span>
          </div>
          {/* ФИО возраст */}
          <div className={classes.title}>
            {`${this.props.patient.second_name} 
            ${this.props.patient.first_name} 
            ${this.props.patient.patronymic_name || ""}
            ${this.props.patient.age} лет`}
          </div>
          <form
            className={classes.form}
            onSubmit={(event) => event.preventDefault()}
          >
            {this.props.role === "support" || this.props.role === "admin" ? (
              <React.Fragment>
                <div className={classes.section_title}>
                  <h5>Смена пароля</h5>
                  {this.props.patient.user.is_active ? (
                    <span
                      className={classes.block_btn}
                      onClick={() => this.changeUserStatusHandler(false)}
                    >
                      Заблокировать
                    </span>
                  ) : (
                    <span
                      className={classes.block_btn}
                      onClick={() => this.changeUserStatusHandler(true)}
                    >
                      Разблокировать
                    </span>
                  )}
                  {this.props.patient?.user.is_deleted ? (
                    <span
                      className={classes.block_btn}
                      onClick={this.confirmModalShowOpen}
                    >
                      Восстановить
                    </span>
                  ) : (
                    <span
                      className={classes.block_btn}
                      onClick={this.confirmModalShowOpen}
                    >
                      Удалить
                    </span>
                  )}
                </div>
                <div className={classes.section_content}>
                  <div className={classes.inputs}>
                    {/* новый пароль */}
                    <Input
                      value={this.state.passChange.password.value}
                      type={this.state.passChange.password.type}
                      placeholder={this.state.passChange.password.placeholder}
                      valid={this.state.passChange.password.valid}
                      touched={this.state.passChange.password.touched}
                      label={this.state.passChange.password.label}
                      shouldValidate={
                        this.state.passChange.password.shouldValidate
                      }
                      errorMessage={this.state.passChange.password.errorMessage}
                      onChange={(event) =>
                        this.onChangeInputPassHandler(event, "password")
                      }
                    />
                    {/* повтор нового пароля */}
                    <Input
                      value={this.state.passChange.repeatPassword.value}
                      type={this.state.passChange.repeatPassword.type}
                      placeholder={
                        this.state.passChange.repeatPassword.placeholder
                      }
                      valid={this.state.passChange.repeatPassword.valid}
                      touched={this.state.passChange.repeatPassword.touched}
                      label={this.state.passChange.repeatPassword.label}
                      shouldValidate={
                        this.state.passChange.repeatPassword.shouldValidate
                      }
                      errorMessage={
                        this.state.passChange.repeatPassword.errorMessage
                      }
                      onChange={(event) =>
                        this.onChangeInputPassHandler(event, "repeatPassword")
                      }
                    />
                    <Button
                      disabled={!this.state.passwordInputValid}
                      onClick={() => this.sendNewPassword()}
                      type={"primary"}
                    >
                      Сохранить
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            ) : null}
            <h5 className={classes.section_title}>Личные данные</h5>
            <div className={classes.section_withPhoto}>
              <div className={classes.inputs}>
                {/* фамилия */}
                <Input
                  className={classes.Input}
                  value={this.props.patient.second_name}
                  placeholder={"Фамилия"}
                  label={"Фамилия"}
                  shouldValidate={false}
                  disabled
                />
                {/* имя */}
                <Input
                  className={classes.Input}
                  value={this.props.patient.first_name}
                  placeholder={"Имя"}
                  label={"Имя"}
                  shouldValidate={false}
                  disabled
                />
                {/* отчество */}
                <Input
                  className={classes.Input}
                  value={this.props.patient.patronymic_name || ""}
                  placeholder={"Отчество"}
                  label={"Отчество"}
                  shouldValidate={false}
                  disabled
                />
                <div className={classes.row}>
                  {/* пол */}
                  <GenderControl
                    value={this.props.patient.gender}
                    onChange={() => null}
                  />
                  {/* дата рожд */}
                  <Input
                    className={classes.Input}
                    value={convertDateForDev(this.props.patient.date_of_birth)}
                    placeholder={"Дата рождения"}
                    label={"Дата рождения"}
                    shouldValidate={false}
                    disabled
                  />
                </div>
                {/* адрес регистр */}
                <Input
                  className={classes.Input}
                  value={this.props.patient.registration_address}
                  placeholder={"Адрес регистрации"}
                  label={"Адрес регистрации"}
                  shouldValidate={false}
                  disabled
                />
                {/* адр места жительства */}
                <Input
                  className={classes.Input}
                  value={this.props.patient.real_address}
                  placeholder={"Адрес места жительства"}
                  label={"Адрес места жительства"}
                  shouldValidate={false}
                  disabled
                />
              </div>
              {this.props.patient.userpic_url !== "" &&
              this.props.patient.userpic_url !== null ? (
                <div
                  className={classes.photo}
                  style={{
                    background: `url(${this.props.patient.userpic_url}) center / cover no-repeat`,
                  }}
                />
              ) : null}
            </div>

            {/* Представители */}
            {this.props.representatives.length > 0 ? (
              <React.Fragment>
                <h5 className={classes.section_title}>Представители</h5>
                <div className={classes.section_content}>
                  <div className={classes.container}>
                    {this.props.representatives.map((representative) => {
                      return (
                        <Representative
                          key={representative.id}
                          id={representative.id}
                          firstName={representative.first_name}
                          secondName={representative.second_name}
                          patronymic={representative.patronymic_name}
                          phone={representative.phone}
                          delete={this.deleteRepresentativeHandler}
                          isEdit={false}
                        />
                      );
                    })}
                  </div>
                </div>
              </React.Fragment>
            ) : null}
            {/* Результаты тестов */}
            {this.props.testResults.length > 0 ? (
              <React.Fragment>
                <h5 className={classes.section_title}>
                  Результаты тестов пациента
                </h5>
                <div className={[classes.section_content]}>
                  <div className={classes.results_list}>
                    {this.props.testResults.map((item) => {
                      return (
                        <TestResultItem
                          key={item.id}
                          type={tests[item.test_type].type}
                          date={convertDateForDev(
                            item.created_at.substring(0, 10)
                          )}
                          result_url={item.result_url}
                          color={item.ofn15_code}
                          score={item.ofn15_score}
                        />
                      );
                    })}
                  </div>
                </div>
              </React.Fragment>
            ) : null}
            {/* Архив документов */}
            {this.props.documentsAccessStatus === "ALLOWED" ? (
              <React.Fragment>
                <h5 className={classes.section_title}>Архив документов</h5>
                <div className={classes.section_content}>
                  <div className={classes.subtitle}>
                    Пациент открыл вам доступ к своему архиву документов
                  </div>
                  <div className={classes.doc_list}>
                    {this.props.documentsList.length > 0 ? (
                      this.props.documentsList.map((document) => {
                        return (
                          <DocumentItem
                            title={document.title}
                            type={documentTypes[document.document_type]}
                            medicalOrganization={document.medical_organization}
                            date={convertDateForDev(document.document_date)}
                            fileUrl={formatedFileName(document.document_url)}
                            createdAt={document.created_at}
                          />
                        );
                      })
                    ) : (
                      <div className={classes.empty_list}>
                        Список документов пуст
                      </div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : null}
            {this.props.documentsAccessStatus === "DENIED" ? (
              <React.Fragment>
                <h5 className={classes.section_title}>Архив документов</h5>
                <div className={classes.section_content}>
                  <div className={classes.subtitle}>
                    Вы можете запросить доступ у пациента к его документам.
                    После отправки запроса пациент получит уведомление о вашем
                    намерении получить доступ к его архиву документов
                  </div>
                  <div className={classes.button_wrapper}>
                    <Button
                      type={"additional"}
                      onClick={this.getDocumentsAccessHandler}
                    >
                      Запросить доступ
                    </Button>
                  </div>
                  {this.props.getAccessStatus === "SENDED" ? (
                    <div className={classes.message}>Запрос отправлен!</div>
                  ) : null}
                </div>
              </React.Fragment>
            ) : null}
          </form>
          {/* модальное окно*/}
          {this.state.modalShow && this.props.patient ? (
            <ModalWindow closed={this.modalShowClose}>
              {this.props.patient.user.is_active ? (
                <ModalContent
                  title={"Пользователь разблокирован!"}
                  icon={"/profile/images/unblocked.svg"}
                  subtitle={`Для его блокировки нажмите "Заблокировать"`}
                ></ModalContent>
              ) : (
                <ModalContent
                  title={"Пользователь заблокирован!"}
                  icon={"/profile/images/blocked.svg"}
                  subtitle={`Для его разблокировки нажмите "Разблокировать"`}
                ></ModalContent>
              )}
            </ModalWindow>
          ) : null}
          {this.state.confirmModalShow ? (
            <ModalWindow closed={this.modalShowClose}>
              <DeleteConfirm
                confirmed={
                  !this.props.patient.user.is_deleted
                    ? this.deleteConfirmedHandler
                    : this.restoreConfirmedHandler
                }
                closed={this.modalShowClose}
                title={`Вы точно хотите ${
                  this.props.patient.user.is_deleted
                    ? "восстановить"
                    : "удалить"
                } пациента: "${this.props.patient.second_name} ${
                  this.props.patient.first_name
                }" ?`}
                action={
                  this.props.patient.user.is_deleted
                    ? "Восстановить"
                    : "Удалить"
                }
              ></DeleteConfirm>
            </ModalWindow>
          ) : null}
          {this.state.modalPassChangedShow && this.props.editStatus !== "" ? (
            <ModalWindow closed={this.modalShowClose}>
              {this.props.editStatus === "SUCCESS" ? (
                <ModalContent
                  title={"Успех!"}
                  status={this.props.editStatus}
                ></ModalContent>
              ) : (
                <ModalContent
                  title={"Ошибка!"}
                  subtitle={`Попробуйте позже`}
                  status={this.props.editStatus}
                ></ModalContent>
              )}
            </ModalWindow>
          ) : null}
          {this.props.accountDeleteStatus !== "" ? (
            <ModalWindow closed={this.modalShowClose}>
              {this.props.accountDeleteStatus === "Duplicate user" ? (
                <ModalContent
                  title={"Ошибка!"}
                  subtitle={`Пользователь с данным Email не может быть восстановлен, т.к. уже зарегистрирован другой пользователь с данным Email`}
                  icon={"/profile/images/ERROR.svg"}
                  status={this.props.accountDeleteStatus}
                ></ModalContent>
              ) : null}
            </ModalWindow>
          ) : null}
        </section>
      );
    } else {
      return <section className={classes.PatientEdit}></section>;
    }
  }
}
function mapStateToProps(state) {
  return {
    role: state.profile.role,
    patient: state.patients.activePatient,
    representatives: state.patients.representatives,
    testResults: state.patients.testResults,
    documentsAccessStatus: state.patients.documentsAccessStatus,
    getAccessStatus: state.patients.getAccessStatus,
    documentsList: state.patients.documentsList,
    editStatus: state.assistants.editAssistantStatus,
    accountDeleteStatus: state.patients.deletePatientStatus,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getPatientByID: (id) => dispatch(getPatientByID(id)),
    getRepresentativesByID: (id) => dispatch(getRepresentativesByID(id)),
    getTestResultsByID: (id) => dispatch(getTestResultsByID(id)),
    sendNewPassword: (data) => dispatch(sendNewPassword(data)),
    changeUserStatus: (id, data) => dispatch(changeUserStatus(id, data)),
    changeDeletedPatientStatus: (id, data) =>
      dispatch(changeDeletedPatientStatus(id, data)),
    getDocumentsAccess: (id) => dispatch(getDocumentsAccess(id)),
    getDocumentsByID: (id) => dispatch(getDocumentsByID(id)),
    clearGetAccessStatus: () => dispatch(clearGetAccessStatus()),
    clearEditStatus: () => dispatch(clearEditAssistantStatus()),
    clearDeletedPatientStatus: () => dispatch(clearDeletedPatientStatus()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(PatientEdit);
