import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import ModalWindow from "components/UI/ModalWindow/ModalWindow";
import DeleteConfirm from "components/UI/ModalWindow/DeleteConfirm/DeleteConfirm";
import Button from "components/UI/Button/Button";
import Card from "components/Article/Article";
import EmptySection from "components/Profile/EmptySection/EmptySection";
import Navigation from "components/UI/Navigation/Navigation";
import classes from "./Articles.module.scss";
import { getArticlesList, deleteArticle } from "store/Profile/Articles/actions";
const CARDS_IN_ONE_PAGE = 10;

class Articles extends Component {
  state = {
    activePage: 1,
    modalShow: false,
    pickedIDforDelete: null,
    pickedArticleTitleForDelete: '',
  };
  modalShowOpen = (id, title) => {
    let newState = { ...this.state };
    newState.modalShow = true;
    newState.pickedIDforDelete = id;
    newState.pickedArticleTitleForDelete = title
    this.setState({ ...newState });
  };
  componentDidMount() {
    this.props.getArticlesList(this.state.activePage - 1, CARDS_IN_ONE_PAGE);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activePage !== this.state.activePage) {
      this.props.getArticlesList(this.state.activePage - 1, CARDS_IN_ONE_PAGE);
    }
    if (this.props.needUpdate && !prevProps.needUpdate) {
      this.props.getArticlesList(this.state.activePage - 1, CARDS_IN_ONE_PAGE);
    }
    // перелистывание страницы на 1 назад, при удалении всех статей на странице
    if (prevProps.articlesList) {
      if (prevProps.articlesList.length === 1 && this.props.articlesList.length === 0 && this.state.activePage > 1) {
        this.setState({ activePage: this.state.activePage - 1 })
      }
    }
  }
  modalShowClose = () => {
    let newState = { ...this.state };
    newState.modalShow = false;
    newState.pickedIDforDelete = null;
    newState.pickedArticleTitleForDelete = '';
    this.setState({ ...newState });
  };
  deleteConfirmedHandler = () => {
    this.props.deleteArticle(this.state.pickedIDforDelete);
    this.modalShowClose();
  };
  getConvertedDate(date) {
    let cuttedDate = date.slice(0, 10);
    let splittedDate = cuttedDate.split("-");
    return [splittedDate[2], splittedDate[1], splittedDate[0]].join(".");
  }
  changePageHandler = (pages, jumpTo) => {
    let activePage = this.state.activePage;

    if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
      return;
    } else {
      activePage = jumpTo;
    }
    this.setState({ activePage });

    window.scrollTo(0, 0);
  };
  renderSection = () => {
    let pagesAmount =
      Math.trunc(this.props.total / CARDS_IN_ONE_PAGE) +
      (this.props.total % CARDS_IN_ONE_PAGE ? 1 : 0);

    const section = (
      <React.Fragment>
        {this.props.articlesList.map((item) => {
          return (
            <div className={classes.card} key={item.id}>
              <Card
                type={"longAdmin"}
                id={item.id}
                linkTo={`articles/article_edit/${item.id}`}
                title={item.title}
                date={this.getConvertedDate(item.created_at)}
                text={item.body}
                imageUrl={item.image_url}
                deleteHandler={this.modalShowOpen}
              ></Card>
            </div>
          );
        })}
        {pagesAmount > 1 ? (
          <Navigation
            activePage={this.state.activePage}
            pages={pagesAmount}
            onChange={this.changePageHandler}
          />
        ) : null}
      </React.Fragment>
    );

    return section;
  };
  render() {
    return (
      <section className={classes.Articles}>
        <Helmet>
          <title>Статьи. Портал здоровья</title>
          <meta name="title" content="Статьи. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Статьи. Портал здоровья" />
        </Helmet>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>Статьи</div>
          {this.props.role === "admin" &&
            this.props.articlesList &&
            this.props.articlesList.length !== 0 ? (
              <Link to={"/profile/articles/new_article"}>
                <Button type={"plus"} text={"Добавить статью"}></Button>
              </Link>
            ) : null}
        </div>
        {this.props.articlesList && this.props.articlesList.length !== 0 ? (
          this.renderSection()
        ) : (
            <EmptySection
              title={"Пока нет статей"}
              text={`Здесь хранятся все статьи.
                 Администратор может добавлять новые статьи`}
            >
              {this.props.role === "admin" ? (
                <Link to={"/profile/articles/new_article"}>
                  <Button type={"plus"} text={"Добавить статью"}></Button>
                </Link>
              ) : null}
            </EmptySection>
          )}
        {/* модальное окно подтв. удаления */}
        {this.state.modalShow ? (
          <ModalWindow closed={this.modalShowClose}>
            <DeleteConfirm
              confirmed={this.deleteConfirmedHandler}
              closed={this.modalShowClose}
              title={`Вы точно хотите удалить статью: "${this.state.pickedArticleTitleForDelete}" ?`}
            ></DeleteConfirm>
          </ModalWindow>
        ) : null}
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    role: state.profile.role,
    articlesList: state.articles.articlesList,
    total: state.articles.articlesTotal,
    needUpdate: state.articles.needUpdate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getArticlesList: (page, size) => dispatch(getArticlesList(page, size)),
    deleteArticle: (id) => dispatch(deleteArticle(id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Articles);
