export const formatExperienceYears = (n) => {
  return (
    n +
    " " +
    (n % 10 === 1 && n % 100 !== 11
      ? "год"
      : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
      ? "года"
      : "лет")
  );
};
