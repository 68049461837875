import React from 'react';
import classes from './Representative.module.scss';

const Representative = props => {
    return (
        <div className={classes.Representative}>
            <div className={classes.info}>
                <div className={classes.name}>
                    {props.secondName} {props.firstName} {props.patronymic || ""}
                </div>
                <div className={classes.phone}>
                    {props.phone}
                </div>
            </div>
            {props.isEdit ?
                <div
                    className={classes.delete_btn}
                    onClick={() => props.delete(props.id)}>
                    Удалить
                </div>
                :
                null
            }

        </div>
    )
}


export default Representative