export function converDateForJournal(date) {
    if (!date) return;

    var dd = date.getDate();
    if (dd < 10) dd = '0' + dd;
  
    var mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;
  
    var yy = date.getFullYear() % 100;
    if (yy < 10) yy = '0' + yy;

    var hours = date.getHours() % 100;
    if (hours < 10) hours = '0' + hours;

    var minutes = date.getMinutes() % 100;
    if (minutes < 10) minutes = '0' + minutes;
  
    return `${dd}.${mm}.${yy} ${hours}:${minutes}`;
}