import React from 'react';
import { Link } from 'react-router-dom';

import classes from './DoctorCard.module.scss';


const DoctorCard = props => {
    return (
        <Link to={"/our_specialists/" + props.specialization.id} className={classes.DoctorCard}>
            {props.specialization.title}
        </Link>

    )
}


export default DoctorCard