import {
  GET_RESULTS,
  SEND_RESULTS_SUCCESS,
  SEND_RESULTS_ERROR,
} from "./actionTypes";

const initialState = {
  error: null,
  results: null,
  status: null,
};

export default function testsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RESULTS:
      return {
        ...state,
        results: action.results,
      };
    case SEND_RESULTS_SUCCESS:
      return {
        ...state,
        status: "ok",
      };
    case SEND_RESULTS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
}
