import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

import BackLink from 'components/UI/BackLink/BackLink';

import classes from './DoctorInfo.module.scss';
import { getDoctorInfoByID } from 'store/Profile/Doctors/actions'
import { connect } from 'react-redux';
import DoctorProfile from './DoctorProfile/DoctorProfile';

class DoctorInfo extends Component {


    componentDidMount() {
        this.props.getDoctorInfoByID(this.props.match.params.id);
    }

    render() {
        let fullName;

        if (this.props.doctorInfo) {
           fullName = `${this.props.doctorInfo.profile.second_name} ${this.props.doctorInfo.profile.first_name}${this.props.doctorInfo.profile.patronymic_name ? " " + this.props.doctorInfo.profile.patronymic_name : ""}${this.props.doctorInfo.profile.second_name ? ". " : ""}`; 
        }

        return (
            <section className={classes.DoctorInfo}>
                {fullName ?
                    <Helmet>
                        <title>{fullName}Портал здоровья</title>
                        <meta name="title" content={`${fullName}Портал здоровья`} />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:title" content={`${fullName}Портал здоровья`} />
                    </Helmet>
                    :
                    null
                }
                <div className={classes.link_wrapper}>
                    <BackLink to="/profile/doctors" />
                    <span className={classes.link_text}>Назад</span>
                </div>
                {this.props.doctorInfo ?
                    <DoctorProfile doctor={this.props.doctorInfo} type={"full"} />
                    :
                    null
                }
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        doctorInfo: state.doctors.editedDoctorProfile,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getDoctorInfoByID: (id) => dispatch(getDoctorInfoByID(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DoctorInfo)