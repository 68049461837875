import {
    POST_FEEDBACK_SUCCESS,
    POST_FEEDBACK_ERROR,
    GET_FEEDBACK_SUCCESS,
    GET_FEEDBACK_ERROR,
    CLEAR_FEEDBACK_STATUS
} from './actionTypes';

const initialState = {
    postFeedbackStatus: "",
    feedbackList: null,
    feedbackTotal: null
}

export default function feedbackReducer(state = initialState, action) {
    switch (action.type) {
        case POST_FEEDBACK_SUCCESS:
            return {
                ...state,
                postFeedbackStatus: "SUCCESS"
            }
        case POST_FEEDBACK_ERROR:
            return {
                ...state,
                postFeedbackStatus: "ERROR"
            }
        case GET_FEEDBACK_SUCCESS:
            return {
                ...state,
                feedbackList: action.data.items,
                feedbackTotal: action.data.total
            }
        case GET_FEEDBACK_ERROR:
            return {
                ...state,
            }
        case CLEAR_FEEDBACK_STATUS:
            return {
                ...state,
                postFeedbackStatus: ""
            }
        default:
            return state
    }
}