import {
  GET_RESULTS_SUCCESS,
  GET_RESULTS_ERROR,
} from "./actionTypes";

const initialState = {
  results: null,
};

export default function healthReducer(state = initialState, action) {
  switch (action.type) {
    case GET_RESULTS_SUCCESS:
      return {
        ...state,
        results: action.results,
      };
    case GET_RESULTS_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
