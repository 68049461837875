import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Navigation from "components/UI/Navigation/Navigation"
import StatusTabs from './StatusTabs/StatusTabs';
import RequestItem from './RequestItem/RequestItem';

import { getRequestsList, changeRequestStatus } from "store/Profile/Medcard/actions";
import classes from './Accesses.module.scss';
import { getFormatDate } from 'components/Profile/CardList/enums';

const statusEnum = {
    created: "На рассмотрении",
    accepted: "Одобрена",
    declined: "Отклонена",
}

// максимум карточек на одной странице
const CARDS_IN_PAGE = 4;

const Accesses = props => {

    // Страницы
    const [activePage, setActivePage] = React.useState(1);
    const [pagesAmount, setPagesAmount] = React.useState();

    // componentDidMount
    React.useEffect(() => {
        props.getRequestsList();
    }, []);

    // изменение статуса
    const [activeStatus, setActiveStatus] = React.useState(0);
    React.useEffect(() => {
        setActivePage(1);
    }, [activeStatus]);

    // пагинация
    const [filteredList, setFilteredList] = React.useState([]);
    React.useEffect(() => {
        //фильтр по статусу
        const activeStatusList = props.requestsList.filter(i => (i.status === Object.keys(statusEnum)[activeStatus]));

        //разбиение на страницы
        const filteredList = activeStatusList.slice((activePage - 1) * CARDS_IN_PAGE, activePage * CARDS_IN_PAGE);

        //колличество страниц
        const pagesAmount = Math.trunc(activeStatusList.length / CARDS_IN_PAGE) + (activeStatusList.length % CARDS_IN_PAGE ? 1 : 0);
        setPagesAmount(pagesAmount);

        setFilteredList(filteredList);
    }, [activePage, activeStatus, props.requestsList]);

    function changePageHandler(pages, jumpTo) {
        if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
            return;
        } else {
            setActivePage(jumpTo);
        }
        window.scrollTo(0, 0);
    };

    //изменение статуса заявки
    function changeStatusHandler(id, newStatus) {
        const data = { status: newStatus };
        props.changeRequestStatus(id, data);
    };

    return (
        <section className={classes.Accesses}>
            <Helmet>
                <title>Управление доступами. Портал здоровья</title>
                <meta name="title" content="Управление доступами. Портал здоровья" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content="Управление доступами. Портал здоровья" />
            </Helmet>
            <div className={classes.titleWrapper}>
                <Link to={'/profile/medcard'} className={classes.title}>Медицинский архив</Link>
                <div className={classes.title_active}>Доступы</div>
            </div>
            <div className={classes.tip}>
                В данном разделе отображаются запросы на доступ к загруженным Вами документам от пользователей экспертов по здоровьесбережению, инструкторов здоровья, специалистов по питанию, управлению стрессом, по движению. Вы можете самостоятельно управлять доступом - предоставлять, прекращать или приостанавливать его.
            </div>
            <div className={classes.tabs}>
                <StatusTabs
                    list={Object.values(statusEnum)}
                    activeItem={activeStatus}
                    onChange={setActiveStatus}
                />
            </div>
            <div className={classes.list}>
                {filteredList.map(request => {
                    return (
                        <RequestItem
                            key={request.id}
                            id={request.id}
                            status={statusEnum[request.status]}
                            createdAt={getFormatDate(new Date(request.created_at))}
                            user={request.user}
                            changeStatus={changeStatusHandler}
                        />
                    );
                })}
            </div>
            {pagesAmount > 1 ? (
                <Navigation
                    activePage={activePage}
                    pages={pagesAmount}
                    onChange={changePageHandler}
                />
            ) : null}
        </section>
    )
}

function mapStateToProps(state) {
    return {
        requestsList: state.medcard.requestsList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getRequestsList: () => dispatch(getRequestsList()),
        changeRequestStatus: (id, data) => dispatch(changeRequestStatus(id, data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Accesses)