import {
  GET_PROVIDERS_SUCCESS,
  GET_PROVIDERS_ERROR,
  CREATE_PROVIDER_ERROR,
  CREATE_PROVIDER_SUCCESS,
  CLEAR_CREATE_PROVIDER_STATUS,
  EDIT_PROVIDER_SUCCESS,
  EDIT_PROVIDER_ERROR,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_ERROR,
  CHANGE_DELETED_STATUS_SUCCESS,
  CHANGE_DELETED_STATUS_ERROR,
  CLEAR_DELETE_PROVIDER_STATUS,
  CLEAR_EDIT_PROVIDER_STATUS,
  GET_PROVIDERBYID_SUCCESS,
  GET_PROVIDERBYID_ERROR,
  LOAD_PICTURE_SUCCESS,
  LOAD_PICTURE_ERROR,
  REMOVE_PICTURE,
} from "./actionTypes";

const initialState = {
  providersList: null,
  providersTotal: 0,
  providersStatus: null,
  editedProvider: null,
  createProviderStatus: "",
  editProviderStatus: "",
  deleteProviderStatus: "",
  userpic_url: null,
};

export default function providersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        providersList: action.data.items,
        providersTotal: action.data.total,
        providersStatus: "SUCCESS",
      };
    case GET_PROVIDERS_ERROR:
      return {
        ...state,
        providersStatus: "ERROR",
      };
    case CREATE_PROVIDER_SUCCESS:
      return {
        ...state,
        userpic_url: null,
        createProviderStatus: action.createProviderStatus,
      };
    case CREATE_PROVIDER_ERROR:
      return {
        ...state,
        createProviderStatus: action.createProviderStatus,
      };
    case CLEAR_CREATE_PROVIDER_STATUS:
      return {
        ...state,
        createProviderStatus: "",
      };
    case EDIT_PROVIDER_SUCCESS:
      return {
        ...state,
        editProviderStatus: action.editProviderStatus,
      };
    case EDIT_PROVIDER_ERROR:
      return {
        ...state,
        editProviderStatus: action.editProviderStatus,
      };
    case CLEAR_EDIT_PROVIDER_STATUS:
      return {
        ...state,
        editProviderStatus: "",
      };
    case GET_PROVIDERBYID_SUCCESS:
      return {
        ...state,
        editedProvider: action.editedProvider,
        userpic_url: action.editedProvider.userpic_url,
      };
    case GET_PROVIDERBYID_ERROR:
      return {
        ...state,
        editedProvider: null,
      };
    case LOAD_PICTURE_SUCCESS:
      return {
        ...state,
        userpic_url: action.url,
      };
    case LOAD_PICTURE_ERROR:
      return {
        ...state,
        userpic_url: null,
      };
    case REMOVE_PICTURE:
      return {
        ...state,
        userpic_url: null,
      };
    case CHANGE_STATUS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_STATUS_ERROR:
      return {
        ...state,
      };
    case CHANGE_DELETED_STATUS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_DELETED_STATUS_ERROR:
      return {
        ...state,
        deleteProviderStatus: action.error,
      };
    case CLEAR_DELETE_PROVIDER_STATUS:
      return {
        ...state,
        deleteProviderStatus: "",
      };
    default:
      return state;
  }
}
