import React from "react";
import { useHistory, Link } from "react-router-dom";

import classes from "./BackLink.module.scss";

const BackLinkInner = (props) => {
  let history = useHistory();

  return (
    <span
      className={classes.BackLink}
      onClick={() => {
        if (!props.to) {
          history.goBack();
        }
      }}
    >
      <img src={"/profile/images/back-link.svg"} alt={"back"} />
    </span>
  );
};

const BackLink = (props) => {
  if (props.to === "/") {
    return (
      <a
        href={props.to}
        onClick={(e) => {
          if (!props.to) {
            e.preventDefault();
          }
        }}
      >
        <BackLinkInner to={props.to} />
      </a>
    );
  }

  return (
    <Link
      to={props.to}
      onClick={(e) => {
        if (!props.to) {
          e.preventDefault();
        }
      }}
    >
      <BackLinkInner to={props.to} />
    </Link>
  );
};

export default BackLink;
