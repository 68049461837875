import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import Footer from "components/Footer/Footer";
import Logo from "components/Logo/Logo";
import Button from "components/UI/Button/Button";
import TestNavigation from "./TestNavigation/TestNavigation";
import QuestionItem from "./QuestionItem/QuestionItem";
import TestSection from "./TestSection/TestSection";
import BackLink from "components/UI/BackLink/BackLink";
import Back from "components/UI/Back/Back";
import Scroller from "hoc/CustomScrollbar/Scroller.js";

import classes from "./Test.module.scss";

import test from "tests/test1.json";
import { connect } from "react-redux";
import { sendResults } from "store/Profile/Tests/actions";
import { addRedirectLink } from "store/Auth/actions";

const testSections = ["1-5", "6-10", "11-16"];
const QUESTIONS_IN_PAGE = 5;

const extraQuestion = {
  question:
    "Укажите, какой ещё симптом Вас заметно беспокоит, но он не был указан выше:",
  answers: [
    "Этого симптома у меня не бывает никогда или почти никогда (за указанный период)",
    "Бывает редко, минимально влияет на общее самочувствие (качество жизни) и работоспособность",
    "Бывает регулярно, ухудшает самочувствие (качество жизни) или снижает работоспособность не более чем на 25%",
    "Бывает регулярно / часто, и примерно на 50% (в 2 раза) ухудшает самочувствие (качество жизни) или снижает работоспособность",
    "Бывает часто, и резко (на 75%) ухудшает общее самочувствие (качество жизни) или работоспособность",
  ],
};

class Test1 extends Component {
  state = {
    isStarted: false,
    isFinished: false,
    isValid: false,
    activeSection: 0,
    userAnswers: {},
  };

  componentDidMount() {
    this.props.addRedirectLink("");
  }

  startTestHandler = () => {
    this.setState({
      isStarted: true,
      activeSection: 0,
    });
  };

  finishTestHandler = () => {
    let results = {
      // Пустой объект результатов для отправки
      test_type: "test1",
      data: [],
    };

    const answers = Object.values(this.state.userAnswers);

    for (let i = 0; i < answers.length; i++) {
      results.data.push({
        question_number: i,
        answer_number: answers[i].usersInput === "" ? 0 : answers[i].answerId,
        users_input:
          answers[i].usersInput === "" ? null : answers[i].usersInput, // если input пуст, отправить null
        selected_answers: null, // В тесте №1 нет вопросов с множественным выбором
        question_text: test.testData[i]
          ? test.testData[i].question
          : extraQuestion.question,
        answer_text: test.testData[i]
          ? test.testData[i].answers[answers[i].answerId]
          : extraQuestion.answers[answers[i].answerId],
      });
    }

    if (results.data.length < 16) {
      results.data.push({
        question_number: 15,
        answer_number: 0,
        users_input: null,
        selected_answers: null,
        question_text: extraQuestion.question,
        answer_text: null,
      });
    }

    this.props.sendResults(results);

    this.setState({
      isFinished: true,
    });
  };

  // questionNumber - номер вопроса (начинается с 1 и соответствует отображаемому номеру)
  // answerId - id ответа (начинается с 0, может не совпадать с номером ответа в вопросе)
  questionChangeHandler = (
    questionNumber,
    answerId,
    usersInput,
    checkboxValue = null
  ) => {
    let userAnswers = { ...this.state.userAnswers };

    if (userAnswers[questionNumber]) {
      //если пользователь уже отвечал на этот вопрос: изменить
      let answer = { ...userAnswers[questionNumber] };

      if (usersInput !== null) {
        answer.usersInput = usersInput;
        if (usersInput === "") {
          answer.answerId = null;
        }
      } else if (answerId !== null) {
        answer.answerId = answerId;
      }
      userAnswers[questionNumber] = answer;
    } else {
      //иначе: создать
      userAnswers[questionNumber] = { answerId, usersInput }; // добавить/изменить ответ на вопрос
    }

    let isValid = true;

    // если в последнем вопросе есть симптом, но нет ответа или наоборот
    if (questionNumber === 16) {
      if (
        (!!userAnswers[questionNumber].usersInput &&
          userAnswers[questionNumber].answerId === null) ||
        (!userAnswers[questionNumber].usersInput &&
          userAnswers[questionNumber].answerId !== null)
      ) {
        isValid = false;
      }
    }

    this.setState({ userAnswers, isValid });
  };

  changePageHandler = (next) => {
    if (next) {
      // вперед
      this.setState({
        activeSection: this.state.activeSection + 1,
      });
    } else {
      // назад
      if (this.state.activeSection === 0) {
        // первая страница
        this.setState({
          isStarted: false,
          isFinished: false,
          userAnswers: {},
        });
      } else if (this.state.isFinished) {
        //  последняя страница
        this.setState({
          isFinished: false,
        });
      } else {
        // остальные страницы
        this.setState({
          activeSection: this.state.activeSection - 1,
        });
      }
    }
    window.scrollTo(0, 0);
  };

  renderIntro = () => {
    return (
      <div className={classes.intro}>
        <div className={classes.amount}>16 вопросов</div>
        <p>
          Для каждого из <strong>15 вопросов</strong> необходимо выбрать один из
          пяти вариантов ответа <strong>(от 0 до 4)</strong>, в зависимости от
          того, насколько данный симптом был выражен у Вас и влиял на
          самочувствие (качество жизни) и работоспособность в течение последних{" "}
          <strong>2-3 месяцев</strong>.
        </p>
        <p>
          При оценке снижения качества жизни ориентируйтесь на своё оптимальное
          состояние здоровья, а не на то фактическое состояние, к которому Вы
          могли привыкнуть.
        </p>
        <div className={classes.start_button}>
          <Button type={"primary"} onClick={() => this.startTestHandler()}>
            Начать тестирование
          </Button>
        </div>
      </div>
    );
  };

  renderFinishPage = () => {
    return (
      <div className={classes.finish}>
        <p>Спасибо за ваши ответы!</p>
        <p>
          Результаты тестирования и направление на консультацию отобразятся в
          вашем <strong>личном кабинете</strong> в разделе{" "}
          <strong>Здоровье</strong>
        </p>
        <div className={classes.finish_button}>
          <Link to={"/profile/health"}>
            <Button type={"primary"}>Вернуться в личный кабинет</Button>
          </Link>
        </div>
      </div>
    );
  };

  renderTest = () => {
    // Формирование блока вопросов для текущей страницы
    const testSectionList = test.testData.filter((question, index) => {
      return (
        index >= this.state.activeSection * QUESTIONS_IN_PAGE &&
        index < (this.state.activeSection + 1) * QUESTIONS_IN_PAGE
      );
    });

    // Номер первого вопроса на странице
    const firstQuestionNumber =
      this.state.activeSection * QUESTIONS_IN_PAGE + 1;

    return (
      <React.Fragment>
        <div className={classes.navigation}>
          <TestNavigation
            sections={testSections}
            activeSection={this.state.activeSection}
          />
        </div>
        <TestSection
          userAnswers={this.state.userAnswers}
          firstQuestionNumber={firstQuestionNumber}
          questionList={testSectionList}
          onChange={this.questionChangeHandler}
        />

        {this.state.activeSection === testSections.length - 1 ? (
          <React.Fragment>
            <QuestionItem // Последний вопрос
              number={test.testData.length + 1}
              question={extraQuestion.question}
              type={"input"}
              placeholder={"Симптом"}
              answers={extraQuestion.answers}
              firstAnswerNumber={0} // номер первого ответа
              userAnswer={
                this.state.userAnswers[test.testData.length + 1] || null
              } // ответ пользователя на последний вопрос, если он существует
              onChange={this.questionChangeHandler}
              isValid={this.state.isValid}
            />
            <div className={classes.button}>
              <Button
                type={"primary"}
                onClick={() => this.finishTestHandler()}
                disabled={
                  !this.state.isValid ||
                  Object.keys(this.state.userAnswers).length <
                    test.testData.length - 1
                } // выключить, если пользователь не ответил на все вопросы (без учета дополнительного)
              >
                Завершить
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <div className={classes.button}>
            <Button
              type={"primary"}
              onClick={() => this.changePageHandler(true)}
              disabled={
                Object.keys(this.state.userAnswers).length <
                QUESTIONS_IN_PAGE * (this.state.activeSection + 1)
              } // выключить, если не достигнуто нужное колличество ответов, для данной страницы
            >
              Далее
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  };

  render() {
    if (localStorage.getItem("role") !== "patient")
      return <Redirect to="/profile/" />;
    return (
      <section className={classes.Test}>
        <Scroller page={this.state.activeSection} />
        <Helmet>
          <title>ОФН-15. Портал здоровья</title>
          <meta name="title" content="ОФН-15. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="ОФН-15. Портал здоровья" />
        </Helmet>
        <Logo />
        <div className={classes.container}>
          <h3 className={classes.title}>
            {this.state.isStarted ? ( // тест начат? кнопка "назад" : ссылка "назад"
              <Back
                className={classes.back}
                onClick={() => this.changePageHandler(false)}
              />
            ) : (
              <BackLink to={"/profile/health"} />
            )}
            Краткий опросник функциональных нарушений
          </h3>
          {
            this.state.isStarted
              ? this.state.isFinished
                ? this.renderFinishPage() // тест начат и закончен
                : this.renderTest() // тест начат, но не закончен
              : this.renderIntro() // тест не начат
          }
        </div>
        <Footer />
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addRedirectLink: (link) => dispatch(addRedirectLink(link)),
    sendResults: (results) => dispatch(sendResults(results)),
  };
}

export default connect(null, mapDispatchToProps)(Test1);
