import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import BackLink from "components/UI/BackLink/BackLink";
import ModalWindow from "components/UI/ModalWindow/ModalWindow";
import ModalContent from "components/UI/ModalWindow/SuccessErrorMessage/SuccessErrorMessage";
import DeleteConfirm from "components/UI/ModalWindow/DeleteConfirm/DeleteConfirm";
import Button from "components/UI/Button/Button";
import GenderControl from "components/UI/GenderControl/GenderControl";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";
import Input from "components/UI/Input/Input";

import { validateControl } from "containers/Auth/Auth";
import { convertDateToString } from "components/Profile/dateConverter";
import classes from "./SupportEdit.module.scss";
import {
  editSupport,
  getSupportByID,
  sendNewPassword,
  loadPicture,
  removePicture,
  changeUserStatus,
  changeUserDeletedStatus,
  clearDeleteSupportStatus,
} from "store/Profile/Supports/actions";

let modalTimeout;

class SupportEdit extends Component {
  state = {
    isUserpicLoading: false,
    isAddressesMatch: false,
    accountInfoValid: true,
    accountInfoTouched: false,
    passwordInputValid: false,
    passwordMatch: false,
    passChange: {
      password: {
        value: "",
        type: "password",
        errorMessage:
          "Пароль должен содержать не менее 8 символов, цифры и латинские буквы, не должен являться словарным словом или набором символов, находящихся рядом на клавиатуре",
        placeholder: "Пароль",
        label: "Пароль",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
          minLength: 8,
        },
      },
      repeatPassword: {
        value: "",
        type: "password",
        errorMessage: "Пароли должны совпадать",
        placeholder: "Повторите пароль",
        label: "Повторите пароль",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {},
      },
    },
    personalData: {
      second_name: {
        placeholder: "Фамилия",
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        label: "Фамилия",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      first_name: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Имя",
        label: "Имя",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      patronymic_name: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "Отчество",
        label: "Отчество",
        valid: false,
        touched: false,
        shouldValidate: false,
        validation: {},
      },
      gender: {
        value: "male",
        touched: false,
        valid: true,
      },
      date_of_birth: {
        value: null,
        type: "date",
        errorMessage: "Введите корректное значение",
        placeholder: "Дата Рождения",
        label: "Дата Рождения",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      registration_address: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес регистрации",
        label: "Адрес регистрации",
        valid: false,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      real_address: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес места жительства",
        label: "Адрес места жительства",
        valid: false,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
    },
    modalShow: false,
    confirmModalShow: false,
    modalPassChangedShow: false,
  };
  // модалка
  closeOnTimeout() {
    modalTimeout = setTimeout(() => this.modalShowClose(), 3000);
  }
  modalShowOpen = () => {
    this.setState({ modalShow: true });
    this.closeOnTimeout();
  };
  confirmModalShowOpen = () => {
    this.setState({ confirmModalShow: true });
  };
  modalPassChangedShowOpen = () => {
    this.setState({ modalPassChangedShow: true });
    this.closeOnTimeout();
  };
  modalShowClose = () => {
    this.setState({
      modalShow: false,
      confirmModalShow: false,
      modalPassChangedShow: false,
    });
    this.props.clearDeleteSupportStatus();
  };
  componentDidMount() {
    this.props.getSupportByID(this.props.match.params.id);
  }
  componentDidUpdate(prevProps, prevState) {
    let editedSupport = this.props.editedSupport;
    if (prevProps.editedSupport !== editedSupport) {
      this.initState(editedSupport);
    }
    // открываем модалку со статусом отправки запроса
    if (editedSupport && prevProps.editedSupport) {
      if (
        prevProps.editedSupport.user.is_active !== editedSupport.user.is_active
      ) {
        this.modalShowOpen();
      }
    }
    if (prevProps.editSupportStatus !== this.props.editSupportStatus) {
      this.modalPassChangedShowOpen();
    }
    // Дублируем адреса
    if (
      prevState.isAddressesMatch === false &&
      this.state.isAddressesMatch === true
    ) {
      const formControls = { ...this.state.personalData };
      const real_address = { ...formControls["real_address"] };
      const registration_address = { ...formControls["registration_address"] };
      real_address.value = registration_address.value;
      real_address.touched = true;
      registration_address.touched = true;
      registration_address.valid = validateControl(
        registration_address.value,
        registration_address.validation
      );
      real_address.valid = registration_address.valid;
      formControls.real_address = real_address;
      formControls.registration_address = registration_address;
      this.setState({
        personalData: formControls,
        accountInfoTouched: true,
      });
    }
    if (prevProps.userpic_url !== this.props.userpic_url) {
      this.setState({ isUserpicLoading: false });
    }
  }
  initState(editedSupport) {
    let newState = { ...this.state };
    newState.personalData.first_name.value = editedSupport.first_name;
    newState.personalData.first_name.valid = true;
    newState.personalData.second_name.value = editedSupport.second_name;
    newState.personalData.second_name.valid = true;
    newState.personalData.patronymic_name.value = editedSupport.patronymic_name;
    newState.personalData.patronymic_name.valid = true;
    newState.personalData.registration_address.value =
      editedSupport.registration_address;
    newState.personalData.registration_address.valid = true;
    newState.personalData.real_address.value = editedSupport.real_address;
    newState.personalData.real_address.valid = true;
    newState.personalData.date_of_birth.value = new Date(
      editedSupport.date_of_birth
    );
    newState.personalData.date_of_birth.valid = true;

    this.setState({
      ...newState,
    });
  }
  componentWillUnmount() {
    clearTimeout(modalTimeout);
  }
  // добавление фото
  addImageHadler = (event) => {
    this.props.loadPicture(event.target.files[0]);
    this.setState({
      accountInfoTouched: true,
      isUserpicLoading: true,
    });
  };
  removeImageHandler = () => {
    this.props.removePicture();
  };
  onChangeInputHandler = (event, controlName) => {
    const formControls = { ...this.state.personalData };
    const control = { ...formControls[controlName] };

    if (control.type === "address") {
      control.value = event;
      // для инпутов с типом адрес значение хранится в event (костыль)
    } else {
      control.value = event.target.value;
    }
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);

    if (controlName === "registration_address" && this.state.isAddressesMatch) {
      const real_address = { ...formControls["real_address"] };
      real_address.value = control.value;
      real_address.touched = true;
      real_address.valid = control.valid;
      formControls["real_address"] = real_address;
    }

    formControls[controlName] = control;
    let accountInfoTouched = true;
    let accountInfoValid = true;
    Object.keys(formControls).forEach((key) => {
      if (!formControls[key].valid) {
        accountInfoValid = false;
      }
    });
    this.setState({
      accountInfoTouched: accountInfoTouched,
      accountInfoValid: accountInfoValid,
      personalData: formControls,
    });
  };
  onChangeDateHandler = (date) => {
    const formControls = { ...this.state.personalData };
    let personalData = { ...this.state.personalData };
    personalData.date_of_birth.value = date;
    personalData.date_of_birth.touched = true;
    personalData.date_of_birth.valid = validateControl(
      personalData.date_of_birth.value,
      personalData.date_of_birth.validation
    );

    let accountInfoValid = true;
    Object.keys(formControls).forEach((key) => {
      if (!formControls[key].valid) {
        accountInfoValid = false;
      }
    });
    this.setState({
      accountInfoTouched: true,
      accountInfoValid: accountInfoValid,
      personalData: personalData,
    });
  };
  genderChangeHandler = (gender) => {
    let personalData = { ...this.state.personalData };
    personalData.gender.value = gender;
    personalData.gender.touched = true;
    let accountInfoTouched = true;
    this.setState({
      accountInfoTouched: accountInfoTouched,
      personalData: personalData,
    });
  };
  onChangeInputPassHandler = (event, controlName) => {
    const formControls = { ...this.state.passChange };
    const control = { ...formControls[controlName] };

    control.value = event.target.value;
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);
    formControls[controlName] = control;

    let isPasswordsMatch = false;
    if (formControls.password.value === formControls.repeatPassword.value) {
      isPasswordsMatch = true;
      formControls.repeatPassword.valid = isPasswordsMatch;
    } else {
      formControls.repeatPassword.valid = isPasswordsMatch;
    }
    let passwordInputValid = true;
    Object.keys(formControls).forEach((key) => {
      if (!formControls[key].valid) {
        passwordInputValid = false;
      }
    });

    this.setState({
      passwordInputValid: passwordInputValid,
      passChange: formControls,
      passwordMatch: isPasswordsMatch,
    });
  };
  // отправка формы с личными данными
  sendAccountInfo() {
    let data = {
      id: this.props.match.params.id,
      first_name: this.state.personalData.first_name.value,
      second_name: this.state.personalData.second_name.value,
      patronymic_name: this.state.personalData.patronymic_name.value,
      gender: this.state.personalData.gender.value,
      date_of_birth: convertDateToString(
        this.state.personalData.date_of_birth.value
      ),
      registration_address:
        this.state.personalData.registration_address.value.value ||
        this.state.personalData.registration_address.value,
      real_address:
        this.state.personalData.real_address.value.value ||
        this.state.personalData.real_address.value,
      userpic_url: this.props.userpic_url,
    };
    this.props.editSupport(data);
    if (this.props.editSupportStatus !== "") {
      this.modalPassChangedShowOpen();
    }
    this.setState({
      accountInfoTouched: false,
    });
  }
  // смена пароля
  sendNewPassword() {
    let data = {
      id: this.props.match.params.id,
      password: this.state.passChange.password.value,
      repeatPassword: this.state.passChange.repeatPassword.value,
    };
    this.props.sendNewPassword(data);
    if (this.props.sendNewPasswordStatus !== "") {
      this.modalPassChangedShowOpen();
    }
    let newState = { ...this.state };
    newState.passChange.password.value = "";
    newState.passChange.repeatPassword.value = "";
    this.setState({ ...newState });
  }

  changeUserStatusHandler = (newStatus) => {
    const data = { is_active: newStatus };
    this.props.changeUserStatus(this.props.editedSupport?.user_id, data);
  };

  changeUserDeletedStatusHandler = (newStatus) => {
    const data = { is_deleted: newStatus };
    this.props.changeUserDeletedStatus(this.props.editedSupport?.user_id, data);
  };

  deleteConfirmedHandler = () => {
    this.changeUserDeletedStatusHandler(true);
    this.modalShowClose();
  };

  restoreConfirmedHandler = () => {
    this.changeUserDeletedStatusHandler(false);
    this.modalShowClose();
  };

  render() {
    let fullName;

    if (this.props.editedSupport) {
      fullName = `${this.props.editedSupport.second_name} ${
        this.props.editedSupport.first_name
      }${
        this.props.editedSupport.patronymic_name
          ? " " + this.props.editedSupport.patronymic_name
          : ""
      }${this.props.editedSupport.second_name ? ". " : ""}`;
    }

    return (
      <section className={classes.SupportEdit}>
        {fullName ? (
          <Helmet>
            <title>{fullName}Портал здоровья</title>
            <meta name="title" content={`${fullName}Портал здоровья`} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:title" content={`${fullName}Портал здоровья`} />
          </Helmet>
        ) : null}
        <div className={classes.back}>
          <BackLink to="/profile/supports" />
          <span>Назад</span>
        </div>
        <div className={classes.title}>
          {this.state.personalData.first_name.value}&nbsp;
          {this.state.personalData.second_name.value}&nbsp;
          {this.state.personalData.patronymic_name.value}
        </div>
        <form
          className={classes.form}
          onSubmit={(event) => event.preventDefault()}
        >
          <div className={classes.section_title}>
            <h5>Смена пароля</h5>
            {this.props.editedSupport?.user.is_active ? (
              <span
                className={classes.block_btn}
                onClick={() => this.changeUserStatusHandler(false)}
              >
                Заблокировать
              </span>
            ) : (
              <span
                className={classes.block_btn}
                onClick={() => this.changeUserStatusHandler(true)}
              >
                Разблокировать
              </span>
            )}
            {this.props.editedSupport?.user.is_deleted ? (
              <span
                className={classes.block_btn}
                onClick={this.confirmModalShowOpen}
              >
                Восстановить
              </span>
            ) : (
              <span
                className={classes.block_btn}
                onClick={this.confirmModalShowOpen}
              >
                Удалить
              </span>
            )}
          </div>
          <div className={classes.section_content}>
            <div className={classes.inputs}>
              {/* новый пароль */}
              <Input
                value={this.state.passChange.password.value}
                type={this.state.passChange.password.type}
                placeholder={this.state.passChange.password.placeholder}
                valid={this.state.passChange.password.valid}
                touched={this.state.passChange.password.touched}
                label={this.state.passChange.password.label}
                shouldValidate={this.state.passChange.password.shouldValidate}
                errorMessage={this.state.passChange.password.errorMessage}
                onChange={(event) =>
                  this.onChangeInputPassHandler(event, "password")
                }
              ></Input>

              {/* повтор нового пароля */}
              <Input
                value={this.state.passChange.repeatPassword.value}
                type={this.state.passChange.repeatPassword.type}
                placeholder={this.state.passChange.repeatPassword.placeholder}
                valid={this.state.passChange.repeatPassword.valid}
                touched={this.state.passChange.repeatPassword.touched}
                label={this.state.passChange.repeatPassword.label}
                shouldValidate={
                  this.state.passChange.repeatPassword.shouldValidate
                }
                errorMessage={this.state.passChange.repeatPassword.errorMessage}
                onChange={(event) =>
                  this.onChangeInputPassHandler(event, "repeatPassword")
                }
              ></Input>
              <Button
                disabled={
                  !(this.state.passwordInputValid && this.state.passwordMatch)
                }
                onClick={() => this.sendNewPassword()}
                type={"primary"}
              >
                Сохранить
              </Button>
            </div>
          </div>
          <h5 className={classes.section_title}>Личные данные</h5>
          <div className={classes.section_withPhoto}>
            <div className={classes.inputs}>
              {/* фамилия */}

              <Input
                className={classes.Input}
                value={this.state.personalData.second_name.value}
                placeholder={this.state.personalData.second_name.placeholder}
                valid={this.state.personalData.second_name.valid}
                touched={this.state.personalData.second_name.touched}
                label={this.state.personalData.second_name.label}
                shouldValidate={
                  this.state.personalData.second_name.shouldValidate
                }
                errorMessage={this.state.personalData.second_name.errorMessage}
                onChange={(event) =>
                  this.onChangeInputHandler(event, "second_name")
                }
              ></Input>

              {/* имя */}

              <Input
                className={classes.Input}
                value={this.state.personalData.first_name.value}
                placeholder={this.state.personalData.first_name.placeholder}
                valid={this.state.personalData.first_name.valid}
                touched={this.state.personalData.first_name.touched}
                label={this.state.personalData.first_name.label}
                shouldValidate={
                  this.state.personalData.first_name.shouldValidate
                }
                errorMessage={this.state.personalData.first_name.errorMessage}
                onChange={(event) =>
                  this.onChangeInputHandler(event, "first_name")
                }
              ></Input>

              {/* отчество */}

              <Input
                className={classes.Input}
                value={this.state.personalData.patronymic_name.value}
                placeholder={
                  this.state.personalData.patronymic_name.placeholder
                }
                valid={this.state.personalData.patronymic_name.valid}
                touched={this.state.personalData.patronymic_name.touched}
                label={this.state.personalData.patronymic_name.label}
                shouldValidate={
                  this.state.personalData.patronymic_name.shouldValidate
                }
                errorMessage={
                  this.state.personalData.patronymic_name.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "patronymic_name")
                }
              ></Input>

              <div className={classes.row}>
                {/* пол */}
                <GenderControl
                  value={this.state.personalData.gender.value}
                  onChange={(event) => this.genderChangeHandler(event)}
                />
                {/* дата рожд */}

                <Input
                  className={classes.Input}
                  type={this.state.personalData.date_of_birth.type}
                  value={this.state.personalData.date_of_birth.value}
                  placeholder={
                    this.state.personalData.date_of_birth.placeholder
                  }
                  valid={this.state.personalData.date_of_birth.valid}
                  touched={this.state.personalData.date_of_birth.touched}
                  label={this.state.personalData.date_of_birth.label}
                  shouldValidate={
                    this.state.personalData.date_of_birth.shouldValidate
                  }
                  errorMessage={
                    this.state.personalData.date_of_birth.errorMessage
                  }
                  onChange={(event, date) => this.onChangeDateHandler(date)}
                ></Input>
              </div>

              {/* адрес регистр */}

              <Input
                className={classes.Input}
                type={this.state.personalData.registration_address.type}
                value={this.state.personalData.registration_address.value}
                placeholder={
                  this.state.personalData.registration_address.placeholder
                }
                valid={this.state.personalData.registration_address.valid}
                touched={this.state.personalData.registration_address.touched}
                label={this.state.personalData.registration_address.label}
                shouldValidate={
                  this.state.personalData.registration_address.shouldValidate
                }
                errorMessage={
                  this.state.personalData.registration_address.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "registration_address")
                }
              ></Input>

              {/* адр места жительства */}

              <Input
                className={classes.Input}
                type={this.state.personalData.real_address.type}
                value={this.state.personalData.real_address.value}
                placeholder={this.state.personalData.real_address.placeholder}
                valid={this.state.personalData.real_address.valid}
                touched={this.state.personalData.real_address.touched}
                label={this.state.personalData.real_address.label}
                shouldValidate={
                  this.state.personalData.real_address.shouldValidate
                }
                errorMessage={this.state.personalData.real_address.errorMessage}
                onChange={(event) =>
                  this.onChangeInputHandler(event, "real_address")
                }
                disabled={this.state.isAddressesMatch}
              ></Input>
              {/* адреса совпадают */}
              <label className={classes.checkbox}>
                <input
                  type="checkbox"
                  onChange={(event) => {
                    this.setState({ isAddressesMatch: !!event.target.checked });
                  }}
                  checked={this.state.isAddressesMatch}
                />
                <span className={classes.checkmark} />
                Адреса совпадают
              </label>
              <Button
                disabled={
                  !(
                    this.state.accountInfoValid && this.state.accountInfoTouched
                  )
                }
                onClick={() => this.sendAccountInfo()}
                type={"primary"}
              >
                Сохранить
              </Button>
            </div>
            {/* фото */}
            <div
              className={classes.picture_loader}
              style={{ backgroundImage: `url(${this.props.userpic_url})` }}
            >
              <input
                className={classes.picture_input}
                type={"file"}
                onChange={(event) => this.addImageHadler(event)}
              />
              {this.props.userpic_url ? (
                <div
                  className={classes.remove_btn}
                  onClick={() => this.removeImageHandler()}
                >
                  <img src={"/profile/images/cross.svg"} alt={"remove"} />
                </div>
              ) : (
                <div className={classes.add_btn}>
                  {this.state.isUserpicLoading ? (
                    <FileLoader style={{ height: "14px", margin: 0 }} />
                  ) : (
                    <img src={"/profile/images/plus.svg"} alt={"add"} />
                  )}
                  <div className={classes.add_btn__text}>Загрузить фото</div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* модальное окно*/}
        {this.state.modalShow && this.props.editedSupport ? (
          <ModalWindow closed={this.modalShowClose}>
            {this.props.editedSupport.user.is_active ? (
              <ModalContent
                title={"Пользователь разблокирован!"}
                icon={"/profile/images/unblocked.svg"}
                subtitle={`Для его блокировки нажмите "Заблокировать"`}
              ></ModalContent>
            ) : (
              <ModalContent
                title={"Пользователь заблокирован!"}
                icon={"/profile/images/blocked.svg"}
                subtitle={`Для его разблокировки нажмите "Разблокировать"`}
              ></ModalContent>
            )}
          </ModalWindow>
        ) : null}
        {this.state.confirmModalShow ? (
          <ModalWindow closed={this.modalShowClose}>
            <DeleteConfirm
              confirmed={
                !this.props.editedSupport.user.is_deleted
                  ? this.deleteConfirmedHandler
                  : this.restoreConfirmedHandler
              }
              closed={this.modalShowClose}
              title={`Вы точно хотите ${
                this.props.editedSupport.user.is_deleted
                  ? "восстановить"
                  : "удалить"
              } технического специалиста: "${
                this.state.personalData.second_name.value
              } ${this.state.personalData.first_name.value}" ?`}
              action={
                this.props.editedSupport.user.is_deleted
                  ? "Восстановить"
                  : "Удалить"
              }
            ></DeleteConfirm>
          </ModalWindow>
        ) : null}
        {this.state.modalPassChangedShow &&
        this.props.editSupportStatus !== "" ? (
          <ModalWindow timeout={3000} closed={this.modalShowClose}>
            {this.props.editSupportStatus === "SUCCESS" ? (
              <ModalContent
                title={"Успех!"}
                status={this.props.editSupportStatus}
              ></ModalContent>
            ) : (
              <ModalContent
                title={"Ошибка!"}
                subtitle={`Попробуйте позже`}
                status={this.props.editSupportStatus}
              ></ModalContent>
            )}
          </ModalWindow>
        ) : null}
        {this.props.accountDeleteStatus !== "" ? (
          <ModalWindow closed={this.modalShowClose}>
            {this.props.accountDeleteStatus === "Duplicate user" ? (
              <ModalContent
                title={"Ошибка!"}
                subtitle={`Пользователь с данным Email не может быть восстановлен, т.к. уже зарегистрирован другой пользователь с данным Email`}
                icon={"/profile/images/ERROR.svg"}
                status={this.props.accountDeleteStatus}
              ></ModalContent>
            ) : null}
          </ModalWindow>
        ) : null}
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    account: state.account.account,
    userpic_url: state.supports.userpic_url,
    editSupportStatus: state.supports.editSupportStatus,
    editedSupport: state.supports.editedSupport,
    accountDeleteStatus: state.supports.deleteSupportStatus,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    editSupport: (data) => dispatch(editSupport(data)),
    getSupportByID: (id) => dispatch(getSupportByID(id)),
    sendNewPassword: (data) => dispatch(sendNewPassword(data)),
    loadPicture: (file) => dispatch(loadPicture(file)),
    removePicture: () => dispatch(removePicture()),
    changeUserStatus: (id, data) => dispatch(changeUserStatus(id, data)),
    changeUserDeletedStatus: (id, data) =>
      dispatch(changeUserDeletedStatus(id, data)),
    clearDeleteSupportStatus: () => dispatch(clearDeleteSupportStatus()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SupportEdit);
