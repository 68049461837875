import {
  GET_ARTICLESLIST_ERROR,
  GET_ARTICLESLIST_SUCCESS,
  CREATE_ARTICLE_ERROR,
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_PENDING,
  EDIT_ARTICLE_SUCCESS,
  EDIT_ARTICLE_ERROR,
  GET_ARTICLEBYID_SUCCESS,
  GET_ARTICLEBYID_ERROR,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_ERROR,
  LOAD_PICTURE_SUCCESS,
  LOAD_PICTURE_ERROR,
  REMOVE_PICTURE,
} from "./actionTypes";

const initialState = {
  articlesList: null,
  editedArticle: null,
  createArticleStatus: "",
  article_image: null,
  articlesTotal: 0,
  needUpdate: false,
};

export default function articlesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ARTICLESLIST_SUCCESS:
      return {
        ...state,
        articlesList: action.data.items,
        articlesTotal: action.data.total,
        createArticleStatus: "",
        editArticleStatus: "",
        article_image: null,
        needUpdate: false,
      };
    case GET_ARTICLESLIST_ERROR:
      return {
        ...state,
        article_image: null,
        createArticleStatus: "",
        editArticleStatus: "",
      };
    case CREATE_ARTICLE_SUCCESS:
      return {
        ...state,
        article_image: null,
        createArticleStatus: "SUCESS",
      };
    case CREATE_ARTICLE_PENDING:
      return {
        ...state,
        createArticleStatus: action.createArticleStatus,
      };
    case CREATE_ARTICLE_ERROR:
      return {
        ...state,
        article_image: null,
        createArticleStatus: "ERROR",
      };
    case EDIT_ARTICLE_SUCCESS:
      return {
        ...state,
        editedArticle: null,
        article_image: null,
        editArticleStatus: "SUCCESS",
      };
    case EDIT_ARTICLE_ERROR:
      return {
        ...state,
        editedArticle: null,
        article_image: null,
        editArticleStatus: "ERROR",
      };
    case GET_ARTICLEBYID_SUCCESS:
      return {
        ...state,
        editedArticle: action.editedArticle,
        article_image: action.editedArticle.image_url
      };
    case GET_ARTICLEBYID_ERROR:
      return {
        ...state,
        editedArticle: null,
      };
    case DELETE_ARTICLE_SUCCESS:
      return {
        ...state,
        needUpdate: true,
      };
    case DELETE_ARTICLE_ERROR:
      return {
        ...state,
      };
    case LOAD_PICTURE_SUCCESS:
      return {
        ...state,
        article_image: action.url,
      };
    case LOAD_PICTURE_ERROR:
      return {
        ...state,
        article_image: null,
      };
    case REMOVE_PICTURE:
      return {
        ...state,
        article_image: null,
      };
    default:
      return state;
  }
}
