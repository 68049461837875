import axios from "axios";

import {
  GET_SPECIALIZATIONS_SUCCESS,
  GET_SPECIALIZATIONS_ERROR,
  ADD_FILE_SUCCESS,
  REMOVE_FILE,
  CLEAR_FILE_LIST,
  GET_CARD_INFO_SUCCESS,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_ERROR,
  ACCEPT_PATIENT_INVITATION_SUCCESS,
  ACCEPT_PATIENT_INVITATION_ERROR,
  LOAD_PICTURE_SUCCESS,
  REMOVE_PICTURE,
  CLEAR_ERRORS,
  GET_DOCTORS_SUCCESS,
  GET_DOCTORS_ERROR,
  SHOW_TIP_MODAL,
  CLOSE_TIP_MODAL,
  COLLAPSE_SIDEBAR,
} from "./actionTypes";

export function getDoctorsList() {
  return async (dispatch) => {
    axios
      .get("/users/?role=doctor")
      .then((response) => {
        dispatch(getDoctorsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getDoctorsError());
        console.log(error.response);
      });
  };
}

export function showTipModal() {
  return (dispatch) => {
    dispatch(showModal());
    // setTimeout(() => {
    //   dispatch(closeModal())
    // }, 3000)
  };
}

export function showModal() {
  return {
    type: SHOW_TIP_MODAL,
  };
}

export function closeModal() {
  return {
    type: CLOSE_TIP_MODAL,
  };
}

export function collapseSidebar(data) {
  return {
    type: COLLAPSE_SIDEBAR,
    isSidebarCollapsed: data,
  };
}

export function getDoctorsSuccess(data) {
  return {
    type: GET_DOCTORS_SUCCESS,
    doctorsList: data,
  };
}

export function getDoctorsError(role) {
  return {
    type: GET_DOCTORS_ERROR,
  };
}

export function createProfile(profileData) {
  return async (dispatch) => {
    axios
      .post("/profiles/", profileData)
      .then((response) => {
        dispatch(createProfileSuccess(profileData.role));
        localStorage.setItem("role", profileData.role);
      })
      .catch((error) => {
        dispatch(createProfileError(error.response));
        console.log(error);
      });
  };
}

export function loadPicture(file) {
  return async (dispatch) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", file);

    axios({
      method: "post",
      url: "/profiles/upload_photo",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then(function (response) {
        dispatch(loadPictureSuccess(response.data.url));
      })
      .catch(function (error) {
        dispatch(createProfileError(error.response));
        console.log(error);
      });
  };
}

export function getSpecializations() {
  return async (dispatch) => {
    axios
      .get("/specializations/")
      .then((response) => {
        dispatch(getSpecializationsSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getSpecializationsError());
      });
  };
}

export function addFile(file) {
  return async (dispatch) => {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: "/consultations/upload_attachment",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then(function (response) {
        dispatch(addFileSuccess(response.data.url));
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };
}
export function getCardInfo(id) {
  return async (dispatch) => {
    axios
      .get(`/consultations/${id}`)
      .then((response) => {
        dispatch(getCardInfoSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function createProfileSuccess(role) {
  return {
    type: CREATE_PROFILE_SUCCESS,
    role,
  };
}

export function createProfileError(error) {
  return {
    type: CREATE_PROFILE_ERROR,
    error,
  };
}

export function clearErrors() {
  return {
    type: CLEAR_ERRORS,
  };
}

export function loadPictureSuccess(url) {
  return {
    type: LOAD_PICTURE_SUCCESS,
    url,
  };
}

export function removePicture() {
  return {
    type: REMOVE_PICTURE,
  };
}

export function getSpecializationsSuccess(specializations) {
  return {
    type: GET_SPECIALIZATIONS_SUCCESS,
    specializations,
  };
}

export function getSpecializationsError() {
  return {
    type: GET_SPECIALIZATIONS_ERROR,
  };
}

export function addFileSuccess(url) {
  return {
    type: ADD_FILE_SUCCESS,
    url,
  };
}

export function removeFile(index) {
  return {
    type: REMOVE_FILE,
    index,
  };
}

export function clearFileList() {
  return {
    type: CLEAR_FILE_LIST,
  };
}

export function getCardInfoSuccess(cardInfo) {
  return {
    type: GET_CARD_INFO_SUCCESS,
    cardInfo,
  };
}
