import axios from "axios";

import {
  GET_ACCOUNT_ERROR,
  GET_ACCOUNT_SUCCESS,
  GET_MY_ACCOUNT_SUCCESS,
  EDIT_ACCOUNT_ERROR,
  EDIT_ACCOUNT_SUCCESS,
  CLEAR_ACCOUNT_STATUS,
  ADD_REPRESENTATIVE_SUCCESS,
  ADD_REPRESENTATIVE_ERROR,
  GET_REPRESENTATIVES_SUCCESS,
  GET_REPRESENTATIVES_ERROR,
  DELETE_REPRESENTATIVE_SUCCESS,
  LOAD_PICTURE_SUCCESS,
  LOAD_PICTURE_ERROR,
  REMOVE_PICTURE,
  GET_HEALTH_STATUS_SUCCESS,
  GET_HEALTH_STATUS_ERROR,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_ERROR,
  CHANGE_DELETED_STATUS_SUCCESS,
  CHANGE_DELETED_STATUS_ERROR,
  CLEAR_ACCOUNT_DELETED_STATUS,
} from "./actionTypes";

export function getMyAccountInfo() {
  return async (dispatch) => {
    axios
      .get("/profiles/my")
      .then((response) => {
        dispatch(getMyAccountSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAccountInfoError());
        console.log(err);
      });
  };
}

export function getAccountInfo() {
  return async (dispatch) => {
    axios
      .get("/profiles/my")
      .then((response) => {
        dispatch(getAccountInfoSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAccountInfoError());
        console.log(err);
      });
  };
}
export function getHealthStatus() {
  return async (dispatch) => {
    axios
      .get("/profiles/my/health_status")
      .then((response) => {
        dispatch(getHealthStatusSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getHealthStatusError());
        console.log(err);
      });
  };
}
export function editAccountInfo(data) {
  return async (dispatch) => {
    axios
      .patch("/profiles/my", data)
      .then(() => {
        dispatch(editAccountInfoSuccess());
        dispatch(getAccountInfo());
      })
      .catch((err) => {
        dispatch(editAccountInfoError());
        console.log(err);
      });
  };
}

export function getAccountInfoByID(id) {
  return async (dispatch) => {
    axios
      .get(`/profiles/${id}`)
      .then((response) => {
        dispatch(getAccountInfoSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAccountInfoError());
        console.log(err);
      });
  };
}
export function editAccountInfoByID(data) {
  return async (dispatch) => {
    axios
      .patch(`/profiles/${data.id}`, data)
      .then(() => {
        dispatch(editAccountInfoSuccess());
        dispatch(getAccountInfoByID(data.id));
      })
      .catch((err) => {
        dispatch(editAccountInfoError());
        console.log(err);
      });
  };
}
export function changeUserStatus(userId, data) {
  return async (dispatch) => {
    axios
      .post(`/users/${userId}/change_status`, data)
      .then(() => {
        dispatch(changeUserStatusSuccess());
        dispatch(getAccountInfoByID(userId));
      })
      .catch((err) => {
        dispatch(changeUserStatusError());
        console.log(err);
      });
  };
}
export function changeUserDeletedStatus(userId, data) {
  return async (dispatch) => {
    axios
      .post(`/users/${userId}/change_deleted_status`, data)
      .then(() => {
        dispatch(changeUserDeletedStatusSuccess());
        dispatch(getAccountInfoByID(userId));
      })
      .catch((err) => {
        dispatch(changeUserDeletedStatusError(err.response.data.detail));
        console.log(err);
      });
  };
}
export function changePassword(data) {
  return async (dispatch) => {
    axios
      .post("/users/change_password", data)
      .then(() => {
        dispatch(editAccountInfoSuccess());
      })
      .catch((err) => {
        dispatch(editAccountInfoError());
        console.log(err);
      });
  };
}
export function sendNewPassword(data) {
  return async (dispatch) => {
    let sendingData = {
      new_password1: data.password,
      new_password2: data.repeatPassword,
    };
    axios
      .post(`/users/${data.id}/change_password`, sendingData)
      .then(() => {
        dispatch(editAccountInfoSuccess());
        dispatch(getAccountInfoByID(data.id));
      })
      .catch((err) => {
        dispatch(editAccountInfoError());
        console.log(err);
      });
  };
}
export function getRepresentatives() {
  return async (dispatch) => {
    axios
      .get("/representatives/")
      .then((response) => {
        dispatch(getRepresentativesSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getRepresentativesError());
        console.log(err);
      });
  };
}
export function addRepresentative(data) {
  return async (dispatch) => {
    axios
      .post("/representatives/", data)
      .then(() => {
        dispatch(addRepresentativeSuccess());
        dispatch(getRepresentatives());
      })
      .catch((err) => {
        dispatch(addRepresentativeError());
        console.log(err);
      });
  };
}
export function deleteRepresentative(id) {
  return async (dispatch) => {
    axios
      .delete(`/representatives/${id}`)
      .then(() => {
        dispatch(deleteRepresentativeSuccess());
        dispatch(getRepresentatives());
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function loadPicture(file) {
  return async (dispatch) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: "/profiles/upload_photo",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    })
      .then(function (response) {
        dispatch(loadPictureSuccess(response.data.url));
      })
      .catch(function (error) {
        dispatch(loadPictureError());
        console.log(error);
      });
  };
}

export function removePicture() {
  return {
    type: REMOVE_PICTURE,
  };
}
export function loadPictureSuccess(url) {
  return {
    type: LOAD_PICTURE_SUCCESS,
    url,
  };
}
export function loadPictureError() {
  return {
    type: LOAD_PICTURE_ERROR,
  };
}
export function getAccountInfoSuccess(account) {
  return {
    type: GET_ACCOUNT_SUCCESS,
    account: account,
  };
}

export function getAccountInfoError() {
  return {
    type: GET_ACCOUNT_ERROR,
  };
}
export function editAccountInfoSuccess() {
  return {
    type: EDIT_ACCOUNT_SUCCESS,
    accountEditStatus: "SUCCESS",
  };
}

export function editAccountInfoError() {
  return {
    type: EDIT_ACCOUNT_ERROR,
    accountEditStatus: "ERROR",
  };
}
export function getRepresentativesSuccess(data) {
  return {
    type: GET_REPRESENTATIVES_SUCCESS,
    representatives: data,
    getRepresentativesStatus: "SUCCESS",
  };
}

export function getRepresentativesError() {
  return {
    type: GET_REPRESENTATIVES_ERROR,
    getRepresentativesStatus: "ERROR",
  };
}

export function addRepresentativeSuccess() {
  return {
    type: ADD_REPRESENTATIVE_SUCCESS,
    addRepresentativeStatus: "SUCCESS",
  };
}

export function addRepresentativeError() {
  return {
    type: ADD_REPRESENTATIVE_ERROR,
    addRepresentativeStatus: "ERROR",
  };
}

export function deleteRepresentativeSuccess() {
  return {
    type: DELETE_REPRESENTATIVE_SUCCESS,
  };
}
export function getHealthStatusSuccess(data) {
  return {
    type: GET_HEALTH_STATUS_SUCCESS,
    data,
  };
}
export function getHealthStatusError() {
  return {
    type: GET_HEALTH_STATUS_ERROR,
  };
}
export function getMyAccountSuccess(account) {
  return {
    type: GET_MY_ACCOUNT_SUCCESS,
    myAccount: account,
  };
}
export function clearAccountStatus() {
  return {
    type: CLEAR_ACCOUNT_STATUS,
  };
}
export function changeUserStatusSuccess() {
  return {
    type: CHANGE_STATUS_SUCCESS,
  };
}

export function changeUserStatusError() {
  return {
    type: CHANGE_STATUS_ERROR,
  };
}

export function changeUserDeletedStatusSuccess() {
  return {
    type: CHANGE_DELETED_STATUS_SUCCESS,
  };
}

export function changeUserDeletedStatusError(error) {
  return {
    type: CHANGE_DELETED_STATUS_ERROR,
    error,
  };
}
export function clearAccountDeletedStatus() {
  return {
    type: CLEAR_ACCOUNT_DELETED_STATUS,
  };
}
