import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

import Logo from "components/Logo/Logo";
import CreateRole from "./CreateRole/CreateRole";

import classes from "./CreateProfile.module.scss";
import { clearErrors } from "store/actions/profile";
import { getAccountInfo } from "store/Profile/Account/actions";

class CreateProfile extends Component {
  state = {
    selectedRole: null,
  };

  onRoleClickHandler = (role) => {
    this.setState({ selectedRole: role });
  };

  resetHandler = () => {
    this.props.clearErrors();
    this.setState({ selectedRole: null });
  };

  componentDidMount() {
    this.props.getAccountInfo();
  }

  render() {
    if (
      this.props.isProfileCreated ||
      (this.props.role && this.props.role !== "null")
    ) {
      localStorage.setItem("role", this.props.role);
      return <Redirect to="/profile/" />;
    }
    return (
      <section className={classes.CreateProfile}>
        <Helmet>
          <title>Создание профиля. Портал здоровья</title>
          <meta name="title" content="Создание профиля. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:title"
            content="Создание профиля. Портал здоровья"
          />
        </Helmet>
        <Logo />
        {this.state.selectedRole ? (
          <CreateRole
            getAccountInfo={this.props.getAccountInfo}
            role={this.state.selectedRole}
            reset={this.resetHandler}
          />
        ) : (
          <React.Fragment>
            <h3 className={classes.title}>Зарегистрироваться как</h3>
            <div className={classes.role_selector}>
              <div
                className={classes.role}
                onClick={() => this.onRoleClickHandler("patient")}
              >
                <span className={classes.role_name}>Пациент</span>
                <div
                  className={classes.icon}
                  style={{
                    maskImage: `url("/profile/images/patient-role.svg")`,
                    WebkitMaskImage: `url("/profile/images/patient-role.svg")`,
                  }}
                />
              </div>
              <div
                className={classes.role}
                onClick={() => this.onRoleClickHandler("doctor")}
              >
                <span className={classes.role_name}>Специалист</span>
                <div
                  className={classes.icon}
                  style={{
                    maskImage: `url("/profile/images/doctor-role.svg")`,
                    WebkitMaskImage: `url("/profile/images/doctor-role.svg")`,
                  }}
                />
              </div>
              <div
                className={classes.role}
                onClick={() => this.onRoleClickHandler("assistant")}
              >
                <span className={classes.role_name}>Ассистент</span>
                <div
                  className={classes.icon}
                  style={{
                    maskImage: `url("/profile/images/assistant-role.svg")`,
                    WebkitMaskImage: `url("/profile/images/assistant-role.svg")`,
                  }}
                />
              </div>
              <div
                className={classes.role}
                onClick={() => this.onRoleClickHandler("provider")}
              >
                <span className={classes.role_name}>Провайдер</span>
                <div
                  className={classes.icon}
                  style={{
                    maskImage: `url("/profile/images/provider-role.svg")`,
                    WebkitMaskImage: `url("/profile/images/provider-role.svg")`,
                  }}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.profile.role,
    isProfileCreated: state.profile.isProfileCreated,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearErrors: () => dispatch(clearErrors()),
    getAccountInfo: () => dispatch(getAccountInfo()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateProfile));
