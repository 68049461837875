import {
  GET_SUPPORTSLIST_ERROR,
  GET_SUPPORTSLIST_SUCCESS,
  CREATE_SUPPORT_ERROR,
  CREATE_SUPPORT_SUCCESS,
  CLEAR_CREATE_SUPPORT_STATUS,
  EDIT_SUPPORT_SUCCESS,
  EDIT_SUPPORT_ERROR,
  GET_SUPPORTBYID_SUCCESS,
  GET_SUPPORTBYID_ERROR,
  LOAD_PICTURE_SUCCESS,
  LOAD_PICTURE_ERROR,
  REMOVE_PICTURE,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_ERROR,
  CHANGE_DELETED_STATUS_SUCCESS,
  CHANGE_DELETED_STATUS_ERROR,
  CLEAR_DELETE_SUPPORT_STATUS,
} from "./actionTypes";

const initialState = {
  supportsList: null,
  supportsTotal: 0,
  editedSupport: null,
  createSupportStatus: "",
  editSupportStatus: "",
  deleteSupportStatus: "",
  userpic_url: null,
};

export default function articlesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUPPORTSLIST_SUCCESS:
      return {
        ...state,
        supportsList: action.data.items,
        supportsTotal: action.data.total,
        createSupportStatus: "",
        userpic_url: null,
      };
    case GET_SUPPORTSLIST_ERROR:
      return {
        ...state,
        userpic_url: null,
      };
    case CREATE_SUPPORT_SUCCESS:
      return {
        ...state,
        userpic_url: null,
        createSupportStatus: action.createSupportStatus,
      };
    case CREATE_SUPPORT_ERROR:
      return {
        ...state,
        createSupportStatus: action.createSupportStatus,
      };
    case CLEAR_CREATE_SUPPORT_STATUS:
      return {
        ...state,
        createSupportStatus: "",
      };
    case EDIT_SUPPORT_SUCCESS:
      return {
        ...state,
        editSupportStatus: action.editSupportStatus,
      };
    case EDIT_SUPPORT_ERROR:
      return {
        ...state,
        editSupportStatus: action.editSupportStatus,
      };
    case GET_SUPPORTBYID_SUCCESS:
      return {
        ...state,
        editedSupport: action.editedSupport,
        userpic_url: action.editedSupport.userpic_url,
      };
    case GET_SUPPORTBYID_ERROR:
      return {
        ...state,
        editedSupport: null,
      };
    case LOAD_PICTURE_SUCCESS:
      return {
        ...state,
        userpic_url: action.url,
      };
    case LOAD_PICTURE_ERROR:
      return {
        ...state,
        userpic_url: null,
      };
    case REMOVE_PICTURE:
      return {
        ...state,
        userpic_url: null,
      };
    case CHANGE_STATUS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_STATUS_ERROR:
      return {
        ...state,
      };
    case CHANGE_DELETED_STATUS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_DELETED_STATUS_ERROR:
      return {
        ...state,
        deleteSupportStatus: action.error,
      };
    case CLEAR_DELETE_SUPPORT_STATUS:
      return {
        ...state,
        deleteSupportStatus: "",
      };
    default:
      return state;
  }
}
