import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";

import Button from "components/UI/Button/Button";
import Input from "components/UI/Input/Input";
import FileItem from "components/UI/FileControl/FileItem/FileItem";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";

import classes from "./Chat.module.scss";
import { converDateForJournal } from "containers/Profile/Journal/functions";

export default class Chat extends Component {
  state = {
    input: {
      fileLoading: false,
      placeholder: "Ваше сообщение",
      value: "",
      type: "text",
      errorMessage: "Обязательное поле",
      label: "Ваше сообщение",
      valid: true,
      touched: false,
      shouldValidate: true,
      validation: {
        required: false,
      },
    },
    files: [],
    socketState: "",

    messagesHistoryNextPage: 0,
    messagesHistoryRemain: false,
    messagesHistorySize: 0,
    messages: [],
  };

  baseWSS = axios.defaults.baseURL.replace("https://", "");
  socket = this.props.disabled
    ? null
    : new WebSocket(
        `wss://${this.baseWSS}/chats/ws/${this.props.chatInfo.id}?user_id=${this.props.chatInfo.patient_id}`
      );

  messagesEndRef = React.createRef();

  componentDidMount() {
    // получение истории сообщений
    this.getMessageHistory(true);
    // инициализация методов чата
    if (!this.props.disabled) {
      this.socket.onopen = () => {
        this.setState({ socketState: "OPENED" });
      };
      this.socket.onmessage = (event) => {
        let messages = [...this.state.messages];
        let data = JSON.parse(event.data);
        messages.push(data);
        this.setState({ messages: messages });
        this.scrollToBottom();
      };
      this.socket.onclose = () => {
        this.setState({ socketState: "CLOSED" });
      };
      this.socket.onerror = (err) => {
        console.log("Socket error:", err);
      };
    }
  }

  onChangeInputHandler = (event) => {
    event.preventDefault();

    let control = { ...this.state.input };
    control.value = event.target.value;
    control.touched = true;

    this.setState({
      input: control,
    });
  };
  scrollToBottom = () => {
    const lastMesNode = ReactDOM.findDOMNode(this.messagesEndRef.current);
    const chatNode = document.getElementById("messages");
    if (lastMesNode) {
      chatNode.scrollTo(0, lastMesNode.offsetTop);
    }
    if (this.state.messages.length > 0) {
      this.markChatMessageRead(
        this.props.chatInfo.id,
        this.state.messages[this.state.messages.length - 1].id
      );
    }
  };
  sendMessage = (event) => {
    event.preventDefault();
    if (this.socket) {
      let data = JSON.stringify({
        sender_id: localStorage.getItem("id"),
        token: localStorage.getItem("access_token"),
        text: this.state.input.value,
        attachments: this.state.files,
      });
      try {
        this.socket.send(data);
      } catch (error) {
        console.log(error);
      }

      let newState = { ...this.state };
      newState.input.value = "";
      newState.files = [];
      this.setState({ ...newState });
    }
  };
  addFileHandler = (event) => {
    let newState = { ...this.state };
    newState.fileLoading = true;
    this.setState({ ...newState });
    return new Promise((resolve, reject) => {
      var bodyFormData = new FormData();
      bodyFormData.append("file", event.target.files[0]);
      axios({
        method: "post",
        url: "/chats/upload_attachment",
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          newState.fileLoading = false;
          newState.files.push(response.data.url);
          this.setState({ ...newState });
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  removeFileHandler = (event, index) => {
    let newState = { ...this.state };
    newState.files.splice(index, 1);
    this.setState({ ...newState });
  };

  getMessageHistory = (needScroll) => {
    let newState = { ...this.state };
    return new Promise((resolve, reject) => {
      let page = this.state.messagesHistoryNextPage;
      axios({
        method: "get",
        url: `/chats/${this.props.chatInfo.id}/messages?page=${page}`,
        data: page,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          let newMessagesArray = [];
          // сначала пушим старые сообщения, которые только пришли
          response.data.items.reverse().map((msg) => {
            newMessagesArray.push(msg);
          });
          // потом пушим то, что лежало в стэйте
          newState.messages.map((item) => {
            newMessagesArray.push(item);
          });
          newState.messages = newMessagesArray;
          newState.messagesHistoryNextPage = response.data.page + 1;
          newState.messagesHistorySize = response.data.size;
          newState.messagesHistoryRemain =
            response.data.items.length < response.data.size ? false : true;
          this.setState({ ...newState });
          resolve(response);
          if (needScroll) this.scrollToBottom();
        })
        .catch((err) => {
          // reject(err);
          console.log(err);
        });
    });
  };
  loadPrevMessagesHandler = (e) => {
    e.preventDefault();
    this.getMessageHistory(false);
  };

  markChatMessageRead = async (chatId, messageId) => {
    await axios
      .post(
        `/chats/${chatId}/mark_read/${messageId}`,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then()
      .catch((e) => console.log(e));
  };
  render() {
    return (
      <section className={classes.Chat}>
        <div className={classes.header}>
          <div className={classes.header__title}>чат по заявке</div>
        </div>

        <div className={classes.messages} id={"messages"}>
          {/* кнопка дозагрузки сообщений */}
          {this.state.messagesHistoryRemain ? (
            <button
              className={classes.loadPrevMessages}
              onClick={(e) => this.loadPrevMessagesHandler(e)}
            >
              Загрузить предыдущие
            </button>
          ) : null}
          {/* вывод сообщений */}
          {this.state.messages.length > 0
            ? this.state.messages.map((msg, index) => {
                return (
                  <div className={classes.message} key={index}>
                    {/* обертка имени для создания стенки слева */}
                    <div className={classes.message__column}>
                      {/* роль */}
                      <div className={classes.message__role}>
                        {+msg.sender_id === +this.props.chatInfo.assistant_id
                          ? "Ассистент"
                          : +msg.sender_id === +this.props.chatInfo.patient_id
                          ? "Пациент"
                          : +msg.sender_id === +this.props.chatInfo.performer_id
                          ? this.props.chatInfo.performer.doctor_profile
                              .specialization.title
                          : null}
                      </div>
                      {/* имя  + время*/}
                      <div className={classes.message__row}>
                        <div className={classes.message__name}>
                          {+msg.sender_id === +this.props.chatInfo.assistant_id
                            ? this.props.chatInfo.assistant.profile
                                .second_name +
                              " " +
                              this.props.chatInfo.assistant.profile.first_name +
                              " " +
                              this.props.chatInfo.assistant.profile
                                .patronymic_name
                            : +msg.sender_id === +this.props.chatInfo.patient_id
                            ? this.props.chatInfo.patient.profile.second_name +
                              " " +
                              this.props.chatInfo.patient.profile.first_name +
                              " " +
                              this.props.chatInfo.patient.profile
                                .patronymic_name
                            : +msg.sender_id ===
                              +this.props.chatInfo.performer_id
                            ? this.props.chatInfo.performer.profile
                                .second_name +
                              " " +
                              this.props.chatInfo.performer.profile.first_name +
                              " " +
                              this.props.chatInfo.performer.profile
                                .patronymic_name
                            : null}
                        </div>
                        <div className={classes.message__time}>
                          {/* dd.mm.yy hh:mm */}
                          {converDateForJournal(new Date(msg.created_at))}
                        </div>
                      </div>
                    </div>
                    {/* текст сообщения */}
                    <div className={classes.message__text}>{msg.text}</div>
                    {/* файлы */}
                    {msg.attachments.length > 0 ? (
                      <div className={classes.message__files}>
                        {msg.attachments.map((file, index) => {
                          return (
                            <FileItem
                              index={index}
                              key={index}
                              file={file}
                              download={true}
                            />
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                );
              })
            : null}
          {/* пустышка для скролла вниз к этому элементу */}
          <div className={classes.ref} ref={this.messagesEndRef} id="ref"></div>
        </div>
        {/* отправка сообщения */}
        {this.props.disabled ? null : (
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (event.keyCode === 13) {
                this.sendMessage(event);
              }
            }}
            className={classes.bottom}
          >
            <div className={classes.row}>
              <div className={classes.bottom__fileInput}>
                <label
                  className={[
                    classes.button,
                    this.state.files.length >= 6 ? classes.disabled : null,
                  ].join(" ")}
                >
                  <input
                    className={classes.input}
                    disabled={this.state.files.length >= 6}
                    value=""
                    type={"file"}
                    onChange={(event) => this.addFileHandler(event)}
                  />
                  <div className={classes.icon}></div>
                </label>
              </div>
              <div className={classes.bottom__textInput}>
                <Input
                  id="chatInput"
                  className={classes.Input}
                  value={this.state.input.value}
                  placeholder={this.state.input.placeholder}
                  valid={this.state.input.valid}
                  touched={this.state.input.touched}
                  label={this.state.input.label}
                  shouldValidate={this.state.input.shouldValidate}
                  errorMessage={this.state.input.errorMessage}
                  onChange={(e) => this.onChangeInputHandler(e)}
                ></Input>
              </div>
              <div className={classes.bottom__sendBtn}>
                <Button
                  disabled={
                    this.state.input.value === "" &&
                    this.state.files.length <= 0
                  }
                  type={"none"}
                  onClick={(event) => this.sendMessage(event)}
                >
                  <img src={"/profile/images/send.svg"} alt="send"></img>
                </Button>
              </div>
            </div>
            <div className={classes.bottom__attachedFiles}>
              {this.state.files.map((file, index) => {
                return (
                  <FileItem
                    index={index}
                    key={index}
                    file={file}
                    remove={(event, index) =>
                      this.removeFileHandler(event, index)
                    }
                    download={false}
                  />
                );
              })}
              {this.state.fileLoading ? <FileLoader /> : null}
            </div>
          </form>
        )}
      </section>
    );
  }
}
