export const typeEnum = {
  diagnostics: "Диагностика",
  treatment: "Лечение",
  rehabilitation: "Медицинская реабилитация",
  skl: "СКЛ(санаторно - курортное лечение)",
  support: "Долгосрочное сопровождение",
  consultation: "Консультация",
};


export const statusEnum = {
  created: "Создана",
  moderation: "На модерации",
  accepted: "Принята",
  in_work: "В работе",
  paused: "Пауза",
  waiting_for_payment: "Ожидает оплаты",
  finished: "Завершена",
  declined: "Отклонена",
};

export function getFormatDate(date) {
  const formatDate =
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const formatMonth =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const formatYear = date.getFullYear();

  return `${formatDate}.${formatMonth}.${formatYear}`;
}

export function getTimeFromDate(date) {
  var hours = date.getHours() % 100;
    if (hours < 10) hours = '0' + hours;

    var minutes = date.getMinutes() % 100;
    if (minutes < 10) minutes = '0' + minutes;

  return `${hours}:${minutes}`;
}

