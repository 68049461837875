import axios from "axios";

import {
  GET_ARTICLESLIST_ERROR,
  GET_ARTICLESLIST_SUCCESS,
  CREATE_ARTICLE_ERROR,
  CREATE_ARTICLE_SUCCESS,
  CREATE_ARTICLE_PENDING,
  EDIT_ARTICLE_SUCCESS,
  EDIT_ARTICLE_ERROR,
  GET_ARTICLEBYID_SUCCESS,
  GET_ARTICLEBYID_ERROR,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_ERROR,
  LOAD_PICTURE_SUCCESS,
  LOAD_PICTURE_ERROR,
  REMOVE_PICTURE,
} from "./actionTypes";

export function getArticlesList(page = null, size = null) {
  return async (dispatch) => {
    axios
      .get('/articles/' + (page || size ? '?' : '') + (page ? "page=" + page : "") + (page && size ? '&' : '') + (size ? "size=" + size : ""))
      .then((response) => {
        dispatch(getArticlesListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getArticlesListError());
        console.log(err);
      });
  };
}

export function createArticle(article_title, article_desc, article_image) {
  return async (dispatch) => {
    let data = {
      title: article_title,
      body: article_desc,
      image_url: article_image,
    };
    axios
      .post('/articles/', data)
      .then(() => {
        dispatch(createArticleSuccess());
      })
      .catch((err) => {
        dispatch(createArticleError());
        console.log(err);
      });
  };
}
export function getArticleByID(id) {
  return async (dispatch) => {
    axios
      .get(`/articles/${id}`)
      .then((response) => {
        dispatch(getArticleByIDSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getArticleByIDError());
        console.log(err);
      });
  };
}
export function editArticle(id, article_title, article_desc, article_image) {
  return async (dispatch) => {
    let data = {
      title: article_title,
      body: article_desc,
      image_url: article_image
    };
    axios
      .patch(`/articles/${id}`, data)
      .then(() => {
        dispatch(editArticleSuccess());
      })
      .catch((err) => {
        dispatch(editArticleError());
        console.log(err);
      });
  };
}
export function deleteArticle(id) {
  return async (dispatch) => {
    axios
      .delete(`/articles/${id}`)
      .then(() => {
        dispatch(deleteArticleSuccess());
      })
      .catch((err) => {
        dispatch(deleteArticleError());
        console.log(err);
      });
  };
}

export function loadPicture(file) {
  return async (dispatch) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", file);

    axios({
      method: "post",
      url: `/articles/upload_image`,
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
      .then(function (response) {
        dispatch(loadPictureSuccess(response.data.url));
      })
      .catch(function (error) {
        dispatch(loadPictureError());
        console.log(error);
      });
  };
}

export function removePicture() {
  return {
    type: REMOVE_PICTURE,
  };
}
export function loadPictureSuccess(url) {
  return {
    type: LOAD_PICTURE_SUCCESS,
    url,
  };
}
export function loadPictureError() {
  return {
    type: LOAD_PICTURE_ERROR,
  };
}
export function getArticlesListSuccess(data) {
  return {
    type: GET_ARTICLESLIST_SUCCESS,
    data: data,
  };
}

export function getArticlesListError() {
  return {
    type: GET_ARTICLESLIST_ERROR,
  };
}
export function createArticlePending() {
  return {
    type: CREATE_ARTICLE_PENDING,
    createArticleStatus: "PENDING",
  };
}
export function createArticleSuccess() {
  return {
    type: CREATE_ARTICLE_SUCCESS,
    createArticleStatus: "SUCCESS",
  };
}

export function createArticleError() {
  return {
    type: CREATE_ARTICLE_ERROR,
    createArticleStatus: "ERROR",
  };
}
export function editArticleSuccess() {
  return {
    type: EDIT_ARTICLE_SUCCESS,
    editArticleStatus: "SUCCESS",
  };
}

export function editArticleError() {
  return {
    type: EDIT_ARTICLE_ERROR,
    editArticleStatus: "ERROR",
  };
}
export function getArticleByIDSuccess(editedArticle) {
  return {
    type: GET_ARTICLEBYID_SUCCESS,
    editedArticle: editedArticle,
  };
}

export function getArticleByIDError() {
  return {
    type: GET_ARTICLEBYID_ERROR,
  };
}
export function deleteArticleSuccess() {
  return {
    type: DELETE_ARTICLE_SUCCESS,
  };
}

export function deleteArticleError() {
  return {
    type: DELETE_ARTICLE_ERROR,
  };
}
