import axios from "axios";

import {
  GET_FAQLIST_ERROR,
  GET_FAQLIST_SUCCESS,
  CREATE_FAQ_ERROR,
  CREATE_FAQ_SUCCESS,
  CREATE_FAQ_PENDING,
  EDIT_FAQ_SUCCESS,
  EDIT_FAQ_ERROR,
  GET_FAQBYID_SUCCESS,
  GET_FAQBYID_ERROR,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_ERROR,
} from "./actionTypes";

export function getFaqList(page = null, size = null) {
  return async (dispatch) => {
    axios
      .get(`/faq/` + (page || size ? '?' : '') + (page ? "page=" + page : "") + (page && size ? '&' : '') + (size ? "size=" + size : ""))
      .then((response) => {
        dispatch(getFaqListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getFaqListError());
        console.log(err);
      });
  };
}

export function createFaq(faq_question, faq_answer) {
  return async (dispatch) => {
    let data = {
      question: faq_question,
      answer: faq_answer,
    };
    axios
      .post('/faq/', data)
      .then(() => {
        dispatch(createFaqSuccess());
      })
      .catch((err) => {
        dispatch(createFaqError());
        console.log(err);
      });
  };
}
export function getFaqByID(id) {
  return async (dispatch) => {
    axios
      .get(`/faq/${id}`)
      .then((response) => {
        dispatch(getFaqByIDSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getFaqByIDError());
        console.log(err);
      });
  };
}
export function deleteFaq(id) {
  return async (dispatch) => {
    axios
      .delete(`/faq/${id}`)
      .then(() => {
        dispatch(deleteFaqSuccess());
      })
      .catch((err) => {
        dispatch(deleteFaqError());
        console.log(err);
      });
  };
}
export function editFaq(id, faq_question, faq_answer) {
  return async (dispatch) => {
    let data = {
      question: faq_question,
      answer: faq_answer,
    };
    axios
      .patch(`/faq/${id}`, data)
      .then(() => {
        dispatch(editFaqSuccess());
      })
      .catch((err) => {
        dispatch(editFaqError());
        console.log(err);
      });
  };
}
export function getFaqListSuccess(data) {
  return {
    type: GET_FAQLIST_SUCCESS,
    data: data,
  };
}

export function getFaqListError() {
  return {
    type: GET_FAQLIST_ERROR,
  };
}
export function createFaqPending() {
  return {
    type: CREATE_FAQ_PENDING,
    createFaqStatus: "PENDING",
  };
}
export function createFaqSuccess() {
  return {
    type: CREATE_FAQ_SUCCESS,
    createFaqStatus: "SUCCESS",
  };
}

export function createFaqError() {
  return {
    type: CREATE_FAQ_ERROR,
    createFaqStatus: "ERROR",
  };
}
export function editFaqSuccess() {
  return {
    type: EDIT_FAQ_SUCCESS,
    editFaqStatus: "SUCCESS",
  };
}

export function editFaqError() {
  return {
    type: EDIT_FAQ_ERROR,
    editFaqStatus: "ERROR",
  };
}
export function getFaqByIDSuccess(editedFaq) {
  return {
    type: GET_FAQBYID_SUCCESS,
    editedFaq: editedFaq,
  };
}

export function getFaqByIDError() {
  return {
    type: GET_FAQBYID_ERROR,
  };
}
export function deleteFaqSuccess() {
  return {
    type: DELETE_FAQ_SUCCESS,
  };
}

export function deleteFaqError() {
  return {
    type: DELETE_FAQ_ERROR,
  };
}
