import React from "react";
import { connect } from "react-redux";

import { closeModal } from "store/actions/profile";
import classes from "./TipModal.module.scss";

const TipModal = (props) => {
  return (
    <div className={classes.TipModal}>
      Пожалуйста, Укажите недостающие данные и повторите попытку
      <img
        alt="close"
        src="/profile/images/cross.svg"
        onClick={() => props.closeModal()}
      />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    closeModal: () => dispatch(closeModal()),
  };
}

export default connect(null, mapDispatchToProps)(TipModal);
