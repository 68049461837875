import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Navigation from "components/UI/Navigation/Navigation";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";
import EmptySection from "components/Profile/EmptySection/EmptySection";
import ConsultationsLogTable from "./ConsultationsLogTable/ConsultationsLogTable";
import CustomDatePicker from "components/UI/CustomDatePicker/CustomDatePicker";
import { SelectAsync } from "components/UI/SelectAsync/SelectAsync";

import {
  getConsultations,
  getLogFile,
} from "store/Profile/ConsultationsLog/actions";

import classes from "./ConsultationsLog.module.scss";

const CARDS_IN_ONE_PAGE = 50;

class ConsultationsLog extends Component {
  state = {
    loading: false,
    activePage: 1,
    currentType: "all",
    startDate: null,
    endDate: null,
    selectedStartDate: null,
    selectedEndDate: null,
    currentPerformer: { value: "all", label: "Все" },
    currentCreator: { value: "all", label: "Все" },
  };

  convertDate(date) {
    const dateArr = date.toLocaleString("ru").substring(0, 10).split(".");
    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
  }

  getDates = () => {
    return [
      this.state.selectedStartDate
        ? this.convertDate(this.state.selectedStartDate)
        : null,
      this.state.selectedEndDate
        ? this.convertDate(this.state.selectedEndDate)
        : null,
    ];
  };

  componentDidMount() {
    this.props.getConsultations(
      this.state.activePage - 1,
      CARDS_IN_ONE_PAGE,
      this.state.selectedStartDate,
      this.state.selectedEndDate,
      this.state.currentPerformer.value,
      this.state.currentCreator.value
    );
    this.props.getLogFile(
      this.state.selectedStartDate,
      this.state.selectedEndDate,
      this.state.currentPerformer.value,
      this.state.currentCreator.value
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activePage !== this.state.activePage) {
      const [date1, date2] = this.getDates();
      this.props.getConsultations(
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        date1,
        date2,
        this.state.currentPerformer.value,
        this.state.currentCreator.value
      );
    }
    if (
      this.state.selectedStartDate !== prevState.selectedStartDate ||
      this.state.selectedEndDate !== prevState.selectedEndDate
    ) {
      const [date1, date2] = this.getDates();
      this.props.getConsultations(
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        date1,
        date2,
        this.state.currentPerformer.value,
        this.state.currentCreator.value
      );
      this.props.getLogFile(
        date1,
        date2,
        this.state.currentPerformer.value,
        this.state.currentCreator.value
      );
      this.setState({ loading: true });
    }

    if (
      prevState.currentPerformer.value !== this.state.currentPerformer.value
    ) {
      const [date1, date2] = this.getDates();
      this.props.getConsultations(
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        date1,
        date2,
        this.state.currentPerformer.value,
        this.state.currentCreator.value
      );
      this.props.getLogFile(
        date1,
        date2,
        this.state.currentPerformer.value,
        this.state.currentCreator.value
      );
      this.setState({ loading: true });
    }

    if (prevState.currentCreator.value !== this.state.currentCreator.value) {
      const [date1, date2] = this.getDates();
      this.props.getConsultations(
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        date1,
        date2,
        this.state.currentPerformer.value,
        this.state.currentCreator.value
      );
      this.props.getLogFile(
        date1,
        date2,
        this.state.currentPerformer.value,
        this.state.currentCreator.value
      );
      this.setState({ loading: true });
    }

    if (
      prevProps.consultationsList !== this.props.consultationsList &&
      this.state.loading
    ) {
      this.setState({ loading: false });
    }
  }

  changePageHandler = (pages, jumpTo) => {
    let activePage = this.state.activePage;

    if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
      return;
    } else {
      activePage = jumpTo;
    }
    this.setState({ activePage });
    window.scrollTo(0, 0);
  };

  changePerformerHandler = ({ value, label }) => {
    this.setState({
      currentPerformer: { value, label },
      activePage: 1,
    });
  };

  changeCreatorHandler = ({ value, label }) => {
    this.setState({
      currentCreator: { value, label },
      activePage: 1,
    });
  };

  changeDateHandler = (dates) => {
    const [start, end] = dates;
    this.setState({
      startDate: start,
      endDate: end,
    });
  };

  dateRangeSelectHandler = () => {
    if (this.state.endDate === null) {
      this.setState({
        endDate: this.state.startDate,
        selectedStartDate: this.state.startDate,
        selectedEndDate: this.state.startDate,
      });
    } else {
      this.setState({
        selectedStartDate: this.state.startDate,
        selectedEndDate: this.state.endDate,
      });
    }
  };

  dateRangeResetHandler = () => {
    this.setState({
      startDate: null,
      endDate: null,
      selectedStartDate: null,
      selectedEndDate: null,
    });
  };

  render() {
    let pagesAmount = null;

    if (this.props.consultationsList) {
      pagesAmount =
        Math.trunc(this.props.total / CARDS_IN_ONE_PAGE) +
        (this.props.total % CARDS_IN_ONE_PAGE ? 1 : 0);
    }

    return (
      <section className={classes.ConsultationsLog}>
        <div className={classes.header}>
          <div className={classes.select_creator}>
            <span>Пациент:</span>
            <div className={classes.select_wrapper}>
              <SelectAsync
                value={this.state.currentCreator}
                onChange={this.changeCreatorHandler}
                role={this.props.role}
                url={"/users/creators"}
                noOptionsMessage="Нет пациентов"
              />
            </div>
          </div>
          <div className={classes.datepicker_wrapper}>
            <CustomDatePicker
              selected={this.state.startDate}
              onChange={this.changeDateHandler}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              selectsRange={true}
              maxDate={new Date()}
              onOkClick={this.dateRangeSelectHandler}
              onCancelClick={this.dateRangeResetHandler}
            />
          </div>
          <div className={classes.select_performer}>
            <span>Исполнитель:</span>
            <div className={classes.select_wrapper}>
              <SelectAsync
                value={this.state.currentPerformer}
                onChange={this.changePerformerHandler}
                role={this.props.role}
                url={"/users/performers"}
                noOptionsMessage="Нет исполнителей"
              />
            </div>
          </div>
          <div className={classes.status_loader}>
            {this.state.loading ? (
              <FileLoader style={{ height: "100%", maxWidth: "100%" }} />
            ) : null}
          </div>
        </div>

        {this.props.consultationsList ? (
          this.props.consultationsList.length !== 0 ? (
            <React.Fragment>
              <div
                className={`${classes.list} ${
                  this.props.sidebarCollapsed ? classes.sidebar_collapsed : ""
                }`}
              >
                <ConsultationsLogTable
                  consultationsList={this.props.consultationsList}
                />
              </div>
              {pagesAmount > 1 ? (
                <Navigation
                  activePage={this.state.activePage}
                  pages={pagesAmount}
                  onChange={this.changePageHandler}
                />
              ) : null}
            </React.Fragment>
          ) : (
            <EmptySection
              title={"Услуги не найдены"}
              text={"Ни одной услуги с выбранными параметрами не найдено"}
            />
          )
        ) : (
          <div className={classes.loader}>
            <FileLoader style={{ maxWidth: "100%" }} />
          </div>
        )}
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    consultationsList: state.consultationsLog.consultations,
    total: state.consultationsLog.consultationsTotal,
    sidebarCollapsed: state.profile.isSidebarCollapsed,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getConsultations: (
      page,
      size,
      startDate,
      endDate,
      performer_id,
      creator_id,
      q
    ) =>
      dispatch(
        getConsultations(
          page,
          size,
          startDate,
          endDate,
          performer_id,
          creator_id,
          q
        )
      ),
    getLogFile: (startDate, endDate, performer_id, creator_id) =>
      dispatch(getLogFile(startDate, endDate, performer_id, creator_id)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsultationsLog);
