
export const GET_FAQLIST_SUCCESS = 'GET_FAQLIST_SUCCESS';
export const GET_FAQLIST_ERROR = 'GET_FAQLIST_ERROR';
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS';
export const CREATE_FAQ_ERROR = 'CREATE_FAQ_ERROR';
export const CREATE_FAQ_PENDING = 'CREATE_FAQ_PENDING';
export const EDIT_FAQ_SUCCESS = 'EDIT_FAQ_SUCCESS';
export const EDIT_FAQ_ERROR = 'EDIT_FAQ_ERROR';
export const GET_FAQBYID_SUCCESS = 'GET_FAQBYID_SUCCESS';
export const GET_FAQBYID_ERROR = 'GET_FAQBYID_ERROR';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_ERROR = 'DELETE_FAQ_ERROR';