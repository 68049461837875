import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import TestItem from "./TestItem/TestItem";

import classes from "./Health.module.scss";
import { getResults } from "store/Profile/Health/actions";
import ResultItem from "./ResultItem/ResultItem";
import { getFormatDate } from "components/Profile/CardList/enums";

export const tests = {
  test1: {
    name: "Тест № 1",
    type: "ОФН-15",
    description: "Краткий опросник функциональных нарушений",
    questionsAmount: 16,
    href: "/profile/test1",
  },
  test2: {
    name: "Тест № 2",
    type: "ОФН-75",
    description: "Полный опросник функциональных нарушений",
    questionsAmount: 75,
    href: "/profile/test2",
  },
  test3: {
    name: "Тест № 3",
    type: "ОФОЖ",
    description: "Опросник факторов образа жизни",
    questionsAmount: 200,
    href: "/profile/test3",
  },
};

class Health extends Component {
  componentDidMount() {
    this.props.getResults();
  }

  render() {
    return (
      <section className={classes.Health}>
        <Helmet>
          <title>Мое здоровье. Портал здоровья</title>
          <meta name="title" content="Мое здоровье. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Мое здоровье. Портал здоровья" />
        </Helmet>
        <div className={classes.tests}>
          <div className={classes.title}>
            Пройдите тест и узнайте о своем здоровье
          </div>
          <div className={classes.test_list}>
            {Object.keys(tests).map((testName) => {
              return <TestItem test={tests[testName]} />;
            })}
          </div>
        </div>
        {this.props.results ? (
          <div className={classes.results}>
            <div className={classes.title}>
              Результаты предыдущих исследований
            </div>
            <div className={classes.results_list}>
              {this.props.results.map((result) => {
                return (
                  <ResultItem
                    type={tests[result.test_type].type}
                    date={getFormatDate(new Date(result.created_at))}
                    href={tests[result.test_type].href}
                    description={tests[result.test_type].description}
                    file={result.result_url}
                    color={result.ofn15_code}
                    score={result.ofn15_score}
                  />
                );
              })}
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    results: state.health.results,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getResults: () => dispatch(getResults()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Health);
