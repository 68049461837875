import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Button from "components/UI/Button/Button";
import BackLink from "components/UI/BackLink/BackLink";
import Input from "components/UI/Input/Input";
import { validateControl } from "containers/Auth/Auth";

import classes from "./NewInvite.module.scss";
import { createInvite } from "store/Profile/Invites/actions";

class NewInvite extends Component {
  state = {
    invite: {
      email: {
        value: "",
        type: "email",
        errorMessage: "Введите корректный адрес электронной почты",
        placeholder: "Введите почту",
        label: "Почта",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: { required: true, email: true },
      },
    },
  };

  inputChangeHandler = (event, controlName) => {
    // let formControl = { ...this.state };
    // formControl[targetName] = event.target.value;
    // this.setState({ ...formControl });

    const formControls = { ...this.state.invite };
    const control = { ...formControls[controlName] };

    if (control.type === "address") {
      control.value = event;
      // для инпутов с типом адрес значение хранится в event
    } else {
      control.value = event.target.value;
    }
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);
    formControls[controlName] = control;
    this.setState({
      invite: formControls,
    });

  };

  sendFormHandler = () => {
    this.props.createInvite(this.state.invite.email.value);
    if (this.props.createInviteStatus === "SUCCESS") {
      const newState = { ...this.state };
      newState.invite.email.value = "";
      this.setState({ ...newState });
      this.props.history.push({ pathname: "/profile/invites" });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.createInviteStatus !== this.props.createInviteStatus) {
      if (this.props.createInviteStatus === "SUCCESS") {
        const newState = { ...this.state };
        newState.invite.email.value = "";
        this.setState({ ...newState });
        this.props.history.push({ pathname: "/profile/invites" });
      } else if (this.props.createInviteStatus === "ERROR") {
        //
      }
    }
  }

  render() {
    return (
      <section className={classes.NewInvite}>
        <Helmet>
          <title>Новое приглашение. Портал здоровья</title>
          <meta name="title" content="Новое приглашение. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Новое приглашение. Портал здоровья" />
        </Helmet>
        <div className={classes.back}>
          <BackLink to={"/profile/invites"} />
          <span>Назад</span>
        </div>

        <form
          className={classes.form}
          onSubmit={(event) => event.preventDefault()}
        >
          <h4 className={classes.title}>Отправка приглашения</h4>
          <Input
            value={this.state.invite.email.value}
            type={this.state.invite.email.type}
            placeholder={this.state.invite.email.placeholder}
            valid={this.state.invite.email.valid}
            touched={this.state.invite.email.touched}
            label={this.state.invite.email.label}
            shouldValidate={this.state.invite.email.shouldValidate}
            errorMessage={this.state.invite.email.errorMessage}
            onChange={(event) => this.inputChangeHandler(event, "email")}
          ></Input>
          <div className={classes.btn_submit}>
            <Button
              disabled={!this.state.invite.email.valid}
              onClick={() => this.sendFormHandler()}
              type={"primary"}
              needTip={true}
            >
              Отправить приглашение
            </Button>
          </div>

          {/* сообщение об ошибке */}
          {this.props.createInviteStatus !== "" ? (
            <div className={classes.dataFetchStatus}>
              {this.props.createInviteStatus === "ERROR" ? (
                <div className={classes.error_status}>
                  Не удалось отправить приглашение: пользователь уже зарегистрирован или вы ранее уже отправляли приглашение на эту почту
                </div>
              ) : (
                null
              )}
            </div>
          ) : null}
        </form>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    createInviteStatus: state.invites.createInviteStatus,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    createInvite: (email) =>
      dispatch(createInvite(email)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewInvite);
