import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";

import Button from "components/UI/Button/Button";
import getLinks from "./links";
import classes from "./Sidebar.module.scss";
import headerClasses from "../Header/Header.module.scss";
import "./active.scss";
import { collapseSidebar } from "store/actions/profile";

function isPhone() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else return false;
}

class Sidebar extends Component {
  state = {
    isCollapsed: false,
  };

  clickHandler = () => {
    document.getElementById("body").classList.remove("no-scroll");
    document.getElementById("sidebar").classList.remove("active-sidebar");
    document
      .getElementById("profile-header")
      .classList.remove(headerClasses.active);
  };

  renderLinks() {
    let links = getLinks(this.props.role);
    if (!links) return;

    if (this.props.isConsultationCardOpened && this.props.activeCard) {
      links = links.map((link) => {
        if (link.label === "Консультации") {
          link.to =
            "/profile/consultations/consultation/" + this.props.activeCard.id;
        }
        return link;
      });
    }

    return links.map((link, index) => {
      return (
        <li
          key={index}
          className={classes.nav_item}
          onClick={() => this.clickHandler()}
        >
          <NavLink
            to={link.to}
            exact={link.exact}
            activeClassName={classes.active}
          >
            <div
              className={classes.icon}
              style={{
                maskImage: `url(${link.iconUrl})`,
                WebkitMaskImage: `url(${link.iconUrl})`,
              }}
            ></div>
            {link.label}
          </NavLink>
        </li>
      );
    });
  }

  render() {
    const cls = [classes.Sidebar];

    if (this.props.isCollapsed) {
      cls.push(classes.collapsed);
    }

    return (
      <div className={cls.join(" ")} id="sidebar">
        <nav>
          <ul className={classes.nav_list}>{this.renderLinks()}</ul>

          {this.props.role === "patient" ? (
            <div className={classes.btn}>
              <NavLink to={"/profile/new_consultation"}>
                <Button
                  onClick={() => this.clickHandler()}
                  type={isPhone() ? "sidebar" : "primary"}
                >
                  {this.props.isCollapsed ? "+" : "Получить консультацию"}
                </Button>
              </NavLink>
            </div>
          ) : null}
          {this.props.role === "support" ? (
            <div className={classes.btn}>
              <NavLink to={"/profile/doctors/new_doctor"}>
                <Button
                  onClick={() => this.clickHandler()}
                  type={isPhone() ? "sidebar" : "primary"}
                >
                  {this.props.isCollapsed ? "+С" : "Добавить специалиста"}
                </Button>
              </NavLink>
              <NavLink to={"/profile/assistants/new_assistant"}>
                <Button
                  onClick={() => this.clickHandler()}
                  type={isPhone() ? "sidebar" : "primary"}
                >
                  {this.props.isCollapsed ? "+А" : "Добавить ассистента"}
                </Button>
              </NavLink>
              <NavLink to={"/profile/patients/new_patient"}>
                <Button
                  onClick={() => this.clickHandler()}
                  type={isPhone() ? "sidebar" : "primary"}
                >
                  {this.props.isCollapsed ? "+П" : "Добавить пациента"}
                </Button>
              </NavLink>
            </div>
          ) : null}
        </nav>
        <div
          className={classes.toggle}
          onClick={() => this.props.collapseSidebar(!this.props.isCollapsed)}
        >
          <img src={"/profile/images/toggle-sidebar.svg"} alt={"toggle"} />
          Свернуть
        </div>
        <div className={classes.logout}>
          <Link to="/profile/logout">Выйти</Link>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isConsultationCardOpened: state.consultations.isConsultationCardOpened,
    activeCard: state.consultations.activeCard,
    isCollapsed: state.profile.isSidebarCollapsed,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    collapseSidebar: (state) => dispatch(collapseSidebar(state)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
