import {
  AUTH_LOGOUT,
  LOGIN_SUCCESS,
  FIRST_REG,
  CONFIRM_SUCCESS,
  CONFIRM_ERROR,
  CONFIRM_CLEAR,
  LOGIN_ERROR,
  REG_ERROR,
  SEND_RECOVERY_ERROR,
  SEND_RECOVERY_SUCCESS,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_ERROR,
  CLEAR_RECOVERY_STATUS,
  CLEAR_NEW_PASSWORD_STATUS,
  CLEAR_REG_STATUS,
  ACCEPT_PATIENT_INVITATION_SUCCESS,
  ACCEPT_PATIENT_INVITATION_ERROR,
  ADD_REDIRECT_LINK,
} from "./actionTypes";

const initialState = {
  token: null,
  isConfirmed: null,
  loginError: null,
  signUpError: null,
  recoveryStatus: "",
  createNewPasswordStatus: "",
  regStatus: "",
  acceptPatientInvitationStatus: null,
  redirectLink: null,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        loginError: null,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loginError: action.error,
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        token: null,
      };
    case ADD_REDIRECT_LINK:
      localStorage.setItem("redirectLink", action.link);
      return {
        ...state,
        redirectLink: action.link,
      };
    case FIRST_REG:
      return {
        ...state,
        id: action.id,
        email: action.email,
        is_active: action.is_active,
        role: action.role,
        registered_at: action.registered_at,
        isConfirmed: null,
        signUpError: null,
        regStatus: "NEED_CONFIRMATION",
      };
    case REG_ERROR:
      return {
        ...state,
        signUpError: action.error,
      };
    case CONFIRM_SUCCESS:
      return {
        ...state,
        isConfirmed: true,
      };
    case CONFIRM_ERROR:
      return {
        ...state,
        isConfirmed: false,
        email: action.email,
      };
    case CONFIRM_CLEAR:
      return {
        ...state,
        isConfirmed: null,
        email: null,
      };
    case SEND_RECOVERY_ERROR:
      return {
        ...state,
        recoveryStatus: "ERROR",
      };
    case SEND_RECOVERY_SUCCESS:
      return {
        ...state,
        recoveryStatus: "SUCCESS",
      };
    case CLEAR_RECOVERY_STATUS:
      return {
        ...state,
        recoveryStatus: "",
      };
    case RECOVER_PASSWORD_SUCCESS:
      return {
        ...state,
        createNewPasswordStatus: "SUCCESS",
      };
    case RECOVER_PASSWORD_ERROR:
      return {
        ...state,
        createNewPasswordStatus: "ERROR",
      };
    case CLEAR_NEW_PASSWORD_STATUS:
      return {
        ...state,
        createNewPasswordStatus: "",
      };
    case CLEAR_REG_STATUS:
      return {
        ...state,
        regStatus: "",
      };
    case ACCEPT_PATIENT_INVITATION_SUCCESS:
      return {
        ...state,
        acceptPatientInvitationStatus: action.acceptPatientInvitationStatus,
      };
    case ACCEPT_PATIENT_INVITATION_ERROR:
      return {
        ...state,
        acceptPatientInvitationError: action.error,
        acceptPatientInvitationStatus: action.acceptPatientInvitationStatus,
      };
    default:
      return state;
  }
}
