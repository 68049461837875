import React, { Component } from "react";
import classes from "./ModalWindow.module.scss";
import Button from "components/UI/Button/Button";
class ModalWindow extends Component {
  componentDidMount() {
    if (this.props.timeout) {
      setTimeout(() => this.props.closed(), this.props.timeout);
    }
  }
  render() {
    return (
      <div className={classes.modalMask}>
        <div
          className={classes.modalWrapper}
          onClick={this.props.timeout ? this.props.closed : null}
        >
          <div className={classes.modalContainer}>
            {this.props.children}
            {/* кнопка закрытия модалки */}
            {!this.props.timeout ? (
              <div className={classes.modalClose}>
                <Button onClick={this.props.closed} type={"close"}></Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default ModalWindow;
