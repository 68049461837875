import React from "react";
import { useHistory } from "react-router-dom";

import classes from "./ServicesRow.module.scss";

const ServicesRow = (props) => {
  const history = useHistory();

  const urlBase = {
    info: {
      patient: "/profile/patients/patient/",
      doctor: "/profile/doctors/doctor_info/",
    },
    edit: {
      patient: "/profile/patients/patient/",
      doctor: "/profile/doctors/doctor_edit/",
    },
  };

  const onHyperlinkClick = (column) => {
    let id;

    if (column === "doctor") {
      id = props.performer.id;
    }

    if (column === "patient") {
      id = props.creator.id;
    }

    let openUrl;

    if (props.role === "assistant" || props.role === "provider") {
      openUrl = urlBase.edit[column] + id;
    } else {
      openUrl = urlBase.info[column] + id;
    }

    if (props.performer.role !== "assistant" || column === "patient") {
      if (props.inNewWindow === true) {
        window.open(openUrl);
      } else {
        history.push(openUrl);
      }
    }
  };

  return (
    <tr className={classes.ServicesRow}>
      {props.role !== "patient" ? (
        <td
          className={classes.hoveredColumn}
          onClick={() => onHyperlinkClick("patient")}
        >
          {props.role === "patient"
            ? `${props.account.second_name} ${props.account.first_name} ${
                props.account.patronymic_name || ""
              }`
            : null}
          {props.role === "doctor"
            ? `${props.creator.profile.second_name} ${
                props.creator.profile.first_name
              } ${props.creator.profile.patronymic_name || ""}`
            : null}
          {props.role === "assistant" || props.role === "provider"
            ? `${props.creator.profile.second_name} ${
                props.creator.profile.first_name
              } ${props.creator.profile.patronymic_name || ""}`
            : null}
        </td>
      ) : null}
      <td>{props.type}</td>
      {props.role !== "doctor" ? (
        <td
          className={
            props.performer.role !== "assistant" ? classes.hoveredColumn : ""
          }
          onClick={() => onHyperlinkClick("doctor")}
        >
          {props.role === "patient"
            ? `${props.performer.profile.second_name} ${
                props.performer.profile.first_name
              } ${props.performer.profile.patronymic_name || ""}`
            : null}
          {props.role === "doctor"
            ? `${props.account.second_name} ${props.account.first_name} ${
                props.account.patronymic_name || ""
              }`
            : null}
          {props.role === "assistant" || props.role === "provider"
            ? `${props.performer.profile.second_name} ${
                props.performer.profile.first_name
              } ${props.performer.profile.patronymic_name || ""}`
            : null}
        </td>
      ) : null}

      <td>
        {props.date}&nbsp;{props.time}
      </td>
      <td>
        {props.needs_payment
          ? (props.price ? props.price.toLocaleString() : 0) + " ₽"
          : "Бесплатно"}
      </td>
    </tr>
  );
};

export default ServicesRow;
