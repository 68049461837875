import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Footer from 'components/Footer/Footer';
import Logo from 'components/Logo/Logo';
import Button from 'components/UI/Button/Button';
import TestNavigation from './TestNavigation/TestNavigation';
import TestSection from './TestSection/TestSection';
import BackLink from 'components/UI/BackLink/BackLink';
import Back from 'components/UI/Back/Back';
import Scroller from 'hoc/CustomScrollbar/Scroller.js';


import classes from './Test.module.scss';
import { sendResults } from 'store/Profile/Tests/actions';

import test from 'tests/test2.json';

// Массив имен разделов для (навигации)
const testSections = test.testData.map(section => section.section_name);

class Test2 extends Component {
    state = {
        isStarted: false,
        isFinished: false,
        activeSection: 0,
        userAnswers: {},
    }

    startTestHandler = () => {
        this.setState({
            isStarted: true,
            activeSection: 0,
        });
    }

    finishTestHandler = () => {
        let results = {     // Пустой объект результатов для отправки
            test_type: "test2",
            data: [],
        }

        // разворачиваем объект с ответами в одномерный массив
        const answers = [].concat(...Object.values(this.state.userAnswers).map(section => Object.values(section)));

        for (let i = 0; i < answers.length; i++) {
            results.data.push({
                question_number: i,
                answer_number: answers[i].answerId,
                users_input: answers[i].usersInput === "" ? null : answers[i].usersInput, // если input пуст, отправить null
                selected_answers: null, // В тесте №2 нет вопросов с множественным выбором
                answer_text: answers[i].answer_text || null,
                question_text: answers[i].question_text || null,
            })
        };

        this.props.sendResults(results);

        this.setState({
            isFinished: true,
        });
        window.scrollTo(0, 0);
    }

    // questionNumber - номер вопроса (начинается с 1 и соответствует отображаемому номеру)
    // answerId - id ответа (начинается с 0, может не совпадать с номером ответа в вопросе)
    questionChangeHandler = (questionNumber, answerId, usersInput, checkboxValue = null) => {
        // userAnswers = { [sectionId]:{ [questionNumber]:{ [answerId]:answerId} } }
        let userAnswers = { ...this.state.userAnswers };

        let section = { ...userAnswers[this.state.activeSection] } || null;

        if (section[questionNumber]) { //если пользователь уже отвечал на этот вопрос: изменить
            let answer = { ...section[questionNumber] };

            let isEmpty = true;

            answer.question_text = test.testData[this.state.activeSection].questions[questionNumber - 1].question;

            if (usersInput !== null) {
                answer.usersInput = usersInput;
                isEmpty = usersInput === "";
            } else if (answerId !== null) {
                answer.answerId = answerId;
                answer.answer_text = test.testData[this.state.activeSection].questions[questionNumber - 1].answers[answerId];
                isEmpty = answerId === null;
            }

            if (isEmpty) { //Если ответ пуст - удалить, иначе изменить
                delete section[questionNumber];
            } else {
                section[questionNumber] = answer;
            }

        } else {  //иначе: создать
            section[questionNumber] = { 
                answerId, 
                usersInput, 
                question_text: test.testData[this.state.activeSection].questions[questionNumber - 1].question,
                answer_text: test.testData[this.state.activeSection].questions[questionNumber - 1].answers[answerId],
            }; // добавить/изменить ответ на вопрос
        }

        userAnswers[this.state.activeSection] = section;
        this.setState({ userAnswers });
    }

    changePageHandler = next => {

        if (next) { // вперед
            this.setState({
                activeSection: this.state.activeSection + 1
            })
        } else { // назад
            if (this.state.activeSection === 0) { // первая страница
                this.setState({
                    isStarted: false,
                    isFinished: false,
                    userAnswers: {},
                })
            } else if (this.state.isFinished) { //  последняя страница
                this.setState({
                    isFinished: false,
                })
            } else {  // остальные страницы
                this.setState({
                    activeSection: this.state.activeSection - 1,
                })
            }

        }
        window.scrollTo(0, 0);
    }

    renderIntro = () => {
        return (
            <div className={classes.intro}>
                <div className={classes.amount}>75 вопросов</div>
                <p>
                    Анкета включает в себя 75 вопросов, которые условно разделены на 6 функциональных систем:
                </p>
                <p>
                    «энергия-психика» (ЭП, 15 вопросов), «нервная система» (НС, 11 вопросов), «сердце-лёгкие» (СЛ, 8 вопросов), «желудочно-кишечный тракт» (ЖКТ, 13 вопросов), «опорно-двигательная система» (ОДС, 6 вопросов), «кожа-слизистые» (КС, 21 вопрос) + 1 «свободный» вопрос (проблема, беспокоящая пациента, но не отражённая в других вопросах).
                </p>
                <p>
                    Этот опросник позволит выявить у Вас функциональные нарушения и потребность в изменении образа жизни.
                </p>
                <div className={classes.start_button}>
                    <Button
                        type={"primary"}
                        onClick={() => this.startTestHandler()}
                    >Начать тестирование</Button>
                </div>
            </div>
        )
    }

    renderFinishPage = () => {
        return (
            <div className={classes.finish}>
                <p>
                    Спасибо за ваши ответы!
                </p>
                <p>
                    Результаты тестирования и направление на консультацию отобразятся в вашем <strong>личном кабинете</strong> в разделе <strong>Здоровье</strong>
                </p>
                <div className={classes.finish_button}>
                    <Link to={"/profile/health"}>
                        <Button
                            type={"primary"}
                        >Вернуться в личный кабинет</Button>
                    </Link>
                </div>
            </div>
        )
    }

    renderTest = () => {

        // Формирование блока вопросов для текущей страницы
        const testSectionList = [...test.testData[this.state.activeSection].questions];

        // Номер первого вопроса на странице
        const firstQuestionNumber = 1;

        let answersAmount = 0;
        try {
            answersAmount = Object.keys(this.state.userAnswers[this.state.activeSection]).length;
        } catch { }

        let isTestValid = true;

        if (this.state.userAnswers[6]) {
            let lastQuestion = this.state.userAnswers[6][1]
            isTestValid = lastQuestion? lastQuestion.answerId !== null && !!lastQuestion.usersInput: true;
        }

        return (
            <React.Fragment>
                <Scroller page={this.state.activeSection}/>
                <Helmet>
                    <title>ОФН-75. Портал здоровья</title>
                    <meta name="title" content="ОФН-75. Портал здоровья" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="ОФН-75. Портал здоровья" />
                </Helmet>
                <TestNavigation sections={testSections} activeSection={this.state.activeSection} />
                <TestSection
                    userAnswers={this.state.userAnswers[this.state.activeSection] || {}} // необходимо передать объект
                    firstQuestionNumber={firstQuestionNumber}
                    questionList={testSectionList}
                    onChange={this.questionChangeHandler}
                />

                {this.state.activeSection === testSections.length - 1 ?
                    <div className={classes.button}>
                        <Button
                            type={"primary"}
                            onClick={() => this.finishTestHandler()}
                            disabled={!isTestValid}
                        >Завершить
                            </Button>
                    </div>
                    :
                    <div className={classes.button}>
                        <Button
                            type={"primary"}
                            onClick={() => this.changePageHandler(true)}
                            disabled={answersAmount < test.testData[this.state.activeSection].questions.length}
                        >Далее
                        </Button>
                    </div>
                }
            </React.Fragment>
        )
    }

    render() {
        if (localStorage.getItem("role") !== "patient") return <Redirect to="/profile/"/>;
        return (
            <section className={classes.Test}>
                <Logo />
                <div className={classes.container}>
                    <h3 className={classes.title}>
                        {this.state.isStarted ? // тест начат? кнопка "назад" : ссылка "назад"
                            <Back
                                className={classes.back}
                                onClick={() => this.changePageHandler(false)}
                            />
                            :
                            <BackLink to={"/profile/health"} />
                        }
                    Полный опросник функциональных нарушений</h3>
                    {this.state.isStarted ?
                        this.state.isFinished ?
                            this.renderFinishPage()  // тест начат и закончен
                            :
                            this.renderTest() // тест начат, но не закончен
                        :
                        this.renderIntro()  // тест не начат
                    }
                </div>
                <Footer />
            </section>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        sendResults: (results) => dispatch(sendResults(results)),
    }
}

export default connect(null, mapDispatchToProps)(Test2);