import React from "react";

import ConsultationsLogRow from "./ConsultationsLogRow/ConsultationsLogRow";

import classes from "./ConsultationsLogTable.module.scss";

const ConsultationsLogTable = (props) => {
  if (!props.consultationsList) return null;

  return (
    <table
      className={`${classes.ConsultationsLogTable} ${
        !props.sidebarCollapsed ? classes.sidebar_collapsed : ""
      }`}
    >
      <thead>
        <tr className={classes.table_header}>
          <th className={classes.served_head}>ФИО Пациента</th>
          <th className={classes.performer_head}>Исполнитель услуги</th>
          <th className={classes.date_head}>Дата создания</th>
          <th className={classes.date_head}>"На модерации"</th>
          <th className={classes.date_head}>"Принята"</th>
          <th className={classes.date_head}>"В работе"</th>
          <th className={classes.date_head}>"Пауза"</th>
          <th className={classes.date_head}>"Ожидается оплата"</th>
          <th className={classes.date_head}>"Завершена"</th>
          <th className={classes.date_head}>"Отклонена"</th>
        </tr>
      </thead>
      <tbody>
        {props.consultationsList &&
          props.consultationsList.map((consultation) => {
            return (
              <ConsultationsLogRow
                key={consultation.id}
                id={consultation.id}
                creator={consultation.creator}
                performer={consultation.performer}
                type={consultation.consultation_type}
                createdDate={new Date(consultation.created_at)}
                statusesUpdates={consultation.status_updates}
                inNewWindow
              />
            );
          })}
      </tbody>
    </table>
  );
};

export default ConsultationsLogTable;
