import React, { Component } from "react";
import { connect } from "react-redux";
import FileItem from "components/UI/FileControl/FileItem/FileItem";
import ModalWindow from "components/UI/ModalWindow/ModalWindow";
import DeleteConfirm from "components/UI/ModalWindow/DeleteConfirm/DeleteConfirm";
import classes from "./Card.module.scss";
import { documentTypes } from "../enums";
import { deleteMedcardDoc } from "../../../../../store/Profile/Medcard/actions";
class Card extends Component {
  state = {
    modalShow: false,
  };
  modalShowOpen = () => {
    this.setState({ modalShow: true });
  };
  modalShowClose = () => {
    this.setState({ modalShow: false });
  };
  deleteConfirmedHandler = () => {
    this.props.deleteDocument(this.props.id);
    this.modalShowClose();
  };
  render() {
    return (
      <React.Fragment>
        <div className={classes.Card}>
          <div className={classes.header}>
            <div className={classes.header_info}>
              <div className={classes.title}>
                <strong>{this.props.title}</strong>
              </div>
              <div className={classes.info}>
                {this.props.medical_organization}&nbsp;от&nbsp;
              {this.props.document_date}
              </div>
            </div>

            <div className={classes.deleteBtn} onClick={this.modalShowOpen}>
              Удалить
            </div>
          </div>
          <div className={classes.main}>
            <div className={classes.docType}>
              <span>Тип документа:</span>
              <strong>{documentTypes[this.props.document_type]}</strong>
            </div>
            <div className={classes.file}>
              <FileItem
                file={this.props.document_url}
                download={true}
                remove={false}
              />
            </div>
          </div>
        </div>
        {/* модальное окно подтв. удаления */}
        {this.state.modalShow ? (
          <ModalWindow closed={this.modalShowClose}>
            <DeleteConfirm
              confirmed={this.deleteConfirmedHandler}
              closed={this.modalShowClose}
              title={'Вы точно хотите удалить документ?'}
            ></DeleteConfirm>
          </ModalWindow>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteDocument: (id) => dispatch(deleteMedcardDoc(id)),
  };
};

export default connect(null, mapDispatchToProps)(Card);
