import axios from "axios";

import {
  GET_DOCUMENTS_ERROR,
  GET_DOCUMENTS_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_ERROR,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_ERROR,
  CHANGE_REQUEST_STATUS_SUCCESS,
  CHANGE_REQUEST_STATUS_ERROR,
} from "./actionTypes";

export function getMedcardDocs() {
  return async (dispatch) => {
    axios
      .get('/documents/')
      .then((response) => {
        dispatch(getMedcardDocsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getMedcardDocsError());
        console.log(err);
      });
  };
}

export function deleteMedcardDoc(id) {
  return async (dispatch) => {
    axios
      .delete(`/documents/${id}`)
      .then(() => {
        dispatch(deleteMedcardDocsSuccess());
        dispatch(getMedcardDocs());
      })
      .catch((err) => {
        dispatch(deleteMedcardDocsError());
        console.log(err);
      });
  };
}

export function getRequestsList() {
  return async (dispatch) => {
    axios
      .get('/documents/requests')
      .then((response) => {
        dispatch(getRequestsListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getRequestsListError());
        console.log(err);
      });
  };
}

export function changeRequestStatus(id, data) {
  return async (dispatch) => {
    axios
      .post(`/documents/requests/${id}/change_status`, data)
      .then(() => {
        dispatch(changeRequestStatusSuccess());
        dispatch(getRequestsList());
      })
      .catch((err) => {
        dispatch(changeRequestStatusError());
        console.log(err);
      });
  };
}

export function getMedcardDocsSuccess(documents) {
  return {
    type: GET_DOCUMENTS_SUCCESS,
    documents: documents,
  };
}

export function getMedcardDocsError() {
  return {
    type: GET_DOCUMENTS_ERROR,
  };
}

export function deleteMedcardDocsSuccess() {
  return {
    type: DELETE_DOCUMENT_SUCCESS,
  };
}

export function deleteMedcardDocsError() {
  return {
    type: DELETE_DOCUMENT_ERROR,
  };
}

export function getRequestsListSuccess(data) {
  return {
    type: GET_REQUESTS_SUCCESS,
    requestsList: data
  };
}

export function getRequestsListError() {
  return {
    type: GET_REQUESTS_ERROR,
  };
}

export function changeRequestStatusSuccess() {
  return {
    type: CHANGE_REQUEST_STATUS_SUCCESS,
  };
}

export function changeRequestStatusError() {
  return {
    type: CHANGE_REQUEST_STATUS_ERROR,
  };
}
