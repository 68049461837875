import axios from 'axios'

import {
    POST_FEEDBACK_SUCCESS,
    POST_FEEDBACK_ERROR,
    GET_FEEDBACK_SUCCESS,
    GET_FEEDBACK_ERROR,
    CLEAR_FEEDBACK_STATUS
} from './actionTypes';

export function sendFeedback(data) {
    return async dispatch => {
        axios.post('/feedback/', data)
            .then(() => {
                dispatch(postFeedbackSuccess());
            })
            .catch(error => {
                dispatch(postFeedbackError());
                console.log(error.response);
            });
    }
}

export function getFeedbackList(page, size) {
    return async dispatch => {
        axios.get('/feedback/',{params:{
            page,
            size,
        }})
            .then((response) => {
                dispatch(getFeedbackSuccess(response.data));
            })
            .catch(error => {
                dispatch(getFeedbackError());
                console.log(error.response);
            });
    }
}

export function postFeedbackSuccess() {
    return {
        type: POST_FEEDBACK_SUCCESS,
    }
}

export function postFeedbackError() {
    return {
        type: POST_FEEDBACK_ERROR,
    }
}

export function getFeedbackSuccess(data) {
    return {
        type: GET_FEEDBACK_SUCCESS,
        data,
    }
}

export function getFeedbackError() {
    return {
        type: GET_FEEDBACK_ERROR,
    }
}

export function clearFeedbackStatus() {
    return {
        type: CLEAR_FEEDBACK_STATUS,
    }
}

