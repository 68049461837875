import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Navigation from "components/UI/Navigation/Navigation";
import Button from "components/UI/Button/Button";
import Search from "components/UI/Search/Search";
import EmptySection from "components/Profile/EmptySection/EmptySection";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";

import classes from "./Patients.module.scss";
import { getPatientsList } from "store/Profile/Patients/actions";
import UserTable from "components/Profile/UserTable/UserTable";

const CARDS_IN_ONE_PAGE = 50;

class Patients extends Component {
  state = {
    activePage: 1,
    searchValue: null,
    prevSearch: null,
    isSearching: false,
  };
  componentDidMount() {
    this.props.getPatientsList(
      this.state.activePage - 1,
      CARDS_IN_ONE_PAGE,
      this.state.isSearching ? this.state.searchValue : null
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activePage !== this.state.activePage) {
      this.props.getPatientsList(
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        this.state.isSearching ? this.state.searchValue : null
      );
    }
  }
  changePageHandler = (pages, jumpTo) => {
    let activePage = this.state.activePage;

    if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
      return;
    } else {
      activePage = jumpTo;
    }
    this.setState({ activePage });
    window.scrollTo(0, 0);
  };
  onSearchHandler = () => {
    if (
      this.state.prevSearch === this.state.searchValue ||
      !this.state.searchValue
    )
      return null;

    this.setState({ isSearching: true, prevSearch: this.state.searchValue });

    if (this.state.activePage > 1) {
      this.setState({ activePage: 1 }); // При смене страницы автоматически отправится запрос
    } else {
      this.props.getPatientsList(0, CARDS_IN_ONE_PAGE, this.state.searchValue);
    }
  };
  onSearchResetHandler = () => {
    this.setState({ isSearching: false, prevSearch: null, searchValue: null });
    if (this.state.activePage > 1) {
      this.setState({ activePage: 1 }); // При смене страницы автоматически отправится запрос
    } else {
      this.props.getPatientsList(0, CARDS_IN_ONE_PAGE);
    }
  };

  render() {
    let pagesAmount = null;

    if (this.props.patientsList) {
      pagesAmount =
        Math.trunc(this.props.total / CARDS_IN_ONE_PAGE) +
        (this.props.total % CARDS_IN_ONE_PAGE ? 1 : 0);
    }

    return (
      <section className={classes.Patients}>
        <Helmet>
          <title>Пациенты. Портал здоровья</title>
          <meta name="title" content="Пациенты. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Пациенты. Портал здоровья" />
        </Helmet>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>Список пациентов</div>
          <Link to={"/profile/patients/new_patient"}>
            <Button type={"plus"} text={"Добавить пациента"}></Button>
          </Link>
        </div>
        <div className={classes.search_wrapper}>
          <Search
            value={this.state.searchValue}
            onChange={(value) =>
              this.setState({ searchValue: value === "" ? null : value })
            }
            onSearch={() => this.onSearchHandler()}
            total={this.props.total}
            showReset={this.state.isSearching}
            onReset={this.onSearchResetHandler}
          />
        </div>
        {this.props.patientsList ? (
          this.props.patientsList.length !== 0 ? (
            <React.Fragment>
              <div className={classes.list}>
                <UserTable
                  role={this.props.role}
                  userList={this.props.patientsList}
                  withHealthStatus={true}
                  inNewWindow={true}
                />
              </div>
              {pagesAmount > 1 ? (
                <Navigation
                  activePage={this.state.activePage}
                  pages={pagesAmount}
                  onChange={this.changePageHandler}
                />
              ) : null}
            </React.Fragment>
          ) : (
            <EmptySection
              title={"Пока нет пациентов"}
              text={"Список пациентов пуст"}
            />
          )
        ) : (
          <div className={classes.loader}>
            <FileLoader style={{ maxWidth: "100%" }} />
          </div>
        )}
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    role: state.profile.role,
    patientsList: state.patients.patientsList,
    total: state.patients.patientsTotal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getPatientsList: (page, size, q) =>
      dispatch(getPatientsList(page, size, q)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Patients);
