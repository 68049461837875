import React, { Component } from 'react';
import { connect } from 'react-redux';

import MainHeader from 'components/Main/MainHeader/MainHeader';
import Footer from 'components/Footer/Footer';
import Navigation from 'components/UI/Navigation/Navigation';
import FaqItem from './FaqItem/FaqItem';

import classes from './Faq.module.scss';

import { getFaqList } from 'store/Profile/Faq/actions';
import { Helmet } from 'react-helmet';


const ARTICLSES_IN_ONE_PAGE = 4; // Вопросов на странице

class Faq extends Component {
    state = {
        activePage: 1,
    }

    componentDidMount() {
        this.props.getFaqList();
        window.scrollTo(0, 0);
    }

    changePageHandler = (pages, jumpTo) => {
        let activePage = this.state.activePage;

        if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) { // Если переход на несуществующую или текущую страницу
            return;
        } else {
            activePage = jumpTo;
        }
        this.setState({ activePage })
        window.scrollTo(0, 0);
    }

    render() {
        let pagesAmount = null;
        let currentPageFaqList = null;
        if (this.props.faqList) {
            pagesAmount = Math.trunc(this.props.faqList.length / ARTICLSES_IN_ONE_PAGE) + (this.props.faqList.length % ARTICLSES_IN_ONE_PAGE ? 1 : 0); // Получение целого числа страниц

            currentPageFaqList = this.props.faqList.filter((question, index) => {
                return ((index >= (this.state.activePage - 1) * ARTICLSES_IN_ONE_PAGE && index < this.state.activePage * ARTICLSES_IN_ONE_PAGE))
            }) // получение массива вопросов для текущей страницы
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>FAQ. Портал здоровья</title>
                    <meta name="title" content="FAQ. Портал здоровья" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="FAQ. Портал здоровья" />
                </Helmet>
                <MainHeader />
                {currentPageFaqList ?
                    <React.Fragment>
                        <h2 className={classes.title}>FAQ</h2>
                        <section className={classes.Faq}>
                            {currentPageFaqList.map(question => {
                                return (
                                    <FaqItem
                                        key={question.id}
                                        id={question.id}
                                        question={question.question}
                                        answer={question.answer}
                                    />
                                )
                            })}
                        </section>
                        {pagesAmount > 1 ?
                            <div className={classes.navigation}>
                                <Navigation
                                    activePage={this.state.activePage}
                                    pages={pagesAmount}
                                    onChange={this.changePageHandler}
                                />
                            </div>

                            : null}
                    </React.Fragment>
                    :
                    null
                }
                <Footer />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        faqList: state.faq.faqList,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFaqList: () => dispatch(getFaqList())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Faq);