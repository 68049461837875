
export const GET_ARTICLESLIST_ERROR = 'GET_ARTICLESLIST_ERROR';
export const GET_ARTICLESLIST_SUCCESS = 'GET_ARTICLESLIST_SUCCESS';
export const CREATE_ARTICLE_ERROR = 'CREATE_ARTICLE_ERROR';
export const CREATE_ARTICLE_SUCCESS = 'CREATE_ARTICLE_SUCCESS';
export const EDIT_ARTICLE_SUCCESS = 'EDIT_ARTICLE_SUCCESS';
export const EDIT_ARTICLE_ERROR = 'EDIT_ARTICLE_ERROR';
export const CREATE_ARTICLE_PENDING = 'CREATE_ARTICLE_PENDING';
export const GET_ARTICLEBYID_SUCCESS = 'GET_ARTICLEBYID_SUCCESS';
export const GET_ARTICLEBYID_ERROR = 'GET_ARTICLEBYID_ERROR';
export const DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS';
export const DELETE_ARTICLE_ERROR = 'DELETE_ARTICLE_ERROR';
export const LOAD_PICTURE_SUCCESS = 'LOAD_PICTURE_SUCCESS';
export const LOAD_PICTURE_ERROR = 'LOAD_PICTURE_ERROR';
export const REMOVE_PICTURE = 'REMOVE_PICTURE';