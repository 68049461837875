import React from 'react';

import InviteRow from './InviteRow/InviteRow';

import classes from './InviteTable.module.scss'

const InviteTable = props => {

    if (!props.inviteList) return null;

    return (
        <table className={classes.InviteTable}>
            <thead>
                <tr className={classes.table_header}>
                    <th className={classes.email_head}>Почта</th>
                    <th className={classes.send_head}>Дата отправки</th>
                    <th className={classes.registered_head}>Дата регистрации</th>
                    <th className={classes.name_head}>ФИО пациента</th>
                </tr>
            </thead>
            <tbody>
                {
                    props.inviteList.map(invite => {
                        return (
                            <InviteRow
                                key={invite.id}
                                id={invite.id}
                                email={invite.email}
                                sendDate={new Date(invite.created_at)}
                                registeredDate={invite.user_registered_at ?
                                    new Date(invite.user_registered_at) : null
                                }
                                name={invite.user ?
                                    `${invite.user.profile.second_name} ${invite.user.profile.first_name} ${invite.user.profile.patronymic_name || ''}` : null
                                }
                                user_id={invite.user ?
                                    invite.user.id : null
                                }
                                inNewWindow={true}
                            />
                        )
                    })
                }
            </tbody>
        </table>
    )
}


export default InviteTable