import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";

import classes from "./Payment.module.scss";
import Button from "components/UI/Button/Button";
import { validateControl } from "containers/Auth/Auth";
import Input from "components/UI/Input/Input";
import {getCardInfoInternal} from 'store/Profile/Consultations/actions'


class Payment extends Component {
  state = {
    form: {
      time: {
        value: 0,
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Время",
        label: "Время",
        valid: true,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
          integer: true,
        },
        symbolIcon: "мин",
      },
      price: {
        value: 0,
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Стоимость",
        label: "Стоимость",
        valid: true,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
          integer: true,
        },
        symbolIcon: "₽",
      },
      needs_payment: {
        value: false,
        valid: true,
        touched: false,
      },
    },

    formValid: true,
    formTouched: false,
  };
  componentDidMount() {
    this.initForm();
  }
  initForm = () => {
    let newState = { ...this.state };
    newState.form.needs_payment.value =
      this.props.needs_payment !== null ? this.props.needs_payment : false;
    newState.form.time.value =
      this.props.consultation_time !== null ? this.props.consultation_time : 0;
    newState.form.price.value =
      this.props.price !== null ? this.props.price : 0;
      this.setState({...newState})
  };
  onChangeInputHandler = (event, controlName) => {
    let newState = { ...this.state };
    let form = { ...newState.form };
    let control = { ...form[controlName] };

    control.value = event.target.value;
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);
    form[controlName] = control;

    newState.formTouched = true;
    newState.formValid = true;
    Object.values(form).forEach((item) => {
      if (!item.valid) {
        newState.formValid = false;
      }
    });
    newState.form = form;
    this.setState({
      ...newState,
    });
  };
  checkboxHandler = (event) => {
    let newState = { ...this.state };
    newState.formTouched = true;
    if (event.target.checked) {
      newState.form.needs_payment.value = true;
      this.setState({
        ...newState,
      });
    } else {
      newState.form.needs_payment.value = false;
      this.setState({
        ...newState,
      });
    }
  };
  updatePayment = () => {
    let data = {
      needs_payment: this.state.form.needs_payment.value,
      price: this.state.form.needs_payment.value
        ? this.state.form.price.value
        : 0,
      consultation_time: this.state.form.needs_payment.value
        ? this.state.form.time.value
        : 0,
    };
    return new Promise((resolve, reject) => {
      axios
        .post(`/consultations/${this.props.id}/update_payment_info`, data)
        .then((response) => {
          resolve(response);
          this.props.getCardInfoInternal(this.props.id);
          this.setState({formTouched: false});
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  render() {
    return (
      <div className={classes.payment}>
        <div className={classes.payment__need}>
          <label className={classes.checkbox}>
            <input
              type="checkbox"
              onChange={(event) => {
                this.checkboxHandler(event);
              }}
              checked={this.state.form.needs_payment.value}
            />
            <span className={classes.checkmark}></span>
            Требуется оплата
          </label>
        </div>
        <div className={classes.payment__timeprice_wrapper}>
          <div className={classes.payment__time}>
            <Input
              type={this.state.form.time.type}
              value={this.state.form.time.value}
              placeholder={this.state.form.time.placeholder}
              valid={this.state.form.time.valid}
              touched={this.state.form.time.touched}
              label={this.state.form.time.label}
              symbolIcon={this.state.form.time.symbolIcon}
              shouldValidate={!!this.state.form.time.validation}
              errorMessage={this.state.form.time.errorMessage}
              onChange={(event) => this.onChangeInputHandler(event, "time")}
            />
          </div>
          <div className={classes.payment__price}>
            <Input
              type={this.state.form.price.type}
              value={this.state.form.price.value}
              placeholder={this.state.form.price.placeholder}
              valid={this.state.form.price.valid}
              touched={this.state.form.price.touched}
              label={this.state.form.price.label}
              symbolIcon={this.state.form.price.symbolIcon}
              shouldValidate={!!this.state.form.price.validation}
              errorMessage={this.state.form.price.errorMessage}
              onChange={(event) => this.onChangeInputHandler(event, "price")}
            />
          </div>
        </div>
        <div className={classes.payment__saveBtn}>
          <Button
            type={"primary"}
            disabled={
              !(
                (this.state.formValid ||
                  !this.state.form.needs_payment.value) &&
                this.state.formTouched
              )
            }
            onClick={() => this.updatePayment()}
          >
            Сохранить
          </Button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getCardInfoInternal: (id) => {dispatch(getCardInfoInternal(id))}
  } 
}

export default connect(null,mapDispatchToProps)(Payment);
