import React from "react";
import { connect } from "react-redux";

import { showTipModal } from "store/actions/profile";

import classes from "./Button.module.scss";

const Button = (props) => {
  const cls = [classes.Button, classes[props.type]];
  if (props.disabled) {
    cls.push(classes.disabled);
  }
  props.extraClass && cls.push(classes[props.extraClass]);

  return (
    <div
      className={
        props.type === "plus" || props.type === "download"
          ? classes.ButtonWrapper
          : null
      }
      onClick={() => {
        if (props.needTip && props.disabled && !props.isTipModalOpen) {
          props.showTipModal();
          if (props.onDisableClick) {
            props.onDisableClick();
          }
        }
      }}
    >
      <button
        style={props.extraStyle}
        onClick={props.disabled ? null : props.onClick}
        className={cls.join(" ")}
        disabled={props.disabled}
      >
        {props.children}
      </button>
      {/* внешний текст кнопки (Для типа "Плюс")*/}
      {props.text ? (
        <div
          className={`${classes.outlineText} ${
            props.disabled ? classes.disabledText : null
          }`}
        >
          {props.text}
        </div>
      ) : null}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isTipModalOpen: state.profile.isTipModalOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    showTipModal: () => dispatch(showTipModal()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Button);
