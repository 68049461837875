import React from "react";

import classes from "./CardStatus.module.scss";

const CardStatus = (props) => {
  if (props.role === "doctor") {
    delete props.statuses.created;
  }

  return (
    <div className={classes.CardStatus}>
      {Object.entries(props.statuses).map((statusName, index) => {
        const cls = [classes.item];
        if (props.activeStatus === statusName[0]) {
          cls.push(classes.active);
        }
        return (
          <span
            key={index}
            className={cls.join(" ")}
            onClick={() => props.onChange(statusName[0])}
          >
            {statusName[1]}
          </span>
        );
      })}
    </div>
  );
};

export default CardStatus;
