import React from "react";
import { Link } from "react-router-dom";

import classes from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={classes.Footer}>
      <div className={classes.container}>
        <div className={classes.row}>
          <div className={classes.column}>
            <div className={classes.title}>о сервисе</div>
            <a href="/">Описание проекта</a>
            <a href="/faq">Вопросы и ответы (ЧаВо)</a>
          </div>
          <div className={classes.column}>
            <div className={classes.title}>пользователям</div>
            <a href="/our_specialists/">Наши специалисты</a>
            <a href="/articles">Статьи</a>
          </div>
          <div className={classes.column}>
            <div className={classes.title}>партнерам</div>
            <a href="/partnership" target={"_blank"} rel="noopener noreferrer">
              Принципы работы с партнёрами
            </a>
          </div>
          <div className={classes.column}>
            <div className={classes.title}>соглашения</div>
            <a href="/agreement" target={"_blank"} rel="noopener noreferrer">
              Согласие на обработку данных
            </a>
            <a
              href="/confidentiality"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Политика конфиденциальности
            </a>
            <a href="/terms_of_use" target={"_blank"} rel="noopener noreferrer">
              Пользовательское соглашение
            </a>
          </div>
          <div className={classes.column}>
            <div className={classes.title}>информация</div>
            <a
              href={"/developer_info"}
              target={"_blank"}
              rel="noopener noreferrer"
            >
              Сведения о разработчике
            </a>
            <a href={"/feedback"}>Обратная связь</a>
          </div>
        </div>
        <div className={classes.copy}>
          © 2020 Портал здоровья, All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
