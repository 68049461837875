export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const FIRST_REG = "FIRST_REG";
export const REG_ERROR = "REG_ERROR";
export const CONFIRM_SUCCESS = "CONFIRM_SUCCESS";
export const CONFIRM_ERROR = "CONFIRM_ERROR";
export const CONFIRM_CLEAR = "CONFIRM_CLEAR";
export const CREATE_SUCCESS = "CREATE_SUCCESS";
export const CREATE_ERROR = "CREATE_ERROR";
export const SEND_RECOVERY_SUCCESS = "SEND_RECOVERY_SUCCESS";
export const SEND_RECOVERY_ERROR = "SEND_RECOVERY_ERROR";
export const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
export const RECOVER_PASSWORD_ERROR = "RECOVER_PASSWORD_ERROR";
export const CLEAR_RECOVERY_STATUS = "CLEAR_RECOVERY_STATUS";
export const CLEAR_NEW_PASSWORD_STATUS = "CLEAR_NEW_PASSWORD_STATUS";
export const CLEAR_REG_STATUS = "CLEAR_REG_STATUS";
export const ACCEPT_PATIENT_INVITATION_SUCCESS =
  "ACCEPT_PATIENT_INVITATION_SUCCESS";
export const ACCEPT_PATIENT_INVITATION_ERROR =
  "ACCEPT_PATIENT_INVITATION_ERROR";
export const ADD_REDIRECT_LINK = "ADD_REDIRECT_LINK";
