import React from "react";
import axios from "axios";
import { AsyncPaginate } from "react-select-async-paginate";
import { components } from "react-select";

import classes from "./SelectAcync.module.scss";

const PERFORMERS_IN_ONE_PAGE = 5;

let options = [];

const loadOptions = async (
  search,
  loadedOptions,
  { url, page, role, withoutValue, withAllValue }
) => {
  await axios
    .get(url, {
      params: {
        page,
        size: PERFORMERS_IN_ONE_PAGE,
      },
    })
    .then((response) => {
      options = response.data;
    });

  const formattedOptions = options.items
    .map((item) => ({
      value: item.id,
      label: `${item.profile.second_name} ${item.profile.first_name} ${
        item.profile.patronymic_name || ""
      } ${role !== "patient" ? item.email : ""}`,
    }))
    .filter((item) => item.value !== withoutValue);

  let filteredOptions;

  if (!search) {
    if (page === 0 && withAllValue) {
      filteredOptions = [{ value: "all", label: "Все" }, ...formattedOptions];
    } else {
      filteredOptions = formattedOptions;
    }
  } else {
    const searchLower = search.toLowerCase();

    filteredOptions = formattedOptions.filter(({ label }) =>
      label.toLowerCase().includes(searchLower)
    );
  }

  const hasMore = page < options.total / PERFORMERS_IN_ONE_PAGE;

  return {
    options: filteredOptions,
    hasMore,
    additional: {
      page: page + 1,
      role,
      url,
      withoutValue,
      withAllValue,
    },
  };
};

const NoOptionsMessage = (props) => {
  return (
    <components.NoOptionsMessage {...props}>
      {props.children}
    </components.NoOptionsMessage>
  );
};

const LoadingMessage = (props) => {
  return (
    <div {...props.innerProps} style={props.getStyles("loadingMessage", props)}>
      Загрузка...
    </div>
  );
};

export const SelectAsync = ({
  url,
  value,
  onChange,
  role,
  withoutValue,
  withAllValue,
  noOptionsMessage,
}) => {
  return (
    <div>
      <AsyncPaginate
        value={value}
        loadOptions={loadOptions}
        onChange={onChange}
        className={classes.select}
        additional={{
          page: 0,
          role,
          url,
          withoutValue,
          withAllValue,
        }}
        placeholder="Выбрите провайдера"
        components={{
          NoOptionsMessage: (props) => (
            <NoOptionsMessage {...props}>{noOptionsMessage}</NoOptionsMessage>
          ),
          LoadingMessage,
        }}
      />
    </div>
  );
};
