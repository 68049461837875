import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Scroller from "hoc/CustomScrollbar/Scroller.js";
import Footer from "components/Footer/Footer";
import Logo from "components/Logo/Logo";
import Button from "components/UI/Button/Button";
import TestNavigation from "./TestNavigation/TestNavigation";
import TestSection from "./TestSection/TestSection";
import BackLink from "components/UI/BackLink/BackLink";
import Back from "components/UI/Back/Back";
import Input from "components/UI/Input/Input";
import ContactForm from "../Covid/ContactForm";
import { sendCovidResults, sendContacts } from "store/Profile/Tests/actions";

import classes from "./Test.module.scss";
import questionClasses from "./QuestionItem/QuestionItem.module.scss";

import test from "tests/test4.json";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";

const testResultsParams = ["one", "two", "three"];

// тест4 состоит из анкет (questionnaire), анкеты из секций (section), секции из вопросов.

class TestCovid extends Component {
  state = {
    isStarted: false,
    isFinished: false,
    activeSection: 0,
    activeQuestionnaire: 0,
    userAnswers: {},
    height: null,
    weight: null,
  };

  userChoiceParam = +this.props.location.search.split("=")[1] || 1;

  startTestHandler = () => {
    this.setState({
      isStarted: true,
      activeQuestionnaire: 0,
      activeSection: 0,
    });
  };

  finishTestHandler = () => {
    let results = {
      // Пустой объект результатов для отправки
      data: [],
      user_choice_code: testResultsParams[this.userChoiceParam - 1],
    };

    // разворачиваем объект с ответами в одномерный массив
    const questionnaires = Object.values(this.state.userAnswers);
    const sections = [].concat(
      ...questionnaires.map((section) => Object.values(section))
    );
    const answers = [].concat(
      ...sections.map((answer) => Object.values(answer))
    );

    for (let i = 0; i < answers.length; i++) {
      results.data.push({
        question_number: i,
        answer_number: answers[i].answerId,
        users_input:
          answers[i].usersInput === "" ? null : answers[i].usersInput, // если input пуст, отправить null
        selected_answers:
          answers[i].selectedAnswers[0] !== null
            ? answers[i].selectedAnswers
            : null, // если список ответов содежрит null значит он пуст, в таком случае отправить null
        question_text: answers[i].question_text || null,
        answer_text: answers[i].answer_text || null,
      });
    }

    results.data.push({
      answer_number: null,
      answer_text: null,
      question_number: 21,
      question_text: "Ваш рост",
      selected_answers: null,
      users_input: `${this.state.height}`,
    });

    results.data.push({
      answer_number: null,
      answer_text: null,
      question_number: 22,
      question_text: "Ваш вес",
      selected_answers: null,
      users_input: `${this.state.weight}`,
    });

    this.props.sendCovidResults(results);
    this.setState({ isFinished: true });
  };

  finishQuestionnaireHandler = () => {
    // текущая анкета не последняя?
    if (this.state.activeQuestionnaire < test.testData.length - 1) {
      this.setState({
        activeQuestionnaire: this.state.activeQuestionnaire + 1,
        activeSection: 0,
      });
    } else {
      this.finishTestHandler();
    }
    window.scrollTo(0, 0);
  };

  // questionNumber - номер вопроса (начинается с 1 и соответствует отображаемому номеру)
  // answerId - id ответа (начинается с 0, может не совпадать с номером ответа в вопросе)
  questionChangeHandler = (
    questionNumber,
    answerId,
    usersInput,
    checkboxValue
  ) => {
    // userAnswers = { [questionnaireId]{ [sectionId]:{ [questionNumber]:{ [answerId]:answerId} } } }
    let userAnswers = { ...this.state.userAnswers };

    let questionnaire =
      { ...userAnswers[this.state.activeQuestionnaire] } || {}; // получить анкету

    let section = { ...questionnaire[this.state.activeSection] } || null; // получить раздел

    if (section[questionNumber]) {
      //если пользователь уже отвечал на этот вопрос: изменить

      let answer = { ...section[questionNumber] }; // предыдущее значение ответа

      answer.question_text =
        test.testData[this.state.activeQuestionnaire].sections[
          this.state.activeSection
        ].questions[questionNumber - 1].question;

      let isEmpty = true;

      if (usersInput !== null) {
        // если ввод пользовательского значения
        answer.usersInput = usersInput;
        isEmpty = usersInput === "";
      } else if (answerId !== null) {
        // если ответ на вопрос с единственным ответом
        answer.answerId = answerId;
        answer.answer_text =
          test.testData[this.state.activeQuestionnaire].sections[
            this.state.activeSection
          ].questions[questionNumber - 1].answers[answerId];
        isEmpty = answerId === null;
      } else {
        // если ответ на вопрос с множественным ответом: заменить/добавить значение
        let selectedAnswers = [...answer.selectedAnswers];

        const index = selectedAnswers.indexOf(checkboxValue);

        if (index > -1) {
          selectedAnswers.splice(index, 1);
        } else {
          selectedAnswers.push(checkboxValue);
          selectedAnswers.sort();
        }

        isEmpty = selectedAnswers.length === 0;

        answer.selectedAnswers = selectedAnswers;
      }

      if (isEmpty) {
        //Если ответ пуст - удалить, иначе изменить
        delete section[questionNumber];
      } else {
        section[questionNumber] = answer;
      }
    } else {
      //иначе: создать
      section[questionNumber] = {
        answerId,
        usersInput,
        selectedAnswers: [checkboxValue],
        answer_text:
          test.testData[this.state.activeQuestionnaire].sections[
            this.state.activeSection
          ].questions[questionNumber - 1].answers[answerId],
        question_text:
          test.testData[this.state.activeQuestionnaire].sections[
            this.state.activeSection
          ].questions[questionNumber - 1].question,
      }; // добавить ответ на вопрос
    }

    questionnaire[this.state.activeSection] = section;

    userAnswers[this.state.activeQuestionnaire] = questionnaire;

    this.setState({ userAnswers });
  };

  changePageHandler = (next) => {
    if (next) {
      // вперед
      const firstSectionAnswers = this.state.userAnswers;
      if (Object.keys(this.state.userAnswers[0][0]).length < 16) {
        firstSectionAnswers[0][0][16] = {
          answerId: 0,
          usersInput: null,
          selectedAnswers: [null],
          answer_text: null,
          question_text:
            "Укажите, какой ещё симптом Вас заметно беспокоит, но он не был указан выше:",
        };
      }
      console.log(firstSectionAnswers);
      this.setState({
        activeSection: this.state.activeSection + 1,
        userAnswers: firstSectionAnswers,
      });
    } else {
      // назад

      if (this.state.activeQuestionnaire === 0) {
        // Первая анкета

        if (this.state.activeSection === 0) {
          // первая страница анкеты
          this.setState({
            isStarted: false,
            isFinished: false,
            userAnswers: {},
          });
        } else {
          // остальные страницы
          this.setState({
            activeSection: this.state.activeSection - 1,
          });
        }
      } else if (this.state.isFinished) {
        // тест закончен
        this.setState({
          isFinished: false,
        });
      } else {
        // Остальные анкеты

        if (this.state.activeSection === 0) {
          // первая страница анкеты
          this.setState({
            // возврат к последней странице предыдущей анкеты
            activeQuestionnaire: this.state.activeQuestionnaire - 1,
            activeSection:
              test.testData[this.state.activeQuestionnaire - 1].sections
                .length - 1,
          });
        } else {
          this.setState({
            activeSection: this.state.activeSection - 1,
          });
        }
      }
    }
    window.scrollTo(0, 0);
  };

  renderIntro = () => {
    return (
      <div className={classes.intro}>
        <div className={classes.amount}></div>
        <p>
          Для каждого из <strong>15 вопросов</strong> необходимо выбрать один из
          пяти вариантов ответа <strong>(от 0 до 4)</strong>, в зависимости от
          того, насколько данный симптом был выражен у Вас и влиял на
          самочувствие (качество жизни) и работоспособность в течение последних{" "}
          <strong>2-3 месяцев</strong>.
        </p>
        <p>
          При оценке снижения качества жизни ориентируйтесь на своё оптимальное
          состояние здоровья, а не на то фактическое состояние, к которому Вы
          могли привыкнуть.
        </p>
        <div className={classes.start_button}>
          <Button type={"primary"} onClick={() => this.startTestHandler()}>
            Начать тестирование
          </Button>
        </div>
      </div>
    );
  };

  renderFinishPage = () => {
    return (
      <>
        <div className={classes.finish}>
          <p>Спасибо за ваши ответы!</p>
          <p>Текстовая интерпретация результатов:</p>
          <p className={classes.warning}>
            Приведённая ниже интерпретация основана на Ваших ответах и не может
            рассматриваться как основание для постановки диагноза и назначения
            лечения. Чем точнее ответы отражают Ваше состояние, тем больше можно
            полагаться на интерпретацию в принятии дальнейших решений.
          </p>
          <div className={classes.results}>{this.renderResults()}</div>
          <ContactForm
            testId={this.props.results?.id}
            onSubmit={this.props.sendContacts}
            status={this.props.status}
          />
        </div>
      </>
    );
  };

  renderResults = () => {
    const results = this.props.results;
    const testResults = {
      red: "высокого риска («красная» зона); тяжёлого течения ковида",
      yellow:
        "среднего риска («жёлтая зона»); длительного восстановления от ковида",
      grey: "относительно низкого риска («серая» зона); повторной инфекции",
      green: "низкого риска («зелёная» зона)",
    };
    return (
      <>
        {results ? (
          <>
            <p>
              {`На основании заполненного Вами опросника Вас можно отнести к
              зоне: ${testResults[results.result_code]}.`}
            </p>
            <p>
              Чтобы получить более подробную интерпретацию результатов,
              пожалуйста, укажите, Ваш адрес электронной почты.
            </p>
          </>
        ) : (
          <FileLoader style={{ maxWidth: "100%" }} />
        )}
      </>
    );
  };

  renderTest = () => {
    // Массив имен разделов текущей анкеты (для навигации)
    const questionnaireSections = test.testData[
      this.state.activeQuestionnaire
    ].sections.map((section) => section.section_name);

    // Формирование блока вопросов для текущей страницы
    const testSectionList = [
      ...test.testData[this.state.activeQuestionnaire].sections[
        this.state.activeSection
      ].questions,
    ];

    // ответы пользователя для текущей анкеты
    const questionnaireUserAnswers = {
      ...this.state.userAnswers[this.state.activeQuestionnaire],
    };
    // ответы пользователя для текущего раздела
    const sectionUserAnswers = {
      ...questionnaireUserAnswers[this.state.activeSection],
    };

    // Номер первого вопроса на странице
    const firstQuestionNumber = 1;

    // Массив с колличеством вопросов в каждом разделе текущей анкеты
    const questionnaireQuestionsAmount = test.testData[
      this.state.activeQuestionnaire
    ].sections.map((section) => section.questions.length);

    return (
      <React.Fragment>
        <div className={classes.questionnaire}>
          {/* Номер текщей анкеты, отображаемой на странице*/}
          <div className={classes.questionnaire_number}>
            Анкета&nbsp;{this.state.activeQuestionnaire + 1}&nbsp;из&nbsp;
            {test.testData.length}
          </div>

          {/* Название текщей анкеты*/}
          <div className={classes.questionnaire_name}>
            {test.testData[this.state.activeQuestionnaire].questionnaire_name}
          </div>

          {/* Описание текщей анкеты*/}
          <div className={classes.questionnaire_info}>
            {test.testData[this.state.activeQuestionnaire].questionnaire_info}

            {/* Список текщей анкеты (если он описан)*/}
            {test.testData[this.state.activeQuestionnaire]
              .questionnaire_list ? (
              <ul className={classes.list}>
                {test.testData[
                  this.state.activeQuestionnaire
                ].questionnaire_list.map((item) => (
                  <li>{item}</li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>

        {questionnaireSections.length > 1 ? ( //Если в анкете больше одной страницы
          <TestNavigation
            sections={questionnaireSections}
            activeSection={this.state.activeSection}
          />
        ) : null}
        <TestSection
          userAnswers={sectionUserAnswers || {}}
          firstQuestionNumber={firstQuestionNumber}
          questionList={testSectionList}
          onChange={this.questionChangeHandler}
        />

        {this.state.activeSection === questionnaireSections.length - 1 && (
          <>
            <div className={questionClasses.QuestionItem}>
              <div className={questionClasses.question}>
                <strong className={questionClasses.question_number}>
                  Вопрос&nbsp;6.&nbsp;
                </strong>
                Укажите, пожалуйста, свой рост (в сантиметрах)
              </div>
              <div className={questionClasses.question_input}>
                <Input
                  label="Укажите рост"
                  placeholder="Укажите рост"
                  value={this.state.height}
                  onChange={(event) =>
                    this.setState({ height: event.target.value })
                  }
                  valid={true}
                  touched={true}
                  shouldValidate={false}
                />
              </div>
            </div>
            <div className={questionClasses.QuestionItem}>
              <div className={questionClasses.question}>
                <strong className={questionClasses.question_number}>
                  Вопрос&nbsp;7.&nbsp;
                </strong>
                Укажите, пожалуйста, свой вес (в килограммах)
              </div>
              <div className={questionClasses.question_input}>
                <Input
                  label="Укажите вес"
                  placeholder="Укажите вес"
                  value={this.state.weight}
                  onChange={(event) =>
                    this.setState({ weight: event.target.value })
                  }
                  valid={true}
                  touched={true}
                  shouldValidate={false}
                />
              </div>
            </div>
            <div className={questionClasses.QuestionItem}>
              <div className={questionClasses.question}>
                Ваш индекс массы тела (ИМТ)
              </div>
              <div className={questionClasses.question_input}>
                <strong>
                  {(
                    +this.state.weight /
                    (+this.state.height * +this.state.height)
                  ).toFixed(5) * 10000 || 0}
                </strong>
              </div>
            </div>
          </>
        )}

        {this.state.activeSection === questionnaireSections.length - 1 ? ( // Последняя страница анкеты?
          <div className={classes.button}>
            <Button
              type={"primary"}
              onClick={this.finishQuestionnaireHandler}
              disabled={
                Object.keys(sectionUserAnswers).length <
                questionnaireQuestionsAmount[this.state.activeSection]
              }
            >
              {this.state.activeQuestionnaire === test.testData.length - 1 // последняя анкета?
                ? "Завершить тест"
                : "Следующая анкета"}
            </Button>
          </div>
        ) : (
          <div className={classes.button}>
            <Button
              type={"primary"}
              onClick={() => this.changePageHandler(true)}
              disabled={
                Object.keys(sectionUserAnswers).length + 1 <
                questionnaireQuestionsAmount[this.state.activeSection]
              }
            >
              Далее
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  };

  render() {
    return (
      <section className={classes.Test}>
        <Scroller page={this.state.activeSection} />
        <Helmet>
          <title>ОФН-15к. Портал здоровья</title>
          <meta name="title" content="ОФН-15к. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="ОФН-15к. Портал здоровья" />
        </Helmet>
        <Logo />
        <div className={classes.container}>
          <h3 className={classes.title}>
            {this.state.isStarted ? ( // тест начат? кнопка "назад" : ссылка "назад"
              <Back
                className={classes.back}
                onClick={() => this.changePageHandler(false)}
              />
            ) : (
              <BackLink to={"/covid"} />
            )}
            {this.userChoiceParam === 1
              ? "Проверка здоровья и симптомы после возможного COVID-19"
              : "Проверка здоровья и симптомы после COVID-19"}
          </h3>
          {
            this.state.isStarted
              ? this.state.isFinished
                ? this.renderFinishPage() // тест начат и закончен
                : this.renderTest() // тест начат, но не закончен
              : this.renderIntro() // тест не начат
          }
        </div>
        <Footer />
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  results: state.tests.results,
  status: state.tests.status,
});

function mapDispatchToProps(dispatch) {
  return {
    sendCovidResults: (results) => dispatch(sendCovidResults(results)),
    sendContacts: (contacts) => dispatch(sendContacts(contacts)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestCovid);
