import React from "react";

import UserRow from "./UserRow/UserRow";

import { convertDateForDev } from "components/Profile/dateConverter";
import classes from "./UserTable.module.scss";

const UserTable = (props) => {
  if (!props.userList) return null;

  return (
    <table className={classes.UserTable}>
      <tr className={classes.table_header}>
        <th className={classes.name_head}>ФИО</th>
        {props.userList[0].role === "doctor" ? (
          <th className={classes.spec_head}>Специализация</th>
        ) : null}
        {props.userList[0].role === "patient" ? (
          <th className={classes.birth_head}>Дата рождения</th>
        ) : null}
        <th className={classes.email_head}>Почта</th>
        <th className={classes.birth_head}>Дата регистрации</th>
        {props.withHealthStatus ? (
          <th className={classes.status_head}>Балл здоровья</th>
        ) : null}
        {props.role === "support" || props.role === "admin" ? (
          <th className={classes.deleted_head}></th>
        ) : null}
      </tr>
      {props.userList.map((user) => {
        return (
          <UserRow
            key={user.id}
            id={user.id}
            accountRole={props.role}
            email={user.is_deleted ? user.old_email : user.email}
            name={`${user.profile.second_name} ${user.profile.first_name} ${
              user.profile.patronymic_name || ""
            }`}
            role={user.role}
            dateOfBirth={convertDateForDev(user.profile.date_of_birth)}
            idDeleted={user.is_deleted}
            status={
              props.withHealthStatus
                ? {
                    color: user.profile.ofn15_code,
                    score: user.profile.ofn15_score,
                  }
                : null
            }
            registeredDate={
              user.registered_at ? new Date(user.registered_at) : null
            }
            doctor_profile={user.doctor_profile}
            inNewWindow={props.inNewWindow}
          />
        );
      })}
    </table>
  );
};

export default UserTable;
