import React from 'react';
import classes from './HealthStatusIcon.module.scss';

import { animate } from "./animate";
import { isPhone } from "./isPhone";

const COLORS = {
    red: "#FF3636",
    yellow: "#FFFF35",
    gray: "#ADADAD",
    green: "#1EC21E",
}


const HealthStatusIcon = props => {
    const healthStatusIconRef = React.useRef();
    const [isHover, setIsHover] = React.useState(false);

    const initIconSizeInPx = isPhone() ? 20 : 10;

    const animation = () => (!isHover && props.isAnimated) ?
        animate({
            duration: 700,
            timing: function (timeFraction) {
                if (timeFraction > 0 && timeFraction <= 0.33) return 1;
                if (timeFraction > 0.33 && timeFraction <= 0.66) return 0.3;
                if (timeFraction > 0.66 && timeFraction < 0.99) return 0.8;
                return 0;
            },
            draw: function (progress) {
                if (healthStatusIconRef.current) {
                    healthStatusIconRef.current.style.width = initIconSizeInPx + progress * (30 - initIconSizeInPx) + "px";
                    healthStatusIconRef.current.style.height = initIconSizeInPx + progress * (30 - initIconSizeInPx) + "px";
                }
            }
        })
        : null;

    const timerId = setInterval(animation, 180000); //3 мин

    React.useEffect(() => {
        return () => clearInterval(timerId);
    })

    if (props.color !== null) {
        return (
            <div className={classes.wrapper}
                onMouseOver={() => {
                    healthStatusIconRef.current.style.width = "100%";
                    healthStatusIconRef.current.style.height = "100%";
                    setIsHover(true);
                }}
                onMouseLeave={() => {
                    healthStatusIconRef.current.style.width = initIconSizeInPx + "px";
                    healthStatusIconRef.current.style.height = initIconSizeInPx + "px";
                    setIsHover(false);
                }}
                onClick={props.onClick}
            >
                <div
                    className={classes.HealthStatusIcon}
                    style={{ backgroundColor: COLORS[props.color] }}
                    ref={healthStatusIconRef}
                />
            </div>
        )
    } else return null;
}


export default HealthStatusIcon