import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import MainHeader from 'components/Main/MainHeader/MainHeader';
import Footer from 'components/Footer/Footer';
import Tabs from './Tabs/Tabs';
import DoctorItem from './DoctorItem/DoctorItem';
import DoctorProfile from '../Profile/DoctorInfo/DoctorProfile/DoctorProfile';
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";
import EmptySection from "components/Profile/EmptySection/EmptySection";
import Button from "components/UI/Button/Button";


import { getSpecializations } from 'store/Profile/Consultations/actions';
import { getPublicDoctorsList, getDoctorInfoByID, loadMoreDoctors } from "store/Profile/Doctors/actions";
import classes from './OurSpecialists.module.scss';

const CARDS_IN_ONE_PAGE = 50;

class OurSpecialists extends Component {
    state = {
        isLoading: false,
        activePage: 1,
        currentSpecializationTitle: null,
        activeDoctorId: null,
    }

    componentDidMount() {
        this.props.getSpecializations();
        this.props.getPublicDoctorsList(
            this.props.match.params.id,
            false,
            this.state.activePage - 1,
            CARDS_IN_ONE_PAGE,
        );
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.getPublicDoctorsList(
                this.props.match.params.id,
                false,
                0,
                CARDS_IN_ONE_PAGE,
            );
            this.setState({ isLoading: true });
        }
        if (prevProps.doctorsList !== this.props.doctorsList) {
            this.setState({ isLoading: false });
        }
        // выбор первого врача по умолчанию
        if (prevProps.doctorsList !== this.props.doctorsList && this.props.doctorsList[0]) {
            if (this.state.activeDoctorId && this.state.activeDoctorId !== this.props.doctorsList[0].id) {
                this.changeDoctorHandler(this.props.doctorsList[0].id)
            } else if (!this.state.activeDoctorId) {
                this.changeDoctorHandler(this.props.doctorsList[0].id)
            }
        }
    }

    changeSpecializationHandler = (title, id) => {
        this.setState({
            currentSpecializationId: id,
            currentSpecializationTitle: title,
        })
    }

    changeDoctorHandler = (id) => {
        this.setState({
            activeDoctorId: id,
        })
        this.props.getDoctorInfoByID(id);
    }

    changePageHandler = (pages, jumpTo) => {
        let activePage = this.state.activePage;

        if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
            return;
        } else {
            activePage = jumpTo;
        }
        this.setState({ activePage });
        window.scrollTo(0, 0);
    };

    loadMoreDoctors = () => {

    }

    render() {

        return (
            <React.Fragment>
                <Helmet>
                    <title>Наши специалисты. Портал здоровья</title>
                    <meta name="title" content="Наши специалисты. Портал здоровья" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="Наши специалисты. Портал здоровья" />
                </Helmet>
                <MainHeader />
                <section className={classes.OurSpecialists}>
                    <h2 className={classes.title}>Наши специалисты</h2>
                    <div className={classes.subtitle}>Специалисты, которые осуществляют деятельность на портале</div>
                    <div className={classes.specializations}>
                        {this.props.specializations ?
                            // Специализации
                            <Tabs
                                list={this.props.specializations}
                                currentItemId={this.props.match.params.id}
                                onChange={this.changeSpecializationHandler}
                            />
                            :
                            null
                        }

                    </div>
                    {this.props.doctorsList && !this.state.isLoading ?
                        this.props.doctorsList.length !== 0 ?
                            <div className={classes.content}>

                                <div className={classes.list}>
                                    {/* Список врачей */}
                                    {this.props.doctorsList.map(doctor => {
                                        return (
                                            <DoctorItem
                                                key={doctor.id}
                                                id={doctor.id}
                                                firstName={doctor.profile.first_name}
                                                secondName={doctor.profile.second_name}
                                                patronymic={doctor.profile.patronymic_name}
                                                experience={doctor.doctor_profile.medical_speciality_work_experience}
                                                imageUrl={doctor.profile.userpic_url}
                                                active={doctor.id === this.state.activeDoctorId}
                                                onClick={this.changeDoctorHandler}
                                            />
                                        )
                                    })}
                                    {this.props.doctorsList.length !== this.props.total ?
                                        <div
                                            className={classes.more}
                                            onClick={() => {
                                                if (this.props.doctorsList.length < this.props.total) {
                                                    this.props.loadMoreDoctors(this.props.match.params.id, this.state.activePage - 1, CARDS_IN_ONE_PAGE);
                                                    this.setState({ activePage: this.state.activePage + 1 });
                                                }
                                            }}>Показать больше 
                                    </div>
                                        :
                                        null}
                                </div>
                                <div className={classes.doctors_page}>
                                    {/* Страница врача */}
                                    {this.props.doctorInfo ?
                                        <DoctorProfile doctor={this.props.doctorInfo} type={"small"} />
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            :
                            <EmptySection title="К сожалению, на нашем портале еще нет специалистов данной специальности." text="Если вы им являетесь, то зарегистрируйтесь.">
                                <Link to="/profile/signup" className={classes.start_btn}>
                                    <Button type="primary">Начать</Button>
                                </Link>
                            </EmptySection>
                        :
                        <div className={classes.list}>
                            <FileLoader style={{ maxWidth: "100%", margin: "30px 10px", width: "100%" }} />
                        </div>
                    }
                </section>
                <Footer />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        specializations: state.profile.specializations,
        doctorsList: state.doctors.doctorsList,
        doctorInfo: state.doctors.editedDoctorProfile,
        total: state.doctors.doctorsTotal,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getSpecializations: () => dispatch(getSpecializations()),
        getPublicDoctorsList: (specialization_id, no_pagination, page, size) => dispatch(getPublicDoctorsList(specialization_id, no_pagination, page, size)),
        loadMoreDoctors: (specialization_id, page, size) => dispatch(loadMoreDoctors(specialization_id, page, size)),
        getDoctorInfoByID: (id) => dispatch(getDoctorInfoByID(id))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OurSpecialists);