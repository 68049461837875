import React from 'react';
import { Link } from 'react-router-dom';

import classes from './Tabs.module.scss';

const Tabs = props => {

    return (
        <div className={classes.Tabs}>
            {props.list.map((item) => {
                // классы для item
                const itemCls = [classes.item];
                if (item.id.toString() === props.currentItemId) {
                    itemCls.push(classes.active)
                }
                return (
                    // item
                    <Link
                        key={item.id}
                        to={"/our_specialists/" + item.id}
                        className={itemCls.join(' ')}
                    >
                        {item.title}
                    </Link>
                )
            })}
        </div>
    )
}


export default Tabs