export const GET_PROVIDERS_SUCCESS = "GET_PROVIDERS_SUCCESS";
export const GET_PROVIDERS_ERROR = "GET_PROVIDERS_ERROR";
export const CREATE_PROVIDER_ERROR = "CREATE_PROVIDER_ERROR";
export const CREATE_PROVIDER_SUCCESS = "CREATE_PROVIDER_SUCCESS";
export const CLEAR_CREATE_PROVIDER_STATUS = "CLEAR_CREATE_PROVIDER_STATUS";
export const EDIT_PROVIDER_SUCCESS = "EDIT_PROVIDER_SUCCESS";
export const EDIT_PROVIDER_ERROR = "EDIT_PROVIDER_ERROR";
export const CLEAR_EDIT_PROVIDER_STATUS = "CLEAR_EDIT_PROVIDER_STATUS";
export const GET_PROVIDERBYID_SUCCESS = "GET_PROVIDERBYID_SUCCESS";
export const GET_PROVIDERBYID_ERROR = "GET_PROVIDERBYID_ERROR";
export const LOAD_PICTURE_SUCCESS = "LOAD_PICTURE_SUCCESS";
export const LOAD_PICTURE_ERROR = "LOAD_PICTURE_ERROR";
export const REMOVE_PICTURE = "REMOVE_PICTURE";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_ERROR = "CHANGE_STATUS_ERROR";
export const CHANGE_DELETED_STATUS_SUCCESS = "CHANGE_DELETED_STATUS_SUCCESS";
export const CHANGE_DELETED_STATUS_ERROR = "CHANGE_DELETED_STATUS_ERROR";
export const CLEAR_DELETE_PROVIDER_STATUS = "CLEAR_DELETE_PROVIDER_STATUS";
