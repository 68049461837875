export const GET_SUPPORTSLIST_ERROR = "GET_SUPPORTSLIST_ERROR";
export const GET_SUPPORTSLIST_SUCCESS = "GET_SUPPORTSLIST_SUCCESS";
export const CREATE_SUPPORT_ERROR = "CREATE_SUPPORT_ERROR";
export const CLEAR_CREATE_SUPPORT_STATUS = "CLEAR_CREATE_SUPPORT_STATUS";
export const CREATE_SUPPORT_SUCCESS = "CREATE_SUPPORT_SUCCESS";
export const EDIT_SUPPORT_SUCCESS = "EDIT_SUPPORT_SUCCESS";
export const EDIT_SUPPORT_ERROR = "EDIT_SUPPORT_ERROR";
export const GET_SUPPORTBYID_SUCCESS = "GET_SUPPORTBYID_SUCCESS";
export const GET_SUPPORTBYID_ERROR = "GET_SUPPORTBYID_ERROR";
export const LOAD_PICTURE_SUCCESS = "LOAD_PICTURE_SUCCESS";
export const LOAD_PICTURE_ERROR = "LOAD_PICTURE_ERROR";
export const REMOVE_PICTURE = "REMOVE_PICTURE";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_ERROR = "CHANGE_STATUS_ERROR";
export const CHANGE_DELETED_STATUS_SUCCESS = "CHANGE_DELETED_STATUS_SUCCESS";
export const CHANGE_DELETED_STATUS_ERROR = "CHANGE_DELETED_STATUS_ERROR";
export const CLEAR_DELETE_SUPPORT_STATUS = "CLEAR_DELETE_SUPPORT_STATUS";
