import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Button from "components/UI/Button/Button";
import GenderControl from "components/UI/GenderControl/GenderControl";
import ModalWindow from "components/UI/ModalWindow/ModalWindow";
import ModalContent from "components/UI/ModalWindow/SuccessErrorMessage/SuccessErrorMessage";
import Input from "components/UI/Input/Input";
import Representative from "components/Profile/Representative/Representative";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";

import { validateControl } from "containers/Auth/Auth";
import { convertDateToString } from "components/Profile/dateConverter";
import classes from "./Account.module.scss";

import {
  getAccountInfo,
  editAccountInfo,
  changePassword,
  addRepresentative,
  getRepresentatives,
  deleteRepresentative,
  loadPicture,
  removePicture,
} from "store/Profile/Account/actions";

class Account extends Component {
  state = {
    isUserpicLoading: false,
    isAddressesMatch: true,
    accountInfoValid: true,
    accountInfoTouched: false,
    userpic_url: {
      url: "",
    },
    personalData: {
      lastName: {
        placeholder: "Фамилия",
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        label: "Фамилия",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      firstName: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Имя",
        label: "Имя",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      patronymic: {
        value: null,
        type: "text",
        errorMessage: "",
        placeholder: "Отчество",
        label: "Отчество",
        valid: false,
        touched: false,
        shouldValidate: false,
        validation: {},
      },
      gender: {
        value: "male",
        touched: false,
        valid: true,
      },
      date: {
        value: null,
        type: "date",
        errorMessage: "Выберите корректное значение",
        placeholder: "Дата Рождения",
        label: "Дата Рождения",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      registrationAddress: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес",
        label: "Адрес",
        valid: false,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      realAddress: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес места жительства",
        label: "Адрес места жительства",
        valid: false,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_name: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Название организации",
        label: "Название организации",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_address: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес местонахождения",
        label: "Адрес местонахождения",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_phone: {
        masked: true,
        mask: "+7 (999) 999-99-99",
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Телефон",
        label: "Телефон",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_email: {
        value: "",
        type: "email",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес электронной почты",
        label: "Адрес электронной почты",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_bik: {
        value: "",
        type: "bic",
        errorMessage: "Обязательное поле",
        placeholder: "БИК Банка",
        label: "БИК Банка",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_account: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Расчетный счет",
        label: "Расчетный счет",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_agreement_number: {
        value: "",
        type: "number",
        errorMessage: "Обязательное поле",
        placeholder: "Номер агентского договора",
        label: "Номер агентского договора",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_agreement_date: {
        value: null,
        type: "date",
        errorMessage: "Обязательное поле",
        placeholder: "Дата агентского договора",
        label: "Дата агентского договора",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
    },
    passwordChanging: {
      oldPassword: {
        value: "",
        type: "password",
        errorMessage: "",
        placeholder: "Текущий пароль",
        label: "Текущий пароль",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: { minLength: 8, required: true },
      },
      password: {
        value: "",
        type: "password",
        errorMessage:
          "Пароль должен содержать не менее 8 символов, цифры и латинские буквы, не должен являться словарным словом или набором символов, находящихся рядом на клавиатуре",
        placeholder: "Новый пароль",
        label: "Новый пароль",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
          minLength: 8,
        },
      },
      repeatPassword: {
        value: "",
        type: "password",
        errorMessage: "Пароли должны совпадать",
        placeholder: "Повторите новый пароль",
        label: "Повторите новый пароль",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {},
      },
    },
    addRepresentatives: {
      lastName: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        label: "Фамилия представителя",
        placeholder: "Фамилия представителя",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      firstName: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Имя представителя",
        label: "Имя представителя",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      patronymic: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "Отчество представителя",
        label: "Отчество представителя",
        valid: false,
        touched: false,
        shouldValidate: false,
        validation: {},
      },
      phoneNumber: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Телефон представителя",
        label: "Телефон представителя",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
          phone: true,
        },
      },
    },
    representativesFormValid: false,
    representativesFormExpanded: false,
    passwordInputValid: false,
    passwordMatch: false,
  };
  componentDidMount() {
    this.props.getAccountInfo();
    this.props.getRepresentatives();
  }
  componentDidUpdate(prevProps, prevState) {
    let account_info = this.props.account_info;
    if (prevProps.account_info !== account_info) {
      this.initpersonalData(account_info);
    }
    // открываем модалку со статусом отправки запроса
    if (prevProps.accountEditStatus !== this.props.accountEditStatus) {
      this.modalShowOpen();
    }
    if (prevProps.userpic_url !== this.props.userpic_url) {
      this.setState({ isUserpicLoading: false });
    }
    // Дублируем адреса
    if (
      prevState.isAddressesMatch === false &&
      this.state.isAddressesMatch === true
    ) {
      const formControls = { ...this.state.personalData };
      const realAddress = { ...formControls["realAddress"] };
      const registrationAddress = { ...formControls["registrationAddress"] };
      realAddress.value = registrationAddress.value;
      realAddress.touched = true;
      registrationAddress.touched = true;
      registrationAddress.valid = validateControl(
        registrationAddress.value,
        registrationAddress.validation
      );
      realAddress.valid = registrationAddress.valid;
      formControls.realAddress = realAddress;
      formControls.registrationAddress = registrationAddress;
      this.setState({
        personalData: formControls,
        accountInfoTouched: true,
      });
    }
  }
  // модалка
  modalShowOpen = () => {
    let newState = { ...this.state };
    newState.modalShow = true;
    this.setState({ ...newState });
  };
  modalShowClose = () => {
    let newState = { ...this.state };
    newState.modalShow = false;
    this.setState({ ...newState });
  };
  // ------------------------  личные данные
  initpersonalData(account_info) {
    var organization_agreement_date = null;
    if (account_info.organization_agreement_date !== null) {
      organization_agreement_date = new Date(
        account_info.organization_agreement_date
      );
    }

    let personalData = { ...this.state.personalData };
    personalData.lastName.value = account_info.second_name;
    personalData.firstName.value = account_info.first_name;
    personalData.patronymic.value = account_info.patronymic_name;
    personalData.gender.value = account_info.gender;
    personalData.date.value = new Date(account_info.date_of_birth);
    personalData.registrationAddress.value = account_info.registration_address;
    personalData.realAddress.value = account_info.real_address;
    personalData.organization_name.value = account_info.organization_name;
    personalData.organization_name.valid = true;
    personalData.organization_address.value = account_info.organization_address;
    personalData.organization_address.valid = true;
    personalData.organization_phone.value = account_info.organization_phone;
    personalData.organization_phone.valid = true;
    personalData.organization_email.value = account_info.organization_email;
    personalData.organization_email.valid = true;
    personalData.organization_bik.value = account_info.organization_bik;
    personalData.organization_bik.valid = true;
    personalData.organization_account.value = account_info.organization_account;
    personalData.organization_account.valid = true;
    personalData.organization_agreement_number.value =
      account_info.organization_agreement_number;
    personalData.organization_agreement_number.valid = true;
    personalData.organization_agreement_date.value =
      organization_agreement_date;
    personalData.organization_agreement_date.valid = true;
    this.setState({
      userpic_url: {
        url: account_info.userpic_url,
      },
      personalData: personalData,
    });
  }
  onChangeInputHandler = (event, controlName) => {
    const formControls = { ...this.state.personalData };
    const control = { ...formControls[controlName] };

    if (control.type === "address") {
      control.value = event;
      // для инпутов с типом адрес значение хранится в event (костыль)
    } else if (control.type === "bic") {
      control.value = event.data ? event.data.bic : "";
    } else {
      control.value = event.target.value;
    }
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);

    if (controlName === "registrationAddress" && this.state.isAddressesMatch) {
      const realAddress = { ...formControls["realAddress"] };
      realAddress.value = control.value;
      realAddress.touched = true;
      realAddress.valid = control.valid;
      formControls["realAddress"] = realAddress;
    }

    let accountInfoTouched = true;
    let accountInfoValid = validateControl(control.value, control.validation);
    formControls[controlName] = control;

    this.setState({
      accountInfoTouched: accountInfoTouched,
      accountInfoValid: accountInfoValid,
      personalData: formControls,
    });
  };
  onChangeDateHandler = (date) => {
    let personalData = { ...this.state.personalData };
    personalData.date.value = date;
    personalData.date.touched = true;
    personalData.date.valid = validateControl(
      personalData.date.value,
      personalData.date.validation
    );
    this.setState({
      accountInfoTouched: true,
      accountInfoValid: personalData.date.valid,
      personalData: personalData,
    });
  };
  onChangeAgreementDateHandler = (date) => {
    let personalData = { ...this.state.personalData };
    personalData.organization_agreement_date.value = date;
    personalData.organization_agreement_date.touched = true;
    personalData.organization_agreement_date.valid = validateControl(
      personalData.organization_agreement_date.value,
      personalData.organization_agreement_date.validation
    );
    this.setState({
      accountInfoTouched: true,
      accountInfoValid: personalData.organization_agreement_date.valid,
      personalData: personalData,
    });
  };
  genderChangeHandler = (gender) => {
    let personalData = { ...this.state.personalData };
    personalData.gender.value = gender;
    personalData.gender.touched = true;
    let accountInfoTouched = true;
    this.setState({
      accountInfoTouched: accountInfoTouched,
      personalData: personalData,
    });
  };
  sendAccountInfo() {
    let data = {
      first_name: this.state.personalData.firstName.value,
      second_name: this.state.personalData.lastName.value,
      patronymic_name: this.state.personalData.patronymic.value,
      gender: this.state.personalData.gender.value,
      date_of_birth: convertDateToString(this.state.personalData.date.value),
      registration_address:
        this.state.personalData.registrationAddress.value.value ||
        this.state.personalData.registrationAddress.value, // в .value хранится data, а само значание в value.value
      real_address:
        this.state.personalData.registrationAddress.value.value ||
        this.state.personalData.registrationAddress.value,
      userpic_url: this.props.userpic_url,
      organization_name: this.state.personalData.organization_name.value,
      organization_address: this.state.personalData.organization_address.value
        ? this.state.personalData.organization_address.value.value ||
          this.state.personalData.organization_address.value
        : null,
      organization_phone: this.state.personalData.organization_phone.value,
      organization_email: this.state.personalData.organization_email.value,
      organization_bik: this.state.personalData.organization_bik.value
        ? this.state.personalData.organization_bik.value.value ||
          this.state.personalData.organization_bik.value
        : null,
      organization_account: this.state.personalData.organization_account.value,
      organization_agreement_number:
        this.state.personalData.organization_agreement_number.value,
      organization_agreement_date: convertDateToString(
        this.state.personalData.organization_agreement_date.value
      ),
    };

    this.props.editAccountInfo(data);
    this.setState({ accountInfoTouched: false });
    if (this.props.accountEditStatus !== "") {
      this.modalShowOpen();
    }
  }
  //  ----------------- Смена пароля
  onChangeInputPassHandler = (event, controlName) => {
    const formControls = { ...this.state.passwordChanging };
    const control = { ...formControls[controlName] };

    control.value = event.target.value;
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);
    formControls[controlName] = control;

    let isPasswordsMatch = false;
    if (formControls.password.value === formControls.repeatPassword.value) {
      isPasswordsMatch = true;
      formControls.repeatPassword.valid = isPasswordsMatch;
    } else {
      formControls.repeatPassword.valid = isPasswordsMatch;
    }

    let passwordInputValid = true;
    Object.keys(formControls).forEach((key) => {
      if (!formControls[key].valid) {
        passwordInputValid = false;
      }
    });

    this.setState({
      passwordInputValid: passwordInputValid,
      passwordChanging: formControls,
      passwordMatch: isPasswordsMatch,
    });
  };
  sendPasswordChanges() {
    let data = {
      old_password: this.state.passwordChanging.oldPassword.value,
      new_password1: this.state.passwordChanging.password.value,
      new_password2: this.state.passwordChanging.repeatPassword.value,
    };
    this.props.changePassword(data);
    let passwordChanging = { ...this.state.passwordChanging };
    passwordChanging.oldPassword.value = "";
    passwordChanging.password.value = "";
    passwordChanging.repeatPassword.value = "";
    this.setState({
      passwordInputTouched: false,
      passwordChanging: passwordChanging,
    });
    if (this.props.accountEditStatus !== "") {
      this.modalShowOpen();
    }
  }
  //  ----------------- Представители
  onChangeRepresentativesDataInput = (event, controlName) => {
    const formControls = { ...this.state.addRepresentatives };
    const control = { ...formControls[controlName] };

    control.value = event.target.value;
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);

    let isFormValid = true;
    formControls[controlName] = control;

    Object.keys(formControls).forEach((name) => {
      isFormValid = formControls[name].valid && isFormValid;
    });

    this.setState({
      addRepresentatives: formControls,
      representativesFormValid: isFormValid,
    });
  };
  sendRepresentatives() {
    let data = {
      first_name: this.state.addRepresentatives.firstName.value,
      second_name: this.state.addRepresentatives.lastName.value,
      patronymic_name: this.state.addRepresentatives.patronymic.value,
      phone: this.state.addRepresentatives.phoneNumber.value,
    };
    let controls = { ...this.state.addRepresentatives };
    controls.firstName.value = "";
    controls.lastName.value = "";
    controls.patronymic.value = "";
    controls.phoneNumber.value = "";

    this.props.addRepresentative(data);
    this.setState({
      addRepresentatives: controls,
    });
  }

  // Фото
  addImageHadler = (event) => {
    this.props.loadPicture(event.target.files[0]);
    this.setState({
      accountInfoTouched: true,
      isUserpicLoading: true,
    });
  };
  removeImageHandler = () => {
    this.props.removePicture();
    this.setState({
      accountInfoTouched: true,
    });
  };

  deleteRepresentativeHandler = (id) => {
    this.props.deleteRepresentative(id);
  };

  expandFormHandler = () => {
    this.setState({ representativesFormExpanded: true });
  };

  render() {
    const representativesCls = [classes.inputs];

    if (
      !this.state.representativesFormExpanded &&
      this.props.representatives.length === 0
    ) {
      representativesCls.push(classes.hidden);
    }

    let fullName;

    if (this.props.account_info) {
      fullName = `${this.props.account_info.second_name} ${
        this.props.account_info.first_name
      }${
        this.props.account_info.patronymic_name
          ? " " + this.props.account_info.patronymic_name
          : ""
      }${this.props.account_info.second_name ? ". " : ""}`;
    }

    return (
      <section className={classes.Account}>
        {fullName ? (
          <Helmet>
            <title>{fullName}Портал здоровья</title>
            <meta name="title" content={`${fullName}Портал здоровья`} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:title" content={`${fullName}Портал здоровья`} />
          </Helmet>
        ) : null}
        <form
          className={classes.form}
          onSubmit={(event) => event.preventDefault()}
        >
          <h5 className={classes.section_title}>Личные данные</h5>
          <div className={classes.section}>
            <div className={classes.inputs}>
              {/* фамилия */}
              <Input
                className={classes.Input}
                value={this.state.personalData.lastName.value}
                placeholder={this.state.personalData.lastName.placeholder}
                valid={this.state.personalData.lastName.valid}
                touched={this.state.personalData.lastName.touched}
                label={this.state.personalData.lastName.label}
                shouldValidate={this.state.personalData.lastName.shouldValidate}
                errorMessage={this.state.personalData.lastName.errorMessage}
                onChange={(event) =>
                  this.onChangeInputHandler(event, "lastName")
                }
              ></Input>

              {/* имя */}

              <Input
                className={classes.Input}
                value={this.state.personalData.firstName.value}
                placeholder={this.state.personalData.firstName.placeholder}
                valid={this.state.personalData.firstName.valid}
                touched={this.state.personalData.firstName.touched}
                label={this.state.personalData.firstName.label}
                shouldValidate={
                  this.state.personalData.firstName.shouldValidate
                }
                errorMessage={this.state.personalData.firstName.errorMessage}
                onChange={(event) =>
                  this.onChangeInputHandler(event, "firstName")
                }
              ></Input>

              {/* отчество */}

              <Input
                className={classes.Input}
                value={this.state.personalData.patronymic.value}
                placeholder={this.state.personalData.patronymic.placeholder}
                valid={this.state.personalData.patronymic.valid}
                touched={this.state.personalData.patronymic.touched}
                label={this.state.personalData.patronymic.label}
                shouldValidate={
                  this.state.personalData.patronymic.shouldValidate
                }
                errorMessage={this.state.personalData.patronymic.errorMessage}
                onChange={(event) =>
                  this.onChangeInputHandler(event, "patronymic")
                }
              ></Input>

              <div className={classes.row}>
                {/* пол */}
                <GenderControl
                  value={this.state.personalData.gender.value}
                  onChange={(event) => this.genderChangeHandler(event)}
                />
                {/* дата рожд */}

                <Input
                  className={classes.Input}
                  type={this.state.personalData.date.type}
                  value={this.state.personalData.date.value}
                  placeholder={this.state.personalData.date.placeholder}
                  valid={this.state.personalData.date.valid}
                  touched={this.state.personalData.date.touched}
                  label={this.state.personalData.date.label}
                  shouldValidate={this.state.personalData.date.shouldValidate}
                  errorMessage={this.state.personalData.date.errorMessage}
                  onChange={(event, date) => this.onChangeDateHandler(date)}
                ></Input>
              </div>

              {/* адрес */}

              <Input
                className={classes.Input}
                type={this.state.personalData.registrationAddress.type}
                value={this.state.personalData.registrationAddress.value}
                placeholder={
                  this.state.personalData.registrationAddress.placeholder
                }
                valid={this.state.personalData.registrationAddress.valid}
                touched={this.state.personalData.registrationAddress.touched}
                label={this.state.personalData.registrationAddress.label}
                shouldValidate={
                  this.state.personalData.registrationAddress.shouldValidate
                }
                errorMessage={
                  this.state.personalData.registrationAddress.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "registrationAddress")
                }
              ></Input>

              {this.props.role === "assistant" ||
              this.props.role === "provider" ||
              this.props.role === "doctor" ? (
                <React.Fragment>
                  <Input
                    className={classes.Input}
                    value={this.state.personalData.organization_name.value}
                    placeholder={
                      this.state.personalData.organization_name.placeholder
                    }
                    valid={this.state.personalData.organization_name.valid}
                    touched={this.state.personalData.organization_name.touched}
                    label={this.state.personalData.organization_name.label}
                    shouldValidate={
                      this.state.personalData.organization_name.shouldValidate
                    }
                    errorMessage={
                      this.state.personalData.organization_name.errorMessage
                    }
                    onChange={(event) =>
                      this.onChangeInputHandler(event, "organization_name")
                    }
                  ></Input>

                  <Input
                    className={classes.Input}
                    type={this.state.personalData.organization_address.type}
                    value={this.state.personalData.organization_address.value}
                    placeholder={
                      this.state.personalData.organization_address.placeholder
                    }
                    valid={this.state.personalData.organization_address.valid}
                    touched={
                      this.state.personalData.organization_address.touched
                    }
                    label={this.state.personalData.organization_address.label}
                    shouldValidate={
                      this.state.personalData.organization_address
                        .shouldValidate
                    }
                    errorMessage={
                      this.state.personalData.organization_address.errorMessage
                    }
                    onChange={(event) =>
                      this.onChangeInputHandler(event, "organization_address")
                    }
                  ></Input>

                  <Input
                    className={classes.Input}
                    masked={this.state.personalData.organization_phone.masked}
                    mask={this.state.personalData.organization_phone.mask}
                    type={this.state.personalData.organization_phone.type}
                    value={this.state.personalData.organization_phone.value}
                    placeholder={
                      this.state.personalData.organization_phone.placeholder
                    }
                    valid={this.state.personalData.organization_phone.valid}
                    touched={this.state.personalData.organization_phone.touched}
                    label={this.state.personalData.organization_phone.label}
                    shouldValidate={
                      this.state.personalData.organization_phone.shouldValidate
                    }
                    errorMessage={
                      this.state.personalData.organization_phone.errorMessage
                    }
                    onChange={(event) =>
                      this.onChangeInputHandler(event, "organization_phone")
                    }
                  ></Input>

                  <Input
                    className={classes.Input}
                    value={this.state.personalData.organization_email.value}
                    placeholder={
                      this.state.personalData.organization_email.placeholder
                    }
                    valid={this.state.personalData.organization_email.valid}
                    touched={this.state.personalData.organization_email.touched}
                    label={this.state.personalData.organization_email.label}
                    shouldValidate={
                      this.state.personalData.organization_email.shouldValidate
                    }
                    errorMessage={
                      this.state.personalData.organization_email.errorMessage
                    }
                    onChange={(event) =>
                      this.onChangeInputHandler(event, "organization_email")
                    }
                  ></Input>

                  <Input
                    className={classes.Input}
                    type={this.state.personalData.organization_bik.type}
                    value={this.state.personalData.organization_bik.value}
                    placeholder={
                      this.state.personalData.organization_bik.placeholder
                    }
                    valid={this.state.personalData.organization_bik.valid}
                    touched={this.state.personalData.organization_bik.touched}
                    label={this.state.personalData.organization_bik.label}
                    shouldValidate={
                      this.state.personalData.organization_bik.shouldValidate
                    }
                    errorMessage={
                      this.state.personalData.organization_bik.errorMessage
                    }
                    onChange={(event) =>
                      this.onChangeInputHandler(event, "organization_bik")
                    }
                  ></Input>

                  <Input
                    className={classes.Input}
                    value={this.state.personalData.organization_account.value}
                    placeholder={
                      this.state.personalData.organization_account.placeholder
                    }
                    valid={this.state.personalData.organization_account.valid}
                    touched={
                      this.state.personalData.organization_account.touched
                    }
                    label={this.state.personalData.organization_account.label}
                    shouldValidate={
                      this.state.personalData.organization_account
                        .shouldValidate
                    }
                    errorMessage={
                      this.state.personalData.organization_account.errorMessage
                    }
                    onChange={(event) =>
                      this.onChangeInputHandler(event, "organization_account")
                    }
                  ></Input>

                  <Input
                    className={classes.Input}
                    value={
                      this.state.personalData.organization_agreement_number
                        .value
                    }
                    placeholder={
                      this.state.personalData.organization_agreement_number
                        .placeholder
                    }
                    valid={
                      this.state.personalData.organization_agreement_number
                        .valid
                    }
                    touched={
                      this.state.personalData.organization_agreement_number
                        .touched
                    }
                    label={
                      this.state.personalData.organization_agreement_number
                        .label
                    }
                    shouldValidate={
                      this.state.personalData.organization_agreement_number
                        .shouldValidate
                    }
                    errorMessage={
                      this.state.personalData.organization_agreement_number
                        .errorMessage
                    }
                    onChange={(event) =>
                      this.onChangeInputHandler(
                        event,
                        "organization_agreement_number"
                      )
                    }
                  ></Input>

                  <Input
                    className={classes.Input}
                    type={
                      this.state.personalData.organization_agreement_date.type
                    }
                    value={
                      this.state.personalData.organization_agreement_date.value
                    }
                    placeholder={
                      this.state.personalData.organization_agreement_date
                        .placeholder
                    }
                    valid={
                      this.state.personalData.organization_agreement_date.valid
                    }
                    touched={
                      this.state.personalData.organization_agreement_date
                        .touched
                    }
                    label={
                      this.state.personalData.organization_agreement_date.label
                    }
                    shouldValidate={
                      this.state.personalData.organization_agreement_date
                        .shouldValidate
                    }
                    errorMessage={
                      this.state.personalData.organization_agreement_date
                        .errorMessage
                    }
                    onChange={(event, date) =>
                      this.onChangeAgreementDateHandler(date)
                    }
                  ></Input>
                </React.Fragment>
              ) : null}

              <Button
                disabled={
                  !(
                    ((this.props.role === "patient" ||
                      this.props.role === "admin") &&
                      this.state.accountInfoValid &&
                      this.state.accountInfoTouched) ||
                    ((this.props.role === "assistant" ||
                      this.props.role === "provider" ||
                      this.props.role === "support") &&
                      this.state.accountInfoValid &&
                      this.state.accountInfoTouched &&
                      !!this.props.userpic_url)
                  )
                }
                onClick={() => this.sendAccountInfo()}
                type={"primary"}
              >
                Сохранить
              </Button>
            </div>
            {/* фото профиля */}
            <div
              className={classes.picture_loader}
              style={{ backgroundImage: `url(${this.props.userpic_url})` }}
            >
              <input
                className={classes.picture_input}
                type={"file"}
                onChange={(event) => this.addImageHadler(event)}
              />
              {this.props.userpic_url ? (
                <div
                  className={classes.remove_btn}
                  onClick={() => this.removeImageHandler()}
                >
                  <img src={"/profile/images/cross.svg"} alt={"remove"} />
                </div>
              ) : (
                <div className={classes.add_btn}>
                  {this.state.isUserpicLoading ? (
                    <FileLoader style={{ height: "14px" }} />
                  ) : (
                    <img src={"/profile/images/plus.svg"} alt={"add"} />
                  )}
                  <div className={classes.add_btn__text}>Загрузить фото</div>
                </div>
              )}
            </div>
          </div>
          <h5 className={classes.section_title}>Смена пароля</h5>
          <div className={classes.section_content}>
            <div className={classes.inputs}>
              {/* старый пароль */}
              <Input
                value={this.state.passwordChanging.oldPassword.value}
                type={this.state.passwordChanging.oldPassword.type}
                placeholder={
                  this.state.passwordChanging.oldPassword.placeholder
                }
                valid={this.state.passwordChanging.oldPassword.valid}
                touched={this.state.passwordChanging.oldPassword.touched}
                label={this.state.passwordChanging.oldPassword.label}
                shouldValidate={
                  this.state.passwordChanging.oldPassword.shouldValidate
                }
                errorMessage={
                  this.state.passwordChanging.oldPassword.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputPassHandler(event, "oldPassword")
                }
              ></Input>

              {/* новый пароль */}
              <Input
                value={this.state.passwordChanging.password.value}
                type={this.state.passwordChanging.password.type}
                placeholder={this.state.passwordChanging.password.placeholder}
                valid={this.state.passwordChanging.password.valid}
                touched={this.state.passwordChanging.password.touched}
                label={this.state.passwordChanging.password.label}
                shouldValidate={
                  this.state.passwordChanging.password.shouldValidate
                }
                errorMessage={this.state.passwordChanging.password.errorMessage}
                onChange={(event) =>
                  this.onChangeInputPassHandler(event, "password")
                }
              ></Input>

              {/* повтор нового пароля */}
              <Input
                value={this.state.passwordChanging.repeatPassword.value}
                type={this.state.passwordChanging.repeatPassword.type}
                placeholder={
                  this.state.passwordChanging.repeatPassword.placeholder
                }
                valid={this.state.passwordChanging.repeatPassword.valid}
                touched={this.state.passwordChanging.repeatPassword.touched}
                label={this.state.passwordChanging.repeatPassword.label}
                shouldValidate={
                  this.state.passwordChanging.repeatPassword.shouldValidate
                }
                errorMessage={
                  this.state.passwordChanging.repeatPassword.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputPassHandler(event, "repeatPassword")
                }
              ></Input>

              <Button
                disabled={
                  !(
                    this.state.passwordInputValid &&
                    this.state.passwordMatch &&
                    this.state.passwordChanging.oldPassword.valid
                  )
                }
                onClick={() => this.sendPasswordChanges()}
                type={"primary"}
              >
                Сохранить
              </Button>
            </div>
          </div>
          {this.props.role === "patient" &&
          this.props.account_info &&
          this.props.account_info.user.assistant ? (
            <React.Fragment>
              <h5 className={classes.section_title}>Ваш ассистент</h5>
              <div className={classes.section_content}>
                <div className={classes.representatives}>
                  <Representative
                    key={this.props.account_info.user.assistant.id}
                    id={this.props.account_info.user.assistant.id}
                    firstName={
                      this.props.account_info.user.assistant.profile.first_name
                    }
                    secondName={
                      this.props.account_info.user.assistant.profile.second_name
                    }
                    patronymic={
                      this.props.account_info.user.assistant.profile
                        .patronymic_name
                    }
                    phone={this.props.account_info.user.assistant.email}
                    isEdit={false}
                    delete={this.deleteRepresentativeHandler}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : null}
          {this.props.role === "patient" ? (
            <React.Fragment>
              <h5 className={classes.section_title}>Представители</h5>
              <div className={classes.section_content}>
                <div className={classes.representatives}>
                  {this.props.representatives.length > 0 ? (
                    this.props.representatives.map((representative) => {
                      return (
                        <Representative
                          key={representative.id}
                          id={representative.id}
                          firstName={representative.first_name}
                          secondName={representative.second_name}
                          patronymic={representative.patronymic_name}
                          phone={representative.phone}
                          isEdit={true}
                          delete={this.deleteRepresentativeHandler}
                        />
                      );
                    })
                  ) : (
                    <React.Fragment>
                      <div className={classes.representatives__tip}>
                        Если у вас есть законный представитель, добавьте его
                        данные для экстренного информирования. Указанного
                        представителя необходимо будет подтвердить документально
                      </div>
                      {this.state.representativesFormExpanded ? null : (
                        <Button
                          onClick={() => this.expandFormHandler()}
                          type={"additional"}
                        >
                          Добавить представителя
                        </Button>
                      )}
                    </React.Fragment>
                  )}
                </div>

                {this.props.representatives.length < 3 ? (
                  <div className={representativesCls.join(" ")}>
                    {/* Фамилия представителя */}
                    <Input
                      value={this.state.addRepresentatives.lastName.value}
                      type={this.state.addRepresentatives.lastName.type}
                      placeholder={
                        this.state.addRepresentatives.lastName.placeholder
                      }
                      valid={this.state.addRepresentatives.lastName.valid}
                      touched={this.state.addRepresentatives.lastName.touched}
                      label={this.state.addRepresentatives.lastName.label}
                      shouldValidate={
                        this.state.addRepresentatives.lastName.shouldValidate
                      }
                      errorMessage={
                        this.state.addRepresentatives.lastName.errorMessage
                      }
                      onChange={(event) =>
                        this.onChangeRepresentativesDataInput(event, "lastName")
                      }
                    />
                    {/* Имя представителя */}
                    <Input
                      value={this.state.addRepresentatives.firstName.value}
                      type={this.state.addRepresentatives.firstName.type}
                      placeholder={
                        this.state.addRepresentatives.firstName.placeholder
                      }
                      valid={this.state.addRepresentatives.firstName.valid}
                      touched={this.state.addRepresentatives.firstName.touched}
                      label={this.state.addRepresentatives.firstName.label}
                      shouldValidate={
                        this.state.addRepresentatives.firstName.shouldValidate
                      }
                      errorMessage={
                        this.state.addRepresentatives.firstName.errorMessage
                      }
                      onChange={(event) =>
                        this.onChangeRepresentativesDataInput(
                          event,
                          "firstName"
                        )
                      }
                    />
                    {/* Отчество представителя */}
                    <Input
                      value={this.state.addRepresentatives.patronymic.value}
                      type={this.state.addRepresentatives.patronymic.type}
                      placeholder={
                        this.state.addRepresentatives.patronymic.placeholder
                      }
                      valid={this.state.addRepresentatives.patronymic.valid}
                      touched={this.state.addRepresentatives.patronymic.touched}
                      label={this.state.addRepresentatives.patronymic.label}
                      shouldValidate={
                        this.state.addRepresentatives.patronymic.shouldValidate
                      }
                      errorMessage={
                        this.state.addRepresentatives.patronymic.errorMessage
                      }
                      onChange={(event) =>
                        this.onChangeRepresentativesDataInput(
                          event,
                          "patronymic"
                        )
                      }
                    />
                    {/* Телефон представителя */}
                    <Input
                      masked
                      mask="+7 (999) 999-99-99"
                      value={this.state.addRepresentatives.phoneNumber.value}
                      type={this.state.addRepresentatives.phoneNumber.type}
                      placeholder={
                        this.state.addRepresentatives.phoneNumber.placeholder
                      }
                      valid={this.state.addRepresentatives.phoneNumber.valid}
                      touched={
                        this.state.addRepresentatives.phoneNumber.touched
                      }
                      label={this.state.addRepresentatives.phoneNumber.label}
                      shouldValidate={
                        this.state.addRepresentatives.phoneNumber.shouldValidate
                      }
                      errorMessage={
                        this.state.addRepresentatives.phoneNumber.errorMessage
                      }
                      onChange={(event) =>
                        this.onChangeRepresentativesDataInput(
                          event,
                          "phoneNumber"
                        )
                      }
                    />
                    <Button
                      disabled={!this.state.representativesFormValid}
                      onClick={() => this.sendRepresentatives()}
                      type={"primary"}
                    >
                      Добавить
                    </Button>
                  </div>
                ) : (
                  <div className={classes.representatives__tip}>
                    Вы добавили максимальное кол-во представителей.
                  </div>
                )}
              </div>
            </React.Fragment>
          ) : null}
          {/* модальное окно*/}
          {this.state.modalShow && this.props.accountEditStatus !== "" ? (
            <ModalWindow timeout={3000} closed={this.modalShowClose}>
              {this.props.accountEditStatus === "SUCCESS" ? (
                <ModalContent
                  title={"Успех!"}
                  status={this.props.accountEditStatus}
                ></ModalContent>
              ) : (
                <ModalContent
                  title={"Ошибка!"}
                  subtitle={`Попробуйте позже`}
                  status={this.props.accountEditStatus}
                ></ModalContent>
              )}
            </ModalWindow>
          ) : null}
        </form>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.profile.role,
    account_info: state.account.account,
    accountEditStatus: state.account.accountEditStatus,
    representatives: state.account.representatives,
    userpic_url: state.account.userpic_url,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getAccountInfo: () => dispatch(getAccountInfo()),
    editAccountInfo: (data) => dispatch(editAccountInfo(data)),
    changePassword: (data) => dispatch(changePassword(data)),
    addRepresentative: (data) => dispatch(addRepresentative(data)),
    getRepresentatives: () => dispatch(getRepresentatives()),
    deleteRepresentative: (id) => dispatch(deleteRepresentative(id)),
    loadPicture: (file) => dispatch(loadPicture(file)),
    removePicture: () => dispatch(removePicture()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Account);
