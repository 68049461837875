import {
  GET_ASSISTANTS_SUCCESS,
  GET_ASSISTANTS_ERROR,
  CREATE_ASSISTANT_ERROR,
  CREATE_ASSISTANT_SUCCESS,
  CLEAR_CREATE_ASSISTANT_STATUS,
  EDIT_ASSISTANT_SUCCESS,
  EDIT_ASSISTANT_ERROR,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_ERROR,
  CHANGE_DELETED_STATUS_SUCCESS,
  CHANGE_DELETED_STATUS_ERROR,
  CLEAR_EDIT_ASSISTANT_STATUS,
  CLEAR_DELETE_ASSISTANT_STATUS,
  GET_ASSISTANTBYID_SUCCESS,
  GET_ASSISTANTBYID_ERROR,
  LOAD_PICTURE_SUCCESS,
  LOAD_PICTURE_ERROR,
  REMOVE_PICTURE,
} from "./actionTypes";

const initialState = {
  assistantsList: null,
  assistantsTotal: 0,
  assistantsStatus: null,
  editedAssistant: null,
  createAssistantStatus: "",
  editAssistantStatus: "",
  userpic_url: null,
  deleteAssistantStatus: "",
};

export default function assistantsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ASSISTANTS_SUCCESS:
      return {
        ...state,
        assistantsList: action.data.items,
        assistantsTotal: action.data.total,
        assistantsStatus: "SUCCESS",
      };
    case GET_ASSISTANTS_ERROR:
      return {
        ...state,
        assistantsStatus: "ERROR",
      };
    case CREATE_ASSISTANT_SUCCESS:
      return {
        ...state,
        userpic_url: null,
        createAssistantStatus: action.createAssistantStatus,
      };
    case CREATE_ASSISTANT_ERROR:
      return {
        ...state,
        createAssistantStatus: action.createAssistantStatus,
      };
    case CLEAR_CREATE_ASSISTANT_STATUS:
      return {
        ...state,
        createAssistantStatus: "",
      };
    case EDIT_ASSISTANT_SUCCESS:
      return {
        ...state,
        editAssistantStatus: action.editAssistantStatus,
      };
    case EDIT_ASSISTANT_ERROR:
      return {
        ...state,
        editAssistantStatus: action.editAssistantStatus,
      };
    case CLEAR_EDIT_ASSISTANT_STATUS:
      return {
        ...state,
        editAssistantStatus: "",
      };
    case CLEAR_DELETE_ASSISTANT_STATUS:
      return {
        ...state,
        deleteAssistantStatus: "",
      };
    case GET_ASSISTANTBYID_SUCCESS:
      return {
        ...state,
        editedAssistant: action.editedAssistant,
        userpic_url: action.editedAssistant.userpic_url,
      };
    case GET_ASSISTANTBYID_ERROR:
      return {
        ...state,
        editedAssistant: null,
      };
    case LOAD_PICTURE_SUCCESS:
      return {
        ...state,
        userpic_url: action.url,
      };
    case LOAD_PICTURE_ERROR:
      return {
        ...state,
        userpic_url: null,
      };
    case REMOVE_PICTURE:
      return {
        ...state,
        userpic_url: null,
      };
    case CHANGE_STATUS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_STATUS_ERROR:
      return {
        ...state,
      };
    case CHANGE_DELETED_STATUS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_DELETED_STATUS_ERROR:
      return {
        ...state,
        deleteAssistantStatus: action.error
      };
    default:
      return state;
  }
}
