import {
  GET_SUMMARY_SUCCESS,
  GET_SUMMARY_ERROR,
  GET_ALL_SUMMARY_SUCCESS,
  GET_ALL_SUMMARY_ERROR,
  SET_LOADING,
} from "./actionTypes";

const initialState = {
  summary: null,
  summaryTotal: null,
  loading: false,
};

export default function summaryReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.payload,
      };
    case GET_SUMMARY_ERROR:
      return {
        ...state,
        summary: null,
      };
    case GET_ALL_SUMMARY_SUCCESS:
      return {
        ...state,
        summaryTotal: action.payload,
      };
    case GET_ALL_SUMMARY_ERROR:
      return {
        ...state,
        summaryTotal: null,
      };
    default:
      return state;
  }
}
