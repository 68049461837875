import is from "is_js";
import React from "react";

import Input from "components/UI/Input/Input";

export function validateControl(value, validation) {
  if (!validation) {
    return true;
  }

  let isValid = true;

  if (!validation.required && value?.toString().trim() === "") {
    return true;
  }

  if (validation.required) {
    isValid = value ? value.toString().trim() !== "" && isValid : false;
  }

  if (validation.email) {
    isValid = is.email(value) && isValid;
  }

  if (validation.link) {
    const urlReg =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    isValid = (value.match(urlReg) || value === "") && isValid;
  }

  // TODO: необходимый формат номера +7 (999) 999-99-99
  if (validation.phone) {
    let phoneReg = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/;

    isValid = value.match(phoneReg);
  }

  if (validation.integer) {
    isValid = Number.isInteger(parseInt(value));
  }
  if (validation.minCount) {
    isValid = value >= validation.minCount && isValid;
  }
  if (validation.maxCount) {
    isValid = value <= validation.maxCount && isValid;
  }

  if (validation.minLength) {
    isValid = value.length >= validation.minLength && isValid;
  }
  if (validation.maxLength) {
    isValid = value.length <= validation.maxLength && isValid;
  }
  // TODO: При вводе большого года (напр 19999), возвращает NULL вместо FALSE
  if (validation.date) {
    let dateReg = /^\d{2}[./-]\d{2}[./-]\d{4}$/;
    try {
      let from = value.split(".");
      let date = new Date(from[2], from[1] - 1, from[0]);
      isValid =
        value.match(dateReg) &&
        date < new Date() &&
        date.getFullYear() > 1899 &&
        isValid;
    } catch {
      isValid = false;
    }
  }

  return isValid;
}

export function renderInputs(formControls, onChangeHandler) {
  if (!formControls || !onChangeHandler) {
    return null;
  }

  return Object.keys(formControls).map((controlName, index) => {
    const control = formControls[controlName];
    return (
      <Input
        key={controlName + index}
        masked={control.masked || false}
        mask={control.mask || null}
        type={control.type}
        value={control.value}
        placeholder={control.placeholder}
        valid={control.valid}
        touched={control.touched}
        label={control.label}
        symbolIcon={control.symbolIcon}
        shouldValidate={!!control.validation}
        errorMessage={control.errorMessage}
        onChange={(event, date) => onChangeHandler(event, controlName, date)}
      />
    );
  });
}

export const errorDescriptions = {
  "The user with this username already exists in the system.":
    "Пользователь с таким именем уже существует в системе",
  "Incorrect email or password": "Неверный email или пароль",
  "Not found": "Ошибка сервера",
  "Inactive user": "Почта не подтверждена",
};
