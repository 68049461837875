import React, { Component } from 'react';
import { connect } from 'react-redux';
import parse from 'html-react-parser';


import BackLink from 'components/UI/BackLink/BackLink';
import MainHeader from 'components/Main/MainHeader/MainHeader';
import Footer from 'components/Footer/Footer';

import classes from './ArticleExpanded.module.scss';
import { getArticleByID } from "store/Profile/Articles/actions";
import { Helmet } from 'react-helmet';

class ArticleExpanded extends Component {
    componentDidMount() {
        this.props.getArticleByID(this.props.match.params.id);
        window.scrollTo(0, 0);
    }

    getConvertedDate(date) {
        let cuttedDate = date.slice(0, 10);
        let splittedDate = cuttedDate.split("-");
        return [splittedDate[2], splittedDate[1], splittedDate[0]].join(".");
    }

    render() {
        if (this.props.editedArticle) {
            return (
                <React.Fragment>
                    <Helmet>
                        <title>{this.props.editedArticle.title}. Портал здоровья</title>
                        <meta name="title" content={`${this.props.editedArticle.title}. Портал здоровья`} />
                        <meta property="og:url" content={window.location.href} />
                        <meta property="og:title" content={`${this.props.editedArticle.title}. Портал здоровья`} />
                    </Helmet>
                    <MainHeader />
                    <section className={classes.ArticleExpanded}>
                        <div className={classes.header}>
                            <h5 className={classes.title}>
                                <BackLink />{this.props.editedArticle.title}
                            </h5>
                            <div className={classes.date}>{this.getConvertedDate(this.props.editedArticle.created_at)}</div>
                        </div>
                        <div className={classes.content}>
                            <div
                                className={classes.image}
                                style={{ backgroundImage: `url("${this.props.editedArticle.image_url}")` }}
                            />
                            <div className={"customHTMLblock"}>
                                {parse(this.props.editedArticle.body)}
                            </div>
                        </div>
                    </section>
                    <Footer />
                </React.Fragment>

            );
        } else {
            return (
                <React.Fragment>
                    <MainHeader />
                        Загрузка...
                    <Footer />
                </React.Fragment>
            )
        }

    }
}

function mapStateToProps(state) {
    return {
        editedArticle: state.articles.editedArticle,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        getArticleByID: (id) => dispatch(getArticleByID(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleExpanded);

