import React from 'react';
import classes from './TestItem.module.scss';

import Button from 'components/UI/Button/Button';
import { Link } from 'react-router-dom';

const TestItem = props => {
    return (
        <div className={classes.TestItem}>
            <div className={classes.header}>
                <div className={classes.about}>
                    <div className={classes.name}>
                        {props.test.name}
                    </div>
                    <div className={classes.questions}>
                        {props.test.questionsAmount}&nbsp; вопросов
                    </div>
                </div>
                <div className={classes.type}>
                    {props.test.type}
                </div>
            </div>
            <div className={classes.main}>
                <div className={classes.description}>
                    {props.test.description}
                </div>
                <div className={classes.btn}>
                    <Link to={props.test.href}>
                        <Button type={"additional"}>Пройти</Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}


export default TestItem