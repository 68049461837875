import axios from "axios";

import {
  GET_DOCTORSLIST_ERROR,
  GET_DOCTORSLIST_SUCCESS,
  CREATE_DOCTOR_ERROR,
  CREATE_DOCTOR_SUCCESS,
  CLEAR_CREATE_DOCTOR_STATUS,
  GET_DOCTORPROFILE_SUCCESS,
  GET_DOCTORPROFILE_ERROR,
  LOAD_PICTURE_SUCCESS,
  LOAD_PICTURE_ERROR,
  REMOVE_PICTURE,
  CREATE_DOCTORPROFILE_SUCCESS,
  CREATE_DOCTORPROFILE_ERROR,
  UPDATE_DOCTORPROFILE_SUCCESS,
  UPDATE_DOCTORPROFILE_ERROR,
  LOAD_MORE_DOCTORS_SUCCESS,
  GET_PROFILE_DOCTORSLIST_SUCCESS,
  GET_PROFILE_DOCTORSLIST_ERROR,
  LOAD_MORE_PROFILE_DOCTORS_SUCCESS
} from "./actionTypes";

export function getDoctorsList(specialization_id, page, size, q) {
  return async (dispatch) => {
    axios
      .get(`/users/`, {
        params: {
          role: "doctor",
          specialization_id,
          page,
          size,
          q
        }
      })
      .then((response) => {
        dispatch(getDoctorsListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDoctorsListError());
        console.log(err);
      });
  };
}

export function getPublicDoctorsList(specialization_id, no_pagination, page, size, q) {
  return async (dispatch) => {
    axios
      .get('/users/doctors', {
        params: {
          specialization_id,
          no_pagination,
          page,
          size,
          q
        }
      })
      .then((response) => {
        dispatch(getDoctorsListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDoctorsListError());
        console.log(err);
      });
  };
}

export function loadMoreDoctors(specialization_id, prevPage, size) {
  return async (dispatch) => {
    axios
      .get('/users/doctors', {
        params: {
          specialization_id,
          no_pagination: false,
          page: prevPage + 1,
          size,
        }
      })
      .then((response) => {
        dispatch(LoadMoreDoctorsSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}


export function getProfileDoctorsList(specialization_id, no_pagination, page, size, q) {
  return async (dispatch) => {
    axios
      .get('/users/doctors_profile', {
        params: {
          specialization_id,
          no_pagination,
          page,
          size,
          q
        }
      })
      .then((response) => {
        dispatch(getProfileDoctorsListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getProfileDoctorsListError());
        console.log(err);
      });
  };
}

export function loadMoreProfileDoctors(specialization_id, prevPage, size) {
  return async (dispatch) => {
    axios
      .get('/users/doctors_profile', {
        params: {
          specialization_id,
          no_pagination: false,
          page: prevPage + 1,
          size,
        }
      })
      .then((response) => {
        dispatch(LoadMoreProfileDoctorsSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}



export function createDoctor(data) {
  return async (dispatch) => {
    let sendingData = {
      email: data.email,
      password: data.password,
      first_name: data.first_name,
      second_name: data.second_name,
      patronymic_name: data.patronymic_name,
      gender: data.gender,
      date_of_birth: data.date_of_birth,
      registration_address: data.registration_address,
      real_address: data.real_address,
      userpic_url: data.userpic_url,
    };
    axios
      .post('/users/invite_doctor', sendingData)
      .then(() => {
        dispatch(createDoctorSuccess());
      })
      .catch((err) => {
        dispatch(createDoctorError());
        console.log(err);
      });
  };
}

export function loadPicture(file) {
  return async (dispatch) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: '/profiles/upload_photo',
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
      .then(function (response) {
        dispatch(loadPictureSuccess(response.data.url));
      })
      .catch(function (error) {
        dispatch(loadPictureError());
        console.log(error);
      });
  };
}

export function removePicture() {
  return {
    type: REMOVE_PICTURE,
  };
}
export function loadPictureSuccess(url) {
  return {
    type: LOAD_PICTURE_SUCCESS,
    url,
  };
}
export function loadPictureError() {
  return {
    type: LOAD_PICTURE_ERROR,
  };
}
export function getDoctorsListSuccess(data) {
  return {
    type: GET_DOCTORSLIST_SUCCESS,
    data: data,
  };
}

export function getDoctorsListError() {
  return {
    type: GET_DOCTORSLIST_ERROR,
  };
}

export function LoadMoreDoctorsSuccess(data) {
  return {
    type: LOAD_MORE_DOCTORS_SUCCESS,
    data
  };
}

export function getProfileDoctorsListSuccess(data) {
  return {
    type: GET_PROFILE_DOCTORSLIST_SUCCESS,
    data: data,
  };
}

export function getProfileDoctorsListError() {
  return {
    type: GET_PROFILE_DOCTORSLIST_ERROR,
  };
}

export function LoadMoreProfileDoctorsSuccess(data) {
  return {
    type: LOAD_MORE_PROFILE_DOCTORS_SUCCESS,
    data
  };
}


export function createDoctorSuccess() {
  return {
    type: CREATE_DOCTOR_SUCCESS,
    dataFetchStatus: "SUCCESS",
  };
}

export function createDoctorError() {
  return {
    type: CREATE_DOCTOR_ERROR,
    dataFetchStatus: "ERROR",
  };
}

// Анкета доктора
// создание доктора для самого себя
export function createDoctorProfile(data) {
  return async (dispatch) => {
    let sendingData = {
      medical_speciality_title: data.medical_speciality_title,
      medical_speciality_work_experience:
        data.medical_speciality_work_experience,
      qualification_category: data.qualification_category,
      academic_degree: data.academic_degree,
      academic_title: data.academic_title,
      specialization_id: data.specialization_id,
      professional_skills: data.professional_skills,
      main_education: data.main_education,
      internship: data.internship,
      residency: data.residency,
      further_education: data.further_education,
      work_experience: data.work_experience,
      research_interests: data.research_interests,
      scientific_publications_count: data.scientific_publications_count,
      patents_count: data.patents_count,
      guidelines_count: data.guidelines_count,
      professional_medical_association:
        data.professional_medical_association,
      diseases_and_health_problems:
        data.diseases_and_health_problems,
      working_hours_end_weekdays: data.working_hours_end_weekdays,
      working_hours_end_weekends: data.working_hours_end_weekends,
      working_hours_start_weekdays: data.working_hours_start_weekdays,
      working_hours_start_weekends: data.working_hours_start_weekends,
      contact_phone: data.contact_phone,
      work_phone: data.work_phone,
      email: data.email,
      clinic_title: data.clinic_title,
      clinic_address: data.clinic_address,
      clinic_website: data.clinic_website,
      home_appointments_available: data.home_appointments_available,
      consultation_specialization_id:
        data.consultation_specialization_id,
      clinic_consultation_price: data.clinic_consultation_price,
      home_consultation_price: data.home_consultation_price,
      video_consultation_price: data.video_consultation_price,
      consultation_clinic_title: data.consultation_clinic_title,
      consultation_clinic_address: data.consultation_clinic_address,
      diagnostic_methods: data.diagnostic_methods,
      diagnostic_clinic_title: data.diagnostic_clinic_title,
      diagnostic_clinic_address: data.diagnostic_clinic_address,
      treatment_clinic_title: data.treatment_clinic_title,
      treatment_clinic_address: data.treatment_clinic_address,
      treatment_methods: data.treatment_methods,
      dynamic_observation_clinic_title:
        data.dynamic_observation_clinic_title,
      dynamic_observation_clinic_address:
        data.dynamic_observation_clinic_address,
      dynamic_observation_methods: data.dynamic_observation_methods,
      description: data.description,
      bookform_url: data.bookform_url,
    };
    axios
      .post('/doctor_profiles/', sendingData)
      .then(() => {
        dispatch(createDoctorProfileSuccess());
      })
      .catch((err) => {
        dispatch(createDoctorProfileError());
        console.log(err);
      });
  };
}
// создание доктора для самого себя
export function createDoctorProfileByID(data) {
  return async (dispatch) => {
    let sendingData = {
      medical_speciality_title: data.medical_speciality_title,
      medical_speciality_work_experience:
        data.medical_speciality_work_experience,
      qualification_category: data.qualification_category,
      academic_degree: data.academic_degree,
      academic_title: data.academic_title,
      specialization_id: data.specialization_id,
      professional_skills: data.professional_skills,
      main_education: data.main_education,
      internship: data.internship,
      residency: data.residency,
      further_education: data.further_education,
      work_experience: data.work_experience,
      research_interests: data.research_interests,
      scientific_publications_count: data.scientific_publications_count,
      patents_count: data.patents_count,
      guidelines_count: data.guidelines_count,
      professional_medical_association:
        data.professional_medical_association,
      diseases_and_health_problems:
        data.diseases_and_health_problems,
      working_hours_end_weekdays: data.working_hours_end_weekdays,
      working_hours_end_weekends: data.working_hours_end_weekends,
      working_hours_start_weekdays: data.working_hours_start_weekdays,
      working_hours_start_weekends: data.working_hours_start_weekends,
      contact_phone: data.contact_phone,
      work_phone: data.work_phone,
      email: data.email,
      clinic_title: data.clinic_title,
      clinic_address: data.clinic_address,
      clinic_website: data.clinic_website,
      home_appointments_available: data.home_appointments_available,
      consultation_specialization_id:
        data.consultation_specialization_id,
      clinic_consultation_price: data.clinic_consultation_price,
      home_consultation_price: data.home_consultation_price,
      video_consultation_price: data.video_consultation_price,
      consultation_clinic_title: data.consultation_clinic_title,
      consultation_clinic_address: data.consultation_clinic_address,
      diagnostic_methods: data.diagnostic_methods,
      diagnostic_clinic_title: data.diagnostic_clinic_title,
      diagnostic_clinic_address: data.diagnostic_clinic_address,
      treatment_clinic_title: data.treatment_clinic_title,
      treatment_clinic_address: data.treatment_clinic_address,
      treatment_methods: data.treatment_methods,
      dynamic_observation_clinic_title:
        data.dynamic_observation_clinic_title,
      dynamic_observation_clinic_address:
        data.dynamic_observation_clinic_address,
      dynamic_observation_methods: data.dynamic_observation_methods,
      description: data.description,
      bookform_url: data.bookform_url,
    };
    axios
      .post(`/doctor_profiles/${data.id}`, sendingData)
      .then(() => {
        dispatch(createDoctorProfileSuccess());
      })
      .catch((err) => {
        dispatch(createDoctorProfileError());
        console.log(err);
      });
  };
}
// обновление доктора по айди
export function updateDoctorProfileByID(data) {
  return async (dispatch) => {
    let sendingData = {
      medical_speciality_title: data.medical_speciality_title,
      medical_speciality_work_experience:
        data.medical_speciality_work_experience,
      qualification_category: data.qualification_category,
      academic_degree: data.academic_degree,
      academic_title: data.academic_title,
      specialization_id: data.specialization_id,
      professional_skills: data.professional_skills,
      main_education: data.main_education,
      internship: data.internship,
      residency: data.residency,
      further_education: data.further_education,
      work_experience: data.work_experience,
      research_interests: data.research_interests,
      scientific_publications_count: data.scientific_publications_count,
      patents_count: data.patents_count,
      guidelines_count: data.guidelines_count,
      professional_medical_association:
        data.professional_medical_association,
      diseases_and_health_problems:
        data.diseases_and_health_problems,
      working_hours_end_weekdays: data.working_hours_end_weekdays,
      working_hours_end_weekends: data.working_hours_end_weekends,
      working_hours_start_weekdays: data.working_hours_start_weekdays,
      working_hours_start_weekends: data.working_hours_start_weekends,
      contact_phone: data.contact_phone,
      work_phone: data.work_phone,
      email: data.email,
      clinic_title: data.clinic_title,
      clinic_address: data.clinic_address,
      clinic_website: data.clinic_website,
      home_appointments_available: data.home_appointments_available,
      consultation_specialization_id:
        data.consultation_specialization_id,
      clinic_consultation_price: data.clinic_consultation_price,
      home_consultation_price: data.home_consultation_price,
      video_consultation_price: data.video_consultation_price,
      consultation_clinic_title: data.consultation_clinic_title,
      consultation_clinic_address: data.consultation_clinic_address,
      diagnostic_methods: data.diagnostic_methods,
      diagnostic_clinic_title: data.diagnostic_clinic_title,
      diagnostic_clinic_address: data.diagnostic_clinic_address,
      treatment_clinic_title: data.treatment_clinic_title,
      treatment_clinic_address: data.treatment_clinic_address,
      treatment_methods: data.treatment_methods,
      dynamic_observation_clinic_title:
        data.dynamic_observation_clinic_title,
      dynamic_observation_clinic_address:
        data.dynamic_observation_clinic_address,
      dynamic_observation_methods: data.dynamic_observation_methods,
      description: data.description,
      bookform_url: data.bookform_url,
    };
    axios
      .patch(`/doctor_profiles/${data.id}`, sendingData)
      .then(() => {
        dispatch(updateDoctorProfileSuccess());
        dispatch(getDoctorByID(data.id));
      })
      .catch((err) => {
        dispatch(updateDoctorProfileError());
        console.log(err);
      });
  };
}
// обновление доктора для самого себя
export function updateDoctorProfileMy(data) {
  return async (dispatch) => {
    let sendingData = {
      medical_speciality_title: data.medical_speciality_title,
      medical_speciality_work_experience:
        data.medical_speciality_work_experience,
      qualification_category: data.qualification_category,
      academic_degree: data.academic_degree,
      academic_title: data.academic_title,
      specialization_id: data.specialization_id,
      professional_skills: data.professional_skills,
      main_education: data.main_education,
      internship: data.internship,
      residency: data.residency,
      further_education: data.further_education,
      work_experience: data.work_experience,
      research_interests: data.research_interests,
      scientific_publications_count: data.scientific_publications_count,
      patents_count: data.patents_count,
      guidelines_count: data.guidelines_count,
      professional_medical_association:
        data.professional_medical_association,
      diseases_and_health_problems:
        data.diseases_and_health_problems,
      working_hours_end_weekdays: data.working_hours_end_weekdays,
      working_hours_end_weekends: data.working_hours_end_weekends,
      working_hours_start_weekdays: data.working_hours_start_weekdays,
      working_hours_start_weekends: data.working_hours_start_weekends,
      contact_phone: data.contact_phone,
      work_phone: data.work_phone,
      email: data.email,
      clinic_title: data.clinic_title,
      clinic_address: data.clinic_address,
      clinic_website: data.clinic_website,
      home_appointments_available: data.home_appointments_available,
      consultation_specialization_id:
        data.consultation_specialization_id,
      clinic_consultation_price: data.clinic_consultation_price,
      home_consultation_price: data.home_consultation_price,
      video_consultation_price: data.video_consultation_price,
      consultation_clinic_title: data.consultation_clinic_title,
      consultation_clinic_address: data.consultation_clinic_address,
      diagnostic_methods: data.diagnostic_methods,
      diagnostic_clinic_title: data.diagnostic_clinic_title,
      diagnostic_clinic_address: data.diagnostic_clinic_address,
      treatment_clinic_title: data.treatment_clinic_title,
      treatment_clinic_address: data.treatment_clinic_address,
      treatment_methods: data.treatment_methods,
      dynamic_observation_clinic_title:
        data.dynamic_observation_clinic_title,
      dynamic_observation_clinic_address:
        data.dynamic_observation_clinic_address,
      dynamic_observation_methods: data.dynamic_observation_methods,
      description: data.description,
      bookform_url: data.bookform_url,
    };
    axios
      .patch(`/doctor_profiles/my`, sendingData)
      .then(() => {
        dispatch(updateDoctorProfileSuccess());
        dispatch(getDoctorMy());
      })
      .catch((err) => {
        dispatch(updateDoctorProfileError());
        console.log(err);
      });
  };
}

export function getDoctorByID(id) {
  return async (dispatch) => {
    axios
      .get(`/doctor_profiles/${id}`)
      .then((response) => {
        dispatch(getDoctorByIDSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDoctorByIDError());
        console.log(err);
      });
  };
}
// для пациентов и пользователей без роли
export function getDoctorInfoByID(id) {
  return async (dispatch) => {
    axios
      .get(`/users/doctors/${id}`)
      .then((response) => {
        dispatch(getDoctorByIDSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDoctorByIDError());
        console.log(err);
      });
  };
}
// получение своего профиля доктора
export function getDoctorMy() {
  return async (dispatch) => {
    axios
      .get('/doctor_profiles/my')
      .then((response) => {
        dispatch(getDoctorByIDSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDoctorByIDError());
        console.log(err);
      });
  };
}
export function getDoctorByIDSuccess(editedDoctorProfile) {
  return {
    type: GET_DOCTORPROFILE_SUCCESS,
    editedDoctorProfile: editedDoctorProfile,
  };
}
export function getDoctorByIDError() {
  return {
    type: GET_DOCTORPROFILE_ERROR,
  };
}
export function createDoctorProfileSuccess() {
  return {
    type: CREATE_DOCTORPROFILE_SUCCESS,
    dataFetchStatus: "SUCCESS",
  };
}
export function createDoctorProfileError() {
  return {
    type: CREATE_DOCTORPROFILE_ERROR,
    dataFetchStatus: "ERROR",
  };
}
export function updateDoctorProfileSuccess() {
  return {
    type: UPDATE_DOCTORPROFILE_SUCCESS,
    dataFetchStatus: "SUCCESS",
  };
}
export function updateDoctorProfileError() {
  return {
    type: UPDATE_DOCTORPROFILE_ERROR,
    dataFetchStatus: "ERROR",
  };
}
export function clearCreateDoctorStatus() {
  return {
    type: CLEAR_CREATE_DOCTOR_STATUS,
  };
}
