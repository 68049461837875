import React from 'react';

import classes from './GenderControl.module.scss';


const GenderControl = props => {
    let maleCls = [classes.btn];
    let femaleCls = [classes.btn];
    if (props.value === 'male') {
        maleCls.push(classes.active);
    }
    if (props.value === 'female') {
        femaleCls.push(classes.active);
    }
    return (
        <label className={classes.GenderControl}>
            <span className={classes.label}>Пол:</span>
            <input
                type={'radio'}
                name={'gender'}
                value={'male'}
                checked={props.value === 'male'} 
            />
            <span
                className={maleCls.join(' ')}
                onClick={() => props.onChange('male')}
            >М</span>
            <input
                type={'radio'}
                name={'gender'}
                value={'female'}
                checked={props.value === 'female'}
            />
            <span
                className={femaleCls.join(' ')}
                onClick={() => props.onChange('female')}
            >Ж</span>
        </label>
    )
}

export default GenderControl;