import React from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import classes from "./MainHeader.module.scss";

const MainHeader = (props) => {
  function onMenuClickHandler() {
    document.getElementById("header").classList.toggle(classes.active);
    document.getElementById("body").classList.toggle("no-scroll");
  }

  React.useEffect(() => {
    return () => {
      document.getElementById("body").classList.remove("no-scroll");
    };
  });

  function onLinkClick() {
    document.getElementById("header").classList.remove(classes.active);
    document.getElementById("body").classList.remove("no-scroll");
  }

  return (
    <header id="header" className={classes.MainHeader}>
      <Link to={"/"} className={classes.logo}>
        <img src={"/images/header-logo.svg"} alt={"logo"} />
        Портал здоровья
      </Link>
      <nav className={classes.nav}>
        <ul className={classes.list}>
          <li className={`${classes.item} ${classes.covid_btn}`}>
            <NavLink to="/covid" activeClassName={classes.active}>
              Covid-19
            </NavLink>
          </li>
          <li className={classes.item}>
            <NavLink to="/our_specialists/" activeClassName={classes.active}>
              Наши специалисты
            </NavLink>
          </li>
          <li className={classes.item}>
            <NavLink to="/articles" activeClassName={classes.active}>
              Статьи
            </NavLink>
          </li>
          <li className={classes.item}>
            <NavLink to="/faq" activeClassName={classes.active}>
              FAQ
            </NavLink>
          </li>
        </ul>
        <div className={classes.login}>
          {props.isAuthenticated ? (
            <NavLink to="/profile">Личный кабинет</NavLink>
          ) : (
            <NavLink to="/profile/login">Войти</NavLink>
          )}
        </div>
      </nav>
      <div className={classes.covid_btn__phone}>
        <NavLink to="/covid" activeClassName={classes.active_link}>
          Covid-19
        </NavLink>
      </div>
      <div className={classes.menu_btn} onClick={() => onMenuClickHandler()}>
        <img className={classes.burger} src={"/images/burger.svg"} alt="menu" />
        <img
          className={classes.cross}
          src={"/images/cross-menu.svg"}
          alt="close"
        />
      </div>
      <div className={classes.menu} onClick={() => onLinkClick()}>
        <Link to="/our_specialists/">Наши специалисты</Link>
        <Link to="/articles">Статьи</Link>
        <Link to="/faq">FAQ</Link>
        <div className={classes.filler} />
        {props.isAuthenticated ? (
          <Link to="/profile">Личный кабинет</Link>
        ) : (
          <React.Fragment>
            <Link to="/profile/login">Войти</Link>
            <Link to="/profile/signup">Зарегистрироваться</Link>
          </React.Fragment>
        )}
      </div>
    </header>
  );
};

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.auth.token,
  };
}

export default connect(mapStateToProps, null)(MainHeader);
