import React from "react";
import Button from "components/UI/Button/Button";

import classes from "./RequestItem.module.scss";

const RequestItem = (props) => {
  const userCls = [classes.user];
  if (props.status === "Отклонена") {
    userCls.push(classes.declined);
  }

  return (
    <div className={classes.RequestItem}>
      <div className={classes.header}>
        <div className={classes.title}>Запрос на открытие документов</div>
        <div className={classes.date}>от&nbsp;{props.createdAt}</div>
        <div className={classes.status}>{props.status}</div>
      </div>
      <div className={classes.body}>
        <div
          className={userCls.join(" ")}
          style={props.user.role === "doctor" ? { cursor: "pointer" } : null}
          onClick={(event) => {
            event.preventDefault();
            if (props.user.role === "doctor") {
              window.open(`/profile/doctors/doctor_info/${props.user.id}`);
            }
          }}
        >
          <span className={classes.name}>
            {`${props.user.profile.second_name} ${
              props.user.profile.first_name
            } ${props.user.profile.patronymic_name || ""}`}
          </span>
          <img
            className={classes.arrow}
            src={"/profile/images/more-arrow.svg"}
            alt={"arrow"}
          />
          <br />
          <div className={classes.role}>
            {props.user.role === "support" ? "Поддержка" : null}
            {props.user.role === "doctor" ? "Специалист" : null}
            {props.user.role === "assistant" ? "Ассистент" : null}
          </div>
        </div>
        <div className={classes.buttons}>
          {props.status === "На рассмотрении" ? (
            <React.Fragment>
              <Button
                type={"additional"}
                onClick={() => props.changeStatus(props.id, "accepted")}
              >
                Открыть
              </Button>
              <div
                className={classes.reject_btn}
                onClick={() => props.changeStatus(props.id, "declined")}
              >
                Отклонить
              </div>
            </React.Fragment>
          ) : null}
          {props.status === "Одобрена" ? (
            <div
              className={classes.reject_btn}
              onClick={() => props.changeStatus(props.id, "declined")}
            >
              Закрыть доступ
            </div>
          ) : null}
          {props.status === "Отклонена" ? (
            <div className={classes.filler} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RequestItem;
