import React from "react";
import { Link } from "react-router-dom";
import Button from "components/UI/Button/Button";

import { formatExperienceYears } from "../../../../UI/helpers";

import classes from "./Card.module.scss";

const Card = ({ id, profile, doctorProfile }) => {
  const {
    first_name: firstName,
    second_name: secondName,
    patronymic_name: patronymicName,
    userpic_url: userpicUrl,
    organisation_name: organisationName,
  } = profile || {};

  const {
    specialization,
    qualification_category: qualificationCategory,
    medical_speciality_work_experience: experience,
    medical_speciality_title: title,
    bookform_url: bookformUrl,
  } = doctorProfile || {};

  const renderSpecialistInfo = () => {
    return (
      <>
        <div className={classes.main}>
          {specialization && (
            <div className={classes.specialization}>{specialization.title}</div>
          )}
          <div className={classes.name}>
            {secondName} {firstName} {patronymicName}
          </div>
          {experience && (
            <div className={classes.experience}>
              Стаж {formatExperienceYears(experience)}
            </div>
          )}
          {((!doctorProfile && organisationName) || doctorProfile) && (
            <div className={classes.specialization}>
              {!doctorProfile ? organisationName : qualificationCategory}
            </div>
          )}
          {title && <div className={classes.specialization}>{title}</div>}
          {bookformUrl && (
            <div className={classes.btn}>
              <Button
                type="additional"
                onClick={(event) => {
                  event.preventDefault();
                  window.open(bookformUrl);
                }}
              >
                Записаться на прием
              </Button>
            </div>
          )}
        </div>
        {/* фото */}
        {userpicUrl && userpicUrl.slice(0, 4) === "http" && (
          <div
            className={classes.photo}
            style={{
              background: `url(${userpicUrl}) center / cover no-repeat`,
            }}
          ></div>
        )}
      </>
    );
  };

  return (
    <React.Fragment>
      {!doctorProfile ? (
        <div className={classes.Card}>{renderSpecialistInfo()}</div>
      ) : (
        <Link
          to={`/profile/doctors/doctor_info/${id}`}
          className={classes.Card}
        >
          {renderSpecialistInfo()}
        </Link>
      )}
    </React.Fragment>
  );
};

export default Card;
