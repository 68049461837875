import React from 'react';

import classes from './EmptySection.module.scss';

const EmptySection = props => {
    return (
        <div className={classes.EmptySection}>
            <div className={classes.container}>
                <h4 className={classes.title}>{props.title}</h4>
                <p className={classes.text} style={props.style}>{props.text}</p>
                <div className={classes.extra}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}


export default EmptySection