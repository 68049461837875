import {
  CREATE_SUCCESS,
  CREATE_ERROR,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_ERROR,
  GET_CONSULTATIONS_SUCCESS,
  GET_CONSULTATIONS_ERROR,
  GET_SPECIALIZATIONS_SUCCESS,
  GET_SPECIALIZATIONS_ERROR,
  GET_CARD_INFO_SUCCESS,
  CHANGE_PAGE_STATUS,
  SET_SCROLL_STATUS,
  GET_CONSULTATION_HISTORY_SUCCESS
} from "./actionTypes";

const initialState = {
  changeCardStatusStatus: "",
  consultations: null,
  consultationsTotal: 0,
  specializations: null,
  activeCard: null,
  scrollStatus: false,
  currentPageStatus: "created",
  needUpdate: false,
  created: false,
  isConsultationCardOpened: false,
  consultationHistory: null,
  historyTotal: 0,
};

export default function consultationsReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_SUCCESS:
      return {
        ...state,
        created: true,
        attachments: [],
      };
    case CREATE_ERROR:
      return {
        ...state,
        created: false,
      };
    case CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        changeCardStatusStatus: "SUCCESS",
        needUpdate: true,
      };
    case CHANGE_STATUS_ERROR:
      return {
        ...state,
        changeCardStatusStatus: "ERROR",
      };
    case GET_CONSULTATIONS_SUCCESS:
      return {
        ...state,
        consultations: action.data.items,
        consultationsTotal: action.data.total,
        needUpdate: false,
        created: false,
        isConsultationCardOpened: false,
      };
    case GET_CONSULTATIONS_ERROR:
      return {
        ...state,
        consultations: null,
        activeCard: null,
      };
    case GET_SPECIALIZATIONS_SUCCESS:
      return {
        ...state,
        specializations: action.specializations,
      };
    case GET_SPECIALIZATIONS_ERROR:
      return {
        ...state,
        specializations: null,
      };
    case GET_CARD_INFO_SUCCESS:
      return {
        ...state,
        activeCard: action.cardInfo,
        isConsultationCardOpened: true,
      };
    case CHANGE_PAGE_STATUS:
      return {
        ...state,
        currentPageStatus: action.status
      };
    case SET_SCROLL_STATUS:
      return {
        ...state,
        scrollStatus: action.newStatus
      };
    case GET_CONSULTATION_HISTORY_SUCCESS:
      return {
        ...state,
        consultationHistory: action.events,
        historyTotal: action.total,
      };
    case "AUTH_LOGOUT":
      return {
        ...state,
        currentPageStatus: "created"
      };
    default:
      return state;
  }
}
