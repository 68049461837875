import React from "react";
import InputMask from "react-input-mask";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import { AddressSuggestions, BankSuggestions } from "react-dadata";

import classes from "./Input.module.scss";
import "components/UI/CustomDatePicker/CustomDatePicker.scss";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "./react-dadata.scss";

function isPhone() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else return false;
}

function isInvalid({ valid, touched, shouldValidate }) {
  return !valid && shouldValidate && touched;
}

const Input = (props) => {
  const inputType = props.type || "text";
  const cls = [classes.Input, props.disabled ? classes.disabled : undefined];
  const htmlFor = `${inputType}-${Math.random()}`;
  registerLocale("ru", ru);
  if (isInvalid(props)) {
    cls.push(classes.invalid);
  }

  const [isAddressClear, setIsAddressClear] = React.useState(false);
  const [isBicValueClear, setIsBicValueClear] = React.useState(false);

  const suggestionsRef = React.useRef();

  function inputChangeHandler(type, event) {
    const setValueObj = {
      address: setIsAddressClear,
      bic: setIsBicValueClear,
    };

    const setValueFunc = setValueObj[type];

    if (!process.env.REACT_APP_DADATA_API_KEY) {
      props.onChange(event.target.value);
    } else {
      if (event.target.value === "") {
        setValueFunc(true);
      } else {
        setValueFunc(false);
      }
    }
  }

  function clearInput(type, isInputClear, setIsInputClear) {
    if (inputType === type) {
      if (isInputClear) {
        props.onChange("");
        setIsInputClear(false);
      }
    }
  }

  clearInput("address", isAddressClear, setIsAddressClear);
  clearInput("bic", isBicValueClear, setIsBicValueClear);

  function onSelectBicHandler(event) {
    if (suggestionsRef.current) {
      suggestionsRef.current.setInputValue(event.data.bic);
    } else {
      suggestionsRef.current.setInputValue(props.value);
    }
    props.onChange(event);
  }

  return (
    <div
      className={cls.join(" ")}
      style={inputType === "date" ? { width: "auto" } : null}
    >
      {props.masked ? (
        <InputMask
          mask={props.mask}
          type={inputType}
          id={htmlFor}
          value={props.value}
          onChange={props.onChange}
          placeholder={props.placeholder}
          style={props.style}
        />
      ) : inputType === "date" ? (
        <DatePicker
          className="datepicker"
          selected={props.value}
          onChange={(date) => props.onChange(null, date)}
          dateFormat="dd.MM.yyyy"
          placeholderText={props.placeholder}
          locale="ru"
          calendarClassName="calendar"
          disabledKeyboardNavigation
          closeOnScroll={false}
          withPortal={isPhone()}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          maxDate={new Date()}
        />
      ) : inputType === "address" ? (
        <AddressSuggestions
          token={process.env.REACT_APP_DADATA_API_KEY}
          value={
            typeof props.value === "string"
              ? { value: props.value }
              : props.value
          }
          onChange={props.onChange}
          count={6} // колличество подсказок
          containerClassName={props.disabled ? "react-dadata disabled" : ""}
          inputProps={{
            onChange: (event) => inputChangeHandler("address", event),
          }}
        />
      ) : inputType === "bic" ? (
        <BankSuggestions
          token={process.env.REACT_APP_DADATA_API_KEY}
          ref={suggestionsRef}
          value={
            typeof props.value === "string"
              ? { value: props.value }
              : props.value
          }
          id={props.id ? props.id : htmlFor}
          onChange={onSelectBicHandler}
          count={6} // колличество подсказок
          containerClassName={props.disabled ? "react-dadata disabled" : ""}
          inputProps={{
            onChange: (event) => inputChangeHandler("bic", event),
          }}
        />
      ) : (
        <input
          type={inputType}
          id={props.id ? props.id : htmlFor}
          value={props.value}
          onChange={(event) => props.onChange(event)}
          placeholder={props.placeholder}
          style={props.style}
          disabled={props.disabled}
        />
      )}

      {props.value !== "" ||
      props.type === "address" ||
      props.type === "bic" ? (
        <label htmlFor={htmlFor}>{props.label}</label>
      ) : null}
      {props.symbolIcon ? (
        <div className={classes.symbolIcon}>{props.symbolIcon}</div>
      ) : null}
      {isInvalid(props) ? (
        <span className={classes.msg}>
          {props.errorMessage || "Введите верное значение"}
        </span>
      ) : null}
    </div>
  );
};

export default Input;
