import axios from "axios";

import {
  GET_INVITES_SUCCESS,
  GET_INVITES_ERROR,
  GET_LOG_FILE_SUCCESS,
  GET_LOG_FILE_ERROR,
  CREATE_INVITE_SUCCESS,
  CREATE_INVITE_ERROR,
  CLEAR_CREATE_INVITE_STATUS,
} from "./actionTypes";

export function getInvitesList(
  startSendDate,
  endSendDate,
  startRegDate,
  endRegDate,
  role,
  page,
  size,
  q
) {
  return async (dispatch) => {
    axios
      .get("/invites/", {
        params: {
          page,
          size,
          q,
          role: role === "all" ? null : role,
          created_at_start_date: startSendDate,
          created_at_end_date: endSendDate,
          user_registered_at_start_date: startRegDate,
          user_registered_at_end_date: endRegDate,
        },
      })
      .then((response) => {
        dispatch(getInvitesListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getInvitesListError());
        console.log(err);
      });
  };
}
export function createInvite(email) {
  return async (dispatch) => {
    let sendingData = {
      email: email,
    };
    axios
      .post("/invites/", sendingData)
      .then(() => {
        dispatch(createInviteSuccess());
      })
      .catch((err) => {
        dispatch(createInviteError());
        console.log(err);
      });
  };
}
export function getLogFile(
  startSendDate,
  endSendDate,
  startRegDate,
  endRegDate,
  role
) {
  return async (dispatch) => {
    axios
      .get("/invites/", {
        responseType: "arraybuffer",
        params: {
          role: role === "all" ? null : role,
          created_at_start_date: startSendDate,
          created_at_end_date: endSendDate,
          user_registered_at_start_date: startRegDate,
          user_registered_at_end_date: endRegDate,
          data_format: "excel",
        },
      })
      .then((response) => {
        dispatch(getLogFileSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getLogFileError());
        console.log(err);
      });
  };
}
export function createInviteSuccess() {
  return {
    type: CREATE_INVITE_SUCCESS,
    createInviteStatus: "SUCCESS",
  };
}

export function createInviteError() {
  return {
    type: CREATE_INVITE_ERROR,
    createInviteStatus: "ERROR",
  };
}

export function getInvitesListSuccess(data) {
  return {
    type: GET_INVITES_SUCCESS,
    data: data,
  };
}

export function getInvitesListError() {
  return {
    type: GET_INVITES_ERROR,
  };
}

export function getLogFileSuccess(data) {
  return {
    type: GET_LOG_FILE_SUCCESS,
    data: data,
  };
}

export function getLogFileError() {
  return {
    type: GET_LOG_FILE_ERROR,
  };
}

export function clearCreateInventStatus() {
  return {
    type: CLEAR_CREATE_INVITE_STATUS,
  };
}
