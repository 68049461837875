import React from 'react';

import classes from './TextArea.module.scss';

const TextArea = props => {
    const cls = [classes.TextArea];
    if (props.invalid) {
        cls.push(classes.invalid)
    }
    return (
        <div className={classes.wrapper}>   
            <textarea
                className={cls.join(" ")}
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
                style={props.style}
                disabled={props.disabled}
            />
            <div className={classes.label}>
                {props.label}
            </div>
            {props.invalid?
            <div className={classes.error_message}>
                {props.errorMessage}
            </div>
            :
            null
            }
            
        </div>
    )
}

export default TextArea