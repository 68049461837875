export const CHANGE_STATUS_SUCCESS = 'CHANGE_STATUS_SUCCESS';
export const CHANGE_STATUS_ERROR = 'CHANGE_STATUS_ERROR';
export const GET_CONSULTATIONS_SUCCESS = 'GET_CONSULTATIONS_SUCCESS';
export const GET_CONSULTATIONS_ERROR = 'GET_CONSULTATIONS_ERROR';
export const GET_SPECIALIZATIONS_SUCCESS = 'GET_SPECIALIZATIONS_SUCCESS';
export const GET_SPECIALIZATIONS_ERROR = 'GET_SPECIALIZATIONS_ERROR';
export const GET_CARD_INFO_SUCCESS = 'GET_CARD_INFO_SUCCESS';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const CREATE_ERROR = 'CREATE_ERROR';
export const CHANGE_PAGE_STATUS = 'CHANGE_PAGE_STATUS';
export const SET_SCROLL_STATUS = 'SET_SCROLL_STATUS';
export const GET_CONSULTATION_HISTORY_SUCCESS = 'GET_CONSULTATION_HISTORY_SUCCESS';