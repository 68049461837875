import {
  GET_SERVICES_SUCCESS,
  GET_SERVICES_ERROR,
  GET_LOG_FILE_SUCCESS,
  GET_LOG_FILE_ERROR,
  GET_SPECIALIZATIONS_SUCCESS,
  GET_SPECIALIZATIONS_ERROR,
  GET_PERFORMERS_SUCCESS,
  GET_PERFORMERS_ERROR,
  GET_CREATORS_SUCCESS,
  GET_CREATORS_ERROR,
  SET_SCROLL_STATUS,
} from "./actionTypes";

const initialState = {
  services: null,
  servicesTotal: 0,
  specializations: null,
  performers: null,
  creators: null,
  scrollStatus: false,
  currentPageStatus: "finished",
  needUpdate: false,
  created: false,
  historyTotal: 0,
  file: null,
};

export default function servicesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SERVICES_SUCCESS:
      return {
        ...state,
        services: action.data.items,
        servicesTotal: action.data.total,
        needUpdate: false,
        created: false,
      };
    case GET_SERVICES_ERROR:
      return {
        ...state,
        services: null,
      };
    case GET_LOG_FILE_SUCCESS:
      return {
        ...state,
        file: action.data,
      };
    case GET_LOG_FILE_ERROR:
      return {
        ...state,
        file: null,
      };
    case GET_SPECIALIZATIONS_SUCCESS:
      return {
        ...state,
        specializations: action.specializations,
      };
    case GET_SPECIALIZATIONS_ERROR:
      return {
        ...state,
        specializations: null,
      };
    case GET_PERFORMERS_SUCCESS:
      return {
        ...state,
        performers: action.performers,
      };
    case GET_PERFORMERS_ERROR:
      return {
        ...state,
        performers: null,
      };
    case GET_CREATORS_SUCCESS:
      return {
        ...state,
        creators: action.creators,
      };
    case GET_CREATORS_ERROR:
      return {
        ...state,
        creators: null,
      };
    case SET_SCROLL_STATUS:
      return {
        ...state,
        scrollStatus: action.newStatus,
      };
    default:
      return state;
  }
}
