export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const FIRST_REG = "FIRST_REG";
export const REG_ERROR = "REG_ERROR";
export const CONFIRM_SUCCESS = "CONFIRM_SUCCESS";
export const CONFIRM_ERROR = "CONFIRM_ERROR";
export const CONFIRM_CLEAR = "CONFIRM_CLEAR";
export const CREATE_SUCCESS = "CREATE_SUCCESS";
export const CREATE_ERROR = "CREATE_ERROR";
export const GET_CONSULTATIONS_SUCCESS = "GET_CONSULTATIONS_SUCCESS";
export const GET_CONSULTATIONS_ERROR = "GET_CONSULTATIONS_ERROR";
export const GET_SPECIALIZATIONS_SUCCESS = "GET_SPECIALIZATIONS_SUCCESS";
export const GET_SPECIALIZATIONS_ERROR = "GET_SPECIALIZATIONS_ERROR";
export const ADD_FILE_SUCCESS = "ADD_FILE_SUCCESS";
export const REMOVE_FILE = "REMOVE_FILE";
export const CLEAR_FILE_LIST = "CLEAR_FILE_LIST";
export const GET_CARD_INFO_SUCCESS = "GET_CARD_INFO_SUCCESS";
export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS";
export const CREATE_PROFILE_ERROR = "CREATE_PROFILE_ERROR";
export const ACCEPT_PATIENT_INVITATION_SUCCESS =
  "ACCEPT_PATIENT_INVITATION_SUCCESS";
export const ACCEPT_PATIENT_INVITATION_ERROR =
  "ACCEPT_PATIENT_INVITATION_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOAD_PICTURE_SUCCESS = "LOAD_PICTURE_SUCCESS";
export const REMOVE_PICTURE = "REMOVE_PICTURE";
export const GET_DOCTORS_SUCCESS = "GET_DOCTORS_SUCCESS";
export const GET_DOCTORS_ERROR = "GET_DOCTORS_ERROR";
export const SHOW_TIP_MODAL = "SHOW_TIP_MODAL";
export const CLOSE_TIP_MODAL = "CLOSE_TIP_MODAL";
export const COLLAPSE_SIDEBAR = "COLLAPSE_SIDEBAR";
