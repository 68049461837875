import axios from "axios";

import {
  GET_ASSISTANTS_SUCCESS,
  GET_ASSISTANTS_ERROR,
  CREATE_ASSISTANT_ERROR,
  CREATE_ASSISTANT_SUCCESS,
  CLEAR_CREATE_ASSISTANT_STATUS,
  EDIT_ASSISTANT_SUCCESS,
  EDIT_ASSISTANT_ERROR,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_ERROR,
  CLEAR_EDIT_ASSISTANT_STATUS,
  GET_ASSISTANTBYID_SUCCESS,
  GET_ASSISTANTBYID_ERROR,
  LOAD_PICTURE_SUCCESS,
  LOAD_PICTURE_ERROR,
  REMOVE_PICTURE,
  CHANGE_DELETED_STATUS_SUCCESS,
  CHANGE_DELETED_STATUS_ERROR,
  CLEAR_DELETE_ASSISTANT_STATUS,
} from "./actionTypes";

export function getAssistantsList(page, size, q) {
  return async (dispatch) => {
    axios
      .get("/users/", {
        params: {
          role: "assistant",
          page,
          size,
          q,
        },
      })
      .then((response) => {
        dispatch(getAssistantsListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAssistantsListError());
        console.log(err);
      });
  };
}

export function createAssistant(data) {
  return async (dispatch) => {
    let sendingData = {
      email: data.email,
      password: data.password,
      first_name: data.first_name,
      second_name: data.second_name,
      patronymic_name: data.patronymic_name,
      gender: data.gender,
      date_of_birth: data.date_of_birth,
      registration_address: data.registration_address,
      real_address: data.real_address,
      userpic_url: data.userpic_url,
    };
    axios
      .post("/users/invite_assistant", sendingData)
      .then(() => {
        dispatch(createAssistantSuccess());
      })
      .catch((err) => {
        dispatch(createAssistantError());
        console.log(err);
      });
  };
}
export function getAssistantByID(id) {
  return async (dispatch) => {
    axios
      .get(`/profiles/${id}`)
      .then((response) => {
        dispatch(getAssistantByIDSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getAssistantByIDError());
        console.log(err);
      });
  };
}

export function changeUserStatus(userId, data) {
  return async (dispatch) => {
    axios
      .post(`/users/${userId}/change_status`, data)
      .then(() => {
        dispatch(changeUserStatusSuccess());
        dispatch(getAssistantByID(userId));
      })
      .catch((err) => {
        dispatch(changeUserStatusError());
        console.log(err);
      });
  };
}

export function changeUserDeletedStatus(userId, data) {
  return async (dispatch) => {
    axios
      .post(`/users/${userId}/change_deleted_status`, data)
      .then(() => {
        dispatch(changeUserStatusSuccess());
        dispatch(getAssistantByID(userId));
      })
      .catch((err) => {
        dispatch(changeUserDeletedStatusError(err.response.data.detail));
        console.log(err);
      });
  };
}

export function editAssistant(data) {
  return async (dispatch) => {
    let sendingData = {
      first_name: data.first_name,
      second_name: data.second_name,
      patronymic_name: data.patronymic_name,
      gender: data.gender,
      date_of_birth: data.date_of_birth,
      registration_address: data.registration_address,
      real_address: data.real_address,
      userpic_url: data.userpic_url,
      organization_name: data.organization_name,
      organization_address: data.organization_address,
      organization_phone: data.organization_phone,
      organization_email: data.organization_email,
      organization_bik: data.organization_bik,
      organization_account: data.organization_account,
      organization_agreement_number: data.organization_agreement_number,
      organization_agreement_date: data.organization_agreement_date,
    };
    axios
      .patch(`/profiles/${data.id}`, sendingData)
      .then(() => {
        dispatch(editAssistantSuccess());
        dispatch(getAssistantByID(data.id));
      })
      .catch((err) => {
        dispatch(editAssistantError());
        console.log(err);
      });
  };
}

export function sendNewPassword(data) {
  return async (dispatch) => {
    let sendingData = {
      new_password1: data.password,
      new_password2: data.repeatPassword,
    };
    axios
      .post(`/users/${data.id}/change_password`, sendingData)
      .then(() => {
        dispatch(editAssistantSuccess());
        dispatch(getAssistantByID(data.id));
      })
      .catch((err) => {
        dispatch(editAssistantError());
        console.log(err);
      });
  };
}
export function loadPicture(file) {
  return async (dispatch) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: "/profiles/upload_photo",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        dispatch(loadPictureSuccess(response.data.url));
      })
      .catch(function (error) {
        dispatch(loadPictureError());
        console.log(error);
      });
  };
}

export function removePicture() {
  return {
    type: REMOVE_PICTURE,
  };
}

export function loadPictureSuccess(url) {
  return {
    type: LOAD_PICTURE_SUCCESS,
    url,
  };
}

export function loadPictureError() {
  return {
    type: LOAD_PICTURE_ERROR,
  };
}

export function getAssistantsListSuccess(data) {
  return {
    type: GET_ASSISTANTS_SUCCESS,
    data,
  };
}

export function getAssistantsListError() {
  return {
    type: GET_ASSISTANTS_ERROR,
  };
}

export function createAssistantSuccess() {
  return {
    type: CREATE_ASSISTANT_SUCCESS,
    createAssistantStatus: "SUCCESS",
  };
}

export function createAssistantError() {
  return {
    type: CREATE_ASSISTANT_ERROR,
    createAssistantStatus: "ERROR",
  };
}

export function editAssistantSuccess() {
  return {
    type: EDIT_ASSISTANT_SUCCESS,
    editAssistantStatus: "SUCCESS",
  };
}

export function editAssistantError() {
  return {
    type: EDIT_ASSISTANT_ERROR,
    editAssistantStatus: "ERROR",
  };
}

export function changeUserStatusSuccess() {
  return {
    type: CHANGE_STATUS_SUCCESS,
  };
}

export function changeUserStatusError() {
  return {
    type: CHANGE_STATUS_ERROR,
  };
}

export function changeUserDeletedStatusSuccess() {
  return {
    type: CHANGE_DELETED_STATUS_SUCCESS,
  };
}

export function changeUserDeletedStatusError(error) {
  return {
    type: CHANGE_DELETED_STATUS_ERROR,
    error,
  };
}
export function getAssistantByIDSuccess(editedSupport) {
  return {
    type: GET_ASSISTANTBYID_SUCCESS,
    editedAssistant: editedSupport,
  };
}

export function getAssistantByIDError() {
  return {
    type: GET_ASSISTANTBYID_ERROR,
  };
}
export function clearCreateAssistantStatus() {
  return {
    type: CLEAR_CREATE_ASSISTANT_STATUS,
  };
}
export function clearEditAssistantStatus() {
  return {
    type: CLEAR_EDIT_ASSISTANT_STATUS,
  };
}
export function clearDeleteAssistantStatus() {
  return {
    type: CLEAR_DELETE_ASSISTANT_STATUS,
  };
}
