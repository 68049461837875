import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Scroller from 'hoc/CustomScrollbar/Scroller.js';
import Footer from 'components/Footer/Footer';
import Logo from 'components/Logo/Logo';
import Button from 'components/UI/Button/Button';
import TestNavigation from './TestNavigation/TestNavigation';
import TestSection from './TestSection/TestSection';
import BackLink from 'components/UI/BackLink/BackLink';
import Back from 'components/UI/Back/Back';

import { sendResults } from 'store/Profile/Tests/actions';
import classes from './Test.module.scss';

import test from 'tests/test3.json';

// тест3 состоит из анкет (questionnaire), анкеты из секций (section), секции из вопросов.

class Test3 extends Component {
    state = {
        isStarted: false,
        isFinished: false,
        activeSection: 0,
        activeQuestionnaire: 0,
        userAnswers: {},
    }

    startTestHandler = () => {
        this.setState({
            isStarted: true,
            activeQuestionnaire: 0,
            activeSection: 0,
        });
    }

    finishTestHandler = () => {

        let results = {     // Пустой объект результатов для отправки
            test_type: "test3",
            data: [],
        }

        // разворачиваем объект с ответами в одномерный массив
        const questionnaires = Object.values(this.state.userAnswers);
        const sections = [].concat(...questionnaires.map(section => Object.values(section)));
        const answers = [].concat(...sections.map(answer => Object.values(answer)));

        for (let i = 0; i < answers.length; i++) {

            results.data.push({
                question_number: i,
                answer_number: answers[i].answerId,
                users_input: answers[i].usersInput === "" ? null : answers[i].usersInput, // если input пуст, отправить null
                selected_answers: answers[i].selectedAnswers[0] !== null ? answers[i].selectedAnswers : null, // если список ответов содежрит null значит он пуст, в таком случае отправить null
                question_text: answers[i].question_text || null,
                answer_text: answers[i].answer_text || null
            })
        };

        this.props.sendResults(results);

        this.setState({
            isFinished: true,
        });
    }

    finishQuestionnaireHandler = () => {
        // текущая анкета не последняя?
        if (this.state.activeQuestionnaire < test.testData.length - 1) {
            this.setState({
                activeQuestionnaire: this.state.activeQuestionnaire + 1,
                activeSection: 0,
            });
        } else {
            this.finishTestHandler();
        }
        window.scrollTo(0, 0);
    }

    // questionNumber - номер вопроса (начинается с 1 и соответствует отображаемому номеру)
    // answerId - id ответа (начинается с 0, может не совпадать с номером ответа в вопросе)
    questionChangeHandler = (questionNumber, answerId, usersInput, checkboxValue) => {

        // userAnswers = { [questionnaireId]{ [sectionId]:{ [questionNumber]:{ [answerId]:answerId} } } }
        let userAnswers = { ...this.state.userAnswers };

        let questionnaire = { ...userAnswers[this.state.activeQuestionnaire] } || {}; // получить анкету

        let section = { ...questionnaire[this.state.activeSection] } || null; // получить раздел

        if (section[questionNumber]) { //если пользователь уже отвечал на этот вопрос: изменить

            let answer = { ...section[questionNumber] };  // предыдущее значение ответа

            answer.question_text = test.testData[this.state.activeQuestionnaire].sections[this.state.activeSection].questions[questionNumber - 1].question;

            let isEmpty = true;

            if (usersInput !== null) {  // если ввод пользовательского значения
                answer.usersInput = usersInput;
                isEmpty = usersInput === "";
            } else if (answerId !== null) {  // если ответ на вопрос с единственным ответом
                answer.answerId = answerId;
                answer.answer_text = test.testData[this.state.activeQuestionnaire].sections[this.state.activeSection].questions[questionNumber - 1].answers[answerId];
                isEmpty = answerId === null;
            } else { // если ответ на вопрос с множественным ответом: заменить/добавить значение
                let selectedAnswers = [...answer.selectedAnswers];

                const index = selectedAnswers.indexOf(checkboxValue);

                if (index > -1) {
                    selectedAnswers.splice(index, 1);
                } else {
                    selectedAnswers.push(checkboxValue);
                    selectedAnswers.sort();
                }

                isEmpty = selectedAnswers.length === 0;

                answer.selectedAnswers = selectedAnswers;
            }

            if (isEmpty) { //Если ответ пуст - удалить, иначе изменить
                delete section[questionNumber];
            } else {
                section[questionNumber] = answer;
            }

        } else {  //иначе: создать
            section[questionNumber] = {
                answerId,
                usersInput,
                selectedAnswers: [checkboxValue],
                answer_text: test.testData[this.state.activeQuestionnaire].sections[this.state.activeSection].questions[questionNumber - 1].answers[answerId],
                question_text: test.testData[this.state.activeQuestionnaire].sections[this.state.activeSection].questions[questionNumber - 1].question,
            }; // добавить ответ на вопрос
        }

        questionnaire[this.state.activeSection] = section;

        userAnswers[this.state.activeQuestionnaire] = questionnaire;

        this.setState({ userAnswers });
    }

    changePageHandler = next => {

        if (next) { // вперед
            this.setState({
                activeSection: this.state.activeSection + 1
            })
        } else { // назад

            if (this.state.activeQuestionnaire === 0) { // Первая анкета

                if (this.state.activeSection === 0) { // первая страница анкеты
                    this.setState({
                        isStarted: false,
                        isFinished: false,
                        userAnswers: {},
                    })
                } else {  // остальные страницы
                    this.setState({
                        activeSection: this.state.activeSection - 1,
                    })
                }

            } else if (this.state.isFinished) {  // тест закончен
                this.setState({
                    isFinished: false,
                })
            } else { // Остальные анкеты

                if (this.state.activeSection === 0) { // первая страница анкеты
                    this.setState({  // возврат к последней странице предыдущей анкеты
                        activeQuestionnaire: this.state.activeQuestionnaire - 1,
                        activeSection: test.testData[this.state.activeQuestionnaire - 1].sections.length - 1
                    })
                } else {
                    this.setState({
                        activeSection: this.state.activeSection - 1,
                    })
                }
            }
        }
        window.scrollTo(0, 0);
    }

    renderIntro = () => {
        return (
            <div className={classes.intro}>
                <div className={classes.amount}></div>
                <p>
                    После заполнения опросника функциональных нарушений нам нужно выяснить, по какой причине эти нарушения возникли. Помочь в поиске ответа на этот вопрос призван данный опросник. Предлагаем Вам заполнить несколько анкет, касающихся следующих факторов образа жизни:
                </p>
                <ul className={classes.list}>
                    <li>1) Наличие навыков поддержания здорового образа жизни</li>
                    <li>2) Питание</li>
                    <li>3) Стрессы</li>
                    <li>4) Сон</li>
                    <li>5) Движение</li>
                    <li>6) Токсины (вредные вещества, поступающие в организм)</li>
                </ul>
                <p>
                    Общее время заполнения опросника - в пределах 20 минут. Для сравнения: чтобы собрать сходный объём информации, на приёме у врача вы потратите не менее 30-40 минут.
                </p>
                <div className={classes.start_button}>
                    <Button
                        type={"primary"}
                        onClick={() => this.startTestHandler()}
                    >Начать тестирование</Button>
                </div>
            </div>
        )
    }

    renderFinishPage = () => {
        return (
            <div className={classes.finish}>
                <p>
                    Спасибо за ваши ответы!
                </p>
                <p>
                    Результаты тестирования и направление на консультацию отобразятся в вашем <strong>личном кабинете</strong> в разделе <strong>Здоровье</strong>
                </p>
                <div className={classes.finish_button}>
                    <Link to={"/profile/health"}>
                        <Button
                            type={"primary"}
                        >Вернуться в личный кабинет</Button>
                    </Link>
                </div>
            </div>
        )
    }

    renderTest = () => {
        // Массив имен разделов текущей анкеты (для навигации)
        const questionnaireSections = test.testData[this.state.activeQuestionnaire].sections.map(section => section.section_name);

        // Формирование блока вопросов для текущей страницы
        const testSectionList = [...test.testData[this.state.activeQuestionnaire].sections[this.state.activeSection].questions];

        // ответы пользователя для текущей анкеты
        const questionnaireUserAnswers = { ...this.state.userAnswers[this.state.activeQuestionnaire] };
        // ответы пользователя для текущего раздела
        const sectionUserAnswers = { ...questionnaireUserAnswers[this.state.activeSection] };

        // Номер первого вопроса на странице
        const firstQuestionNumber = 1;

        // Массив с колличеством вопросов в каждом разделе текущей анкеты
        const questionnaireQuestionsAmount = test.testData[this.state.activeQuestionnaire].sections.map(section => section.questions.length);


        return (
            <React.Fragment>
                <div className={classes.questionnaire}>

                    {/* Номер текщей анкеты, отображаемой на странице*/}
                    <div className={classes.questionnaire_number}>
                        Анкета&nbsp;{this.state.activeQuestionnaire + 1}&nbsp;из&nbsp;{test.testData.length}
                    </div>

                    {/* Название текщей анкеты*/}
                    <div className={classes.questionnaire_name}>
                        {test.testData[this.state.activeQuestionnaire].questionnaire_name}
                    </div>

                    {/* Описание текщей анкеты*/}
                    <div className={classes.questionnaire_info}>
                        {test.testData[this.state.activeQuestionnaire].questionnaire_info}

                        {/* Список текщей анкеты (если он описан)*/}
                        {test.testData[this.state.activeQuestionnaire].questionnaire_list ?
                            <ul className={classes.list}>
                                {
                                    test.testData[this.state.activeQuestionnaire].questionnaire_list.map(item => <li>{item}</li>)
                                }
                            </ul>
                            : null
                        }
                    </div>
                </div>

                {questionnaireSections.length > 1 ? //Если в анкете больше одной страницы
                    <TestNavigation sections={questionnaireSections} activeSection={this.state.activeSection} />
                    :
                    null
                }
                < TestSection
                    userAnswers={sectionUserAnswers || {}}
                    firstQuestionNumber={firstQuestionNumber}
                    questionList={testSectionList}
                    onChange={this.questionChangeHandler}
                />

                {this.state.activeSection === questionnaireSections.length - 1 ?  // Последняя страница анкеты?
                    <div className={classes.button}>
                        <Button
                            type={"primary"}
                            onClick={() => this.finishQuestionnaireHandler()}
                            disabled={Object.keys(sectionUserAnswers).length < questionnaireQuestionsAmount[this.state.activeSection]}
                        >
                            {this.state.activeQuestionnaire === test.testData.length - 1 ? // последняя анкета?
                                "Завершить тест"
                                :
                                "Следующая анкета"
                            }
                        </Button>
                    </div>
                    :
                    <div className={classes.button}>
                        <Button
                            type={"primary"}
                            onClick={() => this.changePageHandler(true)}
                            disabled={Object.keys(sectionUserAnswers).length < questionnaireQuestionsAmount[this.state.activeSection]}
                        >Далее
                        </Button>
                    </div>
                }
            </React.Fragment>
        )
    }

    render() {
        if (localStorage.getItem("role") !== "patient") return <Redirect to="/profile/"/>;
        return (
            <section className={classes.Test}>
                <Scroller page={this.state.activeSection}/>
                <Helmet>
                    <title>ОФОЖ. Портал здоровья</title>
                    <meta name="title" content="ОФОЖ. Портал здоровья" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="ОФОЖ. Портал здоровья" />
                </Helmet>
                <Logo />
                <div className={classes.container}>
                    <h3 className={classes.title}>
                        {this.state.isStarted ? // тест начат? кнопка "назад" : ссылка "назад"
                            <Back
                                className={classes.back}
                                onClick={() => this.changePageHandler(false)}
                            />
                            :
                            <BackLink to={"/profile/health"} />
                        }
                    Опросник факторов образа жизни</h3>
                    {this.state.isStarted ?
                        this.state.isFinished ?
                            this.renderFinishPage()  // тест начат и закончен
                            :
                            this.renderTest() // тест начат, но не закончен
                        :
                        this.renderIntro()  // тест не начат
                    }
                </div>
                <Footer />
            </section>
        )
    }
}


function mapDispatchToProps(dispatch) {
    return {
        sendResults: (results) => dispatch(sendResults(results)),
    }
}

export default connect(null, mapDispatchToProps)(Test3);