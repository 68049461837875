// конвертирует дату в формате гггг-мм-дд в формат дд.мм.гггг
export function convertDateForDev(date) {
  let dateSplitted = date.split("-");
  let day = dateSplitted[2];
  let month = dateSplitted[1];
  let year = dateSplitted[0];
  let result = [day, month, year].join(".");
  return result;
}
// конвертирует дату в формате дд.мм.гггг в формат гггг-мм-дд
export function convertDateForServer(date) {
  let dateSplitted = date.split(".");
  let day = dateSplitted[0];
  let month = dateSplitted[1];
  let year = dateSplitted[2];
  let result = [year, month, day].join("-");
  return result;
}

// конвертирует дату c типом Date в формат дд.мм.гггг
export function convertDateToString(date) {
  if (!date) return null;
  let day =
    date.getDate() < 10 ? "0" + date.getDate() : date.getDate().toString();
  let month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : (date.getMonth() + 1).toString();
  let year = date.getFullYear();
  let result = [day, month, year].join(".");
  return result;
}

// конвертирует строку формата дд.мм.гггг в объект Date
export function convertStringToDate(string) {
  if (!string) return null;
  let stringSplitted = string.split(".");
  let day = stringSplitted[0];
  let month = stringSplitted[1] - 1;
  let year = stringSplitted[2];
  let result = new Date(year, month, day);
  return result;
}
