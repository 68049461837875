// Для получения элементов из инпута, введенных через запятую

//  перевод массива в текст
export function convertPointsToInput(inputArray) {
  return inputArray.join(", ");
}
// перевод текста в массив
export function convertInputToPoints(inputText) {
  let rowInput = inputText.split(",").map((word) => {
    return word.trim().charAt(0).toUpperCase() + word.trim().slice(1);
  });
  // убираем пустые элементы, если таковые есть
  return rowInput.filter((item) => {
    return item.length !== 0;
  });
}
