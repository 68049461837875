export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";
export const GET_ACCOUNT_ERROR = "GET_ACCOUNT_ERROR";
export const EDIT_ACCOUNT_SUCCESS = "EDIT_ACCOUNT_SUCCESS";
export const EDIT_ACCOUNT_ERROR = "EDIT_ACCOUNT_ERROR";
export const ADD_REPRESENTATIVE_SUCCESS = "ADD_REPRESENTATIVE_SUCCESS";
export const ADD_REPRESENTATIVE_ERROR = "ADD_REPRESENTATIVE_ERROR";
export const GET_REPRESENTATIVES_SUCCESS = "GET_REPRESENTATIVES_SUCCESS";
export const GET_REPRESENTATIVES_ERROR = "GET_REPRESENTATIVES_ERROR";
export const DELETE_REPRESENTATIVE_SUCCESS = "DELETE_REPRESENTATIVE_SUCCESS";
export const LOAD_PICTURE_SUCCESS = "LOAD_PICTURE_SUCCESS";
export const LOAD_PICTURE_ERROR = "LOAD_PICTURE_ERROR";
export const REMOVE_PICTURE = "REMOVE_PICTURE";
export const GET_HEALTH_STATUS_SUCCESS = "GET_HEALTH_STATUS_SUCCESS";
export const GET_HEALTH_STATUS_ERROR = "GET_HEALTH_STATUS_ERROR";
export const GET_MY_ACCOUNT_SUCCESS = "GET_MY_ACCOUNT_SUCCESS";
export const CLEAR_ACCOUNT_STATUS = "CLEAR_ACCOUNT_STATUS";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_ERROR = "CHANGE_STATUS_ERROR";
export const CHANGE_DELETED_STATUS_SUCCESS = "CHANGE_DELETED_STATUS_SUCCESS";
export const CHANGE_DELETED_STATUS_ERROR = "CHANGE_DELETED_STATUS_ERROR";
export const CLEAR_ACCOUNT_DELETED_STATUS = "CLEAR_ACCOUNT_DELETED_STATUS";
