import { combineReducers } from "redux";
import authReducer from "../Auth/reducer";
import profileReducer from "./profile";
import medcardReducer from "../Profile/Medcard/reducer";
import newDocumentReducer from "../Profile/NewDocument/reducer";
import healthReducer from "../Profile/Health/reducer";
import testsReducer from "../Profile/Tests/reducer";
import accountReducer from "../Profile/Account/reducer";
import faqReducer from "../Profile/Faq/reducer";
import articlesReducer from "../Profile/Articles/reducer";
import supportsReducer from "../Profile/Supports/reducer";
import doctorsReducer from "../Profile/Doctors/reducer";
import assistantsReducer from "../Profile/Assistants/reducer";
import providersReducer from "../Profile/Providers/reducer";
import patientsReducer from "../Profile/Patients/reducer";
import servicesReducer from "../Profile/ServicesLog/reducer";
import consultationsReducer from "../Profile/Consultations/reducer";
import journalReducer from "../Profile/Journal/reducer";
import feedbackReducer from "../Profile/Feedback/reducer";
import invitesReducer from "../Profile/Invites/reducer";
import consultationsLogReducer from "../Profile/ConsultationsLog/reducer";
import summaryReducer from "../Profile/Summary/reducer";

export default combineReducers({
  auth: authReducer,
  profile: profileReducer,
  medcard: medcardReducer,
  newDocument: newDocumentReducer,
  health: healthReducer,
  tests: testsReducer,
  account: accountReducer,
  faq: faqReducer,
  articles: articlesReducer,
  supports: supportsReducer,
  doctors: doctorsReducer,
  assistants: assistantsReducer,
  providers: providersReducer,
  patients: patientsReducer,
  consultations: consultationsReducer,
  services: servicesReducer,
  journal: journalReducer,
  feedback: feedbackReducer,
  invites: invitesReducer,
  consultationsLog: consultationsLogReducer,
  summary: summaryReducer,
});
