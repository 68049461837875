import axios from "axios";

import {
  GET_SUMMARY_SUCCESS,
  GET_SUMMARY_ERROR,
  GET_ALL_SUMMARY_SUCCESS,
  GET_ALL_SUMMARY_ERROR,
  SET_LOADING,
} from "./actionTypes";

export function getSummary(type) {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    axios
      .get("/summary/", {
        params: {
          summary_type: type,
        },
      })
      .then((response) => {
        dispatch(getSummarySuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getSummaryError());
      })
      .finally(() => dispatch({ type: SET_LOADING, payload: false }));
  };
}

export function getAllSummary(types) {
  return async (dispatch) => {
    const fetchType = (type) =>
      axios
        .get("/summary/", {
          params: {
            summary_type: type,
          },
        })
        .then((res) => res.data);

    Promise.all(types.map(fetchType))
      .then((responce) => {
        dispatch(getAllSummarySuccess(responce));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getAllSummaryError());
      });

    // axios
    //   .get("/summary/", {
    //     params: {
    //       summary_type: type,
    //     },
    //   })
    //   .then((response) => {
    //     dispatch(getSummarySuccess(response.data));
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     dispatch(getSummaryError());
    //   });
  };
}

export function getSummarySuccess(data) {
  return {
    type: GET_SUMMARY_SUCCESS,
    payload: data,
  };
}

export function getSummaryError() {
  return {
    type: GET_SUMMARY_ERROR,
  };
}

export function getAllSummarySuccess(data) {
  return {
    type: GET_ALL_SUMMARY_SUCCESS,
    payload: data,
  };
}

export function getAllSummaryError() {
  return {
    type: GET_ALL_SUMMARY_ERROR,
  };
}
