import React from 'react';
import { useHistory } from 'react-router-dom'

import HealthStatus from 'components/UI/HealthStatus/HealthStatus';
import { getFormatDate, getTimeFromDate } from 'components/Profile/CardList/enums'

import classes from './InviteRow.module.scss';


const InviteRow = props => {
    const urlBase = '/profile/patients/patient/';

    const history = useHistory();

    return (
        <tr className={classes.InviteRow} onClick={() => {
            if (props.user_id) {
                if (props.inNewWindow === true) {
                    window.open(urlBase + props.user_id)
                } else {
                    history.push(urlBase + props.user_id)
                }
            }
        }}>
            <td>
                {props.email}
            </td>
            <td>
                {props.sendDate ?
                    `${getFormatDate(props.sendDate)} ${getTimeFromDate(props.sendDate)}` : null
                }
            </td>
            <td>
                {props.registeredDate ?
                    `${getFormatDate(props.registeredDate)} ${getTimeFromDate(props.registeredDate)}` : null
                }
            </td>
            <td>
                {props.name}
            </td>
        </tr>
    )
}


export default InviteRow