import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Logo from 'components/Logo/Logo';

import classes from './InviteSignUp.module.scss';
import { clearErrors } from 'store/actions/profile';
import { getAccountInfo } from 'store/Profile/Account/actions';
import PatientInvite from './PatientInvite/PatientInvite';

class InviteSignUp extends Component {
    state = {
        selectedRole: null,
    }

    onRoleClickHandler = (role) => {
        this.setState({ selectedRole: role });
    }

    resetHandler = () => {
        this.props.clearErrors();
        this.setState({ selectedRole: null });
    }

    componentDidMount() {
        this.props.getAccountInfo();
    }

    render() {
        if (this.props.isProfileCreated || (this.props.role && this.props.role !== "null")) {
            localStorage.setItem('role', this.props.role);
            return <Redirect to="/profile/" />
        }
        return (
            <section className={classes.InviteSignUp}>
                <Helmet>
                    <title>Создание профиля. Портал здоровья</title>
                    <meta name="title" content="Создание профиля. Портал здоровья" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="Создание профиля. Портал здоровья" />
                </Helmet>
                <Logo />
                <PatientInvite
                    getAccountInfo={this.props.getAccountInfo}
                    role={this.state.selectedRole}
                    reset={this.resetHandler}
                />
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        role: state.profile.role,
        isProfileCreated: state.profile.isProfileCreated
    }
}

function mapDispatchToProps(dispatch) {
    return {
        clearErrors: () => dispatch(clearErrors()),
        getAccountInfo: () => dispatch(getAccountInfo())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InviteSignUp))