import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/ru";
import { Redirect } from "react-router-dom";

import Button from "components/UI/Button/Button";
import BackLink from "components/UI/BackLink/BackLink";
import Input from "components/UI/Input/Input";

import classes from "./NewArticle.module.scss";
import {
  createArticle,
  loadPicture,
  removePicture,
} from "store/Profile/Articles/actions";

const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "indent",
    "outdent",
    "|",
    "insertTable",
  ],
  language: "ru",
  placeholder: "Текст статьи",
};

class NewArticle extends Component {
  state = {
    article_title: "",
    article_desc: "",
  };
  addImageHadler = (event) => {
    this.props.loadPicture(event.target.files[0]);
  };

  removeImageHandler = () => {
    this.props.removePicture();
  };
  inputChangeHandler = (event, targetName) => {
    let formControl = { ...this.state };
    formControl[targetName] = event.target.value;
    this.setState({ ...formControl });
  };

  sendFormHandler = () => {
    this.props.createArticle(
      this.state.article_title,
      this.state.article_desc,
      this.props.article_image
    );
    if (this.props.createArticleStatus === "SUCCESS") {
      const newState = { ...this.state };
      newState.article_title = "";
      newState.article_desc = "Текст статьи";
      this.setState({ ...newState });
    }
  };

  render() {
    return (
      <section className={classes.NewArticle}>
        <Helmet>
          <title>Новая статья. Портал здоровья</title>
          <meta name="title" content="Новая статья. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Новая статья. Портал здоровья" />
        </Helmet>
        <div className={classes.back}>
          <BackLink to={"/profile/articles"} />
          <span>Назад</span>
        </div>

        <form
          className={classes.form}
          onSubmit={(event) => event.preventDefault()}
        >
          <h4 className={classes.title}>Создание новой статьи</h4>
          <div className={classes.input_wrapper}>
            <Input
              value={this.state.article_title}
              placeholder={"Заголовок статьи"}
              valid={false}
              touched={false}
              label={"Заголовок статьи"}
              shouldValidate={false}
              errorMessage={""}
              onChange={(event) =>
                this.inputChangeHandler(event, "article_title")
              }
            ></Input>
          </div>
          {/* Текст статьи (редактор) */}
          <CKEditor
            editor={ClassicEditor}
            data={this.state.article_desc}
            onChange={(event, editor) => {
              const data = editor.getData();
              this.setState({ article_desc: data });
            }}
            onReady={(editor) => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  "min-height",
                  "800px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            config={editorConfiguration}
          />
          {/* загрузка фото */}
          <div
            className={classes.picture_loader}
            style={{ backgroundImage: `url(${this.props.article_image})` }}
          >
            <input
              className={classes.picture_input}
              type={"file"}
              onChange={(event) => this.addImageHadler(event)}
            />
            {this.props.article_image ? (
              <div
                className={classes.remove_btn}
                onClick={() => this.removeImageHandler()}
              >
                <img src={"/profile/images/cross.svg"} alt={"remove"} />
              </div>
            ) : (
              <img src={"/profile/images/plus.svg"} alt={"add"} />
            )}
          </div>
          <div className={classes.btn_submit}>
            <Button
              disabled={
                !(
                  this.state.article_title !== "" &&
                  this.state.article_desc !== "" &&
                  this.props.article_image !== null
                )
              }
              onClick={() => this.sendFormHandler()}
              type={"primary"}
              needTip={true}
            >
              Добавить статью
            </Button>
          </div>

          {/* сообщение об ошибке */}
          {this.props.createArticleStatus !== "" ? (
            <div className={classes.dataFetchStatus}>
              {this.props.createArticleStatus === "ERROR" ? (
                <div className={classes.error_status}>
                  Не удалось добавить статью
                </div>
              ) : (
                <Redirect to={"/profile/articles"} />
              )}
            </div>
          ) : null}
        </form>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    createArticleStatus: state.articles.createArticleStatus,
    article_image: state.articles.article_image,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    createArticle: (article_title, article_desc, article_image) =>
      dispatch(createArticle(article_title, article_desc, article_image)),
    loadPicture: (file) => dispatch(loadPicture(file)),
    removePicture: () => dispatch(removePicture()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewArticle);
