import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import Navigation from "components/UI/Navigation/Navigation";
import UserTable from 'components/Profile/UserTable/UserTable';
import Search from 'components/UI/Search/Search';
import EmptySection from "components/Profile/EmptySection/EmptySection";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";

import classes from './MyPatients.module.scss';
import { getPatientsList } from "store/Profile/Patients/actions";


const MyPatients = props => {
    const PAGE_SIZE = 50;

    // Страницы
    const [activePage, setActivePage] = React.useState(1);
    const [pagesAmount, setPagesAmount] = React.useState();
    // Поиск
    const [searchValue, setSearchValue] = React.useState(null);
    const [prevSearch, setPrevSearch] = React.useState(null);
    const [isSearching, setIsSearching] = React.useState(false);


    React.useEffect(() => {
        const pagesAmount = Math.trunc(props.total / PAGE_SIZE) + (props.total % PAGE_SIZE ? 1 : 0);
        setPagesAmount(pagesAmount);
    }, [props.total]);

    React.useEffect(() => {
        props.getPatientsList(activePage - 1, PAGE_SIZE, isSearching ? searchValue : null)
    }, [activePage])

    function changePageHandler(pages, jumpTo) {
        if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
            return;
        } else {
            setActivePage(jumpTo);
        }
    };

    function onSearchHandler() {
        if (prevSearch === searchValue || !searchValue) return null;

        setIsSearching(true);
        setPrevSearch(searchValue);

        if (activePage > 1) {
            setActivePage(1); // При смене страницы автоматически отправится запрос
        } else {
            props.getPatientsList(0, PAGE_SIZE, searchValue);
        }
    };

    function onSearchResetHandler() {
        setIsSearching(false);
        setPrevSearch(null);
        setSearchValue(null);

        if (activePage > 1) {
            setActivePage(1); // При смене страницы автоматически отправится запрос
        } else {
            props.getPatientsList(0, PAGE_SIZE, null);
        }
    };

    return (
        <section className={classes.MyPatients}>
            <Helmet>
                <title>Мои пациенты. Портал здоровья</title>
                <meta name="title" content="Мои пациенты. Портал здоровья" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content="Мои пациенты. Портал здоровья" />
            </Helmet>
            <h2 className={classes.title}>Мои пациенты</h2>
            <div className={classes.search_wrapper}>
                <Search
                    value={searchValue}
                    onChange={(value) => setSearchValue(value === "" ? null : value)}
                    onSearch={() => onSearchHandler()}
                    total={props.total}
                    showReset={isSearching}
                    onReset={onSearchResetHandler}
                />
            </div>
            {
                props.patientsList ?
                    props.patientsList.length !== 0 ?
                        <React.Fragment>
                            <div className={classes.table_wrapper}>
                                <UserTable
                                    userList={props.patientsList}
                                    withHealthStatus={true}
                                    inNewWindow={true}
                                />
                            </div>
                            {pagesAmount > 1 ? (
                                <Navigation
                                    activePage={activePage}
                                    pages={pagesAmount}
                                    onChange={changePageHandler}
                                />
                            ) : null}
                        </React.Fragment>
                        :
                        <EmptySection
                            title={"Пока нет пациентов"}
                            text={"Список пациентов пуст"}
                        />
                    :
                    <div className={classes.loader}>
                        <FileLoader style={{maxWidth: "100%"}}/>
                    </div>
            }

        </section>
    )
}

function mapStateToProps(state) {
    return {
        patientsList: state.patients.patientsList,
        total: state.patients.patientsTotal,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getPatientsList: (page, size, q) => dispatch(getPatientsList(page, size, q)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyPatients)