import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import EmptySection from "components/Profile/EmptySection/EmptySection";
import CardList from "components/Profile//Doctors/CardList/CardList";
import Navigation from "components/UI/Navigation/Navigation";
import Select from "components/UI/Select/Select";
import Button from "components/UI/Button/Button";
import UserTable from "components/Profile/UserTable/UserTable";
import Search from "components/UI/Search/Search";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";

import classes from "./Doctors.module.scss";
import { getSpecializations } from "store/actions/profile";
import {
  getDoctorsList,
  getProfileDoctorsList,
} from "store/Profile/Doctors/actions";
import { getAccountInfo } from "store/Profile/Account/actions";

const CARDS_IN_ONE_PAGE = 50;

class Doctors extends Component {
  state = {
    isLoading: false,
    activePage: 1,
    currentSpecIndex: 0,
    searchValue: null,
    prevSearch: null,
    isSearching: false,
  };

  componentDidMount() {
    this.props.getSpecializations();
    this.props.getAccountInfo();
    if (
      this.props.role === "patient" ||
      localStorage.getItem("role") === "patient"
    ) {
      this.props.getProfileDoctorsList(
        this.state.currentSpecIndex + 1,
        false,
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        this.state.isSearching ? this.state.searchValue : null
      );
    } else {
      this.props.getDoctorsList(
        this.state.currentSpecIndex,
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        this.state.isSearching ? this.state.searchValue : null
      );
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.role === "patient") {
      // для пациента
      if (
        prevState.currentSpecIndex !== this.state.currentSpecIndex ||
        prevState.activePage !== this.state.activePage
      ) {
        this.props.getProfileDoctorsList(
          +this.state.currentSpecIndex + 1,
          false,
          this.state.activePage - 1,
          CARDS_IN_ONE_PAGE,
          this.state.isSearching ? this.state.searchValue : null
        );
        this.setState({ isLoading: true });
      }
    } else {
      // для других ролей
      if (
        prevState.currentSpecIndex !== this.state.currentSpecIndex ||
        prevState.activePage !== this.state.activePage
      ) {
        this.props.getDoctorsList(
          +this.state.currentSpecIndex,
          this.state.activePage - 1,
          CARDS_IN_ONE_PAGE,
          this.state.isSearching ? this.state.searchValue : null
        );
        this.setState({ isLoading: true });
      }
    }
    if (prevProps.doctorsList !== this.props.doctorsList) {
      this.setState({ isLoading: false });
    }
  }
  changePageHandler = (pages, jumpTo) => {
    let activePage = this.state.activePage;

    if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
      return;
    } else {
      activePage = jumpTo;
    }
    this.setState({ activePage });
    window.scrollTo(0, 0);
  };
  noSpecPick = () => {
    this.setState({ currentSpecIndex: -1, activePage: 1 });
  };
  changeSpecHandler = (event) => {
    this.setState({ currentSpecIndex: event.target.value, activePage: 1 });
  };
  onSearchHandler = () => {
    if (
      this.state.prevSearch === this.state.searchValue ||
      !this.state.searchValue
    )
      return null;

    this.setState({ isSearching: true, prevSearch: this.state.searchValue });

    if (this.state.activePage > 1) {
      this.setState({ activePage: 1 }); // При смене страницы автоматически отправится запрос
    } else {
      if (this.props.role === "patient") {
        this.props.getProfileDoctorsList(
          +this.state.currentSpecIndex + 1,
          false,
          0,
          CARDS_IN_ONE_PAGE,
          this.state.searchValue
        );
      } else {
        this.props.getDoctorsList(
          +this.state.currentSpecIndex,
          0,
          CARDS_IN_ONE_PAGE,
          this.state.searchValue
        );
      }
    }
  };
  onSearchResetHandler = () => {
    this.setState({
      isSearching: false,
      prevSearch: null,
      searchValue: null,
      isLoading: true,
    });
    if (this.state.activePage > 1) {
      this.setState({ activePage: 1 }); // При смене страницы автоматически отправится запрос
    } else {
      if (this.props.role === "patient") {
        this.props.getProfileDoctorsList(
          +this.state.currentSpecIndex + 1,
          false,
          0,
          CARDS_IN_ONE_PAGE,
          null
        );
      } else {
        this.props.getDoctorsList(
          +this.state.currentSpecIndex,
          0,
          CARDS_IN_ONE_PAGE,
          null
        );
      }
    }
  };

  filteredDoctors = () => {
    if (!this.props.doctorsList) return null;

    let pagesAmount =
      Math.trunc(this.props.total / CARDS_IN_ONE_PAGE) +
      (this.props.total % CARDS_IN_ONE_PAGE ? 1 : 0);

    const section = (
      <React.Fragment>
        {this.props.doctorsList.length !== 0 ? (
          <React.Fragment>
            {this.props.role === "patient" ? (
              // Список врачей для пациента
              <React.Fragment>
                <CardList cards={this.props.doctorsList} />
              </React.Fragment>
            ) : (
              // Список врачей для остальных ролей
              <div className={classes.table_wraper}>
                <UserTable
                  role={this.props.role}
                  userList={this.props.doctorsList}
                  inNewWindow={true}
                />
              </div>
            )}
            {pagesAmount > 1 ? (
              <Navigation
                activePage={this.state.activePage}
                pages={pagesAmount}
                onChange={this.changePageHandler}
              />
            ) : null}
          </React.Fragment>
        ) : (
          <EmptySection
            title="Специалистов нет"
            text="Ни одного специалиста не было найдено"
          />
        )}
      </React.Fragment>
    );
    return section;
  };

  render() {
    let selectData = null;

    if (this.props.specializations) {
      selectData = this.props.specializations.map((i) => i.title);
      if (this.props.role !== "patient") {
        selectData.unshift("Без специализации");
      }
    }

    return (
      <section className={classes.Doctors}>
        <Helmet>
          <title>Специалисты. Портал здоровья</title>
          <meta name="title" content="Специалисты. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Специалисты. Портал здоровья" />
        </Helmet>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>Специалисты портала</div>
          {this.props.role === "assistant" ||
          this.props.role === "provider" ||
          this.props.role === "support" ||
          this.props.role === "admin" ? (
            <Link to={"/profile/doctors/new_doctor"}>
              <Button type={"plus"} text={"Добавить специалиста"}></Button>
            </Link>
          ) : null}
        </div>
        <div className={classes.spec}>
          <div className={classes.spec_title}>Специализация:</div>
          <div className={classes.spec_select}>
            <Select
              // disabled={this.props.account_info?.user.invited}
              data={selectData}
              value={this.state.currentSpecIndex}
              onChange={this.changeSpecHandler}
            />
          </div>
        </div>
        <div className={classes.search_wrapper}>
          <Search
            value={this.state.searchValue}
            onChange={(value) =>
              this.setState({ searchValue: value === "" ? null : value })
            }
            onSearch={() => this.onSearchHandler()}
            total={this.props.total}
            showReset={this.state.isSearching}
            onReset={this.onSearchResetHandler}
          />
        </div>
        {this.state.isLoading ? (
          <FileLoader
            style={{ maxWidth: "100%", width: "100%", height: "15px" }}
          />
        ) : null}
        {this.props.specializations && this.props.doctorsList ? (
          this.filteredDoctors()
        ) : (
          <div className={classes.loader}>
            <FileLoader style={{ maxWidth: "100%" }} />
          </div>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.profile.role,
    specializations: state.profile.specializations,
    doctorsList: state.doctors.doctorsList,
    total: state.doctors.doctorsTotal,
    account_info: state.account.account,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAccountInfo: () => dispatch(getAccountInfo()),
    getSpecializations: () => dispatch(getSpecializations()),
    getDoctorsList: (specialization_id, page, size, q) =>
      dispatch(getDoctorsList(specialization_id, page, size, q)),
    getProfileDoctorsList: (specialization_id, no_pagination, page, size, q) =>
      dispatch(
        getProfileDoctorsList(specialization_id, no_pagination, page, size, q)
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Doctors);
