import React from 'react';
import { Helmet } from 'react-helmet';

import MainHeader from 'components/Main/MainHeader/MainHeader';
import Footer from 'components/Footer/Footer';
import EmptyPageFiller from 'components/EmptyPageFiller/EmptyPageFiller';


const DeveloperInfo = () => {
    return (
        <React.Fragment>
            <Helmet>
                <title>Сведения о разработчике. Портал здоровья</title>
                <meta name="title" content="Сведения о разработчике" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content="Сведения о разработчике" />
            </Helmet>
            <MainHeader />
            <EmptyPageFiller pageName="Сведения о разработчике" />
            <Footer />
        </React.Fragment>
    )
}

export default DeveloperInfo;