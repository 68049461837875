import axios from "axios";

import {
  CREATE_SUCCESS,
  CREATE_ERROR,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_ERROR,
  GET_CONSULTATIONS_SUCCESS,
  GET_CONSULTATIONS_ERROR,
  GET_SPECIALIZATIONS_SUCCESS,
  GET_SPECIALIZATIONS_ERROR,
  GET_CARD_INFO_SUCCESS,
  GET_CONSULTATION_HISTORY_SUCCESS,
  CHANGE_PAGE_STATUS,
  SET_SCROLL_STATUS,
} from "./actionTypes";

export function createConsultation(
  consultation_type,
  problem_description,
  specialization_id,
  desired_performer_id,
  attachments
) {
  return async (dispatch) => {
    const sendData = {
      consultation_type,
      problem_description,
      specialization_id,
      desired_performer_id,
      attachments,
    };
    axios
      .post("/consultations/", sendData)
      .then(() => {
        dispatch(createSuccess());
      })
      .catch((err) => {
        console.log(err);
        dispatch(createError());
      });
  };
}

export function changeCardStatus(id, data) {
  return async (dispatch) => {
    axios
      .post(`/consultations/${id}/change_status`, data)
      .then(() => {
        dispatch(changeCardStatusSuccess());
        dispatch(getCardInfoInternal(id));
      })
      .catch((err) => {
        dispatch(changeCardStatusError());
        console.log(err);
      });
  };
}
export function getConsultationHistory(consultation_id, page, size) {
  return async (dispatch) => {
    axios
      .get(`/events/`, { params: { consultation_id, page, size } })
      .then((response) => {
        dispatch(getConsultationHistorySuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function assignSpecialist(id, data) {
  return async (dispatch) => {
    axios
      .post(`/consultations/${id}/update_performer`, {
        specialization_id: data.specialization_id,
        performer_id: data.performer_id,
      })
      .then(() => {
        dispatch(changeCardStatusSuccess());
        dispatch(getCardInfoInternal(id));
      })
      .catch((err) => {
        dispatch(changeCardStatusError());
        console.log(err);
      });
  };
}
export function getConsultations(
  status,
  type,
  page,
  size,
  start_date,
  end_date
) {
  return async (dispatch) => {
    axios
      .get("/consultations/", {
        params: {
          status,
          consultation_type: type === "all" ? null : type,
          page,
          size,
          start_date,
          end_date,
        },
      })
      .then((response) => {
        dispatch(getConsultationSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getConsultationError());
      });
  };
}

export function getSpecializations() {
  return async (dispatch) => {
    axios
      .get("/specializations/")
      .then((response) => {
        dispatch(getSpecializationsSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getSpecializationsError());
      });
  };
}

export function getCardInfo(id) {
  return async (dispatch) => {
    axios
      .get(`/consultations/${id}`)
      .then((response) => {
        dispatch(getCardInfoSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function getCardInfoInternal(id) {
  return async (dispatch) => {
    let url = `/consultations/${id}/internal`;

    axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then((response) => {
        dispatch(getCardInfoSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function updateConclusion(id, data) {
  return async (dispatch) => {
    let url = `/consultations/${id}/update_conclusion`;

    axios
      .post(url, data, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      })
      .then(() => {
        dispatch(getCardInfoInternal(id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  };
}

export function createError() {
  return {
    type: CREATE_ERROR,
  };
}

export function changeCardStatusSuccess() {
  return {
    type: CHANGE_STATUS_SUCCESS,
  };
}

export function changeCardStatusError() {
  return {
    type: CHANGE_STATUS_ERROR,
  };
}

export function getConsultationSuccess(data) {
  return {
    type: GET_CONSULTATIONS_SUCCESS,
    data,
  };
}

export function getConsultationError() {
  return {
    type: GET_CONSULTATIONS_ERROR,
  };
}

export function getSpecializationsSuccess(specializations) {
  return {
    type: GET_SPECIALIZATIONS_SUCCESS,
    specializations,
  };
}

export function getSpecializationsError() {
  return {
    type: GET_SPECIALIZATIONS_ERROR,
  };
}

export function getCardInfoSuccess(cardInfo) {
  return {
    type: GET_CARD_INFO_SUCCESS,
    cardInfo,
  };
}

export function changePageStatus(status) {
  return {
    type: CHANGE_PAGE_STATUS,
    status,
  };
}

export function setScrollStatus(newStatus) {
  return {
    type: SET_SCROLL_STATUS,
    newStatus,
  };
}
export function getConsultationHistorySuccess(historyData) {
  return {
    type: GET_CONSULTATION_HISTORY_SUCCESS,
    events: historyData.items,
    total: historyData.total,
  };
}
