import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import Button from "components/UI/Button/Button";
import Input from "components/UI/Input/Input";
import BackLink from "components/UI/BackLink/BackLink";
import FileControl from "components/UI/FileControl/FileControl";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";
import Select from "components/UI/Select/Select";
import { validateControl } from "containers/Auth/Auth";
import classes from "./NewDocument.module.scss";
import {
  addFile,
  removeFile,
  uploadMedcardDoc,
} from "store/Profile/NewDocument/actions";
import { documentTypes } from "components/Profile/Medcard/CardList/enums";

let TYPES = { ...documentTypes };
const selectData = Object.values(TYPES);

class NewDocument extends Component {
  state = {
    fileLoading: false,
    doc_Name: "",
    doc_Organization: "",
    doc_Date: {
      value: null,
      type: "date",
      errorMessage: "Введите корректное значение",
      placeholder: "Дата выдачи",
      label: "Дата выдачи",
      valid: false,
      touched: false,
      validation: {
        required: true,
      },
    },
    currentDocTypeID: "patient_info",
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.attachment !== this.props.attachment) {
      this.setState({ fileLoading: false });
    }
  }

  componentWillUnmount = () => {
    this.props.removeFile(0);
  }

  docNameChangeHandler = (event) => {
    this.setState({
      doc_Name: event.target.value,
    });
  };
  docOrgChangeHandler = (event) => {
    this.setState({
      doc_Organization: event.target.value,
    });
  };
  onChangeDateHandler = (date) => {
    let control = { ...this.state.doc_Date };
    control.value = date;
    control.touched = true;
    control.valid = validateControl(date.value, date.validation);
    this.setState({
      doc_Date: control,
    });
  };
  DocTypeIDChangeHandler = (event) => {
    this.setState({ currentDocTypeID: Object.keys(TYPES)[event.target.value] });
  };

  submitHandler(event) {
    event.preventDefault();
  }

  sendFormHandler = () => {
    this.props.send(
      this.state.doc_Organization,
      this.state.doc_Name,
      this.state.doc_Date.value.toISOString().substring(0, 10),
      this.state.currentDocTypeID,
      this.props.attachment[0]
    );
    const newState = { ...this.state };
    newState.doc_Name = "";
    newState.doc_Organization = "";
    newState.doc_Date = {
      value: "",
      type: "text",
      errorMessage: "Введите корректное значение",
      placeholder: "дд.мм.гггг",
      label: "Дата выдачи",
      valid: false,
      touched: false,
      validation: {
        required: true,
        date: true,
      },
    };
    newState.currentDocTypeID = "patient_info";

    this.setState({ ...newState });

    window.scrollTo(0, 0);
  };

  addFileHandler = (event) => {
    this.props.addFile(event.target.files[0]);
    this.setState({ fileLoading: true })
  };

  removeFileHandler = (index) => {
    this.props.removeFile(index);
  };

  render() {

    if (this.props.addDocumentStatus === "SUCCESS") {
      return <Redirect to="/profile/medcard" />
    }

    return (
      <section className={classes.NewDocument}>
        <Helmet>
          <title>Новый документ. Портал здоровья</title>
          <meta name="title" content="Новый документ. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Новый документ. Портал здоровья" />
        </Helmet>
        <div className={classes.back}>
          <BackLink to={"/profile/medcard"} />
          <span>Назад</span>
        </div>
        <h4 className={classes.title}>Загрузка нового документа</h4>
        <form
          className={classes.form}
          onSubmit={(event) => this.submitHandler(event)}
        >
          <h5 className={classes.section_title}>
            данные загружаемого документа
          </h5>
          <div className={classes.section_content}>
            <div className={classes.input_title}>
              Укажите название организации предоставившей вам документ
              (необязательное поле)
            </div>
            <Input
              className={classes.Input}
              value={this.state.doc_Organization}
              placeholder={"Название организации"}
              valid={false}
              touched={false}
              label={"Название организации"}
              shouldValidate={false}
              errorMessage={""}
              onChange={(event) => this.docOrgChangeHandler(event)}
            ></Input>
          </div>
          <h5 className={classes.section_title}>введите имя документа</h5>
          <div className={classes.section_content}>
            <Input
              className={classes.Input}
              value={this.state.doc_Name}
              placeholder={"Например, СНИЛС"}
              valid={false}
              touched={false}
              label={"имя документа"}
              shouldValidate={true}
              errorMessage={"Обязательное поле"}
              onChange={(event) => this.docNameChangeHandler(event)}
            ></Input>
          </div>
          <h5 className={classes.section_title}>
            Выберите тип документа и дату его выдачи
          </h5>
          <div className={classes.extra_section}>
            <div className={classes.select_box}>
              {selectData ? (
                <Select
                  data={selectData}
                  value={selectData.indexOf(TYPES[this.state.currentDocTypeID])}
                  onChange={this.DocTypeIDChangeHandler}
                  label={"Тип документа"}
                />
              ) : null}
              <div className={classes.date}>
                <Input
                  type={this.state.doc_Date.type}
                  value={this.state.doc_Date.value}
                  placeholder={this.state.doc_Date.placeholder}
                  valid={this.state.doc_Date.valid}
                  touched={this.state.doc_Date.touched}
                  label={this.state.doc_Date.label}
                  shouldValidate={!!this.state.doc_Date.validation}
                  errorMessage={this.state.doc_Date.errorMessage}
                  onChange={(event, date) => this.onChangeDateHandler(date)}
                  style={{ fontFamily: "inherit" }}
                />
              </div>
            </div>
            <div className={classes.file_tip}>
              <strong>Фото</strong> или <strong>pdf.</strong>, размер которого
              не превышает <strong>50 Mb</strong>
            </div>
            <div className={classes.attachments}>
              <FileControl
                style={this.state.fileLoading ? { display: "none" } : null}
                remove={this.removeFileHandler}
                files={this.props.attachment}
                onChange={this.addFileHandler}
                maxFilesCount={1}
              >
                Загрузить документ
            </FileControl>
              {this.state.fileLoading ?
                <FileLoader />
                :
                null
              }
            </div>
            <Button
              disabled={
                this.state.doc_Type === null ||
                !this.state.doc_Name ||
                !this.state.doc_Date.valid ||
                !this.props.attachment.length > 0
              }
              onClick={() => this.sendFormHandler()}
              type={"primary"}
            >
              Готово
              </Button>
          </div>
        </form>
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    send: (
      doc_Organization,
      doc_Name,
      doc_Date,
      currentDocTypeID,
      attachment
    ) =>
      dispatch(
        uploadMedcardDoc(
          doc_Organization,
          doc_Name,
          doc_Date,
          currentDocTypeID,
          attachment
        )
      ),
    addFile: (file) => dispatch(addFile(file)),
    removeFile: (index) => dispatch(removeFile(index)),
  };
}

function mapStateToProps(state) {
  return {
    attachment: state.newDocument.attachment,
    addDocumentStatus: state.newDocument.addDocumentStatus
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDocument);
