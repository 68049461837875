import axios from "axios";

import {
  GET_CONSULTATIONS_SUCCESS,
  GET_CONSULTATIONS_ERROR,
  GET_LOG_FILE_SUCCESS,
  GET_LOG_FILE_ERROR,
  GET_PERFORMERS_SUCCESS,
  GET_PERFORMERS_ERROR,
  SET_SCROLL_STATUS,
} from "./actionTypes";

export function getConsultations(
  page,
  size,
  start_date,
  end_date,
  performer_id,
  creator_id,
  q
) {
  return async (dispatch) => {
    axios
      .get("/consultations/report", {
        params: {
          page,
          size,
          start_date,
          end_date,
          performer_id: performer_id === "all" ? null : performer_id,
          creator_id: creator_id === "all" ? null : creator_id,
        },
      })
      .then((response) => {
        dispatch(getConsultationsSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getConsultationsError());
      });
  };
}

export function getLogFile(start_date, end_date, performer_id, creator_id) {
  return async (dispatch) => {
    axios
      .get("/consultations/report", {
        responseType: "arraybuffer",
        params: {
          start_date,
          end_date,
          performer_id: performer_id === "all" ? null : performer_id,
          creator_id: creator_id === "all" ? null : creator_id,
          data_format: "excel",
        },
      })
      .then((response) => {
        dispatch(getLogFileSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getLogFileError());
      });
  };
}

export function getPerformers(page, size) {
  return async (dispatch) => {
    axios
      .get("/users/performers", {
        params: {
          page,
          size,
        },
      })
      .then((response) => {
        dispatch(getPerformersSuccess(response.data));
      })
      .catch((err) => {
        console.log(err);
        dispatch(getPerformersError());
      });
  };
}

export function getConsultationsSuccess(data) {
  return {
    type: GET_CONSULTATIONS_SUCCESS,
    data,
  };
}

export function getConsultationsError() {
  return {
    type: GET_CONSULTATIONS_ERROR,
  };
}

export function getPerformersSuccess(performers) {
  return {
    type: GET_PERFORMERS_SUCCESS,
    performers,
  };
}

export function getPerformersError() {
  return {
    type: GET_PERFORMERS_ERROR,
  };
}

export function getLogFileSuccess(data) {
  return {
    type: GET_LOG_FILE_SUCCESS,
    data,
  };
}

export function getLogFileError() {
  return {
    type: GET_LOG_FILE_ERROR,
  };
}

export function setScrollStatus(newStatus) {
  return {
    type: SET_SCROLL_STATUS,
    newStatus,
  };
}
