import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import BackLink from "components/UI/BackLink/BackLink";
import ModalWindow from "components/UI/ModalWindow/ModalWindow";
import ModalContent from "components/UI/ModalWindow/SuccessErrorMessage/SuccessErrorMessage";
import DeleteConfirm from "components/UI/ModalWindow/DeleteConfirm/DeleteConfirm";
import Button from "components/UI/Button/Button";
import GenderControl from "components/UI/GenderControl/GenderControl";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";
import { DeleteConfirmProvider } from "components/Profile/DeleteConfirmProvider/DeleteConfirmProvider";
import Input from "components/UI/Input/Input";

import { validateControl } from "containers/Auth/Auth";
import { convertDateToString } from "components/Profile/dateConverter";
import classes from "./ProviderEdit.module.scss";
import {
  editProvider,
  getProviderByID,
  sendNewPassword,
  loadPicture,
  changeUserStatus,
  removePicture,
  clearEditProviderStatus,
  changeUserDeletedStatus,
  clearDeleteProviderStatus,
} from "store/Profile/Providers/actions";

let modalTimeout;
class ProviderEdit extends Component {
  state = {
    isUserpicLoading: false,
    isAddressesMatch: true,
    accountInfoValid: true,
    accountInfoTouched: false,
    passwordInputValid: false,
    passwordMatch: false,
    passChange: {
      password: {
        value: "",
        type: "password",
        errorMessage:
          "Пароль должен содержать не менее 8 символов, цифры и латинские буквы, не должен являться словарным словом или набором символов, находящихся рядом на клавиатуре",
        placeholder: "Пароль",
        label: "Пароль",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
          minLength: 8,
        },
      },
      repeatPassword: {
        value: "",
        type: "password",
        errorMessage: "Пароли должны совпадать",
        placeholder: "Повторите пароль",
        label: "Повторите пароль",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {},
      },
    },
    personalData: {
      second_name: {
        placeholder: "Фамилия",
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        label: "Фамилия",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      first_name: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Имя",
        label: "Имя",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      patronymic_name: {
        value: "",
        type: "text",
        errorMessage: "",
        placeholder: "Отчество",
        label: "Отчество",
        valid: false,
        touched: false,
        shouldValidate: false,
        validation: {},
      },
      gender: {
        value: "male",
        touched: false,
        valid: true,
      },
      date_of_birth: {
        value: null,
        type: "date",
        errorMessage: "Введите корректное значение",
        placeholder: "Дата Рождения",
        label: "Дата Рождения",
        valid: false,
        touched: false,
        shouldValidate: true,
        validation: {
          required: true,
        },
      },
      registration_address: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес",
        label: "Адрес",
        valid: false,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      real_address: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес места жительства",
        label: "Адрес места жительства",
        valid: false,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_name: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Название организации",
        label: "Название организации",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_address: {
        value: "",
        type: "address",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес местонахождения",
        label: "Адрес местонахождения",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_phone: {
        masked: true,
        mask: "+7 (999) 999-99-99",
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Телефон",
        label: "Телефон",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_email: {
        value: "",
        type: "email",
        errorMessage: "Обязательное поле",
        placeholder: "Адрес электронной почты",
        label: "Адрес электронной почты",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_bik: {
        value: "",
        type: "bic",
        errorMessage: "Обязательное поле",
        placeholder: "БИК Банка",
        label: "БИК Банка",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_account: {
        value: "",
        type: "text",
        errorMessage: "Обязательное поле",
        placeholder: "Расчетный счет",
        label: "Расчетный счет",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_agreement_number: {
        value: "",
        type: "number",
        errorMessage: "Обязательное поле",
        placeholder: "Номер агентского договора",
        label: "Номер агентского договора",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
      organization_agreement_date: {
        value: null,
        type: "date",
        errorMessage: "Обязательное поле",
        placeholder: "Дата агентского договора",
        label: "Дата агентского договора",
        valid: true,
        touched: false,
        shouldValidate: false,
        validation: {
          required: false,
        },
      },
    },
    modalShow: false,
    confirmModalShow: false,
    modalPassChangedShow: false,
    providerToAttached: null,
  };
  // модалка
  closeOnTimeout() {
    modalTimeout = setTimeout(() => this.modalShowClose(), 3000);
  }
  modalShowOpen = () => {
    this.setState({ modalShow: true });
    this.closeOnTimeout();
  };
  confirmModalShowOpen = () => {
    this.setState({ confirmModalShow: true });
  };
  modalPassChangedShowOpen = () => {
    this.setState({ modalPassChangedShow: true });
  };
  modalShowClose = () => {
    this.setState({
      modalShow: false,
      confirmModalShow: false,
      modalPassChangedShow: false,
    });
    this.props.clearEditProviderStatus();
    this.props.clearDeleteProviderStatus();
  };
  attachProvider = (provider) => {
    this.setState({ providerToAttached: provider });
  };
  componentDidMount() {
    this.props.getProviderByID(this.props.match.params.id);
  }
  componentDidUpdate(prevProps, prevState) {
    let editedProvider = this.props.editedProvider;
    if (prevProps.editedProvider !== editedProvider) {
      this.initState(editedProvider);
    }
    if (editedProvider && prevProps.editedProvider) {
      if (
        prevProps.editedProvider.user.is_active !==
        editedProvider.user.is_active
      ) {
        this.modalShowOpen();
      }
    }

    // открываем модалку со статусом отправки запроса
    if (prevProps.editProviderStatus !== this.props.editProviderStatus) {
      this.modalPassChangedShowOpen();
    }
    // Дублируем адреса
    if (
      prevState.isAddressesMatch === false &&
      this.state.isAddressesMatch === true
    ) {
      const formControls = { ...this.state.personalData };
      const real_address = { ...formControls["real_address"] };
      const registration_address = { ...formControls["registration_address"] };
      real_address.value = registration_address.value;
      real_address.touched = true;
      registration_address.touched = true;
      registration_address.valid = validateControl(
        registration_address.value,
        registration_address.validation
      );
      real_address.valid = registration_address.valid;
      formControls.real_address = real_address;
      formControls.registration_address = registration_address;
      this.setState({
        personalData: formControls,
        accountInfoTouched: true,
      });
    }
    if (prevProps.userpic_url !== this.props.userpic_url) {
      this.setState({ isUserpicLoading: false });
    }
  }
  componentWillUnmount() {
    clearTimeout(modalTimeout);
  }
  initState(editedProvider) {
    let newState = { ...this.state };

    var organization_agreement_date = null;
    if (newState.personalData.organization_agreement_date.value !== null) {
      organization_agreement_date = new Date(
        editedProvider.organization_agreement_date
      );
    }

    newState.personalData.first_name.value = editedProvider.first_name;
    newState.personalData.first_name.valid = true;
    newState.personalData.second_name.value = editedProvider.second_name;
    newState.personalData.second_name.valid = true;
    newState.personalData.patronymic_name.value =
      editedProvider.patronymic_name;
    newState.personalData.patronymic_name.valid = true;
    newState.personalData.registration_address.value =
      editedProvider.registration_address;
    newState.personalData.registration_address.valid = true;
    newState.personalData.real_address.value = editedProvider.real_address;
    newState.personalData.real_address.valid = true;
    newState.personalData.date_of_birth.value = new Date(
      editedProvider.date_of_birth
    );
    newState.personalData.date_of_birth.valid = true;
    newState.personalData.organization_name.value =
      editedProvider.organization_name;
    newState.personalData.organization_name.valid = true;
    newState.personalData.organization_address.value =
      editedProvider.organization_address;
    newState.personalData.organization_address.valid = true;
    newState.personalData.organization_phone.value =
      editedProvider.organization_phone;
    newState.personalData.organization_phone.valid = true;
    newState.personalData.organization_email.value =
      editedProvider.organization_email;
    newState.personalData.organization_email.valid = true;
    newState.personalData.organization_bik.value =
      editedProvider.organization_bik;
    newState.personalData.organization_bik.valid = true;
    newState.personalData.organization_account.value =
      editedProvider.organization_account;
    newState.personalData.organization_account.valid = true;
    newState.personalData.organization_agreement_number.value =
      editedProvider.organization_agreement_number;
    newState.personalData.organization_agreement_number.valid = true;
    newState.personalData.organization_agreement_date.value =
      organization_agreement_date;
    newState.personalData.organization_agreement_date.valid = true;

    this.setState({
      ...newState,
    });
  }
  // добавление фото
  addImageHadler = (event) => {
    this.props.loadPicture(event.target.files[0]);
    this.setState({
      accountInfoTouched: true,
      isUserpicLoading: true,
    });
  };
  removeImageHandler = () => {
    this.props.removePicture();
  };
  onChangeInputHandler = (event, controlName) => {
    const formControls = { ...this.state.personalData };
    const control = { ...formControls[controlName] };

    if (control.type === "address") {
      control.value = event;
      // для инпутов с типом адрес значение хранится в event
    } else if (control.type === "bic") {
      control.value = event.data ? event.data.bic : "";
    } else {
      control.value = event.target.value;
    }
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);

    if (controlName === "registration_address" && this.state.isAddressesMatch) {
      const real_address = { ...formControls[controlName] };
      real_address.value = control.value;
      real_address.touched = true;
      real_address.valid = control.valid;
      formControls[controlName] = real_address;
    }

    formControls[controlName] = control;
    let accountInfoTouched = true;
    let accountInfoValid = true;
    Object.keys(formControls).forEach((key) => {
      if (!formControls[key].valid) {
        accountInfoValid = false;
      }
    });
    this.setState({
      accountInfoTouched: accountInfoTouched,
      accountInfoValid: accountInfoValid,
      personalData: formControls,
    });
  };
  onChangeDateHandler = (date) => {
    const formControls = { ...this.state.personalData };
    let personalData = { ...this.state.personalData };
    personalData.date_of_birth.value = date;
    personalData.date_of_birth.touched = true;
    personalData.date_of_birth.valid = validateControl(
      personalData.date_of_birth.value,
      personalData.date_of_birth.validation
    );

    let accountInfoValid = true;
    Object.keys(formControls).forEach((key) => {
      if (!formControls[key].valid) {
        accountInfoValid = false;
      }
    });
    this.setState({
      accountInfoTouched: true,
      accountInfoValid: accountInfoValid,
      personalData: personalData,
    });
  };
  onChangeAgreementDateHandler = (date) => {
    let personalData = { ...this.state.personalData };
    personalData.organization_agreement_date.value = date;
    personalData.organization_agreement_date.touched = true;
    personalData.organization_agreement_date.valid = validateControl(
      personalData.organization_agreement_date.value,
      personalData.organization_agreement_date.validation
    );
    this.setState({
      accountInfoTouched: true,
      accountInfoValid: personalData.organization_agreement_date.valid,
      personalData: personalData,
    });
  };
  genderChangeHandler = (gender) => {
    let personalData = { ...this.state.personalData };
    personalData.gender.value = gender;
    personalData.gender.touched = true;
    let accountInfoTouched = true;
    this.setState({
      accountInfoTouched: accountInfoTouched,
      personalData: personalData,
    });
  };
  onChangeInputPassHandler = (event, controlName) => {
    const formControls = { ...this.state.passChange };
    const control = { ...formControls[controlName] };

    control.value = event.target.value;
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);
    formControls[controlName] = control;

    let isPasswordsMatch = false;
    if (formControls.password.value === formControls.repeatPassword.value) {
      isPasswordsMatch = true;
      formControls.repeatPassword.valid = isPasswordsMatch;
    } else {
      formControls.repeatPassword.valid = isPasswordsMatch;
    }
    let passwordInputValid = true;
    Object.keys(formControls).forEach((key) => {
      if (!formControls[key].valid) {
        passwordInputValid = false;
      }
    });

    this.setState({
      passwordInputValid: passwordInputValid,
      passChange: formControls,
      passwordMatch: isPasswordsMatch,
    });
  };
  // отправка формы с личными данными
  sendAccountInfo() {
    let data = {
      id: this.props.match.params.id,
      first_name: this.state.personalData.first_name.value,
      second_name: this.state.personalData.second_name.value,
      patronymic_name: this.state.personalData.patronymic_name.value,
      gender: this.state.personalData.gender.value,
      date_of_birth: convertDateToString(
        this.state.personalData.date_of_birth.value
      ),
      registration_address:
        this.state.personalData.registration_address.value.value ||
        this.state.personalData.registration_address.value,
      real_address:
        this.state.personalData.registration_address.value.value ||
        this.state.personalData.registration_address.value,
      userpic_url: this.props.userpic_url,
      organization_name: this.state.personalData.organization_name.value,
      organization_address: this.state.personalData.organization_address.value
        ? this.state.personalData.organization_address.value.value ||
          this.state.personalData.organization_address.value
        : null,
      organization_phone: this.state.personalData.organization_phone.value,
      organization_email: this.state.personalData.organization_email.value,
      organization_bik: this.state.personalData.organization_bik.value
        ? this.state.personalData.organization_bik.value.value ||
          this.state.personalData.organization_bik.value
        : null,
      organization_account: this.state.personalData.organization_account.value,
      organization_agreement_number:
        this.state.personalData.organization_agreement_number.value,
      organization_agreement_date: convertDateToString(
        this.state.personalData.organization_agreement_date.value
      ),
    };
    this.props.editProvider(data);
    if (this.props.editProviderStatus !== "") {
      this.modalPassChangedShowOpen();
    }
  }
  // смена пароля
  sendNewPassword() {
    let data = {
      id: this.props.match.params.id,
      password: this.state.passChange.password.value,
      repeatPassword: this.state.passChange.repeatPassword.value,
    };
    this.props.sendNewPassword(data);
    if (this.props.sendNewPasswordStatus !== "") {
      this.modalPassChangedShowOpen();
    }
    let newState = { ...this.state };
    newState.passChange.password.value = "";
    newState.passChange.repeatPassword.value = "";
    this.setState({ ...newState });
  }

  changeUserStatusHandler = (newStatus) => {
    const data = { is_active: newStatus };
    this.props.changeUserStatus(this.props.editedProvider?.user_id, data);
  };

  changeUserDeletedStatusHandler = (newStatus, providerId) => {
    const data = providerId
      ? { is_deleted: newStatus, new_provider_id: providerId }
      : { is_deleted: newStatus };
    this.props.changeUserDeletedStatus(
      this.props.editedProvider?.user_id,
      data
    );
  };

  deleteConfirmedHandler = () => {
    this.changeUserDeletedStatusHandler(
      true,
      this.state.providerToAttached?.value
    );
    this.modalShowClose();
    this.attachProvider(null);
  };

  restoreConfirmedHandler = () => {
    this.changeUserDeletedStatusHandler(
      false,
      this.state.providerToAttached?.value
    );
    this.modalShowClose();
  };

  render() {
    let fullName;

    if (this.props.editedProvider) {
      fullName = `${this.props.editedProvider.second_name} ${
        this.props.editedProvider.first_name
      }${
        this.props.editedProvider.patronymic_name
          ? " " + this.props.editedProvider.patronymic_name
          : ""
      }${this.props.editedProvider.second_name ? ". " : ""}`;
    }

    return (
      <section className={classes.ProviderEdit}>
        {fullName ? (
          <Helmet>
            <title>{fullName}Портал здоровья</title>
            <meta name="title" content={`${fullName}Портал здоровья`} />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:title" content={`${fullName}Портал здоровья`} />
          </Helmet>
        ) : null}
        <div className={classes.back}>
          <BackLink to="/profile/providers" />
          <span>Назад</span>
        </div>
        <div className={classes.title}>Редактирование провайдера</div>
        <form
          className={classes.form}
          onSubmit={(event) => event.preventDefault()}
        >
          <div className={classes.section_title}>
            <h5>Смена пароля</h5>
            {this.props.editedProvider?.user.is_active ? (
              <span
                className={classes.block_btn}
                onClick={() => this.changeUserStatusHandler(false)}
              >
                Заблокировать
              </span>
            ) : (
              <span
                className={classes.block_btn}
                onClick={() => this.changeUserStatusHandler(true)}
              >
                Разблокировать
              </span>
            )}
            {this.props.editedProvider?.user.is_deleted ? (
              <span
                className={classes.block_btn}
                onClick={this.confirmModalShowOpen}
              >
                Восстановить
              </span>
            ) : (
              <span
                className={classes.block_btn}
                onClick={this.confirmModalShowOpen}
              >
                Удалить
              </span>
            )}
          </div>
          <div className={classes.section_content}>
            <div className={classes.inputs}>
              {/* новый пароль */}
              <Input
                value={this.state.passChange.password.value}
                type={this.state.passChange.password.type}
                placeholder={this.state.passChange.password.placeholder}
                valid={this.state.passChange.password.valid}
                touched={this.state.passChange.password.touched}
                label={this.state.passChange.password.label}
                shouldValidate={this.state.passChange.password.shouldValidate}
                errorMessage={this.state.passChange.password.errorMessage}
                onChange={(event) =>
                  this.onChangeInputPassHandler(event, "password")
                }
              ></Input>

              {/* повтор нового пароля */}
              <Input
                value={this.state.passChange.repeatPassword.value}
                type={this.state.passChange.repeatPassword.type}
                placeholder={this.state.passChange.repeatPassword.placeholder}
                valid={this.state.passChange.repeatPassword.valid}
                touched={this.state.passChange.repeatPassword.touched}
                label={this.state.passChange.repeatPassword.label}
                shouldValidate={
                  this.state.passChange.repeatPassword.shouldValidate
                }
                errorMessage={this.state.passChange.repeatPassword.errorMessage}
                onChange={(event) =>
                  this.onChangeInputPassHandler(event, "repeatPassword")
                }
              ></Input>

              <Button
                disabled={!this.state.passwordInputValid}
                onClick={() => this.sendNewPassword()}
                type={"primary"}
              >
                Сохранить
              </Button>
            </div>
          </div>
          <h5 className={classes.section_title}>Личные данные</h5>
          <div className={classes.section_withPhoto}>
            <div className={classes.inputs}>
              {/* фамилия */}

              <Input
                className={classes.Input}
                value={this.state.personalData.second_name.value}
                placeholder={this.state.personalData.second_name.placeholder}
                valid={this.state.personalData.second_name.valid}
                touched={this.state.personalData.second_name.touched}
                label={this.state.personalData.second_name.label}
                shouldValidate={
                  this.state.personalData.second_name.shouldValidate
                }
                errorMessage={this.state.personalData.second_name.errorMessage}
                onChange={(event) =>
                  this.onChangeInputHandler(event, "second_name")
                }
              ></Input>

              {/* имя */}

              <Input
                className={classes.Input}
                value={this.state.personalData.first_name.value}
                placeholder={this.state.personalData.first_name.placeholder}
                valid={this.state.personalData.first_name.valid}
                touched={this.state.personalData.first_name.touched}
                label={this.state.personalData.first_name.label}
                shouldValidate={
                  this.state.personalData.first_name.shouldValidate
                }
                errorMessage={this.state.personalData.first_name.errorMessage}
                onChange={(event) =>
                  this.onChangeInputHandler(event, "first_name")
                }
              ></Input>

              {/* отчество */}

              <Input
                className={classes.Input}
                value={this.state.personalData.patronymic_name.value}
                placeholder={
                  this.state.personalData.patronymic_name.placeholder
                }
                valid={this.state.personalData.patronymic_name.valid}
                touched={this.state.personalData.patronymic_name.touched}
                label={this.state.personalData.patronymic_name.label}
                shouldValidate={
                  this.state.personalData.patronymic_name.shouldValidate
                }
                errorMessage={
                  this.state.personalData.patronymic_name.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "patronymic_name")
                }
              ></Input>

              <div className={classes.row}>
                {/* пол */}
                <GenderControl
                  value={this.state.personalData.gender.value}
                  onChange={(event) => this.genderChangeHandler(event)}
                />
                {/* дата рожд */}

                <Input
                  className={classes.Input}
                  type={this.state.personalData.date_of_birth.type}
                  value={this.state.personalData.date_of_birth.value}
                  placeholder={
                    this.state.personalData.date_of_birth.placeholder
                  }
                  valid={this.state.personalData.date_of_birth.valid}
                  touched={this.state.personalData.date_of_birth.touched}
                  label={this.state.personalData.date_of_birth.label}
                  shouldValidate={
                    this.state.personalData.date_of_birth.shouldValidate
                  }
                  errorMessage={
                    this.state.personalData.date_of_birth.errorMessage
                  }
                  onChange={(event, date) => this.onChangeDateHandler(date)}
                ></Input>
              </div>

              {/* адрес */}

              <Input
                className={classes.Input}
                type={this.state.personalData.registration_address.type}
                value={this.state.personalData.registration_address.value}
                placeholder={
                  this.state.personalData.registration_address.placeholder
                }
                valid={this.state.personalData.registration_address.valid}
                touched={this.state.personalData.registration_address.touched}
                label={this.state.personalData.registration_address.label}
                shouldValidate={
                  this.state.personalData.registration_address.shouldValidate
                }
                errorMessage={
                  this.state.personalData.registration_address.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "registration_address")
                }
              ></Input>

              {/* название организации */}

              <Input
                className={classes.Input}
                value={this.state.personalData.organization_name.value}
                placeholder={
                  this.state.personalData.organization_name.placeholder
                }
                valid={this.state.personalData.organization_name.valid}
                touched={this.state.personalData.organization_name.touched}
                label={this.state.personalData.organization_name.label}
                shouldValidate={
                  this.state.personalData.organization_name.shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_name.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "organization_name")
                }
              ></Input>

              {/* адрес местонахождения */}

              <Input
                className={classes.Input}
                type={this.state.personalData.organization_address.type}
                value={this.state.personalData.organization_address.value}
                placeholder={
                  this.state.personalData.organization_address.placeholder
                }
                valid={this.state.personalData.organization_address.valid}
                touched={this.state.personalData.organization_address.touched}
                label={this.state.personalData.organization_address.label}
                shouldValidate={
                  this.state.personalData.organization_address.shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_address.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "organization_address")
                }
              ></Input>

              {/* телефон */}

              <Input
                className={classes.Input}
                masked={this.state.personalData.organization_phone.masked}
                mask={this.state.personalData.organization_phone.mask}
                type={this.state.personalData.organization_phone.type}
                value={this.state.personalData.organization_phone.value}
                placeholder={
                  this.state.personalData.organization_phone.placeholder
                }
                valid={this.state.personalData.organization_phone.valid}
                touched={this.state.personalData.organization_phone.touched}
                label={this.state.personalData.organization_phone.label}
                shouldValidate={
                  this.state.personalData.organization_phone.shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_phone.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "organization_phone")
                }
              ></Input>

              {/* адрес электронной почты */}

              <Input
                className={classes.Input}
                value={this.state.personalData.organization_email.value}
                placeholder={
                  this.state.personalData.organization_email.placeholder
                }
                valid={this.state.personalData.organization_email.valid}
                touched={this.state.personalData.organization_email.touched}
                label={this.state.personalData.organization_email.label}
                shouldValidate={
                  this.state.personalData.organization_email.shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_email.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "organization_email")
                }
              ></Input>

              {/* бик банка */}

              <Input
                className={classes.Input}
                type={this.state.personalData.organization_bik.type}
                value={this.state.personalData.organization_bik.value}
                placeholder={
                  this.state.personalData.organization_bik.placeholder
                }
                valid={this.state.personalData.organization_bik.valid}
                touched={this.state.personalData.organization_bik.touched}
                label={this.state.personalData.organization_bik.label}
                shouldValidate={
                  this.state.personalData.organization_bik.shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_bik.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "organization_bik")
                }
              ></Input>

              {/* расчетный счёт */}

              <Input
                className={classes.Input}
                value={this.state.personalData.organization_account.value}
                placeholder={
                  this.state.personalData.organization_account.placeholder
                }
                valid={this.state.personalData.organization_account.valid}
                touched={this.state.personalData.organization_account.touched}
                label={this.state.personalData.organization_account.label}
                shouldValidate={
                  this.state.personalData.organization_account.shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_account.errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(event, "organization_account")
                }
              ></Input>

              {/* номер агентского договора */}

              <Input
                className={classes.Input}
                value={
                  this.state.personalData.organization_agreement_number.value
                }
                placeholder={
                  this.state.personalData.organization_agreement_number
                    .placeholder
                }
                valid={
                  this.state.personalData.organization_agreement_number.valid
                }
                touched={
                  this.state.personalData.organization_agreement_number.touched
                }
                label={
                  this.state.personalData.organization_agreement_number.label
                }
                shouldValidate={
                  this.state.personalData.organization_agreement_number
                    .shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_agreement_number
                    .errorMessage
                }
                onChange={(event) =>
                  this.onChangeInputHandler(
                    event,
                    "organization_agreement_number"
                  )
                }
              ></Input>

              {/* дата агентского договора */}

              <Input
                className={classes.Input}
                type={this.state.personalData.organization_agreement_date.type}
                value={
                  this.state.personalData.organization_agreement_date.value
                }
                placeholder={
                  this.state.personalData.organization_agreement_date
                    .placeholder
                }
                valid={
                  this.state.personalData.organization_agreement_date.valid
                }
                touched={
                  this.state.personalData.organization_agreement_date.touched
                }
                label={
                  this.state.personalData.organization_agreement_date.label
                }
                shouldValidate={
                  this.state.personalData.organization_agreement_date
                    .shouldValidate
                }
                errorMessage={
                  this.state.personalData.organization_agreement_date
                    .errorMessage
                }
                onChange={(event, date) =>
                  this.onChangeAgreementDateHandler(date)
                }
              ></Input>

              <Button
                disabled={
                  !(
                    this.state.accountInfoValid && this.state.accountInfoTouched
                  )
                }
                onClick={() => this.sendAccountInfo()}
                type={"primary"}
              >
                Сохранить
              </Button>
            </div>
            {/* фото */}
            <div
              className={classes.picture_loader}
              style={{ backgroundImage: `url(${this.props.userpic_url})` }}
            >
              <input
                className={classes.picture_input}
                type={"file"}
                onChange={(event) => this.addImageHadler(event)}
              />
              {this.props.userpic_url ? (
                <div
                  className={classes.remove_btn}
                  onClick={() => this.removeImageHandler()}
                >
                  <img src={"/profile/images/cross.svg"} alt={"remove"} />
                </div>
              ) : (
                <div className={classes.add_btn}>
                  {this.state.isUserpicLoading ? (
                    <FileLoader style={{ height: "14px" }} />
                  ) : (
                    <img src={"/profile/images/plus.svg"} alt={"add"} />
                  )}
                  <div className={classes.add_btn__text}>Загрузить фото</div>
                </div>
              )}
            </div>
          </div>
        </form>
        {/* модальное окно*/}
        {this.state.modalShow && this.props.editedProvider ? (
          <ModalWindow closed={this.modalShowClose}>
            {this.props.editedProvider.user.is_active ? (
              <ModalContent
                title={"Пользователь разблокирован!"}
                icon={"/profile/images/unblocked.svg"}
                subtitle={`Для его блокировки нажмите "Заблокировать"`}
              ></ModalContent>
            ) : (
              <ModalContent
                title={"Пользователь заблокирован!"}
                icon={"/profile/images/blocked.svg"}
                subtitle={`Для его разблокировки нажмите "Разблокировать"`}
              ></ModalContent>
            )}
          </ModalWindow>
        ) : null}
        {this.state.confirmModalShow ? (
          <ModalWindow closed={this.modalShowClose}>
            <DeleteConfirm
              confirmed={
                !this.props.editedProvider.user.is_deleted
                  ? this.deleteConfirmedHandler
                  : this.restoreConfirmedHandler
              }
              closed={this.modalShowClose}
              title={`Вы точно хотите ${
                this.props.editedProvider.user.is_deleted
                  ? "восстановить"
                  : "удалить"
              } провайдера: "${this.state.personalData.second_name.value} ${
                this.state.personalData.first_name.value
              }" ?`}
              action={
                this.props.editedProvider.user.is_deleted
                  ? "Восстановить"
                  : "Удалить"
              }
            >
              {this.props.editedProvider.user.is_deleted ? null : (
                <DeleteConfirmProvider
                  provider={this.state.providerToAttached}
                  setProvider={this.attachProvider}
                  withoutId={this.props.editedProvider.user.id}
                />
              )}
            </DeleteConfirm>
          </ModalWindow>
        ) : null}
        {this.state.modalPassChangedShow &&
        this.props.editProviderStatus !== "" ? (
          <ModalWindow closed={this.modalShowClose}>
            {this.props.editProviderStatus === "SUCCESS" ? (
              <ModalContent
                title={"Успех!"}
                status={this.props.editProviderStatus}
              ></ModalContent>
            ) : (
              <ModalContent
                title={"Ошибка!"}
                subtitle={`Попробуйте позже`}
                status={this.props.editProviderStatus}
              ></ModalContent>
            )}
          </ModalWindow>
        ) : null}
        {this.props.accountDeleteStatus !== "" ? (
          <ModalWindow closed={this.modalShowClose}>
            {this.props.accountDeleteStatus === "Duplicate user" ? (
              <ModalContent
                title={"Ошибка!"}
                subtitle={`Пользователь с данным Email не может быть восстановлен, т.к. уже зарегистрирован другой пользователь с данным Email`}
                icon={"/profile/images/ERROR.svg"}
                status={this.props.accountDeleteStatus}
              ></ModalContent>
            ) : null}
          </ModalWindow>
        ) : null}
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    userpic_url: state.providers.userpic_url,
    editProviderStatus: state.providers.editProviderStatus,
    editedProvider: state.providers.editedProvider,
    accountDeleteStatus: state.providers.deleteProviderStatus,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    editProvider: (data) => dispatch(editProvider(data)),
    getProviderByID: (id) => dispatch(getProviderByID(id)),
    sendNewPassword: (data) => dispatch(sendNewPassword(data)),
    changeUserStatus: (id, data) => dispatch(changeUserStatus(id, data)),
    changeUserDeletedStatus: (id, data) =>
      dispatch(changeUserDeletedStatus(id, data)),
    loadPicture: (file) => dispatch(loadPicture(file)),
    removePicture: () => dispatch(removePicture()),
    clearEditProviderStatus: () => dispatch(clearEditProviderStatus()),
    clearDeleteProviderStatus: () => dispatch(clearDeleteProviderStatus()),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ProviderEdit);
