import React from 'react';
import classes from './FeedbackItem.module.scss';

const TEXT_LIMIT = 300;

const FeedbackItem = props => {

    const [isFull, setIsFull] = React.useState(props.text.length < TEXT_LIMIT);
    let text = props.text;
    if (!isFull) {
        text = text.substring(0, TEXT_LIMIT - 1) + "...";
    }

    return (
        <div className={classes.FeedbackItem}>
            <div className={classes.date}>
                {props.date}
            </div>
            <div className={classes.title}>
                {props.title} <a href={`mailto:${props.email}`}>{props.email}</a>
            </div>
            <div className={classes.text}>
                {text}
            </div>
            {props.text.length >= TEXT_LIMIT ?
                <div
                    className={classes.toggle}
                    onClick={() => { setIsFull(!isFull) }}
                >
                    {isFull ? "Свернуть" : "Читать еще"}
                </div>
                :
                null
            }
        </div>
    )
}


export default FeedbackItem