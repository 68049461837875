import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';

import Input from 'components/UI/Input/Input';
import GenderControl from 'components/UI/GenderControl/GenderControl';
import Button from 'components/UI/Button/Button';

import classes from './PatientInvite.module.scss';
import { renderInputs, validateControl } from 'containers/Auth/Auth';
import { convertDateToString } from 'components/Profile/dateConverter';
import { acceptPatientInvitation } from 'store/Auth/actions';
import { errorDescriptions } from 'containers/Profile/Profile';

class PatientInvite extends Component {
    state = {
        isUserpicLoading: false,
        isAddressesMatch: false,
        nextPage: false,
        gender: 'male',
        selectedRole: null,
        isNameValid: false,
        isAddressValid: true,
        password: {
            password: {
                value: '',
                type: 'password',
                errorMessage: 'Пароль должен содержать не менее 8 символов, цифры и латинские буквы, не должен являться словарным словом или набором символов, находящихся рядом на клавиатуре',
                placeholder: 'Пароль',
                label: 'Пароль',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 8
                }
            },
            repeatPassword: {
                value: '',
                type: 'password',
                errorMessage: 'Пароли должны совпадать',
                placeholder: 'Повторите пароль',
                label: 'Повторите пароль',
                valid: false,
                touched: false,
                validation: {},
            },
        },
        name: {
            secondName: {
                value: '',
                type: 'text',
                errorMessage: 'Обязательное поле',
                placeholder: 'Фамилия',
                label: 'Фамилия',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                }
            },
            firstName: {
                value: '',
                type: 'text',
                errorMessage: 'Обязательное поле',
                placeholder: 'Имя',
                label: 'Имя',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                }
            },
            patronymicName: {
                value: '',
                type: 'text',
                errorMessage: '',
                placeholder: 'Отчество',
                label: 'Отчество',
                valid: true,
                touched: true,
                validation: {},
            }
        },
        date: {
            value: null,
            type: 'date',
            errorMessage: 'Введите корректное значение',
            placeholder: 'Дата Рождения',
            label: 'Дата Рождения',
            valid: false,
            touched: false,
            validation: {
                required: true,
            }
        },
        address: {
            registrationAddress: {
                value: '',
                type: 'address',
                errorMessage: '',
                placeholder: 'Адрес регистрации',
                label: 'Адрес регистрации',
                valid: true,
                touched: true,
                validation: {}
            },
            realAddress: {
                value: '',
                type: 'address',
                errorMessage: '',
                placeholder: 'Адрес места жительства',
                label: 'Адрес места жительства',
                valid: true,
                touched: true,
                validation: {}
            },
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Дублируем адреса
        if (prevState.isAddressesMatch === false && this.state.isAddressesMatch === true) {
            const formControls = { ...this.state.address };
            const realAddress = { ...formControls["realAddress"] }
            const registrationAddress = { ...formControls["registrationAddress"] }
            realAddress.value = registrationAddress.value;
            formControls.realAddress = realAddress;
            formControls.registrationAddress = registrationAddress;
            this.setState({
                address: formControls,
            });
        }
        if (prevProps.userpic_url !== this.props.userpic_url) {
            this.setState({ isUserpicLoading: false })
        }
        if (prevProps.acceptPatientInvitationStatus !== this.props.acceptPatientInvitationStatus) {
            if (this.props.acceptPatientInvitationStatus === "SUCCESS") {
                this.props.history.push({ pathname: "/login" });
            }
        }
    }

    onChangeNameHandler = (event, controlName) => {
        const formControls = { ...this.state.name }
        const control = { ...formControls[controlName] }

        control.value = event.target.value
        control.touched = true
        control.valid = validateControl(control.value, control.validation)

        formControls[controlName] = control

        let isNameValid = true

        Object.keys(formControls).forEach(name => {
            isNameValid = formControls[name].valid && isNameValid;
        })

        this.setState({
            name: formControls, isNameValid
        })
    }

    onChangePasswordHandler = (event, controlName) => {
        const formControls = { ...this.state.password }
        const control = { ...formControls[controlName] }

        control.value = event.target.value
        control.touched = true
        control.valid = validateControl(control.value, control.validation)

        formControls[controlName] = control

        let isPasswordValid = true

        Object.keys(formControls).forEach(name => {
            isPasswordValid = formControls[name].valid && isPasswordValid;
        })

        let isPasswordsMatch = false;

        if (controlName === "repeatPassword") {
            isPasswordsMatch = (control.value === formControls.password.value);
            control.valid = isPasswordsMatch;
        }

        this.setState({
            password: formControls, isPasswordValid, isPasswordsMatch
        })
    }

    onChangeAddressHandler = (event, controlName) => {
        const formControls = { ...this.state.address };
        const control = { ...formControls[controlName] };

        control.value = event;
        control.touched = true;
        control.valid = validateControl(control.value, control.validation);

        if (controlName === "registrationAddress" && this.state.isAddressesMatch) {
            const realAddress = { ...formControls["realAddress"] };
            realAddress.value = control.value;
            realAddress.touched = true;
            realAddress.valid = control.valid;
            formControls["realAddress"] = realAddress;
        }

        formControls[controlName] = control;
        let isAddressValid = true;

        Object.keys(formControls).forEach(name => {
            isAddressValid = formControls[name].valid && isAddressValid;
        })

        this.setState({
            address: formControls, isAddressValid,
        })
    }

    onChangeDateHandler = (date) => {
        let control = { ...this.state.date };
        control.value = date;
        control.touched = true;
        control.valid = validateControl(control.value, control.validation)
        this.setState({
            date: control
        })
    }

    genderChangeHandler = (gender) => {
        this.setState({ gender });
    }

    changePageHandler = (isNext) => {
        this.setState({ nextPage: isNext });
    }

    createHandler() {
        const search = document.location.search;
        let token = null;
        if (search.indexOf("token") !== -1) {
            const tokenStartIndex = search.indexOf("token=") + ("token=").length;
            token = search.slice(tokenStartIndex);
        }
        const profileData = {
            token: token,
            password: this.state.password.password.value,
            first_name: this.state.name.firstName.value,
            second_name: this.state.name.secondName.value,
            patronymic_name: this.state.name.patronymicName.value,
            date_of_birth: convertDateToString(this.state.date.value),
            gender: this.state.gender,
            registration_address: this.state.address.registrationAddress.value.value ||
                this.state.address.registrationAddress.value,
            real_address: this.state.address.realAddress.value.value ||
                this.state.address.realAddress.value,
            userpic_url: this.props.userpic_url,
        }
        this.props.acceptPatientInvitation(profileData);
    }

    renderForm = () => {
        return (
            <React.Fragment>
                {/* Личная информация */}
                <div className={classes.subtitle}>Личная информация</div>
                {renderInputs(this.state.password, this.onChangePasswordHandler)}
                {renderInputs(this.state.name, this.onChangeNameHandler)}
                <div className={classes.row}>
                    <div className={classes.gender}>
                        <GenderControl
                            value={this.state.gender}
                            onChange={this.genderChangeHandler}
                        />
                    </div>
                    <div className={classes.date}>
                        <Input
                            type={this.state.date.type}
                            value={this.state.date.value}
                            placeholder={this.state.date.placeholder}
                            valid={this.state.date.valid}
                            touched={this.state.date.touched}
                            label={this.state.date.label}
                            shouldValidate={!!this.state.date.validation}
                            errorMessage={this.state.date.errorMessage}
                            onChange={(event, date) => this.onChangeDateHandler(date)}
                            style={{ fontFamily: 'inherit' }}
                        />
                    </div>
                </div>
                {/* Адрес регистрации */}
                <Input
                    type={this.state.address.registrationAddress.type}
                    value={this.state.address.registrationAddress.value}
                    placeholder={this.state.address.registrationAddress.placeholder}
                    valid={this.state.address.registrationAddress.valid}
                    touched={this.state.address.registrationAddress.touched}
                    label={this.state.address.registrationAddress.label}
                    shouldValidate={!!this.state.address.registrationAddress.validation}
                    errorMessage={this.state.address.registrationAddress.errorMessage}
                    onChange={(event) => this.onChangeAddressHandler(event, "registrationAddress")}
                    style={{ fontFamily: 'inherit' }}
                />
                <Input
                    type={this.state.address.realAddress.type}
                    value={this.state.address.realAddress.value}
                    placeholder={this.state.address.realAddress.placeholder}
                    valid={this.state.address.realAddress.valid}
                    touched={this.state.address.realAddress.touched}
                    label={this.state.address.realAddress.label}
                    shouldValidate={!!this.state.address.realAddress.validation}
                    errorMessage={this.state.address.realAddress.errorMessage}
                    onChange={(event) => this.onChangeAddressHandler(event, "realAddress")}
                    style={{ fontFamily: 'inherit' }}
                    disabled={this.state.isAddressesMatch}
                />
                {/* адреса совпадают */}
                <label className={classes.checkbox}>
                    <input
                        type="checkbox"
                        onChange={event => { this.setState({ isAddressesMatch: !!event.target.checked }) }}
                        checked={this.state.isAddressesMatch}
                    />
                    <span className={classes.checkmark} />
                  Адреса совпадают
              </label>
                <Button
                    type={'primary'}
                    disabled={!(this.state.isAddressValid && this.state.isNameValid && this.state.date.valid && this.state.isPasswordsMatch)}
                    onClick={() => this.createHandler()}
                >Готово</Button>
                {this.props.acceptPatientInvitationError ?
                    <div className={classes.error}>
                        {errorDescriptions[this.props.acceptPatientInvitationError.data.detail]}
                    </div>
                    :
                    null
                }
            </React.Fragment>
        )
    }

    render() {
        return (
            <div className={classes.PatientInvite}>
                <h3 className={classes.title}>Регистрация пациента</h3>
                {this.renderForm()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        acceptPatientInvitationError: state.auth.acceptPatientInvitationError,
        acceptPatientInvitationStatus: state.auth.acceptPatientInvitationStatus
    }
}

function mapDispatchToProps(dispatch) {
    return {
        acceptPatientInvitation: (profileData) => dispatch(acceptPatientInvitation(profileData)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PatientInvite))