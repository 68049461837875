import React from "react";
import { useHistory } from "react-router-dom";

import {
  getFormatDate,
  getTimeFromDate,
} from "components/Profile/CardList/enums";

import classes from "./UsersLogRow.module.scss";

const rolesEnum = {
  patient: "Пациент",
  doctor: "Специалист",
};

const UsersLogRow = (props) => {
  const history = useHistory();

  const urlBase = {
    patient: "/profile/patients/patient/",
    doctor: "/profile/doctors/doctor_edit/",
  };

  const onHyperlinkClick = () => {
    if (props.user) {
      const id = props.user.id;

      let openUrl;

      if (props.role === "assistant" || props.role === "provider") {
        openUrl = urlBase[props.user.role] + id;
      } else {
        openUrl = urlBase[props.user.role] + id;
      }

      if (props.inNewWindow === true) {
        window.open(openUrl);
      } else {
        history.push(openUrl);
      }
    }
  };

  return (
    <tr
      className={`${classes.UsersLogRow} ${
        props.user ? classes.hoveredRow : ""
      }`}
      onClick={onHyperlinkClick}
    >
      <td>{`${props.user?.profile.second_name || ""} ${
        props.user?.profile.first_name || ""
      } ${props.user?.profile.patronymic_name || ""}`}</td>
      <td>{props.email}</td>
      {props.accountRole === "provider" ? (
        <td>{rolesEnum[props.user.role]}</td>
      ) : null}
      <td>
        {props.sendDate
          ? `${getFormatDate(props.sendDate)} ${getTimeFromDate(
              props.sendDate
            )}`
          : null}
      </td>
      <td>
        {props.registeredDate
          ? `${getFormatDate(props.registeredDate)} ${getTimeFromDate(
              props.registeredDate
            )}`
          : null}
      </td>
    </tr>
  );
};

export default UsersLogRow;
