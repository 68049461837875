import axios from "axios";

import {
  GET_SUPPORTSLIST_ERROR,
  GET_SUPPORTSLIST_SUCCESS,
  CREATE_SUPPORT_ERROR,
  CREATE_SUPPORT_SUCCESS,
  CLEAR_CREATE_SUPPORT_STATUS,
  EDIT_SUPPORT_SUCCESS,
  EDIT_SUPPORT_ERROR,
  GET_SUPPORTBYID_SUCCESS,
  GET_SUPPORTBYID_ERROR,
  LOAD_PICTURE_SUCCESS,
  LOAD_PICTURE_ERROR,
  REMOVE_PICTURE,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_ERROR,
  CHANGE_DELETED_STATUS_SUCCESS,
  CHANGE_DELETED_STATUS_ERROR,
  CLEAR_DELETE_SUPPORT_STATUS,
} from "./actionTypes";

export function getSupportsList(page, size, q) {
  return async (dispatch) => {
    axios
      .get("/users/", {
        params: {
          role: "support",
          page,
          size,
          q,
        },
      })
      .then((response) => {
        dispatch(getSupportsListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getSupportsListError());
        console.log(err);
      });
  };
}

export function createSupport(data) {
  return async (dispatch) => {
    let sendingData = {
      email: data.email,
      password: data.password,
      first_name: data.first_name,
      second_name: data.second_name,
      patronymic_name: data.patronymic_name,
      gender: data.gender,
      date_of_birth: data.date_of_birth,
      registration_address: data.registration_address,
      real_address: data.real_address,
      userpic_url: data.userpic_url,
    };
    axios
      .post("/users/invite_support", sendingData)
      .then(() => {
        dispatch(createSupportSuccess());
      })
      .catch((err) => {
        dispatch(createSupportError());
        console.log(err);
      });
  };
}
export function getSupportByID(id) {
  return async (dispatch) => {
    axios
      .get(`/profiles/${id}`)
      .then((response) => {
        dispatch(getSupportByIDSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getSupportByIDError());
        console.log(err);
      });
  };
}

export function changeUserStatus(userId, data) {
  return async (dispatch) => {
    axios
      .post(`/users/${userId}/change_status`, data)
      .then(() => {
        dispatch(changeUserStatusSuccess());
        dispatch(getSupportByID(userId));
      })
      .catch((err) => {
        dispatch(changeUserStatusError());
        console.log(err);
      });
  };
}

export function changeUserDeletedStatus(userId, data) {
  return async (dispatch) => {
    axios
      .post(`/users/${userId}/change_deleted_status`, data)
      .then(() => {
        dispatch(changeUserDeletedStatusSuccess());
        dispatch(getSupportByID(userId));
      })
      .catch((err) => {
        dispatch(changeUserDeletedStatusError(err.response.data.detail));
        console.log(err);
      });
  };
}

export function editSupport(data) {
  return async (dispatch) => {
    let sendingData = {
      first_name: data.first_name,
      second_name: data.second_name,
      patronymic_name: data.patronymic_name,
      gender: data.gender,
      date_of_birth: data.date_of_birth,
      registration_address: data.registration_address,
      real_address: data.real_address,
      userpic_url: data.userpic_url,
    };
    axios
      .patch(`/profiles/${data.id}`, sendingData)
      .then(() => {
        dispatch(editSupportSuccess());
        dispatch(getSupportByID(data.id));
      })
      .catch((err) => {
        dispatch(editSupportError());
        console.log(err);
      });
  };
}

export function sendNewPassword(data) {
  return async (dispatch) => {
    let sendingData = {
      newpassword1: data.password,
      newpassword2: data.repeatPassword,
    };
    axios
      .patch(`/profiles/${data.id}`, sendingData)
      .then(() => {
        dispatch(editSupportSuccess());
        dispatch(getSupportByID(data.id));
      })
      .catch((err) => {
        dispatch(editSupportError());
        console.log(err);
      });
  };
}
export function loadPicture(file) {
  return async (dispatch) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: "/profiles/upload_photo",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        dispatch(loadPictureSuccess(response.data.url));
      })
      .catch(function (error) {
        dispatch(loadPictureError());
        console.log(error);
      });
  };
}

export function removePicture() {
  return {
    type: REMOVE_PICTURE,
  };
}
export function loadPictureSuccess(url) {
  return {
    type: LOAD_PICTURE_SUCCESS,
    url,
  };
}
export function loadPictureError() {
  return {
    type: LOAD_PICTURE_ERROR,
  };
}
export function getSupportsListSuccess(data) {
  return {
    type: GET_SUPPORTSLIST_SUCCESS,
    data,
  };
}

export function getSupportsListError() {
  return {
    type: GET_SUPPORTSLIST_ERROR,
  };
}
export function createSupportSuccess() {
  return {
    type: CREATE_SUPPORT_SUCCESS,
    createSupportStatus: "SUCCESS",
  };
}

export function createSupportError() {
  return {
    type: CREATE_SUPPORT_ERROR,
    createSupportStatus: "ERROR",
  };
}
export function editSupportSuccess() {
  return {
    type: EDIT_SUPPORT_SUCCESS,
    editSupportStatus: "SUCCESS",
  };
}

export function editSupportError() {
  return {
    type: EDIT_SUPPORT_ERROR,
    editSupportStatus: "ERROR",
  };
}
export function getSupportByIDSuccess(editedSupport) {
  return {
    type: GET_SUPPORTBYID_SUCCESS,
    editedSupport: editedSupport,
  };
}

export function getSupportByIDError() {
  return {
    type: GET_SUPPORTBYID_ERROR,
  };
}
export function clearCreateSupportStatus() {
  return {
    type: CLEAR_CREATE_SUPPORT_STATUS,
  };
}
export function changeUserStatusSuccess() {
  return {
    type: CHANGE_STATUS_SUCCESS,
  };
}

export function changeUserStatusError() {
  return {
    type: CHANGE_STATUS_ERROR,
  };
}

export function changeUserDeletedStatusSuccess() {
  return {
    type: CHANGE_DELETED_STATUS_SUCCESS,
  };
}

export function changeUserDeletedStatusError(error) {
  return {
    type: CHANGE_DELETED_STATUS_ERROR,
    error,
  };
}
export function clearDeleteSupportStatus() {
  return {
    type: CLEAR_DELETE_SUPPORT_STATUS,
  };
}
