import React from "react";
import { Link, NavLink } from "react-router-dom";
import classes from "./Article.module.scss";

function getConvertedDate(date) {// Возвращает строку в формате дд.мм.гггг
    let cuttedDate = date.slice(0, 10);
    let splittedDate = cuttedDate.split("-");
    return [splittedDate[2], splittedDate[1], splittedDate[0]].join(".");
}

// удаляет теги и символы пробелов
function cleanedText(text) {
  return text.replace(/<\/?[^>]+(>|$)/g, " ").replace(/&nbsp;/g, "");
}

const Article = (props) => {
  if (props.type === "small") {
    return (
      <Link className={classes.ArticleSmall} to={props.linkTo || "/"}>
        <h5 className={classes.title}>{props.title}</h5>
        <div className={classes.date}>{getConvertedDate(props.date)}</div>
        <div className={classes.excerpt}>
          {/* Отрывок текста статьи */}
          {cleanedText(props.text)}
        </div>
        <div
          className={classes.image}
          style={{ backgroundImage: `url("${props.imageUrl}")` }}
        />
      </Link>
    );
    // props: type, linkTo (edit), title, date, text, imageUrl, id, deleteHandler
  } else if (props.type === "longAdmin") {
    return (
      <div className={classes.ArticleLongAdmin}>
        <div className={classes.textBody_wrapper}>
          <div className={classes.date}>{props.date}</div>
          <h5 className={classes.title}>{props.title}</h5>
          <div className={classes.excerpt}>
            {/* Отрывок текста статьи */}
            {cleanedText(props.text)}
          </div>
          <div className={classes.buttons}>
            <button
              className={classes.buttons__delete}
              onClick={() => props.deleteHandler(props.id, props.title)}
            >
            Удалить
            </button>
            <div className={classes.buttons__ellipse}></div>
            <NavLink to={props.linkTo} className={classes.buttons__edit}>
              Редактировать
            </NavLink>
          </div>
        </div>
        {props.imageUrl ? (
          <div
            className={classes.image}
            style={{ backgroundImage: `url("${props.imageUrl}")` }}
          />
        ) : null}
      </div>
    );
  }
};

export default Article;
