import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from 'components/UI/Button/Button';
import Container from 'hoc/Container/Container';
import BackLink from 'components/UI/BackLink/BackLink';

import { validateControl, renderInputs } from '../Auth';
import classes from './NewPassword.module.scss';
import { createNewPassword, clearNewPasswordStatus } from 'store/Auth/actions';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';


class NewPassword extends Component {
    state = {
        isPasswordsMatch: false,
        formControls: {
            password: {
                value: '',
                type: 'password',
                errorMessage: 'Пароль должен содержать не менее 8 символов, цифры и латинские буквы, не должен являться словарным словом или набором символов, находящихся рядом на клавиатуре',
                placeholder: 'Пароль',
                label: 'Пароль',
                valid: false,
                touched: false,
                validation: {
                    required: true,
                    minLength: 8
                }
            },
            repeatPassword: {
                value: '',
                type: 'password',
                errorMessage: 'Пароли должны совпадать',
                placeholder: 'Повторите пароль',
                label: 'Повторите пароль',
                valid: false,
                touched: false,
                validation: {},
            }
        }
    }
    componentWillUnmount() {
        this.props.clearNewPasswordStatus();
    }
    onChangeHandler = (event, controlName) => {

        const formControls = { ...this.state.formControls }
        const control = { ...formControls[controlName] }

        control.value = event.target.value
        control.touched = true
        control.valid = validateControl(control.value, control.validation)

        let isPasswordsMatch = false;

        if (controlName === "repeatPassword") {
            isPasswordsMatch = (control.value === formControls.password.value);
            control.valid = isPasswordsMatch;
        }

        formControls[controlName] = control;

        this.setState({
            isPasswordsMatch,
            formControls,
        });
    }

    sendHandler = () => {
        const search = document.location.search;
        let sendData = {
            new_password1: this.state.formControls.password.value,
            new_password2: this.state.formControls.repeatPassword.value,
        }

        if (search.indexOf("token") !== -1) {
            const tokenStartIndex = search.indexOf("token=") + ("token=").length;
            const token = search.slice(tokenStartIndex);
            sendData.token = token;
        }

        this.props.createNewPassword(sendData);

        let formControls = { ...this.state.formControls }
        formControls.password.value = "";
        formControls.password.valid = false;
        formControls.password.touched = false;
        formControls.repeatPassword.value = "";
        formControls.repeatPassword.valid = false;
        formControls.repeatPassword.touched = false;

        this.setState({ formControls });
    }

    render() {
        return (
            <section className={classes.NewPassword}>
                <Helmet>
                    <title>Новый пароль. Портал здоровья</title>
                    <meta name="title" content="Новый пароль. Портал здоровья" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="Новый пароль. Портал здоровья" />
                </Helmet>
                {this.props.createNewPasswordStatus === "SUCCESS" ?
                    <Redirect to="/profile/login" />
                    :
                    null
                }
                <Container innerClassName={classes.inner}>
                    <BackLink to={'/profile/login'} />
                    <h3 className={classes.title}>
                        Восстановление пароля
                    </h3>
                    <p className={classes.text}>
                        Придумайте новый пароль
                    </p>
                    {renderInputs(this.state.formControls, this.onChangeHandler)}
                    <Button
                        type={"primary"}
                        onClick={this.sendHandler}
                        disabled={!this.state.isPasswordsMatch}
                    >
                        Сменить пароль
                    </Button>
                    {this.props.createNewPasswordStatus === "ERROR" ?
                        <div className={classes.errorMessage}>
                            Произошла ошибка. Время действия токена закочено.
                    </div>
                        :
                        null
                    }
                    {this.props.createNewPasswordStatus === "SUCCESS" ?
                        <div className={classes.successMessage}>
                            Пароль успешно изменен.
                    </div>
                        :
                        null
                    }
                </Container>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        createNewPasswordStatus: state.auth.createNewPasswordStatus
    }
}


function mapDispatchToProps(dispatch) {
    return {
        createNewPassword: (sendData) => dispatch(createNewPassword(sendData)),
        clearNewPasswordStatus: () => dispatch(clearNewPasswordStatus()),
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NewPassword)