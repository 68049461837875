import React from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import './CustomDatePicker.scss';


function isPhone() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
        return true;
    } else return false;
}

function converDate(date) {
    if (!date) return '';

    var dd = date.getDate();
    if (dd < 10) dd = '0' + dd;

    var mm = date.getMonth() + 1;
    if (mm < 10) mm = '0' + mm;

    var yy = date.getFullYear() % 100;
    if (yy < 10) yy = '0' + yy;

    return `${dd}.${mm}.${yy}`;
}



const CustomDatePicker = props => {
    registerLocale("ru", ru);

    const datePickerRef = React.createRef();

    return (
        <DatePicker
            ref={datePickerRef}
            className="datepicker"
            selected={props.selected}
            onChange={props.onChange}
            dateFormat="yyyy.MM.dd"
            locale="ru"
            calendarClassName="calendar"
            disabledKeyboardNavigation
            closeOnScroll={false}
            withPortal={isPhone()}
            selectsRange={props.selectsRange}
            startDate={props.startDate}
            endDate={props.endDate}
            maxDate={props.maxDate}
            minDate={props.minDate}
            shouldCloseOnSelect={!props.selectsRange}
            value={(converDate(props.startDate) !== converDate(props.endDate) ? converDate(props.startDate) + " - " + converDate(props.endDate) : converDate(props.startDate)) || "Календарь"}
        >
            {props.selectsRange ?
                <div className={"custom-datepicker__btn-wrapper"}>
                    <span
                        onClick={() => {
                            props.onCancelClick();
                            datePickerRef.current.setOpen(false);
                        }}
                        className={"custom-datepicker__btn"}
                    >
                        Отмена
                        </span>
                    <span
                        onClick={() => {
                            props.onOkClick();
                            datePickerRef.current.setOpen(false);
                        }}
                        className={"custom-datepicker__btn"}
                    >
                        Ок
                    </span>
                </div>
                :
                null
            }

        </DatePicker>
    )
}


export default CustomDatePicker