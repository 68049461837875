import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";
import FileItem from "components/UI/FileControl/FileItem/FileItem";
import Button from "components/UI/Button/Button";
import Select from "components/UI/Select/Select";
import classes from "./CardExpanded.module.scss";
import Chat from "components/Profile/Chat/Chat";
import TextArea from "components/UI/TextArea/TextArea";
import Payment from "./Payment/Payment";

import { typeEnum, statusEnum, getFormatDate, getTimeFromDate } from "../enums";
import { isChatDisabled } from "../isChatDisabled";

import {
  changeCardStatus,
  getCardInfo,
  getSpecializations,
  assignSpecialist,
  getCardInfoInternal,
  updateConclusion,
  setScrollStatus,
} from "store/Profile/Consultations/actions";
import {
  getDocumentsAccess,
  clearGetAccessStatus,
} from "store/Profile/Patients/actions";

import { getPublicDoctorsList } from "store/Profile/Doctors/actions";

class CardExpanded extends Component {
  state = {
    //   работа с назначение специалиста
    isLoading: true,
    pickedDoctorIndex: "",
    pickedSpecializationIndex: "",
    pickedSpecialistIndex: 0,
    conclusion: null,
    isConclusionChanged: false,
    pickedDoctorProfileId: null,
  };
  componentDidMount() {
    if (
      this.props.role === "patient" ||
      localStorage.getItem("role") === "patient"
    ) {
      this.props.getCardInfo(this.props.match.params.id);
    } else {
      this.props.getCardInfoInternal(this.props.match.params.id);
    }
    this.props.getSpecializations();
    if (
      this.props.role === "assistant" ||
      localStorage.getItem("role") === "assistant"
    ) {
      this.props.getPublicDoctorsList(
        +this.state.pickedSpecializationIndex + 1,
        true
      );
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.cardInfo !== null &&
      this.props.doctorsList !== null &&
      this.props.specializations !== null &&
      (prevProps.cardInfo !== this.props.cardInfo ||
        // prevProps.doctorsList !== this.props.doctorsList ||
        prevProps.specializations !== this.props.specializations)
    ) {
      this.initState();
    }
    if (prevProps.cardInfo !== this.props.cardInfo) {
      this.setState({ isLoading: false });
    }
    // инициализация заключения
    if (this.props.cardInfo !== null) {
      if (
        this.props.cardInfo.conclusion !== this.state.conclusion &&
        !this.state.isConclusionChanged &&
        prevProps.cardInfo !== this.props.cardInfo
      ) {
        this.setState({
          conclusion: this.props.cardInfo.conclusion,
        });
      }
    }

    // скролл до чата и фокус
    if (this.props.scrollStatus && document.getElementById("chatInput")) {
      const elem = document.getElementById("chatInput");
      elem.focus();
      this.props.setScrollStatus(false);
    }
    if (
      prevState.pickedSpecializationIndex !==
      this.state.pickedSpecializationIndex
    ) {
      this.props.getPublicDoctorsList(
        +this.state.pickedSpecializationIndex + 1,
        true
      );
      this.setState({ pickedDoctorProfileId: null });
    }
  };
  componentWillUnmount = () => {
    this.props.clearGetAccessStatus();
    this.props.setScrollStatus(false);
  };
  initState = () => {
    let newState = { ...this.state };
    let specializations = this.props.specializations;
    let desired_performer =
      this.props.cardInfo.desired_performer === null
        ? null
        : this.props.cardInfo.desired_performer;
    let performer =
      this.props.cardInfo.performer === null
        ? null
        : this.props.cardInfo.performer;

    let performedSpecialization =
      this.props.cardInfo.specialization === null
        ? null
        : this.props.cardInfo.specialization;

    if (performer !== null) {
      if (performer.role === "doctor") {
        newState.pickedSpecialistIndex = 1;
        specializations.forEach((item) => {
          if (item.id === performer.doctor_profile.specialization.id) {
            newState.pickedSpecializationIndex = specializations.indexOf(item);
          }
        });
        let doctors = [];
        this.props.doctorsList.map((obj) => {
          if (
            obj.doctor_profile !== null &&
            obj.doctor_profile.specialization.id ===
              +newState.pickedSpecializationIndex + 1
          ) {
            doctors.push(obj.id);
          }
        });
        doctors.forEach((docId) => {
          if (docId === performer.id) {
            newState.pickedDoctorIndex = doctors.indexOf(docId);
            newState.pickedDoctorProfileId = docId;
          }
        });
      } else if (performer.role === "assistant") {
        newState.pickedSpecialistIndex = 2;
      } else {
        newState.pickedSpecialistIndex = 0;
      }
    } else if (desired_performer !== null) {
      if (desired_performer.role === "doctor") {
        newState.pickedSpecialistIndex = 1;
        specializations.forEach((item) => {
          if (item.id === desired_performer.doctor_profile.specialization.id) {
            newState.pickedSpecializationIndex = specializations.indexOf(item);
          }
        });
        let doctors = [];
        this.props.doctorsList.map((obj) => {
          if (
            obj.doctor_profile !== null &&
            obj.doctor_profile.specialization.id ===
              +newState.pickedSpecializationIndex + 1
          ) {
            doctors.push(obj.id);
          }
        });
        doctors.forEach((docId) => {
          if (docId === desired_performer.id) {
            newState.pickedDoctorIndex = doctors.indexOf(docId);
          }
        });
      } else if (desired_performer.role === "assistant") {
        newState.pickedSpecialistIndex = 2;
      } else {
        newState.pickedSpecialistIndex = 0;
      }
    } else if (performedSpecialization !== null) {
      specializations.forEach((item) => {
        if (item.id === performedSpecialization.id) {
          newState.pickedSpecializationIndex = specializations.indexOf(item);
        }
      });
    }
    this.setState({ ...newState });
  };
  getFullName = () => {
    const creator = { ...this.props.cardInfo.creator.profile };
    return `${creator.second_name} ${creator.first_name} ${
      creator.patronymic_name ? creator.patronymic_name : ""
    }`;
  };

  changeCardStatusHandler = (id, newStatus) => {
    const data = { status: newStatus };

    return new Promise((resolve) => {
      this.props.changeCardStatus(id, data);
      resolve();
    }).then(() => {
      // this.props.getCardInfo(this.props.match.params.id);
    });
    // this.props.history.push("/profile/consultations");
  };

  pickSpecialistHandler = (index) => {
    this.setState({ pickedSpecialistIndex: index });
  };
  pickSpecializationHandler = (index) => {
    this.setState({ pickedSpecializationIndex: index });
  };
  pickDoctorHandler = (index) => {
    this.setState({ pickedDoctorIndex: index });
  };
  getDocumentsAccessHandler = (id) => {
    this.props.getDocumentsAccess(id);
  };
  assignSpecialistHandler = (id) => {
    const data = {
      specialization_id: +this.state.pickedSpecializationIndex + 1,
      performer_id:
        this.state.pickedDoctorIndex === "" ||
        this.state.pickedSpecialistIndex === 0
          ? null
          : this.props.doctorsList[+this.state.pickedDoctorIndex].id,
    };
    this.props.assignSpecialist(id, data);
  };
  changeConclusionHandler = (value) => {
    this.setState({
      conclusion: value,
      isConclusionChanged: true,
    });
  };
  updateConclusionHandler = (id) => {
    const data = {
      conclusion: this.state.conclusion,
    };
    this.props.updateConclusion(id, data);
    this.setState({
      isConclusionChanged: false,
    });
  };
  filteredDoctorsList = () => {
    let filteredDoctors = [];
    this.props.doctorsList.map((obj) => {
      if (
        obj.doctor_profile !== null &&
        obj.doctor_profile.specialization.id ===
          +this.state.pickedSpecializationIndex + 1
      ) {
        filteredDoctors.push(
          obj.profile.second_name +
            " " +
            obj.profile.first_name +
            " " +
            (obj.profile.patronymic_name ? obj.profile.patronymic_name : "")
        );
      }
    });

    return filteredDoctors;
  };
  renderPickStatusSection = () => {
    return (
      <div className={classes.pickStatusSection}>
        <div>
          <div className={classes.label}>Назначить специалиста:</div>
          <div className={classes.SpecialistControl}>
            <div className={classes.row}>
              <input
                type={"radio"}
                name={"specialist"}
                value={0}
                checked={this.state.pickedSpecialistIndex === 0}
              />

              <span
                className={[
                  classes.radio,
                  this.state.pickedSpecialistIndex === 0
                    ? classes.active
                    : null,
                ].join(" ")}
                onClick={() => this.pickSpecialistHandler(0)}
              ></span>
              <div className={classes.subtitle}>специализация</div>
            </div>
            <div className={classes.row}>
              <input
                type={"radio"}
                name={"specialist"}
                value={1}
                checked={this.state.pickedSpecialistIndex === 1}
              />
              <span
                className={[
                  classes.radio,
                  this.state.pickedSpecialistIndex === 1
                    ? classes.active
                    : null,
                ].join(" ")}
                onClick={() => this.pickSpecialistHandler(1)}
              ></span>
              <div className={classes.subtitle}>конкретный специалист</div>
            </div>
            <div className={classes.row}>
              <input
                type={"radio"}
                name={"specialist"}
                value={2}
                checked={this.state.pickedSpecialistIndex === 2}
              />
              <span
                className={[
                  classes.radio,
                  this.state.pickedSpecialistIndex === 2
                    ? classes.active
                    : null,
                ].join(" ")}
                onClick={() => this.pickSpecialistHandler(2)}
              ></span>
              <div className={classes.subtitle}>ассистент</div>
            </div>
          </div>
        </div>
        {this.state.pickedSpecialistIndex !== 2 ? (
          <div className={classes.select}>
            <Select
              data={this.props.specializations.map((obj) => {
                return obj.title;
              })}
              value={this.state.pickedSpecializationIndex}
              onChange={(event) =>
                this.pickSpecializationHandler(event.target.value)
              }
              label={"Специальность"}
            />
          </div>
        ) : null}
        {this.state.pickedSpecialistIndex === 1 ? (
          <div>
            <div className={classes.select}>
              <Select
                data={this.filteredDoctorsList()}
                value={this.state.pickedDoctorIndex}
                onChange={(event) => this.pickDoctorHandler(event.target.value)}
                label={"ФИО специалиста"}
              />
            </div>
            {this.state.pickedDoctorIndex &&
            this.props.doctorsList[this.state.pickedDoctorIndex] ? (
              <a
                href={`/profile/doctors/doctor_edit/${
                  this.props.doctorsList[this.state.pickedDoctorIndex].id
                }`}
                target={"_blank"}
                rel="noopener noreferrer"
              >
                Подробнее о специалисте
              </a>
            ) : null}
          </div>
        ) : null}
        {/* если ассистент */}
        {this.state.pickedSpecialistIndex === 2 ? (
          <div className={classes.assignSpecialistBtn}>
            <Button
              type={"primary"}
              onClick={() =>
                this.changeCardStatusHandler(this.props.cardInfo.id, "accepted")
              }
            >
              Принять в работу
            </Button>
          </div>
        ) : (
          <div className={classes.assignSpecialistBtn}>
            <Button
              type={
                this.props.cardInfo.performer === null
                  ? "primary"
                  : "additional"
              }
              //   доп условия блокировки для назначения конретного врача
              disabled={
                this.state.pickedSpecialistIndex !== 1
                  ? this.state.pickedSpecializationIndex === ""
                  : this.state.pickedSpecializationIndex === "" ||
                    this.state.pickedDoctorIndex === ""
              }
              onClick={() =>
                this.assignSpecialistHandler(this.props.cardInfo.id)
              }
            >
              {this.props.cardInfo.performer === null
                ? "Назначить специалиста"
                : "Сменить специалиста"}
            </Button>
          </div>
        )}
      </div>
    );
  };

  renderAttachments = (attachments) => {
    if (attachments.length === 0) return null;

    return attachments.map((item, index) => {
      return (
        <FileItem file={item} key={index} download={true} remove={false} />
      );
    });
  };

  render() {
    if (!this.props.cardInfo || this.state.isLoading) {
      return (
        <div className={classes.CardExpanded}>
          <FileLoader style={{ margin: "60px auto 0", width: "260px" }} />
        </div>
      );
    } else
      return (
        <div className={classes.CardExpanded}>
          <div className={classes.card}>
            <div className={classes.header}>
              <div className={classes.type}>
                <strong>
                  {typeEnum[this.props.cardInfo.consultation_type]}
                </strong>
              </div>
              <div className={classes.info}>
                {/* №&nbsp;{this.props.cardInfo.id}&nbsp;от&nbsp; */}
                {getFormatDate(new Date(this.props.cardInfo.created_at))}
                {/* &nbsp;
                {getTimeFromDate(new Date(this.props.cardInfo.created_at))} */}
              </div>
              <div className={classes.status}>
                {statusEnum[this.props.cardInfo.status]}
              </div>
            </div>
            <div className={classes.main}>
              {/* Запросить доступ */}
              {this.props.role !== "patient" ? (
                <div
                  className={classes.archive_btn}
                  onClick={() =>
                    this.getDocumentsAccessHandler(
                      this.props.cardInfo.creator.id
                    )
                  }
                >
                  <img src="/profile/images/archive.svg" alt="archive" />
                  {this.props.getAccessStatus
                    ? this.props.getAccessStatus === "SENDED"
                      ? "Запрос отправлен!"
                      : "Доступ запрещен!"
                    : "Запросить доступ к архиву"}
                </div>
              ) : null}
              <div className={classes.creator}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/profile/patients/patient/${this.props.cardInfo.creator.id}`}
                  className={
                    this.props.role === "patient" ? classes.disabled_link : null
                  }
                >
                  <span className={classes.fullname}>{this.getFullName()}</span>
                </a>
                {this.props.cardInfo.creator ? (
                  <span className={classes.age}>
                    {this.props.cardInfo.creator.profile.age} лет
                  </span>
                ) : null}
              </div>
              <div className={classes.problem}>
                {this.props.cardInfo.problem_description}
              </div>
              <div className={classes.attachments}>
                {this.props.cardInfo.attachments.length !== 0 ? (
                  <span className={classes.attachments_label}>
                    Вложения пациента:
                  </span>
                ) : (
                  <span className={classes.attachments_label}>
                    Вложения пациента отсутствуют
                  </span>
                )}
                <br />
                {this.renderAttachments(this.props.cardInfo.attachments)}
              </div>
            </div>
            <div className={classes.footer}>
              <div className={classes.doctor}>
                {this.props.cardInfo.performer ? (
                  <div
                    style={
                      !(
                        this.props.cardInfo.performer.role === "assistant" &&
                        this.props.role === "patient"
                      )
                        ? { cursor: "pointer" }
                        : null
                    }
                    onClick={
                      this.props.cardInfo.performer.role === "doctor" &&
                      this.props.role !== "doctor"
                        ? (event) => {
                            event.preventDefault();
                            window.open(
                              this.props.role === "patient"
                                ? `/profile/doctors/doctor_info/${this.props.cardInfo.performer.id}`
                                : `/profile/doctors/doctor_edit/${this.props.cardInfo.performer.id}`
                            );
                          }
                        : this.props.cardInfo.performer.role === "assistant" &&
                          this.props.role === "support"
                        ? (event) => {
                            event.preventDefault();
                            window.open(
                              `/profile/assistants/assistant_edit/${this.props.cardInfo.performer.id}`
                            );
                          }
                        : null
                    }
                  >
                    <strong>
                      {`${this.props.cardInfo.performer.profile.second_name} ${
                        this.props.cardInfo.performer.profile.first_name
                      } ${
                        this.props.cardInfo.performer.profile.patronymic_name ||
                        ""
                      }`}
                    </strong>
                    {this.props.cardInfo.performer.role === "assistant" &&
                    !this.props.role === "patient" ? (
                      <img
                        className={classes.arrow}
                        src={"/profile/images/more-arrow.svg"}
                        alt={"is"}
                      />
                    ) : null}
                    <br />
                    {this.props.cardInfo.performer.role === "doctor"
                      ? this.props.cardInfo.specialization.title
                      : "Ассистент"}
                  </div>
                ) : this.props.cardInfo.desired_performer ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={
                      this.props.cardInfo.desired_performer.role === "doctor" &&
                      this.props.role !== "doctor"
                        ? (event) => {
                            event.preventDefault();
                            window.open(
                              this.props.role === "patient"
                                ? `/profile/doctors/doctor_info/${this.props.cardInfo.desired_performer.id}`
                                : `/profile/doctors/doctor_edit/${this.props.cardInfo.desired_performer.id}`
                            );
                          }
                        : null
                    }
                  >
                    <strong>
                      {`${
                        this.props.cardInfo.desired_performer.profile
                          .second_name
                      } ${
                        this.props.cardInfo.desired_performer.profile.first_name
                      } ${
                        this.props.cardInfo.desired_performer.profile
                          .patronymic_name || ""
                      }`}
                    </strong>
                    <img
                      className={classes.arrow}
                      src={"/profile/images/more-arrow.svg"}
                      alt={"is"}
                    />
                    <br />
                    {this.props.cardInfo.specialization.title}
                  </div>
                ) : (
                  <span>
                    Направление:{" "}
                    <strong>{this.props.cardInfo.specialization.title}</strong>
                  </span>
                )}
              </div>
              {this.props.role !== "support" &&
              !isChatDisabled(this.props.role, this.props.cardInfo.status) ? (
                <div
                  className={classes.chat}
                  onClick={() => this.props.setScrollStatus(true)}
                >
                  <img src={"/profile/images/dialog.svg"} alt={"dialog"} />
                  <span>Написать в диалог</span>
                </div>
              ) : null}
            </div>
            {/* Заключение врача */}
            {(this.props.role === "doctor" ||
              this.props.role === "assistant") &&
            (this.props.cardInfo.status === "in_work" ||
              this.props.cardInfo.status === "paused" ||
              this.props.cardInfo.status === "waiting_for_payment" ||
              this.props.cardInfo.status === "finished" ||
              this.props.cardInfo.status === "declined") ? (
              <div className={classes.conclusion}>
                <span className={classes.conclusion__title}>Заключение:</span>
                {this.props.cardInfo.conclusion_file_url ? (
                  <div className={classes.conclusion__file}>
                    <FileItem
                      style={{ margin: 0 }}
                      download
                      file={this.props.cardInfo.conclusion_file_url}
                    />
                  </div>
                ) : null}
                {/* Текст заключения */}
                {this.props.cardInfo.status === "finished" ||
                this.props.cardInfo.status === "declined" ? (
                  <div className={classes.conclusion__text}>
                    {this.props.cardInfo.conclusion}
                  </div>
                ) : (
                  // Редактировать заключение
                  <React.Fragment>
                    <TextArea
                      label="Заключение по приему"
                      value={this.state.conclusion}
                      onChange={(event) =>
                        this.changeConclusionHandler(event.target.value)
                      }
                    />
                    <div className={classes.conclusion__button}>
                      <Button
                        onClick={() =>
                          this.updateConclusionHandler(this.props.cardInfo.id)
                        }
                        type="primary"
                        disabled={!this.state.isConclusionChanged}
                      >
                        Сохранить
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </div>
            ) : null}
            {/* Заключение врача для пациента */}
            {this.props.role === "patient" &&
            this.props.cardInfo.status === "finished" ? (
              <div className={classes.conclusion}>
                <span className={classes.conclusion__title}>Заключение:</span>
                {this.props.cardInfo.conclusion_file_url ? (
                  <div className={classes.conclusion__file}>
                    <FileItem
                      style={{ margin: 0 }}
                      download
                      file={this.props.cardInfo.conclusion_file_url}
                    />
                  </div>
                ) : null}
                {/* Текст заключения */}
                <div className={classes.conclusion__text}>
                  {this.props.cardInfo.conclusion}
                </div>
              </div>
            ) : null}
            {/* Стоймость */}
            {this.props.cardInfo.status === "waiting_for_payment" ||
            this.props.cardInfo.status === "finished" ? (
              <div className={classes.price}>
                {this.props.cardInfo.needs_payment
                  ? `${
                      this.props.cardInfo.status === "finished"
                        ? "Оплачено:"
                        : "К оплате:"
                    } ${
                      this.props.cardInfo.price
                        ? this.props.cardInfo.price.toLocaleString()
                        : 0
                    } ₽`
                  : "Бесплатно"}
              </div>
            ) : null}

            {/* оплата */}
            {(this.props.cardInfo.status === "in_work" ||
              this.props.cardInfo.status === "paused" ||
              this.props.cardInfo.status === "waiting_for_payment") &&
            ((this.props.role === "doctor" &&
              +this.props.cardInfo.performer.id ===
                +localStorage.getItem("id")) ||
              this.props.role === "assistant" ||
              this.props.role === "support") ? (
              <Payment
                id={this.props.match.params.id}
                needs_payment={this.props.cardInfo.needs_payment}
                price={this.props.cardInfo.price}
                consultation_time={this.props.cardInfo.consultation_time}
              />
            ) : null}
            {this.props.role === "assistant" &&
            this.props.cardInfo.status === "created" ? (
              <div className={classes.buttons}>
                <Button
                  type={"additional"}
                  onClick={() =>
                    this.changeCardStatusHandler(
                      this.props.cardInfo.id,
                      "moderation"
                    )
                  }
                >
                  Обработать заявку
                </Button>
              </div>
            ) : null}
            {this.props.role === "doctor" &&
            this.props.cardInfo.status === "moderation" ? (
              <div className={classes.buttons}>
                <Button
                  type={"primary"}
                  onClick={() =>
                    this.changeCardStatusHandler(
                      this.props.cardInfo.id,
                      "accepted"
                    )
                  }
                >
                  Принять в работу
                </Button>
              </div>
            ) : null}
            {this.props.role === "assistant" &&
            this.props.cardInfo.status === "moderation" &&
            this.props.specializations !== null &&
            this.props.doctorsList !== null
              ? this.renderPickStatusSection()
              : null}

            {this.props.role === "assistant" &&
            this.props.cardInfo.status === "moderation" &&
            this.props.cardInfo.desired_performer === null ? (
              <div className={classes.buttons}>
                <Button
                  type={"additional"}
                  onClick={() =>
                    this.changeCardStatusHandler(
                      this.props.cardInfo.id,
                      "declined"
                    )
                  }
                >
                  Отменить заявку
                </Button>
              </div>
            ) : null}

            {this.props.role === "assistant" || this.props.role === "doctor" ? (
              this.props.cardInfo.status === "accepted" ? (
                <div className={classes.buttons}>
                  <Button
                    type={"primary"}
                    onClick={() =>
                      this.changeCardStatusHandler(
                        this.props.cardInfo.id,
                        "in_work"
                      )
                    }
                  >
                    Начать консультацию
                  </Button>
                </div>
              ) : this.props.cardInfo.status === "in_work" ? (
                <div className={classes.buttons}>
                  <Button
                    type={"primary"}
                    onClick={() =>
                      this.changeCardStatusHandler(
                        this.props.cardInfo.id,
                        "paused"
                      )
                    }
                  >
                    Приостановить консультацию
                  </Button>
                  <Button
                    disabled={
                      this.props.cardInfo.conclusion === "" ||
                      (!this.props.cardInfo.conclusion &&
                        !this.props.cardInfo.needs_payment)
                    }
                    type={"primary"}
                    onClick={() => {
                      if (this.props.cardInfo.needs_payment) {
                        this.changeCardStatusHandler(
                          this.props.cardInfo.id,
                          "waiting_for_payment"
                        );
                      } else {
                        this.changeCardStatusHandler(
                          this.props.cardInfo.id,
                          "finished"
                        );
                      }
                    }}
                  >
                    Завершить консультацию
                  </Button>
                </div>
              ) : this.props.cardInfo.status === "waiting_for_payment" ? (
                <div className={classes.buttons}>
                  <Button
                    disabled={
                      this.props.cardInfo.conclusion === "" ||
                      !this.props.cardInfo.conclusion
                    }
                    type={"primary"}
                    onClick={() => {
                      this.changeCardStatusHandler(
                        this.props.cardInfo.id,
                        "finished"
                      );
                    }}
                  >
                    Завершить консультацию
                  </Button>
                </div>
              ) : this.props.cardInfo.status === "paused" ? (
                <div className={classes.buttons}>
                  <Button
                    type={"primary"}
                    onClick={() =>
                      this.changeCardStatusHandler(
                        this.props.cardInfo.id,
                        "in_work"
                      )
                    }
                  >
                    Продолжить консультацию
                  </Button>
                </div>
              ) : null
            ) : null}
            {/* Чат */}
            {!isChatDisabled(this.props.role, this.props.cardInfo.status) &&
            this.props.cardInfo.chat &&
            this.props.role !== "support" ? (
              <Chat chatInfo={this.props.cardInfo.chat}></Chat>
            ) : null}
            {/* История чата */}
            {this.props.role !== "support" &&
            (this.props.cardInfo.status === "finished" ||
              this.props.cardInfo.status === "paused") &&
            this.props.cardInfo.chat ? (
              <Chat chatInfo={this.props.cardInfo.chat} disabled></Chat>
            ) : null}
          </div>
        </div>
      );
  }
}

function mapStateToProps(state) {
  return {
    role: state.profile.role,
    cardInfo: state.consultations.activeCard,
    scrollStatus: state.consultations.scrollStatus,
    specializations: state.consultations.specializations,
    doctorsList: state.doctors.doctorsList,
    getAccessStatus: state.patients.getAccessStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCardStatus: (id, data) => dispatch(changeCardStatus(id, data)),
    assignSpecialist: (id, data) => dispatch(assignSpecialist(id, data)),
    getCardInfo: (id) => dispatch(getCardInfo(id)),
    getCardInfoInternal: (id) => dispatch(getCardInfoInternal(id)),
    getSpecializations: () => dispatch(getSpecializations()),
    getPublicDoctorsList: (specialization_id, no_pagination) =>
      dispatch(getPublicDoctorsList(specialization_id, no_pagination)),
    getDocumentsAccess: (id) => dispatch(getDocumentsAccess(id)),
    clearGetAccessStatus: () => dispatch(clearGetAccessStatus()),
    updateConclusion: (id, data) => dispatch(updateConclusion(id, data)),
    setScrollStatus: (newStatus) => dispatch(setScrollStatus(newStatus)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardExpanded);
