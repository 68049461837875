import axios from "axios";

import {
    GET_EVENTS_SUCCESS,
    GET_EVENTS_ERROR,
} from "./actionTypes";


export function getEventsList(date1, date2, role, order_by, q, page, size) {
    return async (dispatch) => {
        axios
            .get(`/events/`, {
                params: {
                    start_date: date1, 
                    end_date: date2? date2 : date1, 
                    role, 
                    order_by,
                    q,
                    page, 
                    size
                }
            })
            .then((response) => {
                dispatch(getEventsSuccess(response.data));
            })
            .catch((err) => {
                dispatch(getEventsError());
                console.log(err);
            });
    };
}


export function getEventsSuccess(data) {
    return {
        type: GET_EVENTS_SUCCESS,
        data: data,
    };
}

export function getEventsError() {
    return {
        type: GET_EVENTS_ERROR,
    };
}