import React from "react";

const DownloadSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.41046 14.7559C9.41078 14.7562 9.41109 14.7565 9.41136 14.7568C9.4305 14.7758 9.45058 14.7939 9.47152 14.8111C9.48121 14.8191 9.4914 14.826 9.50136 14.8334C9.51316 14.8423 9.52472 14.8514 9.53703 14.8596C9.5489 14.8676 9.56124 14.8745 9.57343 14.8818C9.58457 14.8884 9.59546 14.8954 9.60691 14.9015C9.61933 14.9082 9.63214 14.9138 9.64484 14.9198C9.65679 14.9255 9.66855 14.9314 9.68081 14.9365C9.69312 14.9416 9.7057 14.9457 9.7182 14.9502C9.73136 14.9549 9.74433 14.96 9.75777 14.964C9.77027 14.9678 9.783 14.9706 9.79562 14.9737C9.80933 14.9772 9.82289 14.9811 9.83683 14.9838C9.85148 14.9867 9.86624 14.9884 9.88097 14.9906C9.89312 14.9923 9.90507 14.9946 9.91742 14.9959C9.9721 15.0012 10.0272 15.0012 10.0819 14.9959C10.0942 14.9946 10.1062 14.9923 10.1184 14.9906C10.1331 14.9885 10.1479 14.9868 10.1625 14.9838C10.1765 14.9811 10.19 14.9772 10.2037 14.9737C10.2164 14.9706 10.2291 14.9678 10.2416 14.964C10.255 14.96 10.268 14.9549 10.2811 14.9502C10.2936 14.9457 10.3062 14.9416 10.3185 14.9365C10.3308 14.9314 10.3425 14.9255 10.3545 14.9198C10.3672 14.9138 10.38 14.9082 10.3924 14.9015C10.4039 14.8954 10.4148 14.8884 10.4259 14.8818C10.4381 14.8745 10.4504 14.8676 10.4623 14.8596C10.4746 14.8514 10.4862 14.8423 10.498 14.8334C10.5079 14.826 10.5181 14.8191 10.5278 14.8111C10.5487 14.794 10.5688 14.7759 10.588 14.7568C10.5883 14.7565 10.5886 14.7562 10.5889 14.7559L14.7555 10.5893C15.081 10.2638 15.081 9.73617 14.7555 9.41074C14.4301 9.08531 13.9025 9.08531 13.577 9.41074L10.833 12.1548V0.83332C10.833 0.373086 10.46 0 9.99972 0C9.53949 0 9.1664 0.373086 9.1664 0.83332V12.1548L6.42234 9.41074C6.09691 9.08531 5.56926 9.08531 5.24383 9.41074C4.9184 9.73617 4.9184 10.2638 5.24383 10.5893L9.41046 14.7559Z"
        fill="#181818"
      />
      <path
        d="M19.1667 14.1666C18.7064 14.1666 18.3334 14.5397 18.3334 14.9999V18.3333H1.66668V14.9999C1.66668 14.5397 1.29359 14.1666 0.833359 14.1666C0.373086 14.1666 0 14.5397 0 14.9999V19.1666C0 19.6269 0.373086 19.9999 0.83332 19.9999H19.1666C19.6269 19.9999 20 19.6269 20 19.1666V14.9999C20 14.5397 19.6269 14.1666 19.1667 14.1666Z"
        fill="#181818"
      />
    </svg>
  );
};

export default DownloadSvg;
