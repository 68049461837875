import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import EmptySection from "components/Profile/EmptySection/EmptySection";
import CardList from "components/Profile/CardList/CardList";
import CardStatus from "components/Profile/CardStatus/CardStatus";
import Navigation from "components/UI/Navigation/Navigation";
import Button from "components/UI/Button/Button";
import Select from "components/UI/Select/Select";
import CustomDatePicker from "components/UI/CustomDatePicker/CustomDatePicker";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";

import classes from "./Consultations.module.scss";
import {
  changeCardStatus,
  getConsultations,
  getSpecializations,
  changePageStatus,
} from "store/Profile/Consultations/actions";
import { typeEnum } from "components/Profile/CardList/enums";
import { statusEnum } from "components/Profile/CardList/enums";

let TYPES = { ...typeEnum };
TYPES.all = "Все";
const selectData = Object.values(TYPES);

const CARDS_IN_ONE_PAGE = 10;

class Consultations extends Component {
  state = {
    loading: false,
    activePage: 1,
    currentType: "all",
    currentStatus: "created",
    startDate: null,
    endDate: null,
    selectedStartDate: null,
    selectedEndDate: null,
  };

  convertDate(date) {
    const dateArr = date.toLocaleString("ru").substring(0, 10).split(".");
    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
  }

  getDates = () => {
    return [
      this.state.selectedStartDate
        ? this.convertDate(this.state.selectedStartDate)
        : null,
      this.state.selectedEndDate
        ? this.convertDate(this.state.selectedEndDate)
        : null,
    ];
  };

  componentDidMount() {
    this.props.getConsultations(
      this.props.currentPageStatus,
      this.state.currentType,
      this.state.activePage - 1,
      CARDS_IN_ONE_PAGE,
      this.state.selectedStartDate,
      this.state.selectedEndDate
    );
    this.props.getSpecializations();
    this.setState({ currentStatus: this.props.currentPageStatus });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.role === "doctor" &&
      (this.props.currentPageStatus === "created" ||
        !this.props.currentPageStatus)
    ) {
      // изменить вкладку
      this.props.changePageStatus("moderation");
      this.setState({ currentStatus: "moderation" });
    }
    if (prevState.currentType !== this.state.currentType) {
      const [date1, date2] = this.getDates();
      this.props.getConsultations(
        this.state.currentStatus,
        this.state.currentType,
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        date1,
        date2
      );
      this.setState({ loading: true });
    }

    if (prevState.currentStatus !== this.state.currentStatus) {
      const [date1, date2] = this.getDates();
      this.props.getConsultations(
        this.state.currentStatus,
        this.state.currentType,
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        date1,
        date2
      );
      this.setState({ loading: true });
    }

    if (prevState.activePage !== this.state.activePage) {
      const [date1, date2] = this.getDates();
      this.props.getConsultations(
        this.state.currentStatus,
        this.state.currentType,
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        date1,
        date2
      );
      this.setState({ loading: true });
    }
    if (
      this.state.selectedStartDate !== prevState.selectedStartDate ||
      this.state.selectedEndDate !== prevState.selectedEndDate
    ) {
      const [date1, date2] = this.getDates();
      this.props.getConsultations(
        this.state.currentStatus,
        this.state.currentType,
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        date1,
        date2
      );
      this.setState({ loading: true });
    }
    if (this.props.needUpdate && !prevProps.needUpdate) {
      const [date1, date2] = this.getDates();
      this.props.getConsultations(
        this.state.currentStatus,
        this.state.currentType,
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        date1,
        date2
      );
    }
    if (
      prevProps.consultations !== this.props.consultations &&
      this.state.loading
    ) {
      this.setState({ loading: false });
    }
    // перелистывание страницы на 1 назад, при удалении всех вопросов на странице
    if (prevProps.consultations) {
      if (
        prevProps.consultations.length === 1 &&
        this.props.consultations.length === 0 &&
        this.state.activePage > 1
      ) {
        this.setState({ activePage: this.state.activePage - 1 });
      }
    }
    console.log(this.getDates());
  }

  changePageHandler = (pages, jumpTo) => {
    let activePage = this.state.activePage;

    if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
      return;
    } else {
      activePage = jumpTo;
    }
    this.setState({ activePage });
    window.scrollTo(0, 0);
  };

  changeStatusHandler = (status) => {
    // изменить вкладку
    this.setState({ currentStatus: status, activePage: 1 });
    this.props.changePageStatus(status);
  };

  changeTypeHandler = (event) => {
    this.setState({
      currentType: Object.keys(TYPES)[event.target.value],
      activePage: 1,
    });
  };

  changeCardStatusHandler = (id, newStatus, event) => {
    // изменить статус заявки
    // запрещаем открывать карточку на который находится кнопка
    event.preventDefault();
    const data = { status: newStatus };
    this.props.changeCardStatus(id, data, this.state.currentStatus);
  };

  changeDateHandler = (dates) => {
    const [start, end] = dates;
    this.setState({
      startDate: start,
      endDate: end,
    });
  };

  dateRangeSelectHandler = () => {
    if (this.state.endDate === null) {
      this.setState({
        endDate: this.state.startDate,
        selectedStartDate: this.state.startDate,
        selectedEndDate: this.state.startDate,
      });
    } else {
      this.setState({
        selectedStartDate: this.state.startDate,
        selectedEndDate: this.state.endDate,
      });
    }
  };

  dateRangeResetHandler = () => {
    this.setState({
      startDate: null,
      endDate: null,
      selectedStartDate: null,
      selectedEndDate: null,
    });
  };

  renderSection = () => {
    if (!this.props.consultations) return null;

    let pagesAmount =
      Math.trunc(this.props.total / CARDS_IN_ONE_PAGE) +
      (this.props.total % CARDS_IN_ONE_PAGE ? 1 : 0);

    const section = (
      <React.Fragment>
        <CardList
          currentRole={this.props.role}
          cards={this.props.consultations}
          changeStatus={this.changeCardStatusHandler}
        />
        {pagesAmount > 1 ? (
          <Navigation
            activePage={this.state.activePage}
            pages={pagesAmount}
            onChange={this.changePageHandler}
          />
        ) : null}
      </React.Fragment>
    );

    return section;
  };

  render() {
    return (
      <section className={classes.Consultations}>
        <Helmet>
          <title>Консультации. Портал здоровья</title>
          <meta name="title" content="Консультации. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Консультации. Портал здоровья" />
        </Helmet>
        <CardStatus
          role={this.props.role}
          statuses={statusEnum}
          activeStatus={this.state.currentStatus}
          onChange={this.changeStatusHandler}
        />
        <div className={classes.header}>
          <div className={classes.select_wrapper}>
            <Select
              data={selectData}
              value={selectData.indexOf(TYPES[this.state.currentType])}
              onChange={this.changeTypeHandler}
              label={"Выберите вид услуги"}
            />
          </div>
          <div className={classes.datepicker_wrapper}>
            <CustomDatePicker
              selected={this.state.startDate}
              onChange={this.changeDateHandler}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              selectsRange={true}
              maxDate={new Date()}
              onOkClick={this.dateRangeSelectHandler}
              onCancelClick={this.dateRangeResetHandler}
            />
          </div>
          <div className={classes.status_loader}>
            {this.state.loading ? (
              <FileLoader style={{ height: "100%", maxWidth: "100%" }} />
            ) : null}
          </div>
        </div>

        {this.props.consultations ? (
          this.props.consultations.length > 0 ? (
            this.renderSection()
          ) : (
            <EmptySection
              title={"Заявок нет"}
              text={"Ни одной заявки на консультацию не было подано"}
            >
              {this.props.role === "patient" ? (
                <div className={classes.button_wrapper}>
                  <NavLink to={"/profile/new_consultation"}>
                    <Button type={"primary"}>Получить консультацию</Button>
                  </NavLink>
                </div>
              ) : null}
            </EmptySection>
          )
        ) : this.props.role === "doctor" &&
          this.props.doctorProfile === null ? (
          <EmptySection
            style={{ textAlign: "center" }}
            title={"Благодарим за регистрацию на Портале пациента!"}
            text={
              'Для того, чтобы получать входящие заявки от Пациентов, просим вас заполнить анкету Специалиста в разделе "Профиль"'
            }
          >
            <div className={classes.button_wrapper}>
              <NavLink to={"/profile/account"}>
                <Button type={"primary"}>Заполнить</Button>
              </NavLink>
            </div>
          </EmptySection>
        ) : (
          <div className={classes.loader}>
            <FileLoader style={{ maxWidth: "100%" }} />
          </div>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.profile.role,
    consultations: state.consultations.consultations,
    total: state.consultations.consultationsTotal,
    specializations: state.consultations.specializations,
    currentPageStatus: state.consultations.currentPageStatus,
    needUpdate: state.consultations.needUpdate,
    doctorProfile: state.doctors.editedDoctorProfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeCardStatus: (id, data) => dispatch(changeCardStatus(id, data)),
    getConsultations: (status, type, page, size, startDate, endDate) =>
      dispatch(getConsultations(status, type, page, size, startDate, endDate)),
    getSpecializations: () => dispatch(getSpecializations()),
    changePageStatus: (status) => dispatch(changePageStatus(status)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Consultations);
