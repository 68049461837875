export const eventTypeEnum = {
    login: "Вошел в личный кабинет",
    test_passed: "Прошел тест",
    profile_updated: "Изменил профиль",
    password_changed: "Сменил пароль",
    consultation_status_changed: "Сменил статус консультации",
    consultation_created: "Создал консультацию"
}

export const roleEnum = {
    patient: "Пациент",
    doctor: "Специалист",
    assistant: "Ассистент",
    support: "Специалист поддержки",
    admin: "Администратор",
}

export const tabsEnum = {
    patient: "Пациенты",
    doctor: "Специалисты",
    assistant: "Ассистенты",
}