import React from "react";
import { useHistory } from "react-router-dom";

import {
  getFormatDate,
  getTimeFromDate,
} from "components/Profile/CardList/enums";

import classes from "./ConsultationsLogRow.module.scss";

const statuses = [
  "moderation",
  "accepted",
  "in_work",
  "paused",
  "waiting_for_payment",
  "finished",
  "declined",
];

const ConsultationsLogRow = (props) => {
  const history = useHistory();

  const urlBase = {
    patient: "/profile/patients/patient/",
    doctor: "/profile/doctors/doctor_edit/",
  };

  const onHyperlinkClick = (column) => {
    let id;

    if (column === "doctor") {
      id = props.performer.id;
    }

    if (column === "patient") {
      id = props.creator.id;
    }

    let openUrl = urlBase[column] + id;

    if (props.performer.role !== "assistant" || column === "patient") {
      if (props.inNewWindow === true) {
        window.open(openUrl);
      } else {
        history.push(openUrl);
      }
    }
  };

  const [statusesUpdatesSorted, setStatusesUpdatesSorted] = React.useState([]);

  const sortStatuses = () => {
    const arr = [];
    for (let i = 0; i < 8; i++) {
      arr[i] =
        props.statusesUpdates.find((status) => status.status === statuses[i]) ||
        {};
    }
    setStatusesUpdatesSorted([...arr]);
  };

  React.useEffect(() => {
    sortStatuses();
  }, [props.statusesUpdates]);

  return (
    <tr className={classes.ConsultationsLogRow}>
      <td
        className={classes.hoveredColumn}
        onClick={() => onHyperlinkClick("patient")}
      >
        {props.creator &&
          `${props.creator.profile.second_name} ${
            props.creator.profile.first_name
          } ${props.creator.profile.patronymic_name || ""}`}
      </td>
      <td
        className={
          props.performer?.role !== "assistant" ? classes.hoveredColumn : ""
        }
        onClick={() => onHyperlinkClick("doctor")}
      >
        {props.performer &&
          `${props.performer.profile.second_name} ${
            props.performer.profile.first_name
          } ${props.performer.profile.patronymic_name || ""}`}
      </td>
      <td>
            {props.createdDate &&
              `${getFormatDate(props.createdDate)
              }\u00A0${getTimeFromDate(props.createdDate)}`}
          </td>
      {statusesUpdatesSorted.map((status) => {
        return (
          <td>
            {status.updated_at &&
              `${getFormatDate(
                new Date(status.updated_at)
              )}\u00A0${getTimeFromDate(new Date(status.updated_at))}`}
          </td>
        );
      })}
    </tr>
  );
};

export default ConsultationsLogRow;
