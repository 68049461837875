import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import ModalWindow from "components/UI/ModalWindow/ModalWindow";
import DeleteConfirm from "components/UI/ModalWindow/DeleteConfirm/DeleteConfirm";
import Button from "components/UI/Button/Button";
import Card from "components/Article/Article";
import EmptySection from "components/Profile/EmptySection/EmptySection";
import Navigation from "components/UI/Navigation/Navigation";
import classes from "./Faq.module.scss";
import { getFaqList, deleteFaq } from "store/Profile/Faq/actions";
const CARDS_IN_ONE_PAGE = 10;

class Faq extends Component {
  state = {
    activePage: 1,
    modalShow: false,
    pickedIDforDelete: null,
    pickedFaqTitleForDelete: ''
  };
  modalShowOpen = (id, title) => {
    let newState = { ...this.state };
    newState.modalShow = true;
    newState.pickedIDforDelete = id;
    newState.pickedFaqTitleForDelete = title
    this.setState({ ...newState });
  };
  modalShowClose = () => {
    let newState = { ...this.state };
    newState.modalShow = false;
    newState.pickedIDforDelete = null;
    newState.pickedFaqTitleForDelete = '';
    this.setState({ ...newState });
  };
  deleteConfirmedHandler = () => {
    this.props.deleteFaq(this.state.pickedIDforDelete);
    this.modalShowClose();
  };
  componentDidMount() {
    this.props.getFaqList(this.state.activePage - 1, CARDS_IN_ONE_PAGE);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activePage !== this.state.activePage) {
      this.props.getFaqList(this.state.activePage - 1, CARDS_IN_ONE_PAGE);
    }
    if (this.props.needUpdate && !prevProps.needUpdate) {
      this.props.getFaqList(this.state.activePage - 1, CARDS_IN_ONE_PAGE);
    }
    // перелистывание страницы на 1 назад, при удалении всех вопросов на странице
    if (prevProps.faqList) {
      if (prevProps.faqList.length === 1 && this.props.faqList.length === 0 && this.state.activePage > 1) {
        this.setState({ activePage: this.state.activePage - 1 })
      }
    }
  }
  getConvertedDate(date) {
    let cuttedDate = date.slice(0, 10);
    let splittedDate = cuttedDate.split("-");
    return [splittedDate[2], splittedDate[1], splittedDate[0]].join(".");
  }
  changePageHandler = (pages, jumpTo) => {
    let activePage = this.state.activePage;

    if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
      return;
    } else {
      activePage = jumpTo;
    }
    this.setState({ activePage });
    window.scrollTo(0, 0);
  };
  renderSection = () => {
    let pagesAmount =
      Math.trunc(this.props.total / CARDS_IN_ONE_PAGE) +
      (this.props.total % CARDS_IN_ONE_PAGE ? 1 : 0);

    const section = (
      <React.Fragment>
        {this.props.faqList.map((item) => {
          return (
            <div className={classes.card} key={item.id}>
              <Card
                type={"longAdmin"}
                id={item.id}
                linkTo={`faq/faq_edit/${item.id}`}
                title={item.question}
                date={this.getConvertedDate(item.created_at)}
                text={item.answer}
                deleteHandler={this.modalShowOpen}
              ></Card>
            </div>
          );
        })}
        {pagesAmount > 1 ? (
          <Navigation
            activePage={this.state.activePage}
            pages={pagesAmount}
            onChange={this.changePageHandler}
          />
        ) : null}
      </React.Fragment>
    );

    return section;
  };
  render() {
    return (
      <section className={classes.Faq}>
        <Helmet>
          <title>FAQ. Портал здоровья</title>
          <meta name="title" content="FAQ. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="FAQ. Портал здоровья" />
        </Helmet>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>Часто задаваемы вопросы</div>
          {this.props.role === "admin" &&
            this.props.faqList &&
            this.props.faqList.length !== 0 ? (
              <Link to={"/profile/faq/new_faq"}>
                <Button type={"plus"} text={"Добавить вопрос"}></Button>
              </Link>
            ) : null}
        </div>
        {this.props.faqList && this.props.faqList.length !== 0 ? (
          this.renderSection()
        ) : (
            <EmptySection
              title={"Пока нет часто задаваемых вопросов"}
              text={`Здесь хранятся все ваши личные файлы, которые косаются вашего здоровья.
                 Специалист может загружать заключения после проведения консультации,
                 а также вы сами можете добавлять все необходимые документы`}
            >
              {this.props.role === "admin" ? (
                <Link to={"/profile/faq/new_faq"}>
                  <Button type={"plus"} text={"Добавить вопрос"}></Button>
                </Link>
              ) : null}
            </EmptySection>
          )}
        {/* модальное окно подтв. удаления */}
        {this.state.modalShow ? (
          <ModalWindow closed={this.modalShowClose}>
            <DeleteConfirm
              confirmed={this.deleteConfirmedHandler}
              closed={this.modalShowClose}
              title={`Вы точно хотите удалить вопрос: "${this.state.pickedFaqTitleForDelete}" ?`}
            ></DeleteConfirm>
          </ModalWindow>
        ) : null}
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    role: state.profile.role,
    faqList: state.faq.faqList,
    total: state.faq.faqTotal,
    needUpdate: state.faq.needUpdate,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFaqList: (page, size) => dispatch(getFaqList(page, size)),
    deleteFaq: (id) => dispatch(deleteFaq(id))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
