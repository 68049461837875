import React from "react";
import { Link } from "react-router-dom";

import classes from "./Logo.module.scss";

const Logo = () => {
  return (
    <a href="/">
      <div className={classes.Logo}>
        <img
          className={classes.Logo_img}
          src={"/profile/images/logo-main.svg"}
          alt={"logo"}
        />
        <div className={classes.Logo_text}>Портал здоровья</div>
      </div>
    </a>
  );
};

export default Logo;
