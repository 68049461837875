import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import Button from 'components/UI/Button/Button';
import Container from 'hoc/Container/Container';

import classes from './ConfirmationMail.module.scss';

import { confirm, resend } from 'store/Auth/actions';
import { clearRegStatus } from 'store/Auth/actions';
import { Helmet } from 'react-helmet';

class ConfirmationMail extends Component {

    renderContent(isConfirmed) {

        let icon = <img
            className={classes.icon}
            src={'./images/confirm.svg'}
            alt={'confirm'}
        />

        let subtitle =
            <div className={classes.subtitle}>
                Подтверждение почты
            </div>

        let text =
            <p className={classes.text}>
                Для подтверждения адреса электронной почты, пожалуйста, перейдите по ссылке в сообщении
            </p>

        let buttons =
            <div className={classes.buttons}>
                <NavLink to={'/profile/login'}>
                    <Button
                        type={'primary'}
                    >
                        Войти
                            </Button>
                </NavLink>
            </div>

        if (isConfirmed === true) {

            icon = <img
                className={classes.icon}
                src={'./images/accept.svg'}
                alt={'confirm'}
            />

            subtitle =
                <div className={classes.subtitle}>
                    Почта подтверждена
            </div>

            text =
                <p className={classes.text}>
                    Электронная почта подтверждена. Теперь вы можете войти и продолжить регистрацию
                </p>

        } else if (isConfirmed === false) {

            icon = null;

            subtitle =
                <div className={classes.subtitle}>
                    Лимит времени на подтверждение исчерпан
            </div>

            text =
                <p className={classes.text}>
                    Подтвердите адрес электронной почты <strong>{this.props.email}</strong>. Отправить ещё одно сообщение для подтверждения?
            </p>

            buttons =
                <div className={classes.buttons}>
                    <NavLink to={'/profile/confirm_email'}>
                        <Button
                            type={'primary'}
                            onClick={this.resendHandler}
                        > Отправить повторно
                        </Button>
                    </NavLink>
                    <NavLink to={'/profile/signup'}>
                        <Button
                            type={'additional'}
                        > Изменить данные
                        </Button>
                    </NavLink>
                </div>
        }
        return [icon, subtitle, text, buttons]
    }

    resendHandler = () => {
        this.props.resend(this.props.email)
    }

    componentDidMount() {
        this.props.clearRegStatus();
        const search = document.location.search;

        if (search.indexOf("token") !== -1) {
            const tokenStartIndex = search.indexOf("token=") + ("token=").length;
            const token = search.slice(tokenStartIndex);
            this.props.confirm(token);
        }

    }

    render() {
        return (
            <section className={classes.ConfirmationMail}>
                <Helmet>
                    <title>Подтверждение почты. Портал здоровья</title>
                    <meta name="title" content="Подтверждение почты. Портал здоровья" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="Подтверждение почты. Портал здоровья" />
                </Helmet>
                <Container innerClassName={classes.inner}>
                    <h3 className={classes.title}>Регистрация</h3>
                    {this.renderContent(this.props.isConfirmed)}
                </Container>
            </section>
        )
    }
}

function mapStateToProps(state) {
    return {
        isConfirmed: state.auth.isConfirmed,
        email: state.auth.email
    }
}

function mapDispatchToProps(dispatch) {
    return {
        resend: (email) => dispatch(resend(email)),
        confirm: (token) => dispatch(confirm(token)),
        clearRegStatus: () => dispatch(clearRegStatus()),
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationMail)