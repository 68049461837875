import React from "react";

import ServicesRow from "./ServicesRow/ServicesRow";
import {
  typeEnum,
  getFormatDate,
  getTimeFromDate,
} from "components/Profile/CardList/enums";

import classes from "./ServicesTable.module.scss";

const ServicesTable = ({ servicesList, account, role }) => {
  if (!servicesList) return null;

  return (
    <table className={classes.ServicesTable}>
      <tr className={classes.table_header}>
        {role !== "patient" ? (
          <th className={classes.served_head}>Кому оказана</th>
        ) : null}
        <th className={classes.type_head}>Наименование услуги</th>
        {role !== "doctor" ? (
          <th className={classes.performer_head}>Кем оказана</th>
        ) : null}
        <th className={classes.date_head}>Когда оказана</th>
        <th className={classes.price_head}>Стоимость</th>
      </tr>
      {servicesList.map((service) => {
        return (
          <ServicesRow
            key={service.id}
            id={service.id}
            type={typeEnum[service.consultation_type]}
            date={getFormatDate(new Date(service.created_at))}
            time={getTimeFromDate(new Date(service.created_at))}
            performer={service.performer}
            creator={service.creator}
            needs_payment={service.needs_payment}
            price={service.price}
            account={account}
            role={role}
            inNewWindow
          />
        );
      })}
    </table>
  );
};

export default ServicesTable;
