import axios from "axios";

import {
  GET_RESULTS_SUCCESS,
  GET_RESULTS_ERROR,
} from "./actionTypes";

export function getResults() {
  return async (dispatch) => {
    axios
      .get('/tests/results')
      .then((response) => {
        dispatch(getResultsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getResultsError());
        console.log(err);
      });
  };
}

export function getResultsSuccess(results) {
  return {
    type: GET_RESULTS_SUCCESS,
    results,
  };
}

export function getResultsError() {
  return {
    type: GET_RESULTS_ERROR,
  };
}