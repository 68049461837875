import {
  GET_DOCUMENTS_ERROR,
  GET_DOCUMENTS_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_ERROR,
  GET_REQUESTS_SUCCESS,
  GET_REQUESTS_ERROR,
  CHANGE_REQUEST_STATUS_SUCCESS,
  CHANGE_REQUEST_STATUS_ERROR,
} from "./actionTypes";

const initialState = {
  documents: null,
  requestsList: [],
};

export default function medcardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.documents,
      };
    case GET_DOCUMENTS_ERROR:
      return {
        ...state,
      };
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
      };
    case DELETE_DOCUMENT_ERROR:
      return {
        ...state,
      };
    case GET_REQUESTS_SUCCESS:
      return {
        ...state,
        requestsList: action.requestsList,
      };
    case GET_REQUESTS_ERROR:
      return {
        ...state,
      };
    case CHANGE_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_REQUEST_STATUS_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
