import { getTime } from 'date-fns';
import React from 'react';

import Card from './Card/Card'

import classes from './CardList.module.scss';
import { typeEnum, getFormatDate, getTimeFromDate } from './enums';

const CardList = props => {
    return (
        <div className={classes.CardList}>
            {props.cards.map((card, index) => {
                return (
                    <Card
                        key={index}
                        id={card.id}
                        type={typeEnum[card.consultation_type]}
                        date={getFormatDate(new Date(card.created_at))}
                        time={getTimeFromDate(new Date(card.created_at))}
                        status={card.status}
                        files={card.attachments}
                        specialization={card.specialization ? card.specialization.title : null}
                        currentRole={props.currentRole}
                        creator={props.currentRole === 'patient' ? null : card.creator}
                        changeStatus={props.changeStatus}
                        desiredPerformer={card.desired_performer}
                        performer={card.performer}
                        needs_payment = {card.needs_payment}
                        price = {card.price}
                        unread_by_patient={card.unread_by_patient}
                        unread_by_assistant={card.unread_by_assistant}
                        unread_by_performer={card.unread_by_performer}
                    />
                )
            })}
        </div>
    )
}


export default CardList;