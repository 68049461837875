import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import Button from "components/UI/Button/Button";
import BackLink from "components/UI/BackLink/BackLink";
import FileControl from "components/UI/FileControl/FileControl";
import Select from "components/UI/Select/Select";
import Scroller from "hoc/CustomScrollbar/Scroller";
import { addRedirectLink } from "store/Auth/actions";

import classes from "./NewConsultation.module.scss";
import {
  getSpecializations,
  addFile,
  removeFile,
  clearFileList,
} from "store/actions/profile";
import {
  createConsultation,
  changePageStatus,
} from "store/Profile/Consultations/actions";
import {
  getPublicDoctorsList,
  getDoctorInfoByID,
} from "store/Profile/Doctors/actions";
import { getAccountInfo } from "store/Profile/Account/actions";

import { typeEnum } from "components/Profile/CardList/enums";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";

export const TYPES = Object.keys(typeEnum);

class NewConsultation extends Component {
  state = {
    loading: false,
    scrollTrigger: false,
    scrollTop: null,
    problem_description: "",
    currentSpecialization: 0,
    currentType: 5,
    selectedSpecialistIndex: 0,
    initSpecializationSelected: false,
    initDoctorSelected: false,
    fileLoading: false,
  };

  componentDidMount() {
    this.props.addRedirectLink("");

    this.props.getAccountInfo();
    this.props.getSpecializations();
    this.props.getPublicDoctorsList(this.state.currentSpecialization + 1, true); // запрос списка без пагинации
    if (this.props.match.params.hasOwnProperty("id")) {
      this.props.getDoctorInfoByID(this.props.match.params.id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.doctorsList !== this.props.doctorsList) {
      this.setState({ loading: false });
    }
    // устанавливаем врача и специализацию если был совершен переход из провиля врача
    if (this.props.match.params.hasOwnProperty("id")) {
      if (
        this.state.initSpecializationSelected &&
        !this.state.initDoctorSelected
      ) {
        // индекс врача для селекта
        const initDoctorIndex =
          this.props.doctorsList
            .map((doc) => doc.id)
            .indexOf(this.props.performer.id) + 1;

        if (initDoctorIndex !== 0) {
          this.setState({
            selectedSpecialistIndex: initDoctorIndex,
            initDoctorSelected: true,
          });
        }
      }

      if (
        this.props.performer &&
        this.props.specializations &&
        this.props.doctorsList &&
        !this.state.initSpecializationSelected
      ) {
        // индекс специализации для селекта
        const initSpecializationIndex =
          this.props.performer.doctor_profile.specialization.id - 1;

        this.setState({
          currentSpecialization: initSpecializationIndex,
          initSpecializationSelected: true,
        });
      }
    }
    if (prevState.currentSpecialization !== this.state.currentSpecialization) {
      this.props.getPublicDoctorsList(
        this.state.currentSpecialization + 1,
        true
      ); // запрос списка без пагинации
    }
    // выключаем лоадер
    if (prevProps.attachments.length !== this.props.attachments.length) {
      this.setState({ fileLoading: false });
    }
  }

  radioHandler = (index) => {
    this.setState({
      currentType: index,
    });
  };

  textareaChangeHandler = (event) => {
    this.setState({
      problem_description: event.target.value,
    });
  };

  submitHandler(event) {
    event.preventDefault();
  }

  sendFormHandler = () => {
    let selectedDoctorId = null;

    if (
      this.state.selectedSpecialistIndex > 0 &&
      !this.props.account_info.user.invited
    ) {
      selectedDoctorId =
        this.props.doctorsList[this.state.selectedSpecialistIndex - 1].id;
    }
    this.props.send(
      TYPES[this.state.currentType], // тип консультации
      this.state.problem_description, // описание проблемы
      this.props.specializations[this.state.currentSpecialization].id, // id специализации
      selectedDoctorId, // желаемый специалист
      this.props.attachments
    ); // вложения
    const newState = { ...this.state };
    newState.problem_description = "";
    newState.currentSpecialization = 0;
    newState.currentType = null;
    this.props.clearFileList();

    this.setState({ ...newState });

    this.props.changePageStatus("created");
  };

  specializationChangeHandler = (event) => {
    this.setState({
      currentSpecialization: Number.parseInt(event.target.value),
      selectedSpecialistIndex: 0,
      loading: true,
      initDoctorSelected: true,
      initSpecializationSelected: true,
    });
  };
  specialistChangeHandler = (event) => {
    this.setState({
      selectedSpecialistIndex: event.target.value,
      nitDoctorSelected: true,
      initSpecializationSelected: true,
    });
  };

  addFileHandler = (event) => {
    this.props.addFile(event.target.files[0]);
    this.setState({ fileLoading: true });
  };

  removeFileHandler = (index) => {
    this.props.removeFile(index);
  };

  render() {
    if (this.props.isConsultationCreated) {
      return <Redirect to="/profile/consultations" />;
    }

    let filteredDoctorsNames = null;
    if (this.props.doctorsList && this.props.specializations) {
      // Преобразует массив докторов в формат Фамилия И.О. (Фамилия И.)
      filteredDoctorsNames = this.props.doctorsList.map((doctor) => {
        return `${doctor.profile.second_name} ${doctor.profile.first_name[0]}.${
          doctor.profile.patronymic_name
            ? doctor.profile.patronymic_name[0] + "."
            : ""
        }`;
      });
      if (filteredDoctorsNames.length !== 0) {
        filteredDoctorsNames.unshift("Любой");
      }
    }

    const getDataForSpecialistSelect = () => {
      return !this.props.account_info?.user.invited ||
        this.props.account_info?.user.assistant.role === "provider"
        ? filteredDoctorsNames
        : [
            `${this.props.account_info?.user.assistant.profile.second_name} ${
              this.props.account_info?.user.assistant.profile.first_name[0]
            }.${
              this.props.account_info?.user.assistant.profile.patronymic_name
                ? this.props.account_info?.user.assistant.profile
                    .patronymic_name[0] + "."
                : ""
            }`,
          ];
    };

    return (
      <section className={classes.NewConsultation}>
        <Scroller top={this.state.scrollTop} page={this.state.scrollTrigger} />
        <Helmet>
          <title>Новая консультация. Портал здоровья</title>
          <meta name="title" content="Новая консультация. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:title"
            content="Новая консультация. Портал здоровья"
          />
        </Helmet>
        <div className={classes.back}>
          <BackLink />
          <span>Назад</span>
        </div>
        <h4 className={classes.title}>Подача заявки на прием</h4>
        <form
          className={classes.form}
          onSubmit={(event) => this.submitHandler(event)}
        >
          <h5 className={classes.section_title}>описание проблемы</h5>
          <div className={classes.description_section}>
            <textarea
              id="problem_description"
              value={this.state.problem_description}
              className={classes.textarea}
              placeholder={"Комментарий пациента*"}
              onChange={(event) => this.textareaChangeHandler(event)}
            />
            <div className={classes.select_box}>
              {this.props.specializations ? (
                <Select
                  value={this.state.currentSpecialization}
                  onChange={this.specializationChangeHandler}
                  data={this.props.specializations.map((i) => i.title)}
                  label={"Направление"}
                />
              ) : null}
              {filteredDoctorsNames && !this.state.loading ? (
                <Select
                  value={this.state.selectedSpecialistIndex}
                  onChange={this.specialistChangeHandler}
                  data={getDataForSpecialistSelect()}
                  label={"Специалист"}
                  disabled={filteredDoctorsNames.length === 0}
                />
              ) : (
                <FileLoader
                  style={{
                    maxWidth: "100%",
                    margin: 0,
                    height: "100%",
                    minHeight: "30px",
                  }}
                />
              )}
            </div>
            <div className={classes.type}>
              <Select
                value={this.state.currentType}
                data={Object.values(typeEnum)}
                onChange={(event) =>
                  this.setState({ currentType: event.target.value })
                }
                label={"Вид услуги"}
              />
            </div>
          </div>
          <h5 className={classes.section_title}>Дополнительно</h5>
          <div className={classes.extra_section}>
            <p className={classes.text}>
              Вы можете прикрепить к заявке документы, которые по вашему мнению
              будут полезны специалисту. Или добавьте их в медицинский архив в{" "}
              <strong>личном кабинете</strong>. После получения запроса от
              специалиста в разделе <strong>Уведомления</strong>, вы сможете
              открыть доступ к своему архиву.
            </p>
            <div className={classes.download}>
              <p className={classes.download_description}>
                Вы можете загрузить <strong>до пяти</strong> документов в
                формате <strong>.pdf</strong> или <strong>.jpeg</strong>, размер
                которого не превышает <strong>50 Mb</strong>
              </p>
              {this.state.fileLoading ? <FileLoader /> : null}
              <FileControl
                remove={this.removeFileHandler}
                maxFilesCount={5}
                files={this.props.attachments}
                onChange={this.addFileHandler}
              >
                Загрузить документ
              </FileControl>
            </div>
            <p className={classes.attention}>
              Обратите внимание, услуга может быть платной. Оплата происходит по
              завершению оказания услуги
            </p>
            <Button
              disabled={
                this.state.currentType === null ||
                !this.state.problem_description
              }
              onClick={() => this.sendFormHandler()}
              type={"primary"}
              needTip={true}
              onDisableClick={() => {
                if (!this.state.currentType) {
                  this.setState({
                    scrollTop: document.getElementById("type").offsetTop,
                    scrollTrigger: !this.state.scrollTrigger,
                  });
                } else {
                  this.setState({
                    scrollTop:
                      document.getElementById("problem_description").offsetTop -
                      100,
                    scrollTrigger: !this.state.scrollTrigger,
                  });
                  document.getElementById("problem_description").focus();
                }
              }}
            >
              Отправить заявку
            </Button>
          </div>
        </form>
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAccountInfo: () => dispatch(getAccountInfo()),
    send: (
      consultation_type,
      problem_description,
      specialization_id,
      desired_performer_id,
      attachments
    ) =>
      dispatch(
        createConsultation(
          consultation_type,
          problem_description,
          specialization_id,
          desired_performer_id,
          attachments
        )
      ),
    getSpecializations: () => dispatch(getSpecializations()),
    addFile: (file) => dispatch(addFile(file)),
    removeFile: (index) => dispatch(removeFile(index)),
    clearFileList: () => dispatch(clearFileList()),
    getPublicDoctorsList: (specialization_id, no_pagination, page, size) =>
      dispatch(
        getPublicDoctorsList(specialization_id, no_pagination, page, size)
      ),
    getDoctorInfoByID: (id) => dispatch(getDoctorInfoByID(id)),
    changePageStatus: (status) => dispatch(changePageStatus(status)),
    addRedirectLink: (link) => dispatch(addRedirectLink(link)),
  };
}

function mapStateToProps(state) {
  return {
    account_info: state.account.account,
    specializations: state.profile.specializations,
    attachments: state.profile.attachments,
    doctorsList: state.doctors.doctorsList,
    performer: state.doctors.editedDoctorProfile,
    isConsultationCreated: state.consultations.created,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewConsultation);
