import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Navigation from "components/UI/Navigation/Navigation";
import CustomDatePicker from "components/UI/CustomDatePicker/CustomDatePicker";
import Select from "components/UI/Select/Select";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";
import EmptySection from "components/Profile/EmptySection/EmptySection";
import UsersLogTable from "./UsersLogTable/UsersLogTable";

import { getInvitesList, getLogFile } from "store/Profile/Invites/actions";

import classes from "./UsersLog.module.scss";

const CARDS_IN_ONE_PAGE = 50;

const TYPES = {
  all: "Все",
  patient: "Пациенты",
  doctor: "Специалисты",
};
const selectData = Object.values(TYPES);

const DatePickerWrapper = ({
  label,
  startDate,
  endDate,
  changeDateHandlerState,
  dateRangeSelectHandlerState,
  dateRangeResetHandlerState,
}) => {
  const changeDateHandler = (dates) => {
    const [start, end] = dates;
    changeDateHandlerState(start, end);
  };

  const dateRangeSelectHandler = () => {
    if (endDate === null) {
      dateRangeSelectHandlerState(startDate);
    } else {
      dateRangeSelectHandlerState(startDate, endDate);
    }
  };

  return (
    <div className={classes.datepicker_wrapper}>
      <span>{label}</span>
      <div className={classes.datepicker}>
        <CustomDatePicker
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={changeDateHandler}
          selectsRange={true}
          maxDate={new Date()}
          onOkClick={dateRangeSelectHandler}
          onCancelClick={dateRangeResetHandlerState}
        />
      </div>
    </div>
  );
};

class UsersLog extends Component {
  state = {
    loading: false,
    activePage: 1,
    currentType: "all",
    startSendDate: null,
    endSendDate: null,
    startRegDate: null,
    endRegDate: null,
    selectedStartSendDate: null,
    selectedEndSendDate: null,
    selectedStartRegDate: null,
    selectedEndRegDate: null,
  };

  convertDate(date) {
    const dateArr = date.toLocaleString("ru").substring(0, 10).split(".");
    return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
  }

  componentDidMount() {
    this.props.getInvitesList(
      this.state.selectedStartSendDate,
      this.state.selectedEndSendDate,
      this.state.selectedStartRegDate,
      this.state.selectedEndRegDate,
      this.state.currentType,
      this.state.activePage - 1,
      CARDS_IN_ONE_PAGE
    );
    this.props.getLogFile(
      this.state.selectedStartSendDate,
      this.state.selectedEndSendDate,
      this.state.selectedStartRegDate,
      this.state.selectedEndRegDate,
      this.state.currentType
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activePage !== this.state.activePage) {
      const [sendDate1, sendDate2] = this.getSendDates();
      const [regDate1, regDate2] = this.getRegDates();
      this.props.getInvitesList(
        sendDate1,
        sendDate2,
        regDate1,
        regDate2,
        this.state.currentType,
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE
      );
      this.props.getLogFile(
        sendDate1,
        sendDate2,
        regDate1,
        regDate2,
        this.state.currentType
      );
    }

    if (prevState.currentType !== this.state.currentType) {
      const [sendDate1, sendDate2] = this.getSendDates();
      const [regDate1, regDate2] = this.getRegDates();
      this.props.getInvitesList(
        sendDate1,
        sendDate2,
        regDate1,
        regDate2,
        this.state.currentType,
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE
      );
      this.props.getLogFile(
        sendDate1,
        sendDate2,
        regDate1,
        regDate2,
        this.state.currentType
      );
      this.setState({ loading: true });
    }

    if (
      this.state.selectedStartSendDate !== prevState.selectedStartSendDate ||
      this.state.selectedEndSendDate !== prevState.selectedEndSendDate
    ) {
      const [sendDate1, sendDate2] = this.getSendDates();
      const [regDate1, regDate2] = this.getRegDates();
      this.props.getInvitesList(
        sendDate1,
        sendDate2,
        regDate1,
        regDate2,
        this.state.currentType,
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE
      );
      this.props.getLogFile(
        sendDate1,
        sendDate2,
        regDate1,
        regDate2,
        this.state.currentType
      );
      this.setState({ loading: true });
    }

    if (
      this.state.selectedStartRegDate !== prevState.selectedStartRegDate ||
      this.state.selectedEndRegDate !== prevState.selectedEndRegDate
    ) {
      const [sendDate1, sendDate2] = this.getSendDates();
      const [regDate1, regDate2] = this.getRegDates();
      this.props.getInvitesList(
        sendDate1,
        sendDate2,
        regDate1,
        regDate2,
        this.state.currentType,
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE
      );
      this.props.getLogFile(
        sendDate1,
        sendDate2,
        regDate1,
        regDate2,
        this.state.currentType
      );
      this.setState({ loading: true });
    }

    if (
      prevProps.invitesList !== this.props.invitesList &&
      this.state.loading
    ) {
      this.setState({ loading: false });
    }
  }

  changePageHandler = (pages, jumpTo) => {
    let activePage = this.state.activePage;

    if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
      return;
    } else {
      activePage = jumpTo;
    }
    this.setState({ activePage });
    window.scrollTo(0, 0);
  };

  changeTypeHandler = (event) => {
    this.setState({
      currentType: Object.keys(TYPES)[event.target.value],
      activePage: 1,
    });
  };

  getSendDates = () => {
    return [
      this.state.selectedStartSendDate
        ? this.convertDate(this.state.selectedStartSendDate)
        : null,
      this.state.selectedEndSendDate
        ? this.convertDate(this.state.selectedEndSendDate)
        : null,
    ];
  };

  getRegDates = () => {
    return [
      this.state.selectedStartRegDate
        ? this.convertDate(this.state.selectedStartRegDate)
        : null,
      this.state.selectedEndRegDate
        ? this.convertDate(this.state.selectedEndRegDate)
        : null,
    ];
  };

  changeSendDateHandler = (start, end) => {
    this.setState({
      startSendDate: start,
      endSendDate: end,
    });
  };

  changeRegDateHandler = (start, end) => {
    this.setState({
      startRegDate: start,
      endRegDate: end,
    });
  };

  dateSendRangeSelectHandler = (startDate, endDate) => {
    if (endDate === undefined) {
      this.setState({
        endSendDate: startDate,
        selectedStartSendDate: startDate,
        selectedEndSendDate: startDate,
      });
    } else {
      this.setState({
        selectedStartSendDate: startDate,
        selectedEndSendDate: endDate,
      });
    }
  };

  dateRegRangeSelectHandler = (startDate, endDate) => {
    if (endDate === undefined) {
      this.setState({
        endRegDate: startDate,
        selectedStartRegDate: startDate,
        selectedEndRegDate: startDate,
      });
    } else {
      this.setState({
        selectedStartRegDate: startDate,
        selectedEndRegDate: endDate,
      });
    }
  };

  dateSendRangeResetHandler = () => {
    this.setState({
      startSendDate: null,
      endSendDate: null,
      selectedStartSendDate: null,
      selectedEndSendDate: null,
    });
  };

  dateRegRangeResetHandler = () => {
    this.setState({
      startRegDate: null,
      endRegDate: null,
      selectedStartRegDate: null,
      selectedEndRegDate: null,
    });
  };

  render() {
    let pagesAmount = null;

    if (this.props.invitesList) {
      pagesAmount =
        Math.trunc(this.props.total / CARDS_IN_ONE_PAGE) +
        (this.props.total % CARDS_IN_ONE_PAGE ? 1 : 0);
    }

    return (
      <section className={classes.UsersLog}>
        <div className={classes.header}>
          <div className={classes.select_wrapper}>
            <Select
              data={selectData}
              value={selectData.indexOf(TYPES[this.state.currentType])}
              onChange={this.changeTypeHandler}
              label={"Выберите тип пользователей"}
            />
          </div>
          <div className={classes.datepickers}>
            <DatePickerWrapper
              label="Дата отправки:"
              startDate={this.state.startSendDate}
              endDate={this.state.endSendDate}
              getDatesState={this.getSendDates}
              changeDateHandlerState={this.changeSendDateHandler}
              dateRangeSelectHandlerState={this.dateSendRangeSelectHandler}
              dateRangeResetHandlerState={this.dateSendRangeResetHandler}
            />
            <DatePickerWrapper
              label="Дата регистрации:"
              startDate={this.state.startRegDate}
              endDate={this.state.endRegDate}
              getDatesState={this.getRegDates}
              changeDateHandlerState={this.changeRegDateHandler}
              dateRangeSelectHandlerState={this.dateRegRangeSelectHandler}
              dateRangeResetHandlerState={this.dateRegRangeResetHandler}
            />
          </div>
          <div className={classes.status_loader}>
            {this.state.loading ? (
              <FileLoader style={{ height: "100%", maxWidth: "100%" }} />
            ) : null}
          </div>
        </div>
        {this.props.invitesList ? (
          this.props.invitesList.length !== 0 ? (
            <React.Fragment>
              <div className={classes.list}>
                <UsersLogTable
                  inviteList={this.props.invitesList}
                  withHealthStatus={true}
                  inNewWindow={true}
                />
              </div>
              {pagesAmount > 1 ? (
                <Navigation
                  activePage={this.state.activePage}
                  pages={pagesAmount}
                  onChange={this.changePageHandler}
                />
              ) : null}
            </React.Fragment>
          ) : (
            <EmptySection
              title={"Вы ещё никого не приглашали"}
              text={"Список приглашений пуст"}
            />
          )
        ) : (
          <div className={classes.loader}>
            <FileLoader style={{ maxWidth: "100%" }} />
          </div>
        )}
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    invitesList: state.invites.invitesList,
    total: state.invites.invitesTotal,
    file: state.invites.file,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getInvitesList: (
      startSendDate,
      endSendDate,
      startRegDate,
      endRegDate,
      role,
      page,
      size,
      q
    ) =>
      dispatch(
        getInvitesList(
          startSendDate,
          endSendDate,
          startRegDate,
          endRegDate,
          role,
          page,
          size,
          q
        )
      ),
    getLogFile: (startSendDate, endSendDate, startRegDate, endRegDate, role) =>
      dispatch(
        getLogFile(startSendDate, endSendDate, startRegDate, endRegDate, role)
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersLog);
