import React from "react";
import { useHistory } from "react-router-dom";

import HealthStatus from "components/UI/HealthStatus/HealthStatus";
import { getFormatDate } from "components/Profile/CardList/enums";

import classes from "./UserRow.module.scss";

const UserRow = (props) => {
  const urlBase = {
    patient: "/profile/patients/patient/",
    assistant: "/profile/assistants/assistant_edit/",
    provider: "/profile/providers/provider_edit/",
    support: "/profile/supports/support_edit/",
    doctor: "/profile/doctors/doctor_edit/",
  }[props.role];

  const history = useHistory();

  return (
    <tr
      className={classes.UserRow}
      onClick={() => {
        if (props.inNewWindow === true) {
          window.open(urlBase + props.id);
        } else {
          history.push(urlBase + props.id);
        }
      }}
    >
      <td>{props.name}</td>
      {props.role === "doctor" ? (
        <td>
          {props.doctor_profile
            ? props.doctor_profile.specialization.title
            : "Без специализации"}
        </td>
      ) : null}
      {props.role === "patient" ? <td>{props.dateOfBirth}</td> : null}
      <td>
        <a href={`mailto:${props.email}`} onClick={(e) => e.stopPropagation()}>
          {props.email}
        </a>
      </td>
      <td>
        {props.registeredDate ? `${getFormatDate(props.registeredDate)}` : null}
      </td>

      {props.status ? (
        <td>
          {props.status.score ? (
            <HealthStatus
              color={props.status.color}
              score={props.status.score}
            />
          ) : (
            <span className={classes.missing}>Отсутствует</span>
          )}
        </td>
      ) : null}
      {props.accountRole === "admin" || props.accountRole === "support" ? (
        <td className={classes.deleted}>{props.idDeleted ? "Удален" : null}</td>
      ) : null}
    </tr>
  );
};

export default UserRow;
