export const GET_PATIENTS_SUCCESS = "GET_PATIENTS_SUCCESS";
export const GET_PATIENTS_ERROR = "GET_PATIENTS_ERROR";
export const CREATE_PATIENT_SUCCESS = "CREATE_PATIENT_SUCCESS";
export const CREATE_PATIENT_ERROR = "CREATE_PATIENT_ERROR";
export const GET_PATIENTBYID_SUCCESS = "GET_PATIENTBYID_SUCCESS";
export const GET_PATIENTBYID_ERROR = "GET_PATIENTBYID_ERROR";
export const GET_REPRESENTATIVESBYID_SUCCESS =
  "GET_REPRESENTATIVESBYID_SUCCESS";
export const GET_REPRESENTATIVESBYID_ERROR = "GET_REPRESENTATIVESBYID_ERROR";
export const GET_RESULTSBYID_SUCCESS = "GET_RESULTSBYID_SUCCESS";
export const GET_RESULTSBYID_ERROR = "GET_RESULTSBYID_ERROR";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_ERROR = "CHANGE_STATUS_ERROR";
export const CLEAR_GET_ACCESS_STATUS = "CLEAR_GET_ACCESS_STATUS";
export const CLEAR_CREATE_PATIENT_STATUS = "CLEAR_CREATE_PATIENT_STATUS";
export const GET_ACCESS_SUCCESS = "GET_ACCESS_SUCCESS";
export const GET_ACCESS_ERROR = "GET_ACCESS_ERROR";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_ERROR = "GET_DOCUMENTS_ERROR";
export const CHANGE_DELETED_STATUS_SUCCESS = "CHANGE_DELETED_STATUS_SUCCESS";
export const CHANGE_DELETED_STATUS_ERROR = "CHANGE_DELETED_STATUS_ERROR";
export const CLEAR_DELETE_PATIENT_STATUS = "CLEAR_DELETE_PATIENT_STATUS";
