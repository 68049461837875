import axios from "axios";

import {
  GET_RESULTS,
  SEND_RESULTS_SUCCESS,
  SEND_RESULTS_ERROR,
} from "./actionTypes";

export function sendResults(results) {
  return async (dispatch) => {
    axios
      .post("/tests/results", results)
      .then((response) => {
        dispatch(sendResultsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sendResultsError(err.response));
        console.log(err);
      });
  };
}

export function sendCovidResults(results) {
  return async (dispatch) => {
    axios
      .post("/covid/test_results", results)
      .then((response) => {
        dispatch({ type: GET_RESULTS, results: response.data });
        dispatch(sendResultsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sendResultsError(err.response));
        console.log(err);
      });
  };
}

export function sendContacts(results) {
  return async (dispatch) => {
    axios
      .post("/covid/form_data", results)
      .then((response) => {
        dispatch(sendResultsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(sendResultsError(err.response));
        console.log(err);
      });
  };
}

export function sendResultsSuccess() {
  return {
    type: SEND_RESULTS_SUCCESS,
  };
}

export function sendResultsError(error) {
  return {
    type: SEND_RESULTS_ERROR,
    error,
  };
}
