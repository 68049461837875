import React from 'react';

import classes from './StatusTabs.module.scss';

const StatusTabs = props => {

    return (
        <div className={classes.StatusTabs}>
            {props.list.map((item, index) => {
                // классы для item
                const itemCls = [classes.item];
                if (index === props.activeItem) {
                    itemCls.push(classes.active)
                }
                return (
                    // item
                    <span
                        key={index}
                        onClick={() => props.onChange(index)}
                        className={itemCls.join(' ')}
                    >
                        {item}
                    </span>
                )
            })}
        </div>
    )
}


export default StatusTabs