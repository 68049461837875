import React from 'react';
import { connect } from 'react-redux';

import CardExpanded from 'components/Profile/CardList/CardExpanded/CardExpanded';
import BackLink from "components/UI/BackLink/BackLink";
import Navigation from "components/UI/Navigation/Navigation";


import classes from './Consultation.module.scss';
import { getConsultationHistory } from 'store/Profile/Consultations/actions';
import HistoryTable from './HistoryTable/HistoryTable';
import { Helmet } from 'react-helmet';

const HISTORY_PAGE_SIZE = 20;

const Consultation = (props) => {
    const [currentPage, setCurrentPage] = React.useState("consultation");

    // Страницы
    const [activeHistoryPage, setActiveHistoryPage] = React.useState(1);
    const [historyPagesAmount, setHistoryPagesAmount] = React.useState();

    React.useEffect(() => {
        const pagesAmount = Math.trunc(props.historyTotal / HISTORY_PAGE_SIZE) + (props.historyTotal % HISTORY_PAGE_SIZE ? 1 : 0);
        setHistoryPagesAmount(pagesAmount);
    }, [props.historyTotal]);



    React.useEffect(() => {
        props.getConsultationHistory(props.match.params.id, activeHistoryPage - 1, HISTORY_PAGE_SIZE)
    }, [activeHistoryPage]);


    function changeHistoryPageHandler(pages, jumpTo) {
        if (jumpTo > pages || jumpTo < 1 || jumpTo === activeHistoryPage) {
            return;
        } else {
            setActiveHistoryPage(jumpTo);
        }
    };

    return (
        <section className={classes.Consultation}>
            <Helmet>
                <title>Консультация №{props.match.params.id}. Портал здоровья</title>
                <meta name="title" content={`Консультация №${props.match.params.id}. Портал здоровья`} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={`Консультация №${props.match.params.id}. Портал здоровья`} />
            </Helmet>
            <div className={classes.back}>
                <BackLink to={"/profile/consultations"} />
                <span>Назад</span>
            </div>
            {/* Ссылки */}
            <div className={classes.links}>
                <div
                    className={currentPage === "consultation" ? classes.active_link : classes.link}
                    onClick={() => setCurrentPage("consultation")}
                >
                    Консультация
                </div>
                <div
                    className={currentPage === "history" ? classes.active_link : classes.link}
                    onClick={() => setCurrentPage("history")}
                >
                    История
                </div>
            </div>
            {/* Консультация */}
            {currentPage === "consultation" &&
                <CardExpanded match={props.match} />
            }
            {/* История */}
            {currentPage === "history" && props.consultationHistory !== null &&
                <React.Fragment>
                    <HistoryTable eventsList={props.consultationHistory} />
                    {historyPagesAmount > 1 ? (
                        <Navigation
                            activePage={activeHistoryPage}
                            pages={historyPagesAmount}
                            onChange={changeHistoryPageHandler}
                        />
                    ) : null}
                </React.Fragment>
            }
        </section>
    )
}

function mapStateToProps(state) {
    return {
        consultationHistory: state.consultations.consultationHistory,
        historyTotal: state.consultations.historyTotal
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getConsultationHistory: (id, page, size) => dispatch(getConsultationHistory(id, page, size))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Consultation);