import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Layout from "hoc/Layout/Layout";
import Sidebar from "./Sidebar/Sidebar";
import Header from "./Header/Header";
import Footer from "components/Footer/Footer";
import Account from "./Account/Account";
import Doctors from "./Doctors/Doctors";
import Providers from "./Providers/Providers";
import NewProvider from "./NewProvider/NewProvider";
import ProviderEdit from "./ProviderEdit/ProviderEdit";
import NewDoctor from "./NewDoctor/NewDoctor";
import NewPatient from "./NewPatient/NewPatient";
import DoctorEdit from "./DoctorEdit/DoctorEdit";
import Consultations from "./Consultations/Consultations";
import Patients from "./Patients/Patients";
import Assistants from "./Assistants/Assistants";
import Journal from "./Journal/Journal";
import Supports from "./Supports/Supports";
import NewSupport from "./NewSupport/NewSupport";
import SupportEdit from "./SupportEdit/SupportEdit";
import Articles from "./Articles/Articles";
import NewArticle from "./NewArticle/NewArticle";
import ArticleEdit from "./ArticleEdit/ArticleEdit";
import Faq from "./Faq/Faq";
import NewFaq from "./NewFaq/NewFaq";
import FaqEdit from "./FaqEdit/FaqEdit";
import Feedback from "./Feedback/Feedback";
import Health from "./Health/Health";
import Medcard from "./Medcard/Medcard";
import NewConsultation from "./NewConsultation/NewConsultation";
import NewDocument from "./NewDocument/NewDocument";
import CardExpanded from "components/Profile/CardList/CardExpanded/CardExpanded";
import Consultation from "./Consultation/Consultation";
import NewAssistant from "./NewAssistant/NewAssistant";
import AssistantEdit from "./AssistantEdit/AssistantEdit";
import DoctorInfo from "./DoctorInfo/DoctorInfo";
import Accesses from "./Accesses/Accesses";
import PatientEdit from "./PatientEdit/PatientEdit";
import MyPatients from "./MyPatients/MyPatients";
import TipModal from "components/UI/TipModal/TipModal";
import Reports from "./Reports/Reports";
import Invites from "./Invites/Invites";
import NewInvite from "./NewInvite/NewInvite";
import Summary from "./Summary/Summary";

import classes from "./Profile.module.scss";

export const errorDescriptions = {
  "Patient can't upload photos": "Пациент не может загружать фотографии",
  "You can create profile only for users with empty role":
    "Создать профиль может только пользователь, не имеющий роли",
  "Invite not found": "Приглашение не активно",
  "The user with this email already exists in the system.":
    "Пользователь уже зарегистрирован",
};

class Profile extends Component {
  state = {
    redirectLink: localStorage.getItem("redirectLink"),
  };

  render() {
    let routes = null;
    switch (
      localStorage.getItem("role")
        ? localStorage.getItem("role")
        : this.props.role
    ) {
      case "patient":
        routes = (
          <Switch>
            <Route
              path="/profile/consultations/consultation/:id"
              component={Consultation}
            />
            <Route path="/profile/consultations" component={Consultations} />
            <Route path="/profile/health" component={Health} />
            <Route exact path="/profile/medcard" component={Medcard} />
            <Route
              exact
              path="/profile/medcard/accesses"
              component={Accesses}
            />
            <Route exact path="/profile/reports" component={Reports} />
            <Route path="/profile/account" component={Account} />
            <Route
              path="/profile/new_consultation/:id"
              component={NewConsultation}
            />
            <Route
              exact
              path="/profile/new_consultation"
              component={NewConsultation}
            />
            <Route path="/profile/new_document" component={NewDocument} />
            <Route exact path="/profile/doctors" component={Doctors} />
            <Route
              path="/profile/doctors/doctor_info/:id"
              component={DoctorInfo}
            />
            <Route path="/profile/summary" component={Summary} />
            {/* Редирект на стартовую */}
            <Route
              exact
              path="/profile/login"
              render={() => (
                <Redirect to={this.state.redirectLink || "/profile/summary"} />
              )}
            />
            <Route
              exact
              path="/profile/signup"
              render={() => (
                <Redirect to={this.state.redirectLink || "/profile/summary"} />
              )}
            />
            <Route
              exact
              path="/profile/"
              render={() => (
                <Redirect to={this.state.redirectLink || "/profile/summary"} />
              )}
            />
          </Switch>
        );
        break;
      case "assistant":
        routes = (
          <Switch>
            <Route
              path="/profile/consultations/consultation/:id"
              component={Consultation}
            />
            <Route
              exact
              path="/profile/consultations"
              component={Consultations}
            />
            <Route exact path="/profile/reports" component={Reports} />
            <Route exact path="/profile/doctors" component={Doctors} />
            <Route path="/profile/doctors/new_doctor" component={NewDoctor} />
            <Route
              path="/profile/doctors/doctor_edit/:id"
              component={DoctorEdit}
            />
            <Route exact path="/profile/patients" component={Patients} />
            <Route
              path="/profile/patients/new_patient"
              component={NewPatient}
            />
            <Route
              path="/profile/patients/patient/:id"
              component={PatientEdit}
            />
            <Route path="/profile/account" component={Account} />
            <Route exact path="/profile/invites" component={Invites} />
            <Route path="/profile/invites/new_invite" component={NewInvite} />
            {/* Редирект на стартовую */}
            <Route
              exact
              path="/profile/"
              render={() => <Redirect to="/profile/consultations" />}
            />
            <Route
              exact
              path="/profile/login"
              render={() => <Redirect to="/profile/consultations" />}
            />
            <Route
              exact
              path="/profile/signup"
              render={() => <Redirect to="/profile/consultations" />}
            />
          </Switch>
        );
        break;
      case "provider":
        routes = (
          <Switch>
            <Route exact path="/profile/doctors" component={Doctors} />
            <Route path="/profile/doctors/new_doctor" component={NewDoctor} />
            <Route
              path="/profile/doctors/doctor_edit/:id"
              component={DoctorEdit}
            />
            <Route exact path="/profile/patients" component={Patients} />
            <Route
              path="/profile/patients/new_patient"
              component={NewPatient}
            />
            <Route
              path="/profile/patients/patient/:id"
              component={PatientEdit}
            />
            <Route path="/profile/summary" component={Summary} />
            <Route path="/profile/account" component={Account} />
            <Route exact path="/profile/reports" component={Reports} />
            {/* Редирект на стартовую */}
            <Route
              exact
              path="/profile/"
              render={() => <Redirect to="/profile/summary" />}
            />
            <Route
              exact
              path="/profile/login"
              render={() => <Redirect to="/profile/summary" />}
            />
            <Route
              exact
              path="/profile/signup"
              render={() => <Redirect to="/profile/summary" />}
            />
          </Switch>
        );
        break;
      case "doctor":
        routes = (
          <Switch>
            <Route
              path="/profile/consultations/consultation/:id"
              component={Consultation}
            />
            <Route exact path="/profile/reports" component={Reports} />
            <Route
              path="/profile/patients/patient/:id"
              component={PatientEdit}
            />
            <Route path="/profile/account" component={DoctorEdit} />
            <Route
              exact
              path="/profile/consultations"
              component={Consultations}
            />
            <Route path="/profile/summary" component={Summary} />
            <Route path="/profile/patients/" component={MyPatients} />
            {/* Редирект на стартовую */}
            <Route
              exact
              path="/profile/"
              render={() => <Redirect to="/profile/summary" />}
            />
            <Route
              exact
              path="/profile/login"
              render={() => <Redirect to="/profile/summary" />}
            />
            <Route
              exact
              path="/profile/signup"
              render={() => <Redirect to="/profile/summary" />}
            />
          </Switch>
        );
        break;
      case "support":
        routes = (
          <Switch>
            <Route
              exact
              path="/profile/consultations"
              component={Consultations}
            />
            <Route
              path="/profile/consultations/consultation/:id"
              component={Consultation}
            />
            <Route exact path="/profile/doctors" component={Doctors} />
            <Route path="/profile/doctors/new_doctor" component={NewDoctor} />
            <Route
              path="/profile/doctors/doctor_edit/:id"
              component={DoctorEdit}
            />
            <Route exact path="/profile/assistants" component={Assistants} />
            <Route
              path="/profile/assistants/new_assistant"
              component={NewAssistant}
            />
            <Route
              path="/profile/assistants/assistant_edit/:id"
              component={AssistantEdit}
            />
            <Route exact path="/profile/patients" component={Patients} />
            <Route
              path="/profile/patients/new_patient"
              component={NewPatient}
            />
            <Route
              path="/profile/patients/patient/:id"
              component={PatientEdit}
            />
            <Route path="/profile/journal" component={Journal} />
            <Route path="/profile/account" component={Account} />
            {/* Редирект на стартовую */}
            <Route
              exact
              path="/profile/"
              render={() => <Redirect to="/profile/consultations" />}
            />
            <Route
              exact
              path="/profile/login"
              render={() => <Redirect to="/profile/consultations" />}
            />
            <Route
              exact
              path="/profile/signup"
              render={() => <Redirect to="/profile/consultations" />}
            />
          </Switch>
        );
        break;
      case "admin":
        routes = (
          <Switch>
            <Route exact path="/profile/supports" component={Supports} />
            <Route exact path="/profile/doctors" component={Doctors} />
            <Route path="/profile/doctors/new_doctor" component={NewDoctor} />
            <Route
              path="/profile/doctors/doctor_edit/:id"
              component={DoctorEdit}
            />
            <Route exact path="/profile/assistants" component={Assistants} />
            <Route
              path="/profile/assistants/new_assistant"
              component={NewAssistant}
            />
            <Route
              path="/profile/assistants/assistant_edit/:id"
              component={AssistantEdit}
            />
            <Route exact path="/profile/providers" component={Providers} />
            <Route
              path="/profile/providers/new_provider"
              component={NewProvider}
            />
            <Route
              path="/profile/providers/provider_edit/:id"
              component={ProviderEdit}
            />
            <Route
              path="/profile/supports/new_support"
              component={NewSupport}
            />
            <Route
              path="/profile/supports/support_edit/:id"
              component={SupportEdit}
            />
            <Route exact path="/profile/patients" component={Patients} />
            <Route
              path="/profile/patients/new_patient"
              component={NewPatient}
            />
            <Route
              path="/profile/patients/patient/:id"
              component={PatientEdit}
            />
            <Route exact path="/profile/articles" component={Articles} />
            <Route
              path="/profile/articles/new_article"
              component={NewArticle}
            />
            <Route
              path="/profile/articles/article_edit/:id"
              component={ArticleEdit}
            />
            <Route exact path="/profile/faq" component={Faq} />
            <Route path="/profile/faq/new_faq" component={NewFaq} />
            <Route path="/profile/faq/faq_edit/:id" component={FaqEdit} />
            <Route path="/profile/feedback" component={Feedback} />
            <Route path="/profile/account" component={Account} />
            {/* Редирект на стартовую */}
            <Route
              exact
              path="/profile/"
              render={() => <Redirect to="/profile/supports" />}
            />
            <Route
              exact
              path="/profile/login"
              render={() => <Redirect to="/profile/supports" />}
            />
            <Route
              exact
              path="/profile/signup"
              render={() => <Redirect to="/profile/supports" />}
            />
          </Switch>
        );
        break;
      case "null":
        if (!this.props.isProfileCreated) {
          routes = <Redirect to="/profile/create_profile" />;
        }
        break;
      case null:
        if (!this.props.isProfileCreated) {
          routes = <Redirect to="/profile/create_profile" />;
        }
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>Профиль. Портал здоровья</title>
          <meta name="title" content="Профиль. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Профиль. Портал здоровья" />
        </Helmet>
        <Header role={this.props.role} />
        <Layout>
          <Sidebar role={this.props.role} />
          <div className={classes.content}>
            {routes}
            <Footer />
            {this.props.isTipModalOpen ? <TipModal /> : null}
          </div>
        </Layout>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    role: state.profile.role,
    isProfileCreated: state.profile.isProfileCreated,
    isTipModalOpen: state.profile.isTipModalOpen,
    redirectLink: state.auth.redirectLink,
  };
}

export default withRouter(connect(mapStateToProps)(Profile));
