import React from "react";
import { SelectAsync } from "components/UI/SelectAsync/SelectAsync";

import classes from "containers/Profile/ProviderEdit/ProviderEdit.module.scss";

export const DeleteConfirmProvider = ({ provider, setProvider, withoutId }) => {
  const [isAttached, toggleAttached] = React.useState(false);

  const changeProviderHandle = ({ value, label }) => {
    setProvider({ value, label });
  };

  return (
    <div
      className={`${classes.inner} ${isAttached ? classes.inner_active : ""}`}
    >
      <label className={classes.checkbox}>
        <input
          type="checkbox"
          onChange={(event) => {
            toggleAttached(!!event.target.checked);
          }}
          checked={isAttached}
        />
        <span className={classes.checkmark} />
        Прикрепить пользователей к другому провайдеру
      </label>
      {isAttached && (
        <div className={classes.select}>
          <SelectAsync
            value={provider}
            onChange={changeProviderHandle}
            url={"/users/?role=provider"}
            withoutValue={withoutId}
            noOptionsMessage="Нет провайдеров"
          />
        </div>
      )}
    </div>
  );
};
