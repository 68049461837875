import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import Button from "components/UI/Button/Button";
import EmptySection from "components/Profile/EmptySection/EmptySection";
import Navigation from "components/UI/Navigation/Navigation";
import UserTable from "components/Profile/UserTable/UserTable";
import Search from "components/UI/Search/Search";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";

import classes from "./Supports.module.scss";
import { getSupportsList } from "store/Profile/Supports/actions";

const CARDS_IN_ONE_PAGE = 50;

class Supports extends Component {
  state = {
    activePage: 1,
    searchValue: null,
    prevSearch: null,
    isSearching: false,
  };
  componentDidMount() {
    this.props.getSupportsList(
      this.state.activePage - 1,
      CARDS_IN_ONE_PAGE,
      this.state.isSearching ? this.state.searchValue : null
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activePage !== this.state.activePage) {
      this.props.getSupportsList(
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        this.state.isSearching ? this.state.searchValue : null
      );
    }
  }
  changePageHandler = (pages, jumpTo) => {
    let activePage = this.state.activePage;

    if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
      return;
    } else {
      activePage = jumpTo;
    }
    this.setState({ activePage });
    window.scrollTo(0, 0);
  };
  onSearchHandler = () => {
    if (
      this.state.prevSearch === this.state.searchValue ||
      !this.state.searchValue
    )
      return null;

    this.setState({ isSearching: true, prevSearch: this.state.searchValue });

    if (this.state.activePage > 1) {
      this.setState({ activePage: 1 }); // При смене страницы автоматически отправится запрос
    } else {
      this.props.getSupportsList(0, CARDS_IN_ONE_PAGE, this.state.searchValue);
    }
  };
  onSearchResetHandler = () => {
    this.setState({ isSearching: false, prevSearch: null, searchValue: null });
    if (this.state.activePage > 1) {
      this.setState({ activePage: 1 }); // При смене страницы автоматически отправится запрос
    } else {
      this.props.getSupportsList(0, CARDS_IN_ONE_PAGE);
    }
  };
  render() {
    let pagesAmount =
      Math.trunc(this.props.total / CARDS_IN_ONE_PAGE) +
      (this.props.total % CARDS_IN_ONE_PAGE ? 1 : 0);

    return (
      <section className={classes.Supports}>
        <Helmet>
          <title>Список технической поддержки. Портал здоровья</title>
          <meta
            name="title"
            content="Список технической поддержки. Портал здоровья"
          />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:title"
            content="Список технической поддержки. Портал здоровья"
          />
        </Helmet>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>Список технической поддержки</div>
          {this.props.role === "admin" &&
          this.props.supportsList &&
          this.props.supportsList.length !== 0 ? (
            <Link to={"/profile/supports/new_support"}>
              <Button type={"plus"} text={"Добавить тех. поддержку"}></Button>
            </Link>
          ) : null}
        </div>
        <div className={classes.search_wrapper}>
          <Search
            value={this.state.searchValue}
            onChange={(value) =>
              this.setState({ searchValue: value === "" ? null : value })
            }
            onSearch={() => this.onSearchHandler()}
            total={this.props.total}
            showReset={this.state.isSearching}
            onReset={this.onSearchResetHandler}
          />
        </div>
        {this.props.supportsList ? (
          this.props.supportsList.length !== 0 ? (
            <React.Fragment>
              <div className={classes.list_wrapper}>
                <UserTable
                  role={this.props.role}
                  userList={this.props.supportsList}
                  inNewWindow={true}
                />
              </div>
              {pagesAmount > 1 ? (
                <Navigation
                  activePage={this.state.activePage}
                  pages={pagesAmount}
                  onChange={this.changePageHandler}
                />
              ) : null}
            </React.Fragment>
          ) : (
            <EmptySection
              title={"Пока нет специалистов тех. поддержки"}
              text={`Здесь находятся специалисты тех. поддержки.
                 Администратор может добавлять новых специалистов.`}
            >
              {this.props.role === "admin" ? (
                <Link to={"/profile/supports/new_support"}>
                  <Button
                    type={"plus"}
                    text={"Добавить тех. поддержку"}
                  ></Button>
                </Link>
              ) : null}
            </EmptySection>
          )
        ) : (
          <div className={classes.loader}>
            <FileLoader style={{ maxWidth: "100%" }} />
          </div>
        )}
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    role: state.profile.role,
    supportsList: state.supports.supportsList,
    total: state.supports.supportsTotal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSupportsList: (page, size, q) =>
      dispatch(getSupportsList(page, size, q)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Supports);
