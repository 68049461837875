import axios from "axios";

import {
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_ERROR,
  CREATE_PATIENT_SUCCESS,
  CREATE_PATIENT_ERROR,
  GET_PATIENTBYID_SUCCESS,
  GET_PATIENTBYID_ERROR,
  GET_REPRESENTATIVESBYID_SUCCESS,
  GET_REPRESENTATIVESBYID_ERROR,
  GET_RESULTSBYID_SUCCESS,
  GET_RESULTSBYID_ERROR,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_ERROR,
  GET_ACCESS_SUCCESS,
  GET_ACCESS_ERROR,
  CLEAR_GET_ACCESS_STATUS,
  CLEAR_CREATE_PATIENT_STATUS,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_ERROR,
  CHANGE_DELETED_STATUS_SUCCESS,
  CHANGE_DELETED_STATUS_ERROR,
  CLEAR_DELETE_PATIENT_STATUS,
} from "./actionTypes";

export function getPatientsList(page, size, q) {
  return async (dispatch) => {
    axios
      .get("/users/", {
        params: {
          role: "patient",
          page,
          size,
          q,
        },
      })
      .then((response) => {
        dispatch(getPatientsListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPatientsListError());
        console.log(err);
      });
  };
}
export function createPatient(data) {
  return async (dispatch) => {
    let sendingData = {
      email: data.email,
      password: data.password,
      first_name: data.first_name,
      second_name: data.second_name,
      patronymic_name: data.patronymic_name,
      gender: data.gender,
      date_of_birth: data.date_of_birth,
      registration_address: data.registration_address,
      real_address: data.real_address,
    };
    axios
      .post("/users/invite_patient", sendingData)
      .then(() => {
        dispatch(createPatientSuccess());
      })
      .catch((err) => {
        dispatch(createPatientError());
        console.log(err);
      });
  };
}

export function getPatientByID(id) {
  return async (dispatch) => {
    axios
      .get(`/profiles/${id}`)
      .then((response) => {
        dispatch(getPatientByIDSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getPatientByIDError());
        console.log(err);
      });
  };
}

export function getRepresentativesByID(id) {
  return async (dispatch) => {
    axios
      .get(`/representatives/${id}`)
      .then((response) => {
        dispatch(getRepresentativesByIDSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getRepresentativesByIDError());
        console.log(err);
      });
  };
}

export function getTestResultsByID(id) {
  return async (dispatch) => {
    axios
      .get(`/tests/results/${id}`)
      .then((response) => {
        dispatch(getTestResultsByIDSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getTestResultsByIDError());
        console.log(err);
      });
  };
}

export function changeUserStatus(userId, data) {
  return async (dispatch) => {
    axios
      .post(`/users/${userId}/change_status`, data)
      .then(() => {
        dispatch(changeUserStatusSuccess());
        dispatch(getPatientByID(userId));
      })
      .catch((err) => {
        dispatch(changeUserStatusError());
        console.log(err);
      });
  };
}

export function changeDeletedPatientStatus(userId, data) {
  return async (dispatch) => {
    axios
      .post(`/users/${userId}/change_deleted_status`, data)
      .then(() => {
        dispatch(changeUserDeletedStatusSuccess());
        dispatch(getPatientByID(userId));
      })
      .catch((err) => {
        dispatch(changeUserDeletedStatusError(err.response.data.detail));
        console.log(err);
      });
  };
}

export function getDocumentsAccess(id) {
  return async (dispatch) => {
    let data = {};
    axios
      .post(`/documents/${id}/access`, data)
      .then(() => {
        dispatch(getDocumentsAccessSuccess());
        dispatch(getDocumentsByID(id));
      })
      .catch((err) => {
        dispatch(getDocumentsAccessError());
        console.log(err);
      });
  };
}
export function getDocumentsByID(id) {
  return async (dispatch) => {
    axios
      .get(`/documents/${id}`)
      .then((response) => {
        dispatch(getDocumentsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getDocumentsError());
        console.log(err);
      });
  };
}

export function createPatientSuccess() {
  return {
    type: CREATE_PATIENT_SUCCESS,
    createPatientStatus: "SUCCESS",
  };
}

export function createPatientError() {
  return {
    type: CREATE_PATIENT_ERROR,
    createPatientStatus: "ERROR",
  };
}

export function getPatientsListSuccess(data) {
  return {
    type: GET_PATIENTS_SUCCESS,
    data: data,
  };
}

export function getPatientsListError() {
  return {
    type: GET_PATIENTS_ERROR,
  };
}

export function getPatientByIDSuccess(patientData) {
  return {
    type: GET_PATIENTBYID_SUCCESS,
    activePatient: patientData,
  };
}

export function getPatientByIDError() {
  return {
    type: GET_PATIENTBYID_ERROR,
  };
}

export function getRepresentativesByIDSuccess(data) {
  return {
    type: GET_REPRESENTATIVESBYID_SUCCESS,
    representatives: data,
  };
}

export function getRepresentativesByIDError() {
  return {
    type: GET_REPRESENTATIVESBYID_ERROR,
  };
}

export function getTestResultsByIDSuccess(data) {
  return {
    type: GET_RESULTSBYID_SUCCESS,
    testResults: data,
  };
}

export function getTestResultsByIDError() {
  return {
    type: GET_RESULTSBYID_ERROR,
  };
}

export function changeUserStatusSuccess() {
  return {
    type: CHANGE_STATUS_SUCCESS,
  };
}

export function changeUserStatusError() {
  return {
    type: CHANGE_STATUS_ERROR,
  };
}

export function changeUserDeletedStatusSuccess() {
  return {
    type: CHANGE_DELETED_STATUS_SUCCESS,
  };
}

export function changeUserDeletedStatusError(error) {
  return {
    type: CHANGE_DELETED_STATUS_ERROR,
    error,
  };
}

export function getDocumentsAccessSuccess() {
  return {
    type: GET_ACCESS_SUCCESS,
  };
}

export function getDocumentsAccessError() {
  return {
    type: GET_ACCESS_ERROR,
  };
}
export function getDocumentsSuccess(data) {
  return {
    type: GET_DOCUMENTS_SUCCESS,
    documentsList: data,
  };
}

export function getDocumentsError() {
  return {
    type: GET_DOCUMENTS_ERROR,
  };
}
export function clearGetAccessStatus() {
  return {
    type: CLEAR_GET_ACCESS_STATUS,
  };
}
export function clearCreatePatientStatus() {
  return {
    type: CLEAR_CREATE_PATIENT_STATUS,
  };
}
export function clearDeletedPatientStatus() {
  return {
    type: CLEAR_DELETE_PATIENT_STATUS,
  };
}
