import React from 'react';
import { Link } from 'react-router-dom';

import classes from './FaqCard.module.scss';


const FaqCard = props => {
    return (
        <Link to={props.linkTo || "/"} className={classes.FaqCard}>
            <div className={classes.question}>
                {props.question}
            </div> 
        </Link>
    )
}


export default FaqCard