import React from "react";

import Card from "./Card/Card";

import classes from "./CardList.module.scss";

const CardList = ({ cards }) => {
  return (
    <div className={classes.CardList}>
      {cards.map((card) => {
        return (
          <Card
            key={card.id}
            id={card.id}
            profile={card.profile}
            doctorProfile={card.doctor_profile}
          />
        );
      })}
    </div>
  );
};

export default CardList;
