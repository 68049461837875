import {
  GET_CONSULTATIONS_SUCCESS,
  GET_CONSULTATIONS_ERROR,
  GET_PERFORMERS_SUCCESS,
  GET_PERFORMERS_ERROR,
  SET_SCROLL_STATUS,
  GET_LOG_FILE_SUCCESS,
  GET_LOG_FILE_ERROR,
} from "./actionTypes";

const initialState = {
  consultations: null,
  consultationsTotal: 0,
  scrollStatus: false,
  file: null,
};

export default function servicesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONSULTATIONS_SUCCESS:
      return {
        ...state,
        consultations: action.data.items,
        consultationsTotal: action.data.total,
      };
    case GET_CONSULTATIONS_ERROR:
      return {
        ...state,
        consultations: null,
      };
    case GET_PERFORMERS_SUCCESS:
      return {
        ...state,
        performers: action.performers,
      };
    case GET_PERFORMERS_ERROR:
      return {
        ...state,
        performers: null,
      };
    case GET_LOG_FILE_SUCCESS:
      return {
        ...state,
        file: action.data,
      };
    case GET_LOG_FILE_ERROR:
      return {
        ...state,
        file: null,
      };
    case SET_SCROLL_STATUS:
      return {
        ...state,
        scrollStatus: action.newStatus,
      };
    default:
      return state;
  }
}
