import React, { Component } from 'react';
import { connect } from 'react-redux';

import MainHeader from 'components/Main/MainHeader/MainHeader';
import Footer from 'components/Footer/Footer';
import Article from 'components/Article/Article';
import Navigation from 'components/UI/Navigation/Navigation';

import classes from './Articles.module.scss';

import { getArticlesList } from 'store/Profile/Articles/actions'
import { Helmet } from 'react-helmet';

const ARTICLSES_IN_ONE_PAGE = 12; // Статей на странице

class Articles extends Component {
    state = {
        activePage: 1,
    }

    componentDidMount() {
        this.props.getArticlesList(this.state.activePage - 1, ARTICLSES_IN_ONE_PAGE);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activePage !== this.state.activePage) {
            this.props.getArticlesList(this.state.activePage - 1, ARTICLSES_IN_ONE_PAGE);
        }
    }

    changePageHandler = (pages, jumpTo) => {
        let activePage = this.state.activePage;

        if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) { // Если переход на несуществующую или текущую страницу
            return;
        } else {
            activePage = jumpTo;
        }
        this.setState({ activePage })
        window.scrollTo(0, 0);
    }

    render() {
        let pagesAmount = null;
        if (this.props.total) {
            pagesAmount = Math.trunc(this.props.total / ARTICLSES_IN_ONE_PAGE) + (this.props.total % ARTICLSES_IN_ONE_PAGE ? 1 : 0); // Получение целого числа страниц
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>Статьи. Портал здоровья</title>
                    <meta name="title" content="Статьи. Портал здоровья" />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:title" content="Статьи. Портал здоровья" />
                </Helmet>
                <MainHeader />
                {this.props.articlesList ?
                    <React.Fragment>
                        <h2 className={classes.title}>Статьи</h2>
                        <section className={classes.Articles}>
                            {this.props.articlesList.map(article => {
                                return (
                                    <Article
                                        type={"small"}
                                        text={article.body}
                                        title={article.title}
                                        date={article.created_at}
                                        imageUrl={article.image_url}
                                        linkTo={'/articles/' + article.id}
                                    />
                                )
                            })}
                        </section>
                        {pagesAmount > 1 ?
                            <div className={classes.navigation}>
                                <Navigation
                                    activePage={this.state.activePage}
                                    pages={pagesAmount}
                                    onChange={this.changePageHandler}
                                />
                            </div>

                            : null}
                    </React.Fragment>
                    :
                    <section className={classes.Articles}>
                        
                    </section>
                }
                <Footer />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        articlesList: state.articles.articlesList,
        total: state.articles.articlesTotal,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getArticlesList: (page, size) => dispatch(getArticlesList(page, size))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Articles);