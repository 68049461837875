import React from 'react';
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';

import EmptySection from 'components/Profile/EmptySection/EmptySection';
import Select from "components/UI/Select/Select";
import Navigation from "components/UI/Navigation/Navigation"
import CustomDatePicker from 'components/UI/CustomDatePicker/CustomDatePicker';
import FileLoader from 'components/UI/FileControl/FileLoader/FileLoader';
import Search from "components/UI/Search/Search";


import { getEventsList } from "store/Profile/Journal/actions";
import { eventTypeEnum, roleEnum, tabsEnum } from "./enums";
import { converDateForJournal } from "./functions";
import { statusEnum } from 'components/Profile/CardList/enums';
import classes from './Journal.module.scss';

// Вкладки
const filterTabs = ["Журнал действий", "Пациенты", "Специалисты", "Ассистенты"];
// Сортировка
const selectData = ["По дате: по убыванию", "По дате: по возрастанию"];

const Journal = props => {
    const PAGE_SIZE = 20;

    // Загрузка
    const [isLoading, setIsLoading] = React.useState(false);

    // Страницы
    const [activePage, setActivePage] = React.useState(1);
    const [pagesAmount, setPagesAmount] = React.useState();

    React.useEffect(() => {
        const pagesAmount = Math.trunc(props.total / PAGE_SIZE) + (props.total % PAGE_SIZE ? 1 : 0);
        setPagesAmount(pagesAmount);
    }, [props.total]);


    // Выбор даты
    const [startDate, setStartDate] = React.useState(() => new Date(Date.now() - 1000 * 60 * 60 * 24 * 7)); // неделю назад
    const [endDate, setEndDate] = React.useState(() => new Date());
    const [selectedStartDate, setSelectedStartDate] = React.useState(startDate);
    const [selectedEndDate, setSelectedEndDate] = React.useState(endDate);

    // Сортировка списка событий для отрисовки
    const [activeTab, setActiveTab] = React.useState(0);
    const [activeSelect, setActiveSelect] = React.useState(0);

    // Поиск 
    const [searchValue, setSearchValue] = React.useState(null);
    const [prevSearch, setPrevSearch] = React.useState(null);
    const [isSearching, setIsSearching] = React.useState(false);

    function getDates() {
        return [
            selectedStartDate ? selectedStartDate.toISOString().substring(0, 10) : null,
            selectedEndDate ? selectedEndDate.toISOString().substring(0, 10) : null
        ]
    }

    function getRole() {
        return Object.keys(tabsEnum)[activeTab - 1];
    }

    function getOrderBy() {
        return ['date_desc', 'date_asc'][activeSelect];
    }

    // обновлние списка событий
    React.useEffect(() => {
        setIsLoading(true);
        const [date1, date2] = getDates();
        const role = getRole();
        const order_by = getOrderBy();
        if (activePage > 1) {
            setActivePage(1);
        } else {
            props.getEventsList(date1, date2, role, order_by, isSearching ? searchValue : null, 0, PAGE_SIZE);
        }
    }, [selectedStartDate, activeTab, activeSelect]);

    React.useEffect(() => {
        setIsLoading(true);
        const [date1, date2] = getDates();
        const role = getRole();
        const order_by = getOrderBy();
        props.getEventsList(date1, date2, role, order_by, isSearching ? searchValue : null, activePage - 1, PAGE_SIZE);
    }, [activePage]);

    React.useEffect(() => {
        setIsLoading(false);
    }, [props.eventsList]);

    function changePageHandler(pages, jumpTo) {
        if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
            return;
        } else {
            setActivePage(jumpTo);
        }
        window.scrollTo(0, 0);
    };

    const changeDateHandler = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const dateRangeSelectHandler = () => {
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
    };

    const dateRangeResetHandler = () => {
        setSelectedStartDate(() => new Date(Date.now() - 1000 * 60 * 60 * 24 * 7));
        setSelectedEndDate(() => new Date());
        setStartDate(() => new Date(Date.now() - 1000 * 60 * 60 * 24 * 7));
        setEndDate(() => new Date());
    };

    const onSearchHandler = () => {
        if (prevSearch === searchValue || !searchValue) return null;
        setIsLoading(true);
        setIsSearching(true);
        setPrevSearch(searchValue);
        const [date1, date2] = getDates();
        const role = getRole();
        const order_by = getOrderBy();

        if (activePage > 1) {
            setActivePage(1);
        } else {
            props.getEventsList(date1, date2, role, order_by, searchValue, 0, PAGE_SIZE);
        }
    }
    const onSearchResetHandler = () => {
        setIsLoading(true);
        setIsSearching(false);
        setPrevSearch(null);
        setSearchValue(null);
        const [date1, date2] = getDates();
        const role = getRole();
        const order_by = getOrderBy();
        if (activePage > 1) {
            setActivePage(1); // При смене страницы автоматически отправится запрос
        } else {
            props.getEventsList(date1, date2, role, order_by, null, 0, PAGE_SIZE);
        }
    }

    return (
        <section className={classes.Journal}>
            <Helmet>
                <title>Журнал. Портал здоровья</title>
                <meta name="title" content="Журнал. Портал здоровья" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content="Журнал. Портал здоровья" />
            </Helmet>
            {/* Вкладки */}
            <div className={classes.tabs}>
                {filterTabs.map((tab, index) => {
                    const cls = [classes.tab];
                    if (activeTab === index) {
                        cls.push(classes.activeTab)
                    }
                    return (
                        <div
                            className={cls.join(" ")}
                            key={tab + index}
                            onClick={() => setActiveTab(index)}
                        >
                            {tab}
                        </div>
                    )
                })}
            </div>
            {/* Сортировка */}
            <div className={classes.sort}>
                Сортировать
                <div className={classes.select}>
                    <Select
                        data={selectData}
                        value={activeSelect}
                        onChange={(event) => { setActiveSelect(event.target.value) }}
                    />
                </div>
                {/* Выбор даты */}
                <div className={classes.datepicker}>
                    <CustomDatePicker
                        selected={startDate}
                        onChange={changeDateHandler}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange={true}
                        maxDate={new Date()}
                        onOkClick={dateRangeSelectHandler}
                        onCancelClick={dateRangeResetHandler}
                    />
                </div>
            </div>
            {/* Поиск */}
            <div className={classes.search}>
                <div className={classes.search_title}>Найти по почте</div>
                <div className={classes.search_input}>
                    <Search
                        value={searchValue}
                        onChange={(value) => setSearchValue(value === "" ? null : value)}
                        onSearch={() => onSearchHandler()}
                        total={props.total}
                        showReset={isSearching}
                        onReset={onSearchResetHandler}
                        placeholder="Введите почту пользователя"
                    />
                </div>
            </div>
            {/* Таблица */}
            {props.eventsList && !isLoading ?
                props.eventsList.length > 0 ?
                    <div className={classes.table_wrapper}>
                        <table className={classes.table}>
                            <tr className={classes.table_header}>
                                <th className={classes.time_head}>Дата и время</th>
                                <th className={classes.name_head}>ФИО</th>
                                <th className={classes.email_head}>Почта</th>
                                <th className={classes.role_head}>роль</th>
                                <th className={classes.action_head}>Действия</th>
                            </tr>
                            {/* Событие */}
                            {props.eventsList.map(event => {
                                return (
                                    <tr key={event.id} className={classes.table_data}>
                                        <td className={classes.time_data}>
                                            {converDateForJournal(new Date(event.created_at))}
                                        </td>
                                        {event.user.profile ?
                                            <td className={classes.name_data}>
                                                {event.user.profile.second_name} {event.user.profile.first_name} {event.user.profile.patronymic_name || ""}
                                            </td>
                                            :
                                            <td>
                                                —
                                            </td>
                                        }
                                        <td className={classes.email_data}>
                                            {event.user.email}
                                        </td>
                                        {event.user.role ?
                                            <td className={classes.role_data}>
                                                {roleEnum[event.user.role]}
                                            </td>
                                            :
                                            <td>
                                                —
                                            </td>
                                        }
                                        {event.event_type === "consultation_status_changed" ?
                                            <td className={classes.action_data}>{eventTypeEnum[event.event_type]} №{event.consultation_id} на "{statusEnum[event.new_consultation_status]}"</td>
                                            :
                                            event.event_type === "consultation_created" ?
                                                <td className={classes.action_data}>{eventTypeEnum[event.event_type]} №{event.consultation_id}</td>
                                                :
                                                <td className={classes.action_data}>{eventTypeEnum[event.event_type]}</td>
                                        }
                                    </tr>
                                )
                            })}
                        </table>
                        {pagesAmount > 1 ? (
                            <Navigation
                                activePage={activePage}
                                pages={pagesAmount}
                                onChange={changePageHandler}
                            />
                        ) : null}
                    </div>
                    :
                    <div className={classes.empty}>
                        <EmptySection title={'Журнал пуст'} text={'Ничего нет'} />
                    </div>
                :
                <div className={classes.loader}>
                    <FileLoader style={{ maxWidth: "100%" }} />
                </div>
            }
        </section>
    )
}

function mapStateToProps(state) {
    return {
        eventsList: state.journal.eventsList,
        total: state.journal.total,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getEventsList: (date1, date2, role, order_by, q, page, size) => dispatch(getEventsList(date1, date2, role, order_by, q, page, size)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Journal);