import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";

import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";
import EmptySection from "components/Profile/EmptySection/EmptySection";
import Button from "components/UI/Button/Button";
import { statusEnum } from "components/Profile/CardList/enums";

import { getSummary, getAllSummary } from "store/Profile/Summary/actions";

import {
  getFormatDate,
  getTimeFromDate,
} from "../../../components/Profile/CardList/enums";

import classes from "./Summary.module.scss";

const cx = classNames.bind(classes);

const tabsEnum = {
  // all: "Все",
  consultation_status_change: "Статус",
  archive_access_request: "Мед. архив",
  new_chat_message: "Чат",
  consultation_created: "Запросы",
  new_patient_registration: "Регистрация пациента",
  new_doctor_registration: "Регистрация специалиста",
};

const patientTabs = [
  "consultation_status_change",
  "archive_access_request",
  "new_chat_message",
];
const providerTabs = [
  "consultation_created",
  "consultation_status_change",
  "new_patient_registration",
  "new_doctor_registration",
];
const doctorTabs = ["consultation_created", "new_chat_message"];

const roleTabs = {
  patient: patientTabs,
  doctor: doctorTabs,
  provider: providerTabs,
};

const renderTypeTemplate = (
  type,
  user,
  consultationId,
  consultationStatus,
  accountRole
) => {
  const userName = user.profile.first_name + " " + user.profile.second_name;
  const userLink = goToUser(user.id, user.role, accountRole);

  switch (type) {
    case "consultation_status_change":
      return (
        <div className={classes.info_text}>
          <span
            className={`${classes.user} ${userLink ? classes.link : ""}`}
            onClick={() => userLink && window.open(userLink)}
          >
            {userName}
          </span>{" "}
          изменил статус консультации N{consultationId} на{" "}
          <span className={classes.status}>
            «{statusEnum[consultationStatus]}»
          </span>
        </div>
      );
    case "archive_access_request":
      return (
        <div className={classes.info_text}>
          <span
            className={`${classes.user} ${userLink ? classes.link : ""}`}
            onClick={() => userLink && window.open(userLink)}
          >
            {userName}
          </span>{" "}
          запросил доступ в медицинский архив
        </div>
      );
    case "new_chat_message":
      return (
        <div className={classes.info_text}>
          <span
            className={`${classes.user} ${userLink ? classes.link : ""}`}
            onClick={() => userLink && window.open(userLink)}
          >
            {userName}
          </span>{" "}
          отправил новое сообщение в чате консультации N{consultationId}
        </div>
      );
    case "consultation_created":
      return (
        <div className={classes.info_text}>
          <span
            className={`${classes.user} ${userLink ? classes.link : ""}`}
            onClick={() => userLink && window.open(userLink)}
          >
            {userName}
          </span>{" "}
          отправил запрос на консультацию N{consultationId}
        </div>
      );
    case "new_patient_registration":
      return (
        <div
          className={classes.info_text}
          onClick={() => userLink && window.open(userLink)}
        >
          Пациент{" "}
          <span className={`${classes.user} ${userLink ? classes.link : ""}`}>
            {userName}
          </span>{" "}
          зарегистрирован на портале
        </div>
      );
    case "new_doctor_registration":
      return (
        <div
          className={classes.info_text}
          onClick={() => userLink && window.open(userLink)}
        >
          Специалист{" "}
          <span className={`${classes.user} ${userLink ? classes.link : ""}`}>
            {userName}
          </span>{" "}
          зарегистрирован на портале
        </div>
      );
    default:
      return "";
  }
};

const iconPaths = {
  consultation_status_change: "/profile/images/consultations.svg",
  archive_access_request: "/profile/images/medcard.svg",
  new_chat_message: "/profile/images/consultations.svg",
  consultation_created: "/profile/images/consultations.svg",
  new_patient_registration: "/profile/images/patients.svg",
  new_doctor_registration: "/profile/images/doctors.svg",
};

const goToDetail = (event) => {
  switch (event.summary_type) {
    case "consultation_status_change":
    case "new_chat_message":
    case "consultation_created":
      return `/profile/consultations/consultation/${event.consultation_id}`;
    case "archive_access_request":
      return `/profile/medcard/accesses`;
    case "new_patient_registration":
      return `/profile/patients/patient/${event.user.id}`;
    case "new_doctor_registration":
      return `/profile/doctors/doctor_edit/${event.user.id}`;
    default:
      return "";
  }
};

const goToUser = (userId, userRole, accountRole) => {
  if (userRole === "doctor" && accountRole === "patient") {
    return `/profile/doctors/doctor_info/${userId}`;
  }
  if (userRole === "patient" && accountRole === "doctor") {
    return `/profile/patients/patient/${userId}`;
  }
  if (userRole === "doctor" && accountRole === "provider") {
    return `/profile/doctors/doctor_edit/${userId}`;
  }
  if (userRole === "patient" && accountRole === "provider") {
    return `/profile/patients/patient/${userId}`;
  }
};

const Summary = () => {
  const dispatch = useDispatch();
  const {
    profile: { role },
    summary: { summary, summaryTotal, loading },
  } = useSelector((state) => ({
    profile: state.profile,
    summary: state.summary,
  }));

  const [activeTab, setActiveTab] = React.useState(0);

  React.useEffect(() => {
    role && dispatch(getSummary(roleTabs[role][activeTab]));
  }, [activeTab, role]);

  React.useEffect(() => {
    role && dispatch(getAllSummary(roleTabs[role]));
  }, [role]);

  return (
    <div className={classes.Summary}>
      <ul className={classes.tabs}>
        {role &&
          roleTabs[role].map((tab, index) => (
            <li
              key={index}
              className={cx({ tab: true, active: index === activeTab })}
              onClick={() => setActiveTab(index)}
            >
              {tabsEnum[tab]}
              {summaryTotal?.[index] && summaryTotal[index].length > 0 && (
                <span className={classes.count}>
                  {summaryTotal[index].length}
                </span>
              )}
            </li>
          ))}
      </ul>
      {loading ? (
        <div className={classes.status_loader}>
          <FileLoader style={{ height: "100%", maxWidth: "100%" }} />
        </div>
      ) : null}
      <div className={classes.content}>
        <ul className={classes.list}>
          {summary && summary.length > 0 ? (
            summary.map((item) => {
              return (
                <li className={classes.list_item}>
                  <div
                    className={`${classes.icon_wrapper} ${classes.icon_wrapper__desktop}`}
                  >
                    <span
                      className={classes.icon}
                      style={{
                        maskImage: `url(${iconPaths[item.summary_type]})`,
                        WebkitMaskImage: `url(${iconPaths[item.summary_type]})`,
                      }}
                    ></span>
                  </div>
                  <div className={classes.info}>
                    <div className={classes.info_body}>
                      <div
                        className={`${classes.icon_wrapper} ${classes.icon_wrapper__mobile}`}
                      >
                        <span
                          className={classes.icon}
                          style={{
                            maskImage: `url(${iconPaths[item.summary_type]})`,
                            WebkitMaskImage: `url(${
                              iconPaths[item.summary_type]
                            })`,
                          }}
                        ></span>
                      </div>
                      {renderTypeTemplate(
                        item.summary_type,
                        item.user,
                        item.consultation_id,
                        item.new_consultation_status,
                        role
                      )}
                    </div>
                    <div className={classes.date_wrapper}>
                      {item.summary_type === "consultation_status_change" && (
                        <div className={classes.date}>
                          Создано:{" "}
                          {getFormatDate(
                            new Date(item.consultation_created_at)
                          )}{" "}
                          {getTimeFromDate(
                            new Date(item.consultation_created_at)
                          )}
                        </div>
                      )}
                      <div className={classes.date}>
                        Событие: {getFormatDate(new Date(item.date))}{" "}
                        {getTimeFromDate(new Date(item.date))}
                      </div>
                    </div>
                  </div>
                  <div className={classes.button}>
                    {role === "provider" &&
                    (item.summary_type === "consultation_status_change" ||
                      item.summary_type === "consultation_created") ? null : (
                      <Link
                        to={goToDetail(item)}
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        <Button type={"primary"}>Смотреть</Button>
                      </Link>
                    )}
                  </div>
                </li>
              );
            })
          ) : (
            <EmptySection text={"У Вас пока нет новых событий"} />
          )}
        </ul>
      </div>
    </div>
  );
};

export default Summary;
