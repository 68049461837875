import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import MainHeader from 'components/Main/MainHeader/MainHeader';
import Footer from 'components/Footer/Footer';
import Input from 'components/UI/Input/Input';
import TextArea from 'components/UI/TextArea/TextArea';
import Button from 'components/UI/Button/Button';
import TipModal from 'components/UI/TipModal/TipModal';
import { validateControl } from 'containers/Auth/Auth';

import { sendFeedback, clearFeedbackStatus } from 'store/Profile/Feedback/actions';

import classes from './Feedback.module.scss';

const THEME_LIMIT = 100;
const TEXT_LIMIT = 1000;

const Feedback = props => {
    const [theme, setTheme] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [messageBody, setMessageBody] = React.useState("");

    const [isThemeValid, setIsThemeValid] = React.useState(false);
    const [isEmailValid, setIsEmailValid] = React.useState(false);
    const [isMessageBodyValid, setIsMessageBodyValid] = React.useState(false);

    const [isThemeTouched, setIsThemeTouched] = React.useState(false);
    const [isEmailTouched, setIsEmailTouched] = React.useState(false);
    const [isMessageBodyTouched, setIsMessageBodyTouched] = React.useState(false);

    React.useEffect(() => {
        setIsThemeValid(theme.length > 0 && theme.length <= 100);
        setIsEmailValid(validateControl(email, { require: true, email: true }));
        setIsMessageBodyValid(messageBody.length > 0 && messageBody.length <= 1000);
        if (props.postFeedbackStatus !== "") {
            props.clearFeedbackStatus();
        }
    }, [theme, email, messageBody])

    React.useEffect(() => {
        return () => props.clearFeedbackStatus();
    }, [])

    const isFormValid = isThemeValid && isEmailValid && isMessageBodyValid;

    function sendForm() {
        const data = {
            title: theme,
            text: messageBody,
            email: email,
        }
        props.sendFeedback(data);
        setTheme("");
        setEmail("");
        setMessageBody("");
        setIsThemeTouched(false);
        setIsMessageBodyTouched(false);
        setIsEmailTouched(false);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Обратная связь. Портал здоровья</title>
                <meta name="title" content="Обратная связь. Портал здоровья" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content="Обратная связь. Портал здоровья" />
            </Helmet>
            <MainHeader />
            <section className={classes.Feedback}>
                <h2 className={classes.title}>Обратная связь</h2>
                <div className={classes.subtitle}>
                    Заполните форму и мы обязательно свяжемся с вами
                </div>
                <form className={classes.form} onSubmit={e => e.preventDefault()}>
                    <div className={classes.wrapper}>
                        {theme.length <= THEME_LIMIT ?
                            <div className={classes.limit}>
                                <span>
                                    {THEME_LIMIT - theme.length}
                                </span>
                            </div>
                            : null
                        }
                        <Input
                            value={theme}
                            placeholder={"Тема сообщения"}
                            valid={isThemeValid}
                            touched={isThemeTouched}
                            label={"Тема сообщения"}
                            shouldValidate={true}
                            errorMessage={`Обязательное поле. Не больше ${THEME_LIMIT} символов`}
                            onChange={(event) => {
                                setIsThemeTouched(true);
                                setTheme(event.target.value);
                            }}
                        />
                        <Input
                            value={email}
                            placeholder={"Почта"}
                            valid={isEmailValid}
                            touched={isEmailTouched}
                            label={"Почта"}
                            shouldValidate={true}
                            errorMessage={"Введите корректный email"}
                            onChange={(event) => {
                                setIsEmailTouched(true);
                                setEmail(event.target.value);
                            }}
                        />
                        <div className={classes.textarea_wrapper}>
                            {messageBody.length <= TEXT_LIMIT ?
                                <div className={classes.limit}>
                                    <span>
                                        {TEXT_LIMIT - messageBody.length}
                                    </span>
                                </div>
                                : null
                            }
                            <TextArea
                                placeholder="Комментарий"
                                label={messageBody.length > 0 ? "Комментарий" : ""}
                                value={messageBody}
                                onChange={(event) => {
                                    setIsMessageBodyTouched(true);
                                    setMessageBody(event.target.value);
                                }}
                                invalid={!isMessageBodyValid && isMessageBodyTouched}
                                errorMessage={`Обязательное поле. Не больше ${TEXT_LIMIT} символов`}
                            />
                        </div>
                        <Button
                            type="primary"
                            onClick={sendForm}
                            disabled={!isFormValid}
                            needTip={true}
                        >Отправить</Button>
                        {props.isTipModalOpen ?
                            <TipModal />
                            :
                            null
                        }
                        {
                            props.postFeedbackStatus === "SUCCESS" ?
                                <div className={classes.success}>Сообщение отправлено</div>
                                : null
                        }
                        {
                            props.postFeedbackStatus === "ERROR" ?
                                <div className={classes.error}>Ошибка отправки собщения</div>
                                : null
                        }
                    </div>
                </form>
            </section>
            <Footer />
        </React.Fragment>
    )
}

function mapStateToProps(state) {
    return {
        postFeedbackStatus: state.feedback.postFeedbackStatus,
        isTipModalOpen: state.profile.isTipModalOpen
    }
}

function mapDispatchToProps(dispatch) {
    return {
        sendFeedback: (data) => dispatch(sendFeedback(data)),
        clearFeedbackStatus: () => dispatch(clearFeedbackStatus())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);