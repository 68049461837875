import {
  GET_FAQLIST_ERROR,
  GET_FAQLIST_SUCCESS,
  CREATE_FAQ_ERROR,
  CREATE_FAQ_SUCCESS,
  CREATE_FAQ_PENDING,
  EDIT_FAQ_SUCCESS,
  EDIT_FAQ_ERROR,
  GET_FAQBYID_SUCCESS,
  GET_FAQBYID_ERROR,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_ERROR,
} from "./actionTypes";

const initialState = {
  faqList: null,
  faqTotal: 0,
  editedFaq: null,
  createFaqStatus: "",
  needUpdate: false,
};

export default function medcardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FAQLIST_SUCCESS:
      return {
        ...state,
        faqList: action.data.items,
        faqTotal: action.data.total,
        createFaqStatus: "",
        needUpdate: false,
      };
    case GET_FAQLIST_ERROR:
      return {
        ...state,
      };
    case CREATE_FAQ_SUCCESS:
      return {
        ...state,
        createFaqStatus: action.createFaqStatus,
      };
    case CREATE_FAQ_PENDING:
      return {
        ...state,
        createFaqStatus: action.createFaqStatus,
      };
    case EDIT_FAQ_SUCCESS:
      return {
        ...state,
        editFaqStatus: action.editFaqStatus,
      };
    case EDIT_FAQ_ERROR:
      return {
        ...state,
        editFaqStatus: action.editFaqStatus,
      };
    case CREATE_FAQ_ERROR:
      return {
        ...state,
        createFaqStatus: action.createFaqStatus,
      };
    case GET_FAQBYID_SUCCESS:
      return {
        ...state,
        editedFaq: action.editedFaq,
      };
    case GET_FAQBYID_ERROR:
      return {
        ...state,
        editedFaq: null,
      };
    case DELETE_FAQ_SUCCESS:
      return {
        ...state,
        needUpdate: true,
      };
    case DELETE_FAQ_ERROR:
      return {
        ...state,
      };
    default:
      return state;
  }
}
