import {
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_ERROR,
  CREATE_PATIENT_SUCCESS,
  CREATE_PATIENT_ERROR,
  GET_PATIENTBYID_SUCCESS,
  GET_PATIENTBYID_ERROR,
  GET_REPRESENTATIVESBYID_SUCCESS,
  GET_REPRESENTATIVESBYID_ERROR,
  GET_RESULTSBYID_SUCCESS,
  GET_RESULTSBYID_ERROR,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_ERROR,
  CHANGE_DELETED_STATUS_SUCCESS,
  CHANGE_DELETED_STATUS_ERROR,
  GET_ACCESS_SUCCESS,
  GET_ACCESS_ERROR,
  CLEAR_GET_ACCESS_STATUS,
  CLEAR_CREATE_PATIENT_STATUS,
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_ERROR,
  CLEAR_DELETE_PATIENT_STATUS,
} from "./actionTypes";

const initialState = {
  patientsList: null,
  patientsTotal: 0,
  patientsListStatus: null,
  activePatient: null,
  testResults: [],
  representatives: [],
  documentAccessStatus: "",
  getAccessStatus: "",
  documentsList: [],
  deletePatientStatus: "",
};

export default function patientsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PATIENTS_SUCCESS:
      return {
        ...state,
        patientsList: action.data.items,
        patientsTotal: action.data.total,
        patientsListStatus: "SUCCESS",
      };
    case GET_PATIENTS_ERROR:
      return {
        ...state,
        patientsListStatus: "ERROR",
      };
    case CREATE_PATIENT_SUCCESS:
      return {
        ...state,
        createPatientStatus: action.createPatientStatus,
      };
    case CREATE_PATIENT_ERROR:
      return {
        ...state,
        createPatientStatus: action.createPatientStatus,
      };
    case GET_PATIENTBYID_SUCCESS:
      return {
        ...state,
        activePatient: action.activePatient,
      };
    case GET_PATIENTBYID_ERROR:
      return {
        ...state,
      };
    case GET_REPRESENTATIVESBYID_SUCCESS:
      return {
        ...state,
        representatives: action.representatives,
      };
    case GET_REPRESENTATIVESBYID_ERROR:
      return {
        ...state,
      };
    case GET_RESULTSBYID_SUCCESS:
      return {
        ...state,
        testResults: action.testResults,
      };
    case GET_RESULTSBYID_ERROR:
      return {
        ...state,
      };
    case CHANGE_STATUS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_STATUS_ERROR:
      return {
        ...state,
      };
    case CHANGE_DELETED_STATUS_SUCCESS:
      return {
        ...state,
      };
    case CHANGE_DELETED_STATUS_ERROR:
      return {
        ...state,
        deletePatientStatus: action.error,
      };
    case CLEAR_DELETE_PATIENT_STATUS:
      return {
        ...state,
        deletePatientStatus: "",
      };
    case GET_ACCESS_SUCCESS:
      return {
        ...state,
        getAccessStatus: "SENDED",
      };
    case GET_ACCESS_ERROR:
      return {
        ...state,
        getAccessStatus: "DENIED",
      };
    case CLEAR_GET_ACCESS_STATUS:
      return {
        ...state,
        getAccessStatus: "",
      };
    case CLEAR_CREATE_PATIENT_STATUS:
      return {
        ...state,
        createPatientStatus: "",
      };
    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documentsList: action.documentsList,
        documentsAccessStatus: "ALLOWED",
      };
    case GET_DOCUMENTS_ERROR:
      return {
        ...state,
        documentsAccessStatus: "DENIED",
      };
    case "AUTH_LOGOUT":
      return {
        ...state,
        patientsList: null,
      };

    default:
      return state;
  }
}
