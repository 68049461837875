import React from "react";
import { useSelector } from "react-redux";

import UsersLogRow from "./UsersLogRow/UsersLogRow";

import classes from "./UsersLogTable.module.scss";

const UsersLogTable = (props) => {
  const { role } = useSelector(({ profile }) => profile);

  if (!props.inviteList) return null;

  return (
    <table className={classes.UsersLogTable}>
      <thead>
        <tr className={classes.table_header}>
          <th className={classes.email_head}>ФИО</th>
          <th className={classes.email_head}>Почта</th>
          {role === "provider" ? (
            <th className={classes.role_head}>Роль</th>
          ) : null}
          <th className={classes.send_head}>Дата отправки</th>
          <th className={classes.registered_head}>Дата регистрации</th>
        </tr>
      </thead>
      <tbody>
        {props.inviteList &&
          props.inviteList.map((invite) => {
            return (
              <UsersLogRow
                key={invite.id}
                id={invite.id}
                email={invite.email}
                sendDate={new Date(invite.created_at)}
                registeredDate={
                  invite.user_registered_at
                    ? new Date(invite.user_registered_at)
                    : null
                }
                user={invite.user}
                accountRole={role}
                inNewWindow
              />
            );
          })}
      </tbody>
    </table>
  );
};

export default UsersLogTable;
