import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import EmptySection from 'components/Profile/EmptySection/EmptySection';
import Navigation from "components/UI/Navigation/Navigation"

import { getFeedbackList } from 'store/Profile/Feedback/actions';
import { convertDateForDev } from "components/Profile/dateConverter";

import classes from './Feedback.module.scss';
import FeedbackItem from './FeedbackItem/FeedbackItem';
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";

const Feedback = props => {

    const PAGE_SIZE = 8;
    const [activePage, setActivePage] = React.useState(1);

    const [pagesAmount, setPagesAmount] = React.useState();
    React.useEffect(() => {
        const pagesAmount = Math.trunc(props.total / PAGE_SIZE) + (props.total % PAGE_SIZE ? 1 : 0);
        setPagesAmount(pagesAmount);
    }, [props.total]);

    React.useEffect(() => {
        props.getFeedbackList(activePage - 1, PAGE_SIZE);
    }, [activePage])

    function changePageHandler(pages, jumpTo) {
        if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
            return;
        } else {
            setActivePage(jumpTo);
        }
    };

    return (
        <section className={classes.Feedback}>
            <Helmet>
                <title>Обратная связь. Портал здоровья</title>
                <meta name="title" content="Обратная связь. Портал здоровья" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content="Обратная связь. Портал здоровья" />
            </Helmet>
            <h4 className={classes.title}>Обратная связь</h4>
            {props.feedbackList ?
                props.total === 0 ?
                    <EmptySection title={'Пусто'} text={'Список  пуст'} />
                    :
                    <React.Fragment>
                        <div className={classes.list}>
                            {props.feedbackList.map((item) => {
                                return (
                                    <FeedbackItem
                                        key={item.id}
                                        text={item.text}
                                        email={item.email}
                                        title={item.title}
                                        id={item.id}
                                        date={convertDateForDev(item.created_at.substring(0, 10))}
                                    />
                                )
                            })}
                        </div>
                        {pagesAmount > 1 ? (
                            <Navigation
                                activePage={activePage}
                                pages={pagesAmount}
                                onChange={changePageHandler}
                            />
                        ) : null}
                    </React.Fragment>
                :
                <div className={classes.loader}>
                    <FileLoader style={{ maxWidth: "100%" }} />
                </div>
            }

        </section>
    )
}

function mapStateToProps(state) {
    return {
        feedbackList: state.feedback.feedbackList,
        total: state.feedback.feedbackTotal,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getFeedbackList: (page, size) => dispatch(getFeedbackList(page, size))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)