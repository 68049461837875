import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Button from "components/UI/Button/Button";
import EmptySection from "components/Profile/EmptySection/EmptySection";
import Navigation from "components/UI/Navigation/Navigation";
import UserTable from "components/Profile/UserTable/UserTable";
import Search from "components/UI/Search/Search";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";

import classes from "./Providers.module.scss";
import { getProvidersList } from "store/Profile/Providers/actions";

const CARDS_IN_ONE_PAGE = 50;

class Providers extends Component {
  state = {
    activePage: 1,
    searchValue: null,
    prevSearch: null,
    isSearching: false,
  };
  componentDidMount() {
    this.props.getProvidersList(
      this.state.activePage - 1,
      CARDS_IN_ONE_PAGE,
      this.state.isSearching ? this.state.searchValue : null
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.activePage !== this.state.activePage) {
      this.props.getProvidersList(
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        this.state.isSearching ? this.state.searchValue : null
      );
    }
  }
  changePageHandler = (pages, jumpTo) => {
    let activePage = this.state.activePage;

    if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
      return;
    } else {
      activePage = jumpTo;
    }
    this.setState({ activePage });
    window.scrollTo(0, 0);
  };
  onSearchHandler = () => {
    if (
      this.state.prevSearch === this.state.searchValue ||
      !this.state.searchValue
    )
      return null;

    this.setState({ isSearching: true, prevSearch: this.state.searchValue });

    if (this.state.activePage > 1) {
      this.setState({ activePage: 1 }); // При смене страницы автоматически отправится запрос
    } else {
      this.props.getProvidersList(0, CARDS_IN_ONE_PAGE, this.state.searchValue);
    }
  };
  onSearchResetHandler = () => {
    this.setState({ isSearching: false, prevSearch: null, searchValue: null });
    if (this.state.activePage > 1) {
      this.setState({ activePage: 1 }); // При смене страницы автоматически отправится запрос
    } else {
      this.props.getProvidersList(0, CARDS_IN_ONE_PAGE);
    }
  };

  render() {
    let pagesAmount = null;

    if (this.props.providersList !== []) {
      pagesAmount =
        Math.trunc(this.props.total / CARDS_IN_ONE_PAGE) +
        (this.props.total % CARDS_IN_ONE_PAGE ? 1 : 0);
    }

    return (
      <section className={classes.Providers}>
        <Helmet>
          <title>Провайдеры. Портал здоровья</title>
          <meta name="title" content="Провайдеры. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Провайдеры. Портал здоровья" />
        </Helmet>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>Список провайдеров</div>
          {this.props.role === "support" || this.props.role === "admin" ? (
            <Link to={"/profile/providers/new_provider"}>
              <Button type={"plus"} text={"Добавить провайдера"} />
            </Link>
          ) : null}
        </div>
        <div className={classes.search_wrapper}>
          <Search
            value={this.state.searchValue}
            onChange={(value) =>
              this.setState({ searchValue: value === "" ? null : value })
            }
            onSearch={() => this.onSearchHandler()}
            total={this.props.total}
            showReset={this.state.isSearching}
            onReset={this.onSearchResetHandler}
          />
        </div>
        {this.props.providersList ? (
          this.props.providersList.length !== 0 ? (
            <React.Fragment>
              <div className={classes.list}>
                <UserTable
                  role={this.props.role}
                  userList={this.props.providersList}
                  inNewWindow={true}
                />
              </div>
              {pagesAmount > 1 ? (
                <Navigation
                  activePage={this.state.activePage}
                  pages={pagesAmount}
                  onChange={this.changePageHandler}
                />
              ) : null}
            </React.Fragment>
          ) : (
            <EmptySection
              title={"Пока нет провайдеров"}
              text={
                "Здесь находятся провайдеры. Специалист тех. поддержки может добавлять новых специалистов."
              }
            >
              {this.props.role === "support" || this.props.role === "admin" ? (
                <div className={classes.btn_wrapper}>
                  <Link to={"/profile/providers/new_provider"}>
                    <Button type={"primary"}>Добавить провайдера</Button>
                  </Link>
                </div>
              ) : null}
            </EmptySection>
          )
        ) : (
          <div className={classes.loader}>
            <FileLoader style={{ maxWidth: "100%" }} />
          </div>
        )}
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    role: state.profile.role,
    providersList: state.providers.providersList,
    total: state.providers.providersTotal,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProvidersList: (page, size, q) =>
      dispatch(getProvidersList(page, size, q)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
