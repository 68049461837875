import React from "react";
import classes from "./Back.module.scss";

const Back = (props) => {
  const cls = [classes.Back];
  if (props.className) {
    cls.push(props.className);
  }
  return (
    <span className={cls.join(" ")} onClick={props.onClick || null}>
      <img src={"/profile/images/back-link.svg"} alt={"back"} />
    </span>
  );
};

export default Back;
