import React from "react";

import classes from "./FileItem.module.scss";

export function formatedFileName(url) {
  const extension = url.substring(url.lastIndexOf("."));
  const fileFullName = url.substring(url.lastIndexOf("/") + 1);
  const fileName = fileFullName.slice(0, -extension.length);
  const shortName = `${fileName.slice(0, 3)}...${fileName.slice(
    fileName.length - 3,
    fileName.length
  )}${extension}`;
  return shortName;
}

const FileItem = (props) => {
  const cls = [classes.FileItem];
  if (props.download) {
    cls.push(classes.download_file);
  }

  if (!props.file) return null;

  return (
    <span
      style={props.style}
      className={cls.join(" ")}
      onClick={() => {
        if (!props.remove) return null;
        return props.remove(props.index);
      }}
    >
      {props.remove ? (
        <div
          className={classes.remove}
          style={{
            maskImage: "url('/profile/images/remove.svg')",
            WebkitMaskImage: "url('/profile/images/remove.svg')",
          }}
        />
      ) : null}
      {props.download
        ? [
            <div
              className={classes.download}
              style={{
                maskImage: "url('/profile/images/download.svg')",
                WebkitMaskImage: "url('/profile/images/download.svg')",
              }}
            />,
            <a
              className={classes.link}
              href={props.file}
              download={true}
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              {" "}
            </a>,
          ]
        : null}
      <span className={classes.filename}>{formatedFileName(props.file)}</span>
    </span>
  );
};

export default FileItem;
