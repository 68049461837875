import {
  AUTH_LOGOUT,
  LOGIN_SUCCESS,
  GET_SPECIALIZATIONS_SUCCESS,
  GET_SPECIALIZATIONS_ERROR,
  ADD_FILE_SUCCESS,
  REMOVE_FILE,
  CLEAR_FILE_LIST,
  GET_CARD_INFO_SUCCESS,
  CREATE_PROFILE_SUCCESS,
  CREATE_PROFILE_ERROR,
  LOAD_PICTURE_SUCCESS,
  REMOVE_PICTURE,
  CLEAR_ERRORS,
  GET_DOCTORS_SUCCESS,
  GET_DOCTORS_ERROR,
  SHOW_TIP_MODAL,
  CLOSE_TIP_MODAL,
  COLLAPSE_SIDEBAR,
} from "../actions/actionTypes";

const initialState = {
  role: null,
  created: null,
  consultations: null,
  attachments: [],
  activeCard: null,
  userpic_url: null,
  createProfileError: null,
  specializations: null,
  doctorsList: null,
  postFeedbackStatus: "",
  isProfileCreated: false,
  isTipModalOpen: false,
  isSidebarCollapsed: false,
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        role: action.role,
      };
    case AUTH_LOGOUT:
      return {
        ...state,
        role: null,
      };
    case CREATE_PROFILE_SUCCESS:
      return {
        ...state,
        role: action.role,
        createProfileError: null,
        isProfileCreated: true,
      };
    case CREATE_PROFILE_ERROR:
      return {
        ...state,
        createProfileError: action.error,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        createProfileError: null,
      };
    case LOAD_PICTURE_SUCCESS:
      return {
        ...state,
        userpic_url: action.url,
      };
    case REMOVE_PICTURE:
      return {
        ...state,
        userpic_url: null,
      };
    case GET_SPECIALIZATIONS_SUCCESS:
      return {
        ...state,
        specializations: action.specializations,
      };
    case GET_SPECIALIZATIONS_ERROR:
      return {
        ...state,
        specializations: null,
      };
    case ADD_FILE_SUCCESS:
      let attachments = [...state.attachments];
      attachments.push(action.url);
      return {
        ...state,
        attachments,
      };
    case REMOVE_FILE:
      return {
        ...state,
        attachments: state.attachments.filter((item, i) => i !== action.index),
      };
    case CLEAR_FILE_LIST:
      return {
        ...state,
        attachments: [],
      };
    case GET_CARD_INFO_SUCCESS:
      return {
        ...state,
        activeCard: action.cardInfo,
      };
    case GET_DOCTORS_SUCCESS:
      return {
        ...state,
        doctorsList: action.doctorsList,
      };
    case GET_DOCTORS_ERROR:
      return {
        ...state,
      };
    case SHOW_TIP_MODAL:
      return {
        ...state,
        isTipModalOpen: true,
      };
    case CLOSE_TIP_MODAL:
      return {
        ...state,
        isTipModalOpen: false,
      };
    case COLLAPSE_SIDEBAR:
      return {
        ...state,
        isSidebarCollapsed: action.isSidebarCollapsed,
      };
    default:
      return state;
  }
}
