import {
  GET_DOCTORSLIST_ERROR,
  GET_DOCTORSLIST_SUCCESS,
  CREATE_DOCTOR_ERROR,
  CREATE_DOCTOR_SUCCESS,
  CLEAR_CREATE_DOCTOR_STATUS,
  LOAD_PICTURE_SUCCESS,
  LOAD_PICTURE_ERROR,
  REMOVE_PICTURE,
  CREATE_DOCTORPROFILE_SUCCESS,
  CREATE_DOCTORPROFILE_ERROR,
  UPDATE_DOCTORPROFILE_SUCCESS,
  UPDATE_DOCTORPROFILE_ERROR,
  GET_DOCTORPROFILE_SUCCESS,
  GET_DOCTORPROFILE_ERROR,
  LOAD_MORE_DOCTORS_SUCCESS,
  GET_PROFILE_DOCTORSLIST_ERROR,
  GET_PROFILE_DOCTORSLIST_SUCCESS,
  LOAD_MORE_PROFILE_DOCTORS_SUCCESS
} from "./actionTypes";

import {
  GET_CONSULTATIONS_SUCCESS
} from "../Consultations/actionTypes"

const initialState = {
  doctorsList: null,
  doctorsTotal: 0,
  dataFetchStatus: "",
  userpic_url: null,
  editedDoctorProfile: null,
};

export default function articlesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DOCTORSLIST_SUCCESS:
      return {
        ...state,
        doctorsList: action.data.items,
        doctorsTotal: action.data.total,
        dataFetchStatus: "",
        userpic_url: null,
      };
    case LOAD_MORE_DOCTORS_SUCCESS:
      return {
        ...state,
        doctorsList: state.doctorsList.concat(action.data.items),
        doctorsTotal: action.data.total,
        dataFetchStatus: "",
        userpic_url: null,
      };
    case GET_DOCTORSLIST_ERROR:
      return {
        ...state,
        userpic_url: null,
      };
    case GET_PROFILE_DOCTORSLIST_SUCCESS:
      return {
        ...state,
        doctorsList: action.data.items,
        doctorsTotal: action.data.total,
        dataFetchStatus: "",
        userpic_url: null,
      };
    case LOAD_MORE_PROFILE_DOCTORS_SUCCESS:
      return {
        ...state,
        doctorsList: state.doctorsList.concat(action.data.items),
        doctorsTotal: action.data.total,
        dataFetchStatus: "",
        userpic_url: null,
      };
    case GET_PROFILE_DOCTORSLIST_ERROR:
      return {
        ...state,
        userpic_url: null,
      };
    case CREATE_DOCTOR_SUCCESS:
      return {
        ...state,
        userpic_url: null,
        dataFetchStatus: action.dataFetchStatus,
      };
    case CREATE_DOCTOR_ERROR:
      return {
        ...state,
        dataFetchStatus: action.dataFetchStatus,
      };
    case CLEAR_CREATE_DOCTOR_STATUS:
      return {
        ...state,
        dataFetchStatus: "",
      };
    case LOAD_PICTURE_SUCCESS:
      return {
        ...state,
        userpic_url: action.url,
      };
    case LOAD_PICTURE_ERROR:
      return {
        ...state,
        userpic_url: null,
      };
    case REMOVE_PICTURE:
      return {
        ...state,
        userpic_url: null,
      };
    case CREATE_DOCTORPROFILE_SUCCESS:
      return {
        ...state,
        dataFetchStatus: action.dataFetchStatus,
      };
    case CREATE_DOCTORPROFILE_ERROR:
      return {
        ...state,
        dataFetchStatus: action.dataFetchStatus,
      };
    case UPDATE_DOCTORPROFILE_SUCCESS:
      return {
        ...state,
        dataFetchStatus: action.dataFetchStatus,
      };
    case UPDATE_DOCTORPROFILE_ERROR:
      return {
        ...state,
        dataFetchStatus: action.dataFetchStatus,
      };
    case GET_DOCTORPROFILE_SUCCESS:
      return {
        ...state,
        editedDoctorProfile: action.editedDoctorProfile,
      };
    case GET_DOCTORPROFILE_ERROR:
      return {
        ...state,
      };
    case GET_CONSULTATIONS_SUCCESS:
      return {
        ...state,
        editedDoctorProfile: null
      };
    case "AUTH_LOGOUT":
      return {
        ...state,
        editedDoctorProfile: null
      };
    default:
      return state;
  }
}
