import React from 'react';

import classes from './Select.module.scss';


const Select = props => {
    return (
        <div className={classes.select_wrapper}>
            {props.label ?
                <span className={classes.label_top}>
                    {props.label}
                </span>
                :
                null
            }
            <select
                disabled={props.disabled}
                className={classes.Select}
                onChange={(event) => props.onChange(event)}
                value={props.value}
            >
                <option
                    className={classes.label}
                    value={""}
                    hidden
                    disabled
                >{props.label}</option>
                {props.data ? props.data.map((optionItem, index) => {
                    return (
                        <option value={index} key={index}>{optionItem}</option>
                    )
                }) : null}
            </select>
        </div>

    )
}


export default Select