import React from "react";
import classes from "./DeleteConfirm.module.scss";
import Button from "components/UI/Button/Button";
const DeleteConfirm = (props) => {
  return (
    <div className={classes.DeleteConfirm}>
      <div className={classes.title}>{props.title}</div>
      <img
        className={classes.icon}
        src={"/profile/images/delete.svg"}
        alt="delete"
      />
      {props.children}
      <div className={classes.buttons}>
        <div className={classes.btn}>
          <Button type={"additional"} onClick={props.closed}>
            Отмена
          </Button>
        </div>
        <div className={classes.btn} onClick={props.confirmed}>
          <Button type={"primary"}>{props.action || "Удалить"}</Button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirm;
