import React from "react";

import classes from "./TimePicker.module.scss";

// формирует массив option'ов
function renderOptions(interval) {
  // interval - минутный интервал : число
  const options = [];
  const startDate = new Date(2000, 1, 1, 0, 0);
  const endDate = new Date(2000, 1, 1, 23, 0);
  let date = startDate;
  options.push("--:--");
  options.push(getFormatedTime(date));
  while (date <= endDate) {
    date.setMinutes(date.getMinutes() + interval);
    options.push(getFormatedTime(date));
  }
  return options.map((option) => {
    return (
      <option value={option} key={option} className={classes.option}>
        {option}
      </option>
    );
  });
}

// возвращает время в формате чч:мм
function getFormatedTime(date) {
  const hours =
    date.getHours() < 10 ? `0${date.getHours()}` : date.getHours().toString();
  const minutes =
    date.getMinutes() < 10
      ? `0${date.getMinutes()}`
      : date.getMinutes().toString();
  return `${hours}:${minutes}`;
}

const TimePicker = (props) => {
  return (
    <select
      value={props.value}
      disabled={props.disabled || false}
      className={classes.TimePicker}
      onChange={(event) => props.onChange(event.target.value)}
    >
      {renderOptions(props.interval)}
    </select>
  );
};

export default TimePicker;
