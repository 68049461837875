import React from 'react';
import Input from '../../../components/UI/Input/Input';

import classes from './QuestionItem.module.scss';

const QuestionItem = props => {
    let type = null;
    if (props.type) {
        type = props.type;
    }

    const listCls = [classes.answer_list];
    let btnCls = classes.radio_btn;

    if (type === "multiple") {
        btnCls = classes.checkbox_btn;
    }

    if (type === "horizontal") {
        listCls.push(classes.horizontal)
    }

    return (
        <div className={classes.QuestionItem}>
            <div className={classes.question}>
                <strong className={classes.question_number}>
                    Вопрос&nbsp;{props.number}.&nbsp;
                </strong>
                {props.question}

                {props.explanation ? // Вопрос содержит дополнителььное пояснение?
                    <div className={classes.explanation}>
                        {props.explanation}
                    </div>
                    :
                    null
                }
            </div>
            {type === "input" ?  // вопрос с типом 'input' (содержит поле для самостоятельного ввода)
                <div className={classes.question_input}>
                    <Input
                        label={props.placeholder}
                        placeholder={props.placeholder}
                        value={props.userAnswer ? props.userAnswer.usersInput : null}
                        onChange={(event) => props.onChange(props.number, null, event.target.value, null)}
                        valid={props.isValid}
                        touched={true}
                        shouldValidate={props.isValid !== undefined}
                        errorMessage="Если вы ввели симптом, то выберите вариант ответа"
                    />
                </div>
                :
                null
            }
            <ul className={listCls.join(' ')}>
                {props.answers.map((answer, index) => {
                    return (
                        <li className={classes.answer_item}>
                            <label className={classes.answer_label}>
                                {type === "multiple" ?
                                    <input
                                        className={classes.answer_input}
                                        key={index}
                                        type={"checkbox"}
                                        name={"question" + props.number}
                                        onChange={ () => props.onChange(props.number, null, null, index)}
                                        checked={props.userAnswer && props.userAnswer.selectedAnswers? props.userAnswer.selectedAnswers.indexOf(index) > -1 : null} // если текущий индекс находится в массиве ответов
                                    />
                                    :
                                    <input
                                        className={classes.answer_input}
                                        key={index}
                                        type={"radio"}
                                        name={"question" + props.number}
                                        onChange={() => props.onChange(props.number, index, null, null)}
                                        checked={props.userAnswer ? index === props.userAnswer.answerId : null}
                                    />
                                }

                                <span
                                    className={btnCls}
                                />
                                {type === "unordered" || type === "multiple" ? //Вопрос с типом 'ненумерованный'?
                                    null
                                    :
                                    <span className={classes.answer_number}>
                                        {props.firstAnswerNumber ?
                                            Number(props.firstAnswerNumber) + index
                                            :
                                            index
                                        }
                                    </span>
                                }
                                <span className={classes.answer}
                                >{answer}</span>
                            </label>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}


export default QuestionItem