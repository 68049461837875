import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/UI/Button/Button';
import FileItem from 'components/UI/FileControl/FileItem/FileItem';
import HealthStatus from 'components/UI/HealthStatus/HealthStatus';

import classes from './ResultItem.module.scss';

const ResultItem = props => {
    return (
        <div className={classes.ResultItem}>
            <div className={classes.header}>
                <div className={classes.about}>
                    <div className={classes.type}>
                        {props.type}
                    </div>

                    {props.type === "ОФН-15" && props.score ?
                        <div className={classes.status_wrapper}>
                            <HealthStatus
                                color={props.color}
                                score={props.score}
                                description="ваш балл здоровья"
                            />
                        </div>
                        :
                        null
                    }
                    <div className={classes.date}>
                        от&nbsp;{props.date}
                    </div>
                </div>
                <div className={classes.repeat}>
                    <Link to={props.href}>
                        Повторить
                    </Link>
                </div>
            </div>
            <div className={classes.main}>
                <div className={classes.description}>
                    {props.description}
                </div>
                <div className={classes.row}>
                    <div className={classes.btn}>
                        <Link to={"/profile/new_consultation"}>
                            <Button type={"additional"}>Получить консультацию</Button>
                        </Link>
                    </div>
                    <div className={classes.file}>
                        {props.file ?
                            <FileItem
                                style={{
                                    height: "39px",
                                    width: "100%"
                                }}
                                file={props.file}
                                download
                            />
                            :
                            null
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}


export default ResultItem