import React from "react";
import { Link, withRouter } from "react-router-dom";

import Button from "components/UI/Button/Button";

import { isChatDisabled } from "../isChatDisabled";
import { statusEnum } from "../enums";

import classes from "./Card.module.scss";

const Card = (props) => {
  const hasNewMessages =
    (props.currentRole === "patient" && props.unread_by_patient) ||
    (props.currentRole === "assistant" && props.unread_by_assistant) ||
    (props.currentRole === "doctor" && props.unread_by_performer);

  return (
    <Link
      to={"/profile/consultations/consultation/" + props.id}
      style={{ color: "inherit" }}
    >
      <div className={classes.Card}>
        <div className={classes.header}>
          <div className={classes.type}>
            Вид&nbsp;услуги: <strong>{props.type}</strong>
          </div>
          <div className={classes.info}>
            №&nbsp;{props.id} от&nbsp;{props.date}&nbsp;{props.time}
          </div>
          <div className={classes.status}>{statusEnum[props.status]}</div>
        </div>
        <div className={classes.main}>
          {renderMain(props)}
          <div className={classes.chat}>
            {hasNewMessages &&
            !isChatDisabled(props.currentRole, props.status) ? (
              <React.Fragment>
                <img src={"/profile/images/new-message.svg"} alt={"dialog"} />
                <span className={classes.new_message}>Новые сообщения</span>
              </React.Fragment>
            ) : null}
          </div>
          {/* Стоймость */}
          {props.status === "waiting_for_payment" ||
          props.status === "finished" ? (
            <div className={classes.price}>
              {props.needs_payment
                ? (props.price ? props.price.toLocaleString() : 0) + " ₽"
                : "Бесплатно"}
            </div>
          ) : null}
          {props.currentRole === "assistant" && props.status === "created" ? (
            <div className={classes.buttons}>
              <Button
                type={"additional"}
                onClick={(event) =>
                  props.changeStatus(props.id, "moderation", event)
                }
              >
                Обработать заявку
              </Button>
            </div>
          ) : null}
          {props.currentRole === "doctor" && props.status === "moderation" ? (
            <div className={classes.buttons}>
              <Button
                type={"additional"}
                onClick={(event) =>
                  props.changeStatus(props.id, "accepted", event)
                }
              >
                Принять в работу
              </Button>
            </div>
          ) : null}

          {props.currentRole === "assistant" ||
          props.currentRole === "doctor" ? (
            props.status === "accepted" ? (
              <div className={classes.buttons}>
                <Button
                  type={"additional"}
                  onClick={(event) =>
                    props.changeStatus(props.id, "in_work", event)
                  }
                >
                  Начать консультацию
                </Button>
              </div>
            ) : props.status === "in_work" ? (
              <div className={classes.buttons}>
                <Button
                  type={"additional"}
                  onClick={(event) =>
                    props.changeStatus(props.id, "paused", event)
                  }
                >
                  Приостановить консультацию
                </Button>
              </div>
            ) : props.status === "paused" ? (
              <div className={classes.buttons}>
                <Button
                  type={"additional"}
                  onClick={(event) =>
                    props.changeStatus(props.id, "in_work", event)
                  }
                >
                  Продолжить консультацию
                </Button>
              </div>
            ) : null
          ) : null}
        </div>
      </div>
    </Link>
  );
};

function renderMain(props) {
  if (!props.creator) {
    return (
      <div className={classes.characters}>
        <div className={classes.doctor}>
          {props.performer ? (
            <div className={classes.doctor_link}>
              <strong
                onClick={
                  props.performer.role === "doctor" &&
                  props.currentRole !== "doctor"
                    ? (event) => {
                        event.preventDefault();
                        window.open(
                          props.currentRole === "patient"
                            ? `/profile/doctors/doctor_info/${props.performer.id}`
                            : `/profile/doctors/doctor_edit/${props.performer.id}`
                        );
                      }
                    : props.performer.role === "assistant" &&
                      props.currentRole === "support"
                    ? (event) => {
                        event.preventDefault();
                        window.open(
                          `/profile/assistants/assistant_edit/${props.performer.id}`
                        );
                      }
                    : null
                }
              >
                {`${props.performer.profile.second_name} ${
                  props.performer.profile.first_name
                } ${props.performer.profile.patronymic_name || ""}`}
                {(props.performer.role === "doctor" &&
                  props.currentRole !== "doctor") ||
                (props.performer.role === "assistant" &&
                  props.currentRole === "support") ? (
                  <img
                    className={classes.arrow}
                    src={"/profile/images/more-arrow.svg"}
                    alt={"is"}
                  />
                ) : null}
              </strong>
              <br />
              {props.performer.role === "assistant"
                ? "Ассистент"
                : props.specialization}
            </div>
          ) : (
            <span>
              Направление: <strong>{props.specialization}</strong>
            </span>
          )}
        </div>
      </div>
    );
  } else {
    const creatorProfile = { ...props.creator.profile };
    const patientName = `${creatorProfile.second_name} ${
      creatorProfile.first_name
    } ${creatorProfile.patronymic_name || ""}`;
    return (
      <div className={classes.characters}>
        <div className={classes.creator}>
          <strong
            onClick={(event) => {
              event.preventDefault();
              window.open(`/profile/patients/patient/${props.creator.id}`);
            }}
          >
            {patientName}
          </strong>
        </div>
        <div className={classes.doctor}>
          {props.performer ? (
            <div className={classes.doctor_link}>
              <strong
                onClick={
                  props.performer.role === "doctor" &&
                  props.currentRole !== "doctor"
                    ? (event) => {
                        event.preventDefault();
                        window.open(
                          props.currentRole === "patient"
                            ? `/profile/doctors/doctor_info/${props.performer.id}`
                            : `/profile/doctors/doctor_edit/${props.performer.id}`
                        );
                      }
                    : props.performer.role === "assistant" &&
                      props.currentRole === "support"
                    ? (event) => {
                        event.preventDefault();
                        window.open(
                          `/profile/assistants/assistant_edit/${props.performer.id}`
                        );
                      }
                    : null
                }
              >
                {`${props.performer.profile.second_name} ${
                  props.performer.profile.first_name
                } ${props.performer.profile.patronymic_name || ""}`}
                {(props.performer.role === "doctor" &&
                  props.currentRole !== "doctor") ||
                (props.performer.role === "assistant" &&
                  props.currentRole === "support") ? (
                  <img
                    className={classes.arrow}
                    src={"/profile/images/more-arrow.svg"}
                    alt={"is"}
                  />
                ) : null}
              </strong>
              <br />
              {props.performer.role === "assistant"
                ? "Ассистент"
                : props.specialization}
            </div>
          ) : (
            <span>
              Направление: <strong>{props.specialization}</strong>
            </span>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Card);
