import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import Button from "components/UI/Button/Button";
import Container from "hoc/Container/Container";
import BackLink from "components/UI/BackLink/BackLink";

import classes from "./Login.module.scss";

import { login } from "store/Auth/actions";
import { validateControl, renderInputs, errorDescriptions } from "../Auth";
import { Helmet } from "react-helmet";

class Login extends Component {
  state = {
    isFormValid: false,
    formControls: {
      email: {
        value: "",
        type: "email",
        errorMessage: "Введите корректный email",
        placeholder: "Почта",
        label: "Почта",
        valid: false,
        touched: false,
        validation: {
          required: true,
          email: true,
        },
      },
      password: {
        value: "",
        type: "password",
        errorMessage:
          "Пароль должен содержать не менее 8 символов, цифры и латинские буквы, не должен являться словарным словом или набором символов, находящихся рядом на клавиатуре",
        placeholder: "Пароль",
        label: "Пароль",
        valid: false,
        touched: false,
        validation: {
          required: true,
          minLength: 8,
        },
      },
    },
  };

  loginHandler = () => {
    this.props.login(
      this.state.formControls.email.value,
      this.state.formControls.password.value,
      true
    );

    let formControls = { ...this.state.formControls };
    formControls.password.value = "";
    formControls.password.toched = false;

    this.setState({
      formControls,
      isFormValid: false,
    });
  };

  submitHandler = (event) => {
    event.preventDefault();
  };

  onChangeHandler = (event, controlName) => {
    const formControls = { ...this.state.formControls };
    const control = { ...formControls[controlName] };

    control.value = event.target.value;
    control.touched = true;
    control.valid = validateControl(control.value, control.validation);

    formControls[controlName] = control;

    let isFormValid = true;

    Object.keys(formControls).forEach((name) => {
      isFormValid = formControls[name].valid && isFormValid;
    });

    this.setState({
      formControls,
      isFormValid,
    });
  };

  render() {
    return (
      <section className={classes.Login}>
        <Helmet>
          <title>Вход. Портал здоровья</title>
          <meta name="title" content="Вход. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Вход. Портал здоровья" />
        </Helmet>
        <Container innerClassName={classes.inner}>
          <h3 className={classes.title}>
            <BackLink to="/" />
            Вход
          </h3>
          <form onSubmit={this.submitHandler} className={classes.form}>
            {renderInputs(this.state.formControls, this.onChangeHandler)}
            <NavLink className={classes.forgot} to="/profile/recovery">
              Забыли пароль?
            </NavLink>
            <Button
              type="primary"
              onClick={this.loginHandler}
              disabled={!this.state.isFormValid}
            >
              Войти
            </Button>
            {this.props.loginError ? (
              <div className={classes.error}>
                {errorDescriptions[this.props.loginError.data.detail]}
              </div>
            ) : null}
            <hr className={classes.hr} />
            <div className={classes.create}>
              Вы не зарегистрированы?&nbsp;
              <NavLink to={"/profile/signup"}>Создайте аккаунт</NavLink>
            </div>
          </form>
        </Container>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.auth.token,
    loginError: state.auth.loginError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (email, password) => dispatch(login(email, password)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
