import React, { Component } from "react";
import { connect } from "react-redux";

import Input from "components/UI/Input/Input";
import Button from "components/UI/Button/Button";
import Container from "hoc/Container/Container";
import BackLink from "components/UI/BackLink/BackLink";

import { validateControl } from "../Auth";
import { sendRecovery, clearRecoveryStatus } from "store/Auth/actions";
import classes from "./Recovery.module.scss";
import { Helmet } from "react-helmet";

class Recovery extends Component {
  state = {
    value: "",
    touched: false,
    valid: false,
    validation: {
      required: true,
      email: true,
    },
  };

  onChangeHandler = (event) => {
    const value = event.target.value;
    const valid = validateControl(value, this.state.validation);

    this.setState({ value, valid, touched: true });
  };

  sendHandler = () => {
    this.props.sendRecovery(this.state.value);
    this.setState({
      value: "",
      touched: false,
      valid: false,
    });
  };

  componentWillUnmount() {
    this.props.clearRecoveryStatus();
  }

  render() {
    return (
      <section className={classes.Recovery}>
        <Helmet>
          <title>Восстановление пароля. Портал здоровья</title>
          <meta name="title" content="Восстановление пароля. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:title"
            content="Восстановление пароля. Портал здоровья"
          />
        </Helmet>
        <Container innerClassName={classes.inner}>
          <BackLink to={"/profile/login"} />
          <h3 className={classes.title}>Восстановление пароля</h3>
          <p className={classes.text}>
            1. На указанный электронный адрес будет выслано письмо с ссылкой для
            смены пароля. Ссылка действительна 15 минут
          </p>

          <Input
            type={"email"}
            value={this.state.value}
            placeholder={"Почта"}
            valid={this.state.valid}
            touched={this.state.touched}
            label={"Почта"}
            shouldValidate={true}
            errorMessage={"Введите корректный email"}
            onChange={(event) => this.onChangeHandler(event)}
          />
          <p className={classes.text}>
            2. Перейдите по отправленной ссылке и смените пароль
          </p>
          <Button
            type={"primary"}
            onClick={this.sendHandler}
            disabled={!this.state.valid}
          >
            Отправить
          </Button>
          {this.props.recoveryStatus === "ERROR" ? (
            <div className={classes.errorMessage}>
              Произошла ошибка. Попробуйте еще раз.
            </div>
          ) : null}
          {this.props.recoveryStatus === "SUCCESS" ? (
            <div className={classes.successMessage}>
              Данные успешно отправлены, проверьте почту.
            </div>
          ) : null}
        </Container>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    recoveryStatus: state.auth.recoveryStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sendRecovery: (email) => dispatch(sendRecovery(email)),
    clearRecoveryStatus: () => dispatch(clearRecoveryStatus()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Recovery);
