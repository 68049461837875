import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/ru';

import Button from "components/UI/Button/Button";
import BackLink from "components/UI/BackLink/BackLink";
import Input from "components/UI/Input/Input";

import classes from "./FaqEdit.module.scss";
import { editFaq, getFaqByID } from "store/Profile/Faq/actions";

const editorConfiguration = {
  toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'indent', 'outdent', '|', 'insertTable'],
  language: "ru"
};

class FaqEdit extends Component {
  state = {
    faq_question: "",
    faq_answer: "",
    formTouched: false
  };

  componentDidMount() {
    this.props.getFaqByID(this.props.match.params.id);
  }
  componentDidUpdate(prevProps, prevState) {
    let editedFaq = this.props.editedFaq;
    if (prevProps.editedFaq !== editedFaq) {
      this.initFaq(editedFaq);
    }
    if (prevState.faq_answer !== this.state.faq_answer && prevState.faq_answer !== "") {
      this.setState({ formTouched: true })
    }
  }
  initFaq(editedFaq) {
    let newState = { ...this.state };
    newState.faq_question = editedFaq.question;
    newState.faq_answer = editedFaq.answer;

    this.setState({
      ...newState,
    });
  }
  inputChangeHandler = (event, targetName) => {
    let formControl = { ...this.state };
    formControl[targetName] = event.target.value;
    formControl.formTouched = true;
    this.setState({ ...formControl });
  };

  sendFormHandler = () => {
    this.props.editFaq(this.props.match.params.id, this.state.faq_question, this.state.faq_answer);
    if (this.props.createFaqStatus !== "") {
      const newState = { ...this.state };
      newState.faq_question = "";
      newState.faq_answer = "";
      this.setState({ ...newState });

      this.props.history.push({ pathname: "/profile/faq" });
    }
  };

  render() {
    return (
      <section className={classes.FaqEdit}>
        <Helmet>
          <title>Редактирование вопроса. Портал здоровья</title>
          <meta name="title" content="Редактирование вопроса. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Редактирование вопроса. Портал здоровья" />
        </Helmet>
        <div className={classes.back}>
          <BackLink to={"/profile/faq"} />
          <span>Назад</span>
        </div>

        <form
          className={classes.form}
          onSubmit={(event) => event.preventDefault()}
        >
          <h4 className={classes.title}>Редактирование вопроса</h4>
          <Input
            value={this.state.faq_question}
            placeholder={"Заголовок вопроса"}
            valid={false}
            touched={false}
            label={"Заголовок вопроса"}
            shouldValidate={false}
            errorMessage={""}
            onChange={(event) => this.inputChangeHandler(event, "faq_question")}
          ></Input>
          {/* Текст статьи (редактор) */}
          <CKEditor
            editor={ClassicEditor}
            data={this.state.faq_answer}
            onChange={(event, editor) => {
              const data = editor.getData();
              this.setState({ faq_answer: data });
            }}
            onReady={editor => {
              editor.editing.view.change(writer => {
                writer.setStyle(
                  "min-height",
                  "600px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            config={editorConfiguration}
          />
          <div className={classes.btn_submit}>
            <Button
              disabled={this.state.faq_question !== '' && this.state.faq_answer !== '' && !this.state.formTouched}
              onClick={() => this.sendFormHandler()}
              type={"primary"}
            >
              Сохранить изменения
            </Button>
          </div>

          {/* сообщение об ошибке */}
          {/* {this.props.createFaqStatus !== "" ? (
            <div className={classes.dataFetchStatus}>
              {this.props.changePassStatus === "ERROR" ? (
                <div className={classes.error_status}>
                  Не удалось сохранить изменения
                </div>
              ) : (
                <div className={classes.pending_status}>
                  Сохранение изменений...
                </div>
              )}
            </div>
          ) : null} */}
        </form>
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    editedFaq: state.faq.editedFaq,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getFaqByID: (id) => dispatch(getFaqByID(id)),
    editFaq: (id, faq_question, faq_answer) =>
      dispatch(editFaq(id, faq_question, faq_answer)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FaqEdit);
