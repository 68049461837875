import React from "react";
import classes from "./SuccessErrorMessage.module.scss";
const ModalContent = (props) => {
  return (
    <div className={classes.ModalContent}>
      <div className={classes.title}>{props.title}</div>
      {props.status === "SUCCESS" ? (
        <img
          className={classes.icon}
          src={"/profile/images/SUCCESS.svg"}
          alt="SUCCESS"
        />
      ) : props.status === "ERROR" ? (
        <img
          className={classes.icon}
          src={"/profile/images/ERROR.svg"}
          alt="ERROR"
        />
      ) : null}
      {props.icon ? (
        <img
          className={classes.icon}
          src={props.icon}
          alt="modal_window_icon"
        />
      ) : null}
      <div className={classes.subtitle}>{props.subtitle}</div>
    </div>
  );
};

export default ModalContent;
