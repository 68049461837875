import React from 'react'

import classes from './HealthStatus.module.scss';

const HealthStatus = props => {
    const indicatorCls = [classes.indicator];
    indicatorCls.push(classes[props.color])
    return (
        <div className={classes.HealthStatus} style={props.style}>
            <div className={classes.result}>
                <div className={indicatorCls.join(" ")} />
                <div className={classes.score}>
                    {props.score}
                </div>
            </div>
            <div className={classes.status_text}>
                {props.description}
            </div>
        </div>
    )
}


export default HealthStatus