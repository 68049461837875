import React from 'react'
import classes from './EmptyPageFiller.module.scss';

const EmptyPageFiller = props => {
    return (
        <div className={classes.EmptyPageFiller}>
            <div className={classes.title}>
                {props.pageName || ""}
            </div>
            <div className={classes.subtitle}>
                Скоро здесь будет раздел <br /> “{props.pageName || ""}”
            </div>
        </div>
    )
}


export default EmptyPageFiller