export const GET_ASSISTANTS_SUCCESS = "GET_ASSISTANTS_SUCCESS";
export const GET_ASSISTANTS_ERROR = "GET_ASSISTANTS_ERROR";
export const CREATE_ASSISTANT_ERROR = "CREATE_ASSISTANT_ERROR";
export const CREATE_ASSISTANT_SUCCESS = "CREATE_ASSISTANT_SUCCESS";
export const CLEAR_CREATE_ASSISTANT_STATUS = "CLEAR_CREATE_ASSISTANT_STATUS";
export const EDIT_ASSISTANT_SUCCESS = "EDIT_ASSISTANT_SUCCESS";
export const EDIT_ASSISTANT_ERROR = "EDIT_ASSISTANT_ERROR";
export const CLEAR_EDIT_ASSISTANT_STATUS = "CLEAR_EDIT_ASSISTANT_STATUS";
export const GET_ASSISTANTBYID_SUCCESS = "GET_ASSISTANTBYID_SUCCESS";
export const GET_ASSISTANTBYID_ERROR = "GET_ASSISTANTBYID_ERROR";
export const LOAD_PICTURE_SUCCESS = "LOAD_PICTURE_SUCCESS";
export const LOAD_PICTURE_ERROR = "LOAD_PICTURE_ERROR";
export const REMOVE_PICTURE = "REMOVE_PICTURE";
export const CHANGE_STATUS_SUCCESS = "CHANGE_STATUS_SUCCESS";
export const CHANGE_STATUS_ERROR = "CHANGE_STATUS_ERROR";
export const CHANGE_DELETED_STATUS_SUCCESS = "CHANGE_DELETED_STATUS_SUCCESS";
export const CHANGE_DELETED_STATUS_ERROR = "CHANGE_DELETED_STATUS_ERROR";
export const CLEAR_DELETE_ASSISTANT_STATUS = "CLEAR_DELETE_ASSISTANT_STATUS";
