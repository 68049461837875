import React from 'react';
import { eventTypeEnum, roleEnum } from 'containers/Profile/Journal/enums';

import { converDateForJournal } from 'containers/Profile/Journal/functions';
import { statusEnum } from 'components/Profile/CardList/enums';

import classes from './HistoryTable.module.scss';

const HistoryTable = (props) => {

    return (
        <div className={classes.table_wrapper}>
            <table className={classes.table}>
                <tr className={classes.table_header}>
                    <th className={classes.time_head}>Дата и время</th>
                    <th className={classes.name_head}>Фио</th>
                    <th className={classes.email_head}>Почта</th>
                    <th className={classes.role_head}>роль</th>
                    <th className={classes.action_head}>Действия</th>
                </tr>
                {/* Событие */}
                {props.eventsList.map(event => {
                    return (
                        <tr key={event.id} className={classes.table_data}>
                            <td className={classes.time_data}>
                                {converDateForJournal(new Date(event.created_at))}
                            </td>
                            <td className={classes.name_data}>
                                {event.user.profile.second_name} {event.user.profile.first_name} {event.user.profile.patronymic_name || ""}
                            </td>
                            <td className={classes.email_data}>
                                {event.user.email}
                            </td>
                            <td className={classes.role_data}>
                                {roleEnum[event.user.role]}
                            </td>
                            {event.event_type === "consultation_status_changed" ?
                                <td className={classes.action_data}>{eventTypeEnum[event.event_type]} на "{statusEnum[event.new_consultation_status]}"</td>
                                :
                                <td className={classes.action_data}>{eventTypeEnum[event.event_type]}</td>
                            }
                        </tr>
                    )
                })}
            </table>
        </div>
    )
}


export default HistoryTable