import axios from "axios";
import {getMedcardDocs} from '../Medcard/actions'
import {
  REMOVE_FILE,
  ADD_DOC_FILE_SUCCESS,
  ADD_DOC_FILE_ERROR,
  UPLOAD_DOC_SUCCESS,
  UPLOAD_DOC_ERROR,
} from "./actionTypes";

export function addFile(file) {
  return async (dispatch) => {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: '/documents/upload_document',
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
      .then((response) => {
        dispatch(addFileSuccess(response.data.url));
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function removeFile(index) {
  return {
    type: REMOVE_FILE,
    index,
  };
}
export function uploadMedcardDoc(
  doc_Organization,
  doc_Name,
  doc_Date,
  currentDocTypeID,
  attachment
) {
  return async (dispatch) => {
    let sendingData = {
      title: doc_Name,
      medical_organization: doc_Organization,
      document_date: doc_Date,
      document_type: currentDocTypeID,
      document_url: attachment,
    };
    axios({
      method: "post",
      url: '/documents/',
      data: sendingData,
      headers: {
        "Content-Type": "multipart/form-data"
      },
    })
      .then(function (response) {
        dispatch(uploadMedcardDocSuccess(response.data.url));
        dispatch(getMedcardDocs());
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };
}

export function addFileSuccess(url) {
  return {
    type: ADD_DOC_FILE_SUCCESS,
    url,
  };
}
export function addFileError(url) {
  return {
    type: ADD_DOC_FILE_ERROR,
    url,
  };
}
export function uploadMedcardDocSuccess() {
  return {
    type: UPLOAD_DOC_SUCCESS,
  };
}
export function uploadMedcardDocError() {
  return {
    type: UPLOAD_DOC_ERROR,
  };
}
