import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import Logout from "./components/Logout/Logout";
import { autoLogin } from "./store/Auth/actions";
import SignUp from "./containers/Auth/SignUp/SignUp";
import InviteSignUp from "./containers/Auth/InviteSignUp/InviteSignUp";
import Login from "./containers/Auth/Login/Login";
import Recovery from "./containers/Auth/Recovery/Recovery";
import Profile from "./containers/Profile/Profile";
import ConfirmationMail from "./containers/Auth/ConfirmationMail/ConfirmationMail";
import NewPassword from "./containers/Auth/NewPassword/NewPassword";
import CreateProfile from "./containers/Profile/CreateProfile/CreateProfile";
import Test1 from "./containers/Tests/Test1";
import Test2 from "./containers/Tests/Test2";
import Test3 from "./containers/Tests/Test3";
import Main from "./containers/Main/Main";
import Articles from "./containers/Articles/Articles";
import ArticleExpanded from "./containers/Articles/ArticleExpanded/ArticleExpanded";
import Faq from "./containers/Faq/Faq";
import OurSpecialists from "./containers/OurSpecialists/OurSpecialists";
import Feedback from "./containers/Feedback/Feedback";
import Partnership from "./containers/Partnership/Partnership";
import Confidentiality from "./containers/Сonfidentiality/Confidentiality";
import Agreement from "./containers/Agreement/Agreement";
import TermsOfUse from "./containers/TermsOfUse/TermsOfUse";
import DeveloperInfo from "./containers/DeveloperInfo/DeveloperInfo";
import CovidPage from "./containers/Covid/CovidPage";
import TestCovid from "./containers/Tests/TestCovid";

class App extends Component {
  componentDidMount() {
    this.props.autoLogin();
  }

  render() {
    let routes = (
      <Switch>
        <Route exact path="/" component={Main} />
        <Route exact path="/partnership" component={Partnership} />
        <Route exact path="/developer_info" component={DeveloperInfo} />
        <Route exact path="/confidentiality" component={Confidentiality} />
        <Route exact path="/agreement" component={Agreement} />
        <Route exact path="/terms_of_use" component={TermsOfUse} />
        <Route exact path="/articles" component={Articles} />
        <Route exact path="/articles/:id" component={ArticleExpanded} />
        <Route exact path="/faq" component={Faq} />
        <Route path="/our_specialists/:id" component={OurSpecialists} />
        <Route path="/feedback" component={Feedback} />
        {/* ссылка для хэдера */}
        <Route
          path="/our_specialists/"
          render={() => <Redirect to="/our_specialists/1" />}
        />
        <Route path="/covid/test" component={TestCovid} />
        <Route path="/covid" component={CovidPage} />
        <Route path="/profile/confirm_email" component={ConfirmationMail} />
        <Route path="/profile/recovery" exact component={Recovery} />
        <Route path="/profile/recover_password" component={NewPassword} />
        <Route path="/profile/login" exact component={Login} />
        <Route path="/profile/signup" exact component={SignUp} />
        <Route path="/profile/invite_signup" exact component={InviteSignUp} />
        <Route path="/profile/logout" exact component={Logout} />
        <Redirect to="/profile/login" />
      </Switch>
    );
    if (localStorage.getItem("access_token")) {
      routes = (
        <Switch>
          <Route path="/profile/logout" exact component={Logout} />
          <Route exact path="/" component={Main} />
          <Route exact path="/partnership" component={Partnership} />
          <Route exact path="/developer_info" component={DeveloperInfo} />
          <Route exact path="/confidentiality" component={Confidentiality} />
          <Route exact path="/agreement" component={Agreement} />
          <Route exact path="/terms_of_use" component={TermsOfUse} />
          <Route exact path="/articles" component={Articles} />
          <Route exact path="/articles/:id" component={ArticleExpanded} />
          <Route exact path="/faq" component={Faq} />
          <Route path="/our_specialists/:id" component={OurSpecialists} />
          <Route
            path="/our_specialists/"
            render={() => <Redirect to="/our_specialists/1" />}
          />
          <Route path="/covid/test" component={TestCovid} />
          <Route path="/covid" component={CovidPage} />
          <Route path="/feedback" component={Feedback} />
          <Route path="/profile/test1" component={Test1} />
          <Route path="/profile/test2" component={Test2} />
          <Route path="/profile/test3" component={Test3} />
          <Route path="/profile/create_profile" component={CreateProfile} />
          <Route path="/profile/" component={Profile} />
          <Redirect to="/profile/" />
        </Switch>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          {/* Primary Meta Tags */}
          <title>Портал здоровья</title>
          <meta name="robots" content="noindex,nofollow" />
          <meta name="title" content="Портал здоровья" />
          <meta
            name="description"
            content="Портал здоровья - сервис персонального управления здоровьем"
          />
          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Портал здоровья" />
          <meta
            property="og:description"
            content="Портал здоровья - сервис персонального управления здоровьем"
          />
        </Helmet>
        {routes}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.auth.token,
    role: state.profile.role,
    email: state.auth.email,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    autoLogin: () => dispatch(autoLogin()),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
