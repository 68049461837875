import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

import Navigation from "components/UI/Navigation/Navigation";
import Button from "components/UI/Button/Button";
import Input from "components/UI/Input/Input";
import Search from "components/UI/Search/Search";
import FileLoader from "components/UI/FileControl/FileLoader/FileLoader";
import ModalWindow from "components/UI/ModalWindow/ModalWindow";
import ModalContent from "components/UI/ModalWindow/SuccessErrorMessage/SuccessErrorMessage";
import EmptySection from "components/Profile/EmptySection/EmptySection";
import InviteTable from "components/Profile/InviteTable/InviteTable";

import { validateControl } from "containers/Auth/Auth";

import { getInvitesList } from "store/Profile/Invites/actions";
import {
  createInvite,
  clearCreateInventStatus,
} from "store/Profile/Invites/actions";

import classes from "./Invites.module.scss";

const CARDS_IN_ONE_PAGE = 50;

class Invites extends Component {
  state = {
    activePage: 1,
    searchValue: null,
    prevSearch: null,
    isSearching: false,
    email: {
      value: "",
      type: "email",
      errorMessage: "Введите корректный адрес электронной почты",
      placeholder: "Email",
      valid: false,
      touched: false,
      shouldValidate: true,
      validation: { required: true, email: true },
    },
    modalShow: false,
  };

  modalShowOpen = () => {
    this.setState({ modalShow: true });
  };

  modalShowClose = () => {
    this.setState({ modalShow: false });
    this.props.clearCreateInventStatus();
  };

  componentDidMount() {
    this.props.getInvitesList(
      this.state.activePage - 1,
      CARDS_IN_ONE_PAGE,
      this.state.isSearching ? this.state.searchValue : null
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.activePage !== this.state.activePage) {
      this.props.getInvitesList(
        this.state.activePage - 1,
        CARDS_IN_ONE_PAGE,
        this.state.isSearching ? this.state.searchValue : null
      );
    }

    if (prevProps.createInviteStatus !== this.props.createInviteStatus) {
      if (this.props.createInviteStatus === "SUCCESS") {
        this.modalShowOpen();
        this.props.getInvitesList(
          0,
          CARDS_IN_ONE_PAGE,
          this.state.isSearching ? this.state.searchValue : null
        );
      } else if (this.props.createInviteStatus === "ERROR") {
        this.modalShowOpen();
      }
    }
  }

  inputChangeHandler = (event) => {
    this.setState({
      email: {
        ...this.state.email,
        value: event.target.value,
        touched: event.target.value.length === 0 ? false : true,
        valid:
          event.target.value.length === 0
            ? false
            : validateControl(event.target.value, this.state.email.validation),
      },
    });
  };

  sendFormHandler = () => {
    this.props.createInvite(this.state.email.value);
    this.setState({
      email: { ...this.state.email, value: "", valid: false, touched: false },
    });
  };

  changePageHandler = (pages, jumpTo) => {
    let activePage = this.state.activePage;

    if (jumpTo > pages || jumpTo < 1 || jumpTo === activePage) {
      return;
    } else {
      activePage = jumpTo;
    }
    this.setState({ activePage });
    window.scrollTo(0, 0);
  };

  onSearchHandler = () => {
    if (
      this.state.prevSearch === this.state.searchValue ||
      !this.state.searchValue
    )
      return null;

    this.setState({ isSearching: true, prevSearch: this.state.searchValue });

    if (this.state.activePage > 1) {
      this.setState({ activePage: 1 }); // При смене страницы автоматически отправится запрос
    } else {
      this.props.getInvitesList(0, CARDS_IN_ONE_PAGE, this.state.searchValue);
    }
  };

  onSearchResetHandler = () => {
    this.setState({ isSearching: false, prevSearch: null, searchValue: null });
    if (this.state.activePage > 1) {
      this.setState({ activePage: 1 }); // При смене страницы автоматически отправится запрос
    } else {
      this.props.getInvitesList(0, CARDS_IN_ONE_PAGE);
    }
  };

  render() {
    let pagesAmount = null;

    if (this.props.invitesList) {
      pagesAmount =
        Math.trunc(this.props.total / CARDS_IN_ONE_PAGE) +
        (this.props.total % CARDS_IN_ONE_PAGE ? 1 : 0);
    }

    return (
      <section className={classes.Invites}>
        <Helmet>
          <title>Приглашения. Портал здоровья</title>
          <meta name="title" content="Приглашения. Портал здоровья" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:title" content="Приглашения. Портал здоровья" />
        </Helmet>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>Приглашения</div>
        </div>
        <div className={classes.inviteWrapper}>
          <Input
            value={this.state.email.value}
            type={this.state.email.type}
            placeholder={this.state.email.placeholder}
            valid={this.state.email.valid}
            touched={this.state.email.touched}
            shouldValidate={this.state.email.shouldValidate}
            errorMessage={this.state.email.errorMessage}
            onChange={this.inputChangeHandler}
          ></Input>
          <div className={classes.btn_submit}>
            <Button
              disabled={!this.state.email.valid}
              onClick={() => this.sendFormHandler()}
              type={"primary"}
              needTip={true}
              extraClass={"btn_large"}
            >
              Пригласить
            </Button>
          </div>
        </div>
        <div className={classes.search_wrapper}>
          <Search
            value={this.state.searchValue}
            placeholder={"Введите email для поиска"}
            onChange={(value) =>
              this.setState({ searchValue: value === "" ? null : value })
            }
            onSearch={() => this.onSearchHandler()}
            total={this.props.total}
            showReset={this.state.isSearching}
            onReset={this.onSearchResetHandler}
          />
        </div>
        {this.props.invitesList ? (
          this.props.invitesList.length !== 0 ? (
            <React.Fragment>
              <div className={classes.list}>
                <InviteTable
                  inviteList={this.props.invitesList}
                  withHealthStatus={true}
                  inNewWindow={true}
                />
              </div>
              {pagesAmount > 1 ? (
                <Navigation
                  activePage={this.state.activePage}
                  pages={pagesAmount}
                  onChange={this.changePageHandler}
                />
              ) : null}
            </React.Fragment>
          ) : (
            <EmptySection
              title={"Вы ещё никого не приглашали"}
              text={"Список приглашений пуст"}
            />
          )
        ) : (
          <div className={classes.loader}>
            <FileLoader style={{ maxWidth: "100%" }} />
          </div>
        )}
        {this.state.modalShow && this.props.createInviteStatus !== "" ? (
          <ModalWindow timeout={3000} closed={this.modalShowClose}>
            {this.props.createInviteStatus === "SUCCESS" ? (
              <ModalContent
                title={"Отправлено"}
                subtitle={`Новое приглашение ${this.state.email.value} успешно отправлено`}
                status={this.props.createInviteStatus}
              ></ModalContent>
            ) : (
              <ModalContent
                title={"Ошибка!"}
                subtitle={`Не удалось отправить приглашение: пользователь уже зарегистрирован или вы ранее уже отправляли приглашение на эту почту`}
                status={this.props.createInviteStatus}
              ></ModalContent>
            )}
          </ModalWindow>
        ) : null}
      </section>
    );
  }
}
function mapStateToProps(state) {
  return {
    role: state.profile.role,
    invitesList: state.invites.invitesList,
    total: state.invites.invitesTotal,
    createInviteStatus: state.invites.createInviteStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getInvitesList: (page, size, q) => dispatch(getInvitesList(page, size, q)),
    createInvite: (email) => dispatch(createInvite(email)),
    clearCreateInventStatus: () => dispatch(clearCreateInventStatus()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Invites);
