import {
    GET_EVENTS_SUCCESS,
    GET_EVENTS_ERROR,
  } from "./actionTypes";
  
  const initialState = {
    eventsList: null,
    total: 0,
  };
  
  export default function journalReducer(state = initialState, action) {
    switch (action.type) {
      case GET_EVENTS_SUCCESS:
        return {
          ...state,
          eventsList: action.data.items,
          total: action.data.total,
        };
      case GET_EVENTS_ERROR:
        return {
          ...state,
        };
      default:
        return state;
    }
  }
  