import React from 'react';

import QuestionItem from '../QuestionItem/QuestionItem';

import classes from './TestSection.module.scss';


const TestSection = props => {

    return (
        <div className={classes.TestSection}>
            {props.questionList.map((questionItem, index) => {

                const questionNumber = index + props.firstQuestionNumber;  // номер вопроса

                let userAnswer = props.userAnswers[questionNumber] || null; // ответ пользователя на текущий вопрос
                
                let isValid = true;

                if (questionItem.type === "input" && questionItem.answers.length !== 0 && !!userAnswer) {
                    isValid = !!userAnswer.usersInput && userAnswer.answerId !== null
                }

                return (
                    <React.Fragment>
                        {questionItem.info ? // Вопрос содержит доп.информацию
                            <div className={classes.info}>
                                {questionItem.info}
                            </div>
                            :
                            null}
                        <QuestionItem
                            key={index}
                            number={questionNumber}  // номер вопроса
                            question={questionItem.question}
                            explanation={questionItem.explanation || null} // пояснение (под вопросом)
                            answers={questionItem.answers}
                            firstAnswerNumber={questionItem.firstAnswerNumber} // номер первого ответа
                            userAnswer={userAnswer}
                            type={questionItem.type || null} // тип вопроса (input,unordered,horizontal,multiple)
                            placeholder={questionItem.placeholder || null} // Для вопросов с типом input
                            onChange={props.onChange}
                            isValid={isValid}
                        />
                    </React.Fragment>

                )
            })}
        </div>
    )
}


export default TestSection