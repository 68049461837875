import axios from "axios";

import {
  GET_PROVIDERS_SUCCESS,
  GET_PROVIDERS_ERROR,
  CREATE_PROVIDER_ERROR,
  CREATE_PROVIDER_SUCCESS,
  CLEAR_CREATE_PROVIDER_STATUS,
  EDIT_PROVIDER_SUCCESS,
  EDIT_PROVIDER_ERROR,
  CHANGE_STATUS_SUCCESS,
  CHANGE_STATUS_ERROR,
  CLEAR_EDIT_PROVIDER_STATUS,
  GET_PROVIDERBYID_SUCCESS,
  GET_PROVIDERBYID_ERROR,
  LOAD_PICTURE_SUCCESS,
  LOAD_PICTURE_ERROR,
  REMOVE_PICTURE,
  CHANGE_DELETED_STATUS_SUCCESS,
  CHANGE_DELETED_STATUS_ERROR,
  CLEAR_DELETE_PROVIDER_STATUS,
} from "./actionTypes";

export function getProvidersList(page, size, q) {
  return async (dispatch) => {
    axios
      .get("/users/", {
        params: {
          role: "provider",
          page,
          size,
          q,
        },
      })
      .then((response) => {
        dispatch(getProvidersListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getProvidersListError());
        console.log(err);
      });
  };
}

export function createProvider(data) {
  return async (dispatch) => {
    let sendingData = {
      email: data.email,
      password: data.password,
      first_name: data.first_name,
      second_name: data.second_name,
      patronymic_name: data.patronymic_name,
      gender: data.gender,
      date_of_birth: data.date_of_birth,
      registration_address: data.registration_address,
      real_address: data.real_address,
      userpic_url: data.userpic_url,
    };
    axios
      .post("/users/invite_provider", sendingData)
      .then(() => {
        dispatch(createProviderSuccess());
      })
      .catch((err) => {
        dispatch(createProviderError());
        console.log(err);
      });
  };
}
export function getProviderByID(id) {
  return async (dispatch) => {
    axios
      .get(`/profiles/${id}`)
      .then((response) => {
        dispatch(getProviderByIDSuccess(response.data));
      })
      .catch((err) => {
        dispatch(getProviderByIDError());
        console.log(err);
      });
  };
}

export function changeUserStatus(userId, data) {
  return async (dispatch) => {
    axios
      .post(`/users/${userId}/change_status`, data)
      .then(() => {
        dispatch(changeUserStatusSuccess());
        dispatch(getProviderByID(userId));
      })
      .catch((err) => {
        dispatch(changeUserStatusError());
        console.log(err);
      });
  };
}

export function changeUserDeletedStatus(userId, data) {
  return async (dispatch) => {
    axios
      .post(`/users/${userId}/change_deleted_status`, data)
      .then(() => {
        dispatch(changeUserDeletedStatusSuccess());
        dispatch(getProviderByID(userId));
      })
      .catch((err) => {
        dispatch(changeUserDeletedStatusError(err.response.data.detail));
        console.log(err);
      });
  };
}

export function editProvider(data) {
  return async (dispatch) => {
    let sendingData = {
      first_name: data.first_name,
      second_name: data.second_name,
      patronymic_name: data.patronymic_name,
      gender: data.gender,
      date_of_birth: data.date_of_birth,
      registration_address: data.registration_address,
      real_address: data.real_address,
      userpic_url: data.userpic_url,
      organization_name: data.organization_name,
      organization_address: data.organization_address,
      organization_phone: data.organization_phone,
      organization_email: data.organization_email,
      organization_bik: data.organization_bik,
      organization_account: data.organization_account,
      organization_agreement_number: data.organization_agreement_number,
      organization_agreement_date: data.organization_agreement_date,
    };
    axios
      .patch(`/profiles/${data.id}`, sendingData)
      .then(() => {
        dispatch(editProviderSuccess());
        dispatch(getProviderByID(data.id));
      })
      .catch((err) => {
        dispatch(editProviderError());
        console.log(err);
      });
  };
}

export function sendNewPassword(data) {
  return async (dispatch) => {
    let sendingData = {
      new_password1: data.password,
      new_password2: data.repeatPassword,
    };
    axios
      .post(`/users/${data.id}/change_password`, sendingData)
      .then(() => {
        dispatch(editProviderSuccess());
        dispatch(getProviderByID(data.id));
      })
      .catch((err) => {
        dispatch(editProviderError());
        console.log(err);
      });
  };
}
export function loadPicture(file) {
  return async (dispatch) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", file);
    axios({
      method: "post",
      url: "/profiles/upload_photo",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then(function (response) {
        dispatch(loadPictureSuccess(response.data.url));
      })
      .catch(function (error) {
        dispatch(loadPictureError());
        console.log(error);
      });
  };
}

export function removePicture() {
  return {
    type: REMOVE_PICTURE,
  };
}

export function loadPictureSuccess(url) {
  return {
    type: LOAD_PICTURE_SUCCESS,
    url,
  };
}

export function loadPictureError() {
  return {
    type: LOAD_PICTURE_ERROR,
  };
}

export function getProvidersListSuccess(data) {
  return {
    type: GET_PROVIDERS_SUCCESS,
    data,
  };
}

export function getProvidersListError() {
  return {
    type: GET_PROVIDERS_ERROR,
  };
}

export function createProviderSuccess() {
  return {
    type: CREATE_PROVIDER_SUCCESS,
    createProviderStatus: "SUCCESS",
  };
}

export function createProviderError() {
  return {
    type: CREATE_PROVIDER_ERROR,
    createProviderStatus: "ERROR",
  };
}

export function editProviderSuccess() {
  return {
    type: EDIT_PROVIDER_SUCCESS,
    editProviderStatus: "SUCCESS",
  };
}

export function editProviderError() {
  return {
    type: EDIT_PROVIDER_ERROR,
    editProviderStatus: "ERROR",
  };
}

export function changeUserStatusSuccess() {
  return {
    type: CHANGE_STATUS_SUCCESS,
  };
}

export function changeUserStatusError() {
  return {
    type: CHANGE_STATUS_ERROR,
  };
}

export function changeUserDeletedStatusSuccess() {
  return {
    type: CHANGE_DELETED_STATUS_SUCCESS,
  };
}

export function changeUserDeletedStatusError(error) {
  return {
    type: CHANGE_DELETED_STATUS_ERROR,
    error,
  };
}

export function clearDeleteProviderStatus() {
  return {
    type: CLEAR_DELETE_PROVIDER_STATUS,
  };
}

export function getProviderByIDSuccess(editedSupport) {
  return {
    type: GET_PROVIDERBYID_SUCCESS,
    editedProvider: editedSupport,
  };
}

export function getProviderByIDError() {
  return {
    type: GET_PROVIDERBYID_ERROR,
  };
}
export function clearCreateProviderStatus() {
  return {
    type: CLEAR_CREATE_PROVIDER_STATUS,
  };
}
export function clearEditProviderStatus() {
  return {
    type: CLEAR_EDIT_PROVIDER_STATUS,
  };
}
