import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Footer from "components/Footer/Footer";
import Button from "components/UI/Button/Button";
import MainHeader from "components/Main/MainHeader/MainHeader";
import DoctorCard from "./DoctorCard/DoctorCard";
import Article from "components/Article/Article";
import FaqCard from "./FaqCard/FaqCard";

import classes from "./Main.module.scss";

import { getArticlesList } from "store/Profile/Articles/actions";
import { getFaqList } from "store/Profile/Faq/actions";
import { getSpecializations } from "store/Profile/Consultations/actions";
import { addRedirectLink } from "../../store/Auth/actions";

const ARTICLES_IN_PAGE = 3;
const QUESTIONS_IN_PAGE = 4;
const SPECIALIZATIONS_IN_PAGE = 5;

class Main extends Component {
  state = {
    isAuthenticated: localStorage.getItem("access_token"),
  };

  componentDidMount() {
    this.props.getSpecializations();
    this.props.getArticlesList(0, ARTICLES_IN_PAGE);
    this.props.getFaqList(0, QUESTIONS_IN_PAGE);
  }

  onPassTestClick() {
    this.props.addRedirectLink("/profile/test1");
    this.props.history.push(
      this.state.isAuthenticated ? "/profile/test1" : "/profile/login"
    );
  }

  onApplyRequestClick() {
    this.props.addRedirectLink("/profile/new_consultation");
    this.props.history.push(
      this.state.isAuthenticated
        ? "/profile/new_consultation"
        : "/profile/login"
    );
  }

  render() {
    let firstSpecializations = null;
    if (this.props.specializations) {
      firstSpecializations = this.props.specializations.slice(
        0,
        SPECIALIZATIONS_IN_PAGE
      );
    } // массив специализаций для главной

    return (
      <>
        <MainHeader />

        <div className={classes.Main}>
          <section className={classes.section}>
            <div className={classes.intro_container}>
              <div className={classes.header}>
                <h1 className={classes.company_name}>Портал здоровья</h1>
                <div className={classes.intro_subtitle}>
                  Вы находитесь в точке входа в Экосистему управления здоровьем.
                </div>
              </div>
              <div className={classes.intro}>
                <div className={classes.company}>
                  <div className={classes.intro_list}>
                    <div className={classes.intro_item}>
                      <img
                        className={classes.item_label}
                        src="/images/checkbox.svg"
                        alt="check"
                      />
                      <div className={classes.item_text}>
                        Измерить своё здоровье
                      </div>
                    </div>
                    <div className={classes.intro_item}>
                      <img
                        className={classes.item_label}
                        src="/images/checkbox.svg"
                        alt="check"
                      />
                      <div className={classes.item_text}>
                        Определить вероятные причины ухудшения своего здоровья
                      </div>
                    </div>
                    <div className={classes.intro_item}>
                      <img
                        className={classes.item_label}
                        src="/images/checkbox.svg"
                        alt="check"
                      />
                      <div className={classes.item_text}>
                        Научиться управлять собственным здоровьем
                      </div>
                    </div>
                    <div className={classes.intro_item}>
                      <img
                        className={classes.item_label}
                        src="/images/checkbox.svg"
                        alt="check"
                      />
                      <div className={classes.item_text}>
                        Получить помощь эксперта по здоровьесбережению, других
                        специалистов, поддержку инструктора здоровья в изменении
                        образа жизни;
                      </div>
                    </div>
                    <div className={classes.intro_item}>
                      <img
                        className={classes.item_label}
                        src="/images/checkbox.svg"
                        alt="check"
                      />
                      <div className={classes.item_text}>
                        Стать инструктором здоровья и помогать другим.
                      </div>
                    </div>
                  </div>
                  <div className={classes.intro_tip}>
                    Чтобы сделать всё это, необходимо присоединиться к Порталу.
                    <br />
                    Cервисы портала бесплатны. Узнайте больше в статьях и
                    ответах на вопросы
                  </div>
                  <div className={classes.join_btn}>
                    <Link to={"/profile/signup"}>
                      <Button type={"primary"}>Присоединяйтесь к нам</Button>
                    </Link>
                  </div>
                </div>
                <div className={classes.services}>
                  <div className={classes.service_snippet}>
                    <div className={classes.snippet_icon}>
                      <img src={"/images/measurement.svg"} alt={"hospital"} />
                    </div>
                    <div
                      className={classes.snippet_body}
                      onClick={this.onPassTestClick.bind(this)}
                    >
                      <h4 className={classes.snippet_title}>
                        Измерить свое здоровье
                      </h4>
                      <div className={classes.snippet_description}>
                        Заполните опросник, чтобы понять свои потребности в
                        углублённом обследовании и консультации специалиста
                      </div>
                      <Button type={"primary"} extraClass={"btn_snippet"}>
                        Пройти тест
                      </Button>
                    </div>
                  </div>
                  <div className={classes.service_snippet}>
                    <div className={classes.snippet_icon}>
                      <img src={"/images/note.svg"} alt={"node"} />
                    </div>
                    <div
                      className={classes.snippet_body}
                      onClick={this.onApplyRequestClick.bind(this)}
                    >
                      <h4 className={classes.snippet_title}>
                        Если вы знаете чего хотите
                      </h4>
                      <div className={classes.snippet_description}>
                        Заполните заявку, мы постараемся помочь или подсказать
                        наилучшее решение
                      </div>
                      <Button type={"primary"} extraClass={"btn_snippet"}>
                        Подать заявку
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.doctors}>
                <h3 className={classes.doctors_title}>Выберите специалиста</h3>
                {firstSpecializations ? (
                  <div className={classes.doctors_list}>
                    {firstSpecializations.map((spec) => {
                      return <DoctorCard specialization={spec} />;
                    })}
                  </div>
                ) : null}
                <Link to={"/our_specialists/"} className={classes.more}>
                  Узнать больше
                  <img src={"/images/more-arrow.svg"} alt={"more"} />
                </Link>
              </div>
            </div>
          </section>
          <section className={classes.section}>
            <div className={classes.articles}>
              <h3 className={classes.articles_title}>
                Что нужно знать каждому об управлении своим здоровьем
              </h3>
              {this.props.articlesList ? (
                <div className={classes.articles_list}>
                  {this.props.articlesList.map((article) => {
                    return (
                      <Article
                        key={article.id}
                        type={"small"}
                        linkTo={"/articles/" + article.id}
                        title={article.title}
                        date={article.created_at}
                        text={article.body}
                        imageUrl={article.image_url}
                      />
                    );
                  })}
                </div>
              ) : null}
              <Link
                to="/articles"
                className={classes.more}
                style={{ paddingRight: "30px" }}
              >
                Больше статей
                <img src={"/images/more-arrow.svg"} alt={"more"} />
              </Link>
            </div>
          </section>
          <section id={"join-us"} className={classes.section}>
            <div className={classes.benefits_container}>
              <div className={classes.capabilities}>
                <h3 className={classes.capabilities_title}>
                  Присоединяйтесь к экосистеме
                </h3>
                {/* <div className={classes.capabilities_subtitle}>Начните заботиться о своем здоровье вместе с нами
                            </div> */}
                <div className={classes.capabilities_list}>
                  <div className={classes.capabilities_item}>
                    <div className={classes.capabilities_number}>
                      <img src={"/images/round.svg"} alt={"round"} />
                      <span>1</span>
                    </div>
                    <div className={classes.capabilities_text}>
                      Если Вам нужно восстановить, сохранить или улучшить своё
                      здоровье
                    </div>
                  </div>
                  <div className={classes.capabilities_item}>
                    <div className={classes.capabilities_number}>
                      <img src={"/images/round.svg"} alt={"round"} />
                      <span>2</span>
                    </div>
                    <div className={classes.capabilities_text}>
                      Если Вы умеете помогать другим в сохранении здоровья
                    </div>
                  </div>
                  <div className={classes.capabilities_item}>
                    <div className={classes.capabilities_number}>
                      <img src={"/images/round.svg"} alt={"round"} />
                      <span>3</span>
                    </div>
                    <div className={classes.capabilities_text}>
                      Если Вы хотите зарабатывать, помогая другим сохранять своё
                      здоровье
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.authorization}>
                <div className={classes.auth_btn}>
                  <Link to={"/profile/signup"}>
                    <Button type={"primary"}>Зарегистрироваться</Button>
                  </Link>
                </div>
                <hr className={classes.hr} />
                <div className={classes.login}>
                  У вас уже есть аккаунт?&nbsp;
                  <Link to={"/profile/login"}>Войдите</Link>
                </div>
              </div>
              <div className={classes.faq}>
                <h3 className={classes.faq_title}>Остались вопросы?</h3>
                {this.props.faqList ? (
                  <div className={classes.faq_list}>
                    {this.props.faqList.map((question, index) => {
                      return (
                        <FaqCard
                          key={index}
                          linkTo={"/faq"}
                          question={question.question}
                        />
                      );
                    })}
                  </div>
                ) : null}

                <Link to={"/faq"} className={classes.more}>
                  Больше ответов
                  <img src={"/images/more-arrow.svg"} alt={"more"} />
                </Link>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: !!state.auth.token,
    specializations: state.profile.specializations,
    articlesList: state.articles.articlesList,
    faqList: state.faq.faqList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSpecializations: () => dispatch(getSpecializations()),
    getArticlesList: (page, size) => dispatch(getArticlesList(page, size)),
    getFaqList: (page, size) => dispatch(getFaqList(page, size)),
    addRedirectLink: (link) => dispatch(addRedirectLink(link)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
