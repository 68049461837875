import axios from "axios";
import {
  AUTH_LOGOUT,
  LOGIN_SUCCESS,
  FIRST_REG,
  CONFIRM_SUCCESS,
  CONFIRM_ERROR,
  CONFIRM_CLEAR,
  LOGIN_ERROR,
  REG_ERROR,
  SEND_RECOVERY_SUCCESS,
  SEND_RECOVERY_ERROR,
  RECOVER_PASSWORD_SUCCESS,
  RECOVER_PASSWORD_ERROR,
  CLEAR_RECOVERY_STATUS,
  CLEAR_NEW_PASSWORD_STATUS,
  CLEAR_REG_STATUS,
  ACCEPT_PATIENT_INVITATION_SUCCESS,
  ACCEPT_PATIENT_INVITATION_ERROR,
  ADD_REDIRECT_LINK,
} from "./actionTypes";

export function reg(email, password, token) {
  return async (dispatch) => {
    const authData = {
      email,
      password,
      token,
    };

    axios
      .post("/users/", authData)
      .then((response) => {
        dispatch(firstReg(response.data));
      })
      .catch((error) => {
        dispatch(regError(error.response));
        console.log(error);
      });
  };
}

export function confirm(token) {
  return async (dispatch) => {
    axios
      .get(`/users/confirm_email?token=${token}`)
      .then((response) => {
        if (response.data.status === "error") {
          dispatch(confirmError(response.data.email));
        } else {
          dispatch(confirmSuccess());
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
}

export function resend(email) {
  return async (dispatch) => {
    const postData = {
      email,
    };

    axios
      .post("/users/resend_confirmation", postData)
      .then((response) => {
        console.log(response);
        dispatch(confirmClear());
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.status === "error") {
          dispatch(confirmError(err.response.data.email));
        } else {
        }
      });
  };
}

export function login(email, password) {
  return async (dispatch) => {
    const authData = {
      email,
      password,
    };

    axios
      .post("/auth/login", authData)
      .then((response) => {
        const data = response.data;

        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("role", data.role);
        localStorage.setItem("id", data.id);

        const logoutTimeInSec = 259200; // 3days

        const expirationDate = new Date(
          new Date().getTime() + logoutTimeInSec * 1000
        );
        localStorage.setItem("expirationDate", expirationDate);

        dispatch(loginSuccess(data.access_token, data.role));
        dispatch(autoLogout(logoutTimeInSec));
      })
      .catch((error) => {
        dispatch(loginError(error.response));
        console.log(error.response);
      });
  };
}

export function sendRecovery(email) {
  return async (dispatch) => {
    const postData = {
      email,
    };

    axios
      .post("/users/request_password_recovery", postData)
      .then(() => {
        dispatch(sendRecoverySuccess());
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(sendRecoveryError());
      });
  };
}

export function createNewPassword(data) {
  return async (dispatch) => {
    axios
      .post("/users/recover_password", data)
      .then(() => {
        dispatch(recoverPasswordSuccess());
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(recoverPasswordError());
      });
  };
}

export function acceptPatientInvitation(profileData) {
  return async (dispatch) => {
    axios
      .post("/users/accept_patient_invite", profileData)
      .then((response) => {
        dispatch(acceptPatientInvitationSuccess());
      })
      .catch((error) => {
        dispatch(acceptPatientInvitationError(error.response));
        console.log(error);
      });
  };
}

export function addRedirectLink(link) {
  return {
    type: ADD_REDIRECT_LINK,
    link,
  };
}

export function firstReg(data) {
  return {
    type: FIRST_REG,
    id: data.id,
    email: data.email,
    is_active: data.is_active,
    role: data.role,
    registered_at: new Date(data.registered_at),
  };
}

export function regError(error) {
  return {
    type: REG_ERROR,
    error,
  };
}

export function confirmSuccess() {
  return {
    type: CONFIRM_SUCCESS,
  };
}

export function confirmError(email) {
  return {
    type: CONFIRM_ERROR,
    email,
  };
}

export function confirmClear() {
  return {
    type: CONFIRM_CLEAR,
  };
}

export function autoLogout(time) {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, time * 1000);
  };
}

export function logout() {
  localStorage.removeItem("id");
  localStorage.removeItem("access_token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("role");
  return {
    type: AUTH_LOGOUT,
  };
}

export function autoLogin() {
  return (dispatch) => {
    const token = localStorage.getItem("access_token");
    const role = localStorage.getItem("role");
    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(loginSuccess(token, role));
        dispatch(
          autoLogout((expirationDate.getTime() - new Date().getTime()) / 1000)
        );
      }
    }
  };
}

export function loginSuccess(token, role) {
  return {
    type: LOGIN_SUCCESS,
    token,
    role,
  };
}

export function loginError(error) {
  return {
    type: LOGIN_ERROR,
    error,
  };
}

export function sendRecoverySuccess() {
  return {
    type: SEND_RECOVERY_SUCCESS,
  };
}

export function sendRecoveryError() {
  return {
    type: SEND_RECOVERY_ERROR,
  };
}
export function recoverPasswordSuccess() {
  return {
    type: RECOVER_PASSWORD_SUCCESS,
  };
}

export function recoverPasswordError() {
  return {
    type: RECOVER_PASSWORD_ERROR,
  };
}
export function clearRecoveryStatus() {
  return {
    type: CLEAR_RECOVERY_STATUS,
  };
}
export function clearNewPasswordStatus() {
  return {
    type: CLEAR_NEW_PASSWORD_STATUS,
  };
}

export function clearRegStatus() {
  return {
    type: CLEAR_REG_STATUS,
  };
}

export function acceptPatientInvitationSuccess() {
  return {
    type: ACCEPT_PATIENT_INVITATION_SUCCESS,
    acceptPatientInvitationStatus: "SUCCESS",
  };
}

export function acceptPatientInvitationError(error) {
  return {
    type: ACCEPT_PATIENT_INVITATION_ERROR,
    acceptPatientInvitationStatus: "ERROR",
    error,
  };
}
