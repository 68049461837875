import {
  REMOVE_FILE,
  ADD_DOC_FILE_SUCCESS,
  ADD_DOC_FILE_ERROR,
  UPLOAD_DOC_SUCCESS,
  UPLOAD_DOC_ERROR,
} from "./actionTypes";

import {
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_ERROR
} from "../Medcard/actionTypes";

const initialState = {
  attachment: [],
  addDocumentStatus: "",
};

export default function medcardReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_DOC_FILE_SUCCESS:
      return {
        ...state,
        attachment: [action.url],
      };
    case REMOVE_FILE:
      return {
        ...state,
        attachment: [],
      };
    case ADD_DOC_FILE_ERROR:
      return {
        ...state,
        attachment: [],
      };
    case UPLOAD_DOC_SUCCESS:
      return {
        ...state,
        attachment: [],
        addDocumentStatus: "SUCCESS",
      };
    case UPLOAD_DOC_ERROR:
      return {
        ...state,
        attachment: state.attachment.filter((item, i) => i !== action.index),
        addDocumentStatus: "ERROR",
      };
    case
      GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        addDocumentStatus: "",
      };
    case
      GET_DOCUMENTS_ERROR:
      return {
        ...state,
        addDocumentStatus: "",
      };
    default:
      return state;
  }
}
