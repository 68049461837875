import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

import CardStatus from "components/Profile/CardStatus/CardStatus";
import ServicesLog from "components/Profile/Reports/ServicesLog/ServicesLog";
import UsersLog from "components/Profile/Reports/UsersLog/UsersLog";
import ConsultationsLog from "components/Profile/Reports/ConsultationsLog/ConsultationsLog";
import DownloadSvg from "./DownloadSvg";

import classes from "./Reports.module.scss";

let logs = {
  services: "Оказанные услуги",
};

let assistantsLogs = {
  services: "Оказанные услуги",
  users: "Зарегистрированные пользователи",
};

let providersLogs = {
  services: "Оказанные услуги",
  users: "Зарегистрированные пользователи",
  consultations: "Статусы заявок",
};

const Reports = () => {
  const {
    profile: { role },
    usersLog: { file: usersLogFile },
    servicesLog: { file: servicesLogFile },
    consultationsLog: { file: consultationsLogFile },
  } = useSelector(({ profile, invites, services, consultationsLog }) => ({
    profile,
    usersLog: invites,
    servicesLog: services,
    consultationsLog,
  }));
  const [activeLog, setActiveLog] = React.useState("services");

  const changeLogHandler = (type) => {
    setActiveLog(type);
  };

  const getLogsText = () => {
    if (role === "assistant") {
      return assistantsLogs;
    } else if (role === "provider") {
      return providersLogs;
    } else {
      return logs;
    }
  };

  const downloadExcelHandle = () => {
    let logFile;
    if (activeLog === "services") {
      logFile = servicesLogFile;
    } else {
      logFile = usersLogFile;
    }

    const blob = new Blob([logFile], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, "file.xlsx");
    } else {
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", "file.xlsx");
      document.body.appendChild(link);
      link.download = "";
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <section className={classes.Reports}>
      <Helmet>
        <title>Отчеты. Портал здоровья</title>
        <meta name="title" content="Отчеты. Портал здоровья" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content="Отчеты. Портал здоровья" />
      </Helmet>
      <div className={classes.header}>
        <div className={classes.tabs}>
          <CardStatus
            statuses={getLogsText()}
            activeStatus={activeLog}
            onChange={changeLogHandler}
          />
        </div>
        <div className={classes.btn_wrapper}>
          <button
            onClick={downloadExcelHandle}
            className={classes.btn}
            disabled={
              !usersLogFile || !servicesLogFile || !consultationsLogFile
            }
          >
            <DownloadSvg />
            <span className={classes.btn_text}>Скачать Excel</span>
          </button>
        </div>
      </div>

      {activeLog === "services" ? <ServicesLog /> : null}
      {activeLog === "users" ? <UsersLog /> : null}
      {activeLog === "consultations" ? <ConsultationsLog /> : null}
    </section>
  );
};

export default Reports;
