import React from "react";

import { formatExperienceYears } from "../../../components/UI/helpers";

import classes from "./DoctorItem.module.scss";

const DoctorItem = (props) => {
  const cls = [classes.DoctorItem];
  if (props.active) {
    cls.push(classes.active);
  }

  return (
    <div className={cls.join(" ")} onClick={() => props.onClick(props.id)}>
      <div className={classes.info}>
        <div className={classes.name}>
          <strong>
            {props.secondName} {props.firstName} {props.patronymic || ""}
          </strong>
        </div>
        {props.experience && (
          <div className={classes.experience}>
            Стаж&nbsp;{formatExperienceYears(props.experience)}&nbsp;
          </div>
        )}
      </div>
      <div
        className={classes.photo}
        style={{ backgroundImage: `url("${props.imageUrl}")` }}
      />
    </div>
  );
};

export default DoctorItem;
