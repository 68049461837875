import React from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";

import HealthStatusIcon from "components/UI/HealthStatus/HealthStatusIcon/HealthStatusIcon";
import HealthStatusModal from "components/UI/HealthStatus/HealthStatusModal/HealthStatusModal";

import {
  getHealthStatus,
  getMyAccountInfo,
} from "store/Profile/Account/actions";
import { getDoctorMy } from "store/Profile/Doctors/actions";

import classes from "./Header.module.scss";

const roleEnum = {
  patient: "Пациент",
  doctor: "Специалист",
  assistant: "Ассистент",
  support: "Поддержка",
  admin: "Администратор",
  provider: "Провайдер",
};

const Header = (props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  function onMenuClickHandler() {
    document.getElementById("sidebar").classList.toggle("active-sidebar");
    document.getElementById("body").classList.toggle("no-scroll");
    document.getElementById("profile-header").classList.toggle(classes.active);
  }
  React.useEffect(() => {
    document.getElementById("body").classList.remove("no-scroll");
    document.getElementById("sidebar").classList.remove("active-sidebar");
    document.getElementById("profile-header").classList.remove(classes.active);

    return () => {
      document.getElementById("body").classList.remove("no-scroll");
    };
  }, [window.location.href]);

  React.useEffect(() => {
    if (isModalOpen) {
      document.getElementById("body").classList.add("no-scroll");
    } else {
      document.getElementById("body").classList.remove("no-scroll");
    }
    return () => document.getElementById("body").classList.remove("no-scroll");
  }, [isModalOpen]);

  React.useEffect(() => {
    if (props.role === "doctor") {
      props.getDoctorMy();
    }
    props.getMyAccountInfo();
  }, [props.role]);

  React.useEffect(() => {
    props.getHealthStatus();
  }, []);

  function onAccountClickHandler() {
    document.getElementById("body").classList.remove("no-scroll");
    document.getElementById("sidebar").classList.remove("active-sidebar");
    document.getElementById("profile-header").classList.remove(classes.active);
  }

  const accountIcon = (
    <div
      className={classes.icon}
      style={{
        maskImage: "url(/profile/images/account.svg)",
        WebkitMaskImage: "url(/profile/images/account.svg)",
      }}
    ></div>
  );

  return (
    <header className={classes.Header} id="profile-header">
      <a href={"/"} className={classes.logo}>
        <img src={"/profile/images/header-logo.svg"} alt={"logo"} />
        Портал здоровья
      </a>
      {/* Роль */}
      {props.role ? (
        <div className={classes.role}>{roleEnum[props.role]}</div>
      ) : null}
      {/* Состояние здоровья */}
      {props.health_status && localStorage.getItem("role") === "patient" ? (
        <React.Fragment>
          <HealthStatusIcon
            onClick={() => setIsModalOpen(true)}
            isAnimated={!isModalOpen}
            color={props.health_status.ofn15_code}
          />
          <HealthStatusModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            color={props.health_status.ofn15_code}
          />
        </React.Fragment>
      ) : null}
      <NavLink
        onClick={() => onAccountClickHandler()}
        style={{ marginLeft: "10px" }}
        to={"/profile/account"}
        className={classes.navlink}
        activeClassName={classes.active}
      >
        {accountIcon}
        {props.account ? props.account.user.email : "Профиль"}
      </NavLink>
      <Link to={"/profile/logout"} className={classes.logout}>
        <img src={"/profile/images/logout.svg"} alt={"logout"} />
      </Link>
      <div className={classes.menu_btn} onClick={() => onMenuClickHandler()}>
        <img
          className={classes.burger}
          src={"/profile/images/burger.svg"}
          alt="menu"
        />
        <img
          className={classes.cross}
          src={"/profile/images/cross-menu.svg"}
          alt="close"
        />
      </div>
    </header>
  );
};

function mapStateToProps(state) {
  return {
    health_status: state.account.health_status,
    role: state.profile.role,
    account: state.account.myAccount,
    doctorProfile: state.doctors.editedDoctorProfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getHealthStatus: () => dispatch(getHealthStatus()),
    getMyAccountInfo: () => dispatch(getMyAccountInfo()),
    getDoctorMy: () => dispatch(getDoctorMy()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
