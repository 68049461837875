
export const GET_DOCTORSLIST_ERROR = 'GET_DOCTORSLIST_ERROR';
export const GET_DOCTORSLIST_SUCCESS = 'GET_DOCTORSLIST_SUCCESS';
export const CREATE_DOCTOR_ERROR = 'CREATE_DOCTOR_ERROR';
export const CREATE_DOCTOR_SUCCESS = 'CREATE_DOCTOR_SUCCESS';
export const CLEAR_CREATE_DOCTOR_STATUS = 'CLEAR_CREATE_DOCTOR_STATUS';
export const LOAD_PICTURE_SUCCESS = 'LOAD_PICTURE_SUCCESS';
export const LOAD_PICTURE_ERROR = 'LOAD_PICTURE_ERROR';
export const REMOVE_PICTURE = 'REMOVE_PICTURE';
export const CREATE_DOCTORPROFILE_SUCCESS = 'CREATE_DOCTORPROFILE_SUCCESS';
export const CREATE_DOCTORPROFILE_ERROR = 'CREATE_DOCTORPROFILE_ERROR';
export const UPDATE_DOCTORPROFILE_SUCCESS = 'UPDATE_DOCTORPROFILE_SUCCESS';
export const UPDATE_DOCTORPROFILE_ERROR = 'UPDATE_DOCTORPROFILE_ERROR';
export const GET_DOCTORPROFILE_SUCCESS = 'GET_DOCTORPROFILE_SUCCESS';
export const GET_DOCTORPROFILE_ERROR = 'GET_DOCTORPROFILE_ERROR';
export const LOAD_MORE_DOCTORS_SUCCESS = 'LOAD_MORE_DOCTORS_SUCCESS';
export const GET_PROFILE_DOCTORSLIST_ERROR = 'GET_PROFILE_DOCTORSLIST_ERROR';
export const GET_PROFILE_DOCTORSLIST_SUCCESS = 'GET_PROFILE_DOCTORSLIST_SUCCESS';
export const LOAD_MORE_PROFILE_DOCTORS_SUCCESS = 'LOAD_MORE_PROFILE_DOCTORS_SUCCESS';