import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createStore, compose, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import "./index.scss";
import rootReducer from "./store/reducers/rootReducer";
import App from "./App";
import axios from "axios";
import { CustomScrollbar } from "hoc/CustomScrollbar/CustomScrollbar";

export let baseURL;

if (process.env.REACT_APP_BACKEND_DOMAIN) {
  baseURL = "https://" + process.env.REACT_APP_BACKEND_DOMAIN;
} else {
  baseURL = "https://pp.appvelox.ru";
}

axios.defaults.baseURL = baseURL + "/api/v1";
axios.defaults.withCredentials = true;

// вставляет заголовки с токеном в каждый запрос
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("access_token");
  const tokenType = "Bearer";
  config.headers.Authorization = tokenType + " " + token;

  return config;
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <CustomScrollbar style={{ width: "100%", height: "100vh" }}>
        <App />
      </CustomScrollbar>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
